import React, { useState, useEffect } from "react";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import { styles } from "./styles";

import ArgoLogo from "@/components/ArgoLogo";
import "./background.css";

interface ILoginLayout {
  children?: React.ReactNode;
  action?: () => void;
}
function LoginLayout({ children, action }: ILoginLayout) {
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    setHeight(window.innerHeight);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box className="layout-login" sx={{ ...styles.mainContainer, height }}>
      <Paper elevation={12} sx={styles.paper}>
        <Box sx={styles.scrollContainer}>
          <IconButton color="secondary" size="small" sx={styles.goBackButton} onClick={action}>
            <ArrowBack />
          </IconButton>
          <ArgoLogo color="primary" sx={styles.argoLogo} onClick={() => navigate("/")} />
          {children}
        </Box>
      </Paper>
      <Box sx={styles.textContainer}>
        <Typography gutterBottom color="white" component="h1" variant="h2">
          Navigate, Explore, Connect.
        </Typography>
        <Typography color="white" variant="h6">
          Argo is a easy-to-use navigation and social boating app that helps you find and route to
          new destinations, share local knowledge, and connect with other boaters.
        </Typography>
      </Box>
    </Box>
  );
}

export default LoginLayout;
