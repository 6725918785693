/* global google */

import { useState } from "react";
import { Box } from "@mui/material";

import AdvancedMarker from "../AdvancedMarker";

import { styles } from "./styles";

import { Position, PlannedCoordKind } from "@/types/map";
import {
  IInterimPoint,
  setEditingWaypoint,
  EditWaypointStatus,
  addInterimPoint,
  addRouteCreated,
} from "@/store/slices/route";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { filterPlannedCoords } from "@/utils/waypoints";

interface IWaypointMarker {
  id: number;
  course: Position;
  position: number;
  waypoints: IInterimPoint[];
  onDrag: (event: google.maps.MapMouseEvent) => void;
  onHandleRemoveWaypointEditLine: () => void;
  onUpdateLocalWaypoint?: (waypoint: { lat: number; lng: number }, course: Position) => void;
}

function WaypointIcon(n: number, waypoint: IInterimPoint, isDraggin: boolean) {
  if (waypoint && waypoint?.focused)
    return <Box sx={{ ...styles.iconSelected, ...(isDraggin ? styles.iconDragged : {}) }}>{n}</Box>;

  return <Box sx={styles.icon}>{n}</Box>;
}

function WaypointMarker({
  id,
  course,
  position,
  waypoints,
  onDrag,
  onHandleRemoveWaypointEditLine,
  onUpdateLocalWaypoint,
}: IWaypointMarker) {
  const [isDraggin, setIsDraggin] = useState(false);
  const { routeCreated } = useAppSelector((state) => state.route);
  const dispatch = useAppDispatch();
  const hasNextWaypoint = (waypoints: IInterimPoint[], index: number): boolean => {
    const filteredWaypoints = waypoints.filter((point) => point.lat && point.lng);

    return (
      index < filteredWaypoints.length - 1 &&
      filteredWaypoints[index + 1]?.lat !== undefined &&
      filteredWaypoints[index + 1]?.lng !== undefined
    );
  };

  const handleDragStart = () => {
    const endPoint = routeCreated?.waypoints.find(
      (point) => point.kind === PlannedCoordKind.END_POINT && point.latitude && point.longitude,
    );

    const hasNext = hasNextWaypoint(
      endPoint
        ? [
            ...waypoints,
            {
              id: `Item ${waypoints.length + 1}`,
              value: `${endPoint?.latitude}, ${endPoint?.longitude}`,
              focused: true,
              lat: endPoint?.latitude,
              lng: endPoint?.longitude,
              position: waypoints.length + 1,
            },
          ]
        : waypoints,
      position,
    )
      ? EditWaypointStatus.MULTIPLE
      : EditWaypointStatus.SIMPLE;

    dispatch(setEditingWaypoint(hasNext));
    const pointSelected = waypoints.find((point) => point.focused && point.lat && point.lng);

    const filteredWaypoints = filterPlannedCoords(pointSelected, routeCreated?.planned_coords);

    dispatch(addRouteCreated({ ...routeCreated, filteredWaypoints }));
  };

  const handleDragEnd = (event: google.maps.MapMouseEvent) => {
    if (!event.latLng) return;

    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    const updatedInterimPoints = waypoints.map((point: IInterimPoint) =>
      point.focused
        ? {
            ...point,
            value: `${lat.toFixed(6)}, ${lng.toFixed(6)}`,
            lat: lat.toFixed(6),
            lng: lng.toFixed(6),
          }
        : point,
    );

    setIsDraggin(false);
    onHandleRemoveWaypointEditLine();
    dispatch(addInterimPoint(updatedInterimPoints));
    if (onUpdateLocalWaypoint) onUpdateLocalWaypoint({ lat, lng }, course);
  };

  const handleSetFocus = (position: number) => {
    const updatedInterimPoints = waypoints.map((point, index) =>
      index === position ? { ...point, focused: true } : { ...point, focused: false },
    );

    dispatch(addInterimPoint(updatedInterimPoints));
  };

  const handleDrag = (event: google.maps.MapMouseEvent) => {
    onDrag(event);
    setIsDraggin(true);
  };

  return (
    <AdvancedMarker
      key={`waypoint-${id}`}
      draggable={waypoints[position]?.focused}
      icon={WaypointIcon(position + 1, waypoints[position], isDraggin)}
      position={{ lat: Number(course.latitude), lng: Number(course.longitude) } as any}
      selected={false}
      onClick={() => handleSetFocus(position)}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    />
  );
}

export default WaypointMarker;
