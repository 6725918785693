import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  paper: {
    ...layoutStyles.scroll,

    display: "flex",
    height: "100%",
    width: 80,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    zIndex: 9,
    position: "fixed",
    top: 0,
    left: 0,
    borderRadius: 0,

    boxShadow: `inset -1px 0 0 0 ${alpha(defaultTheme.palette.common.black, 0.12)}`,
    pt: 2,
    overflowX: "hidden",
    overflowY: "auto",
  },

  chip: {
    color: "primary.main",
    border: "1px solid",
    borderColor: `${alpha(defaultTheme.palette.primary.light, 0.12)}`,
    backgroundColor: `${alpha(defaultTheme.palette.primary.light, 0.08)}`,

    "& .MuiChip-label": {
      textTransform: "uppercase",
      letterSpacing: "1px",
      fontSize: "11px",
      fontWeight: "700",
    },
  },
};
