import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";

import AdvancedMarker from "../AdvancedMarker";

import { useAppSelector } from "@/hooks/useRedux";
import StartIndicatorIcon from "@/assets/img/markers/location/StartIndicator.png";
import EndIndicator from "@/assets/img/markers/location/EndIndicator.png";

function RouteMarkers() {
  const pathLocation = useLocation();

  const { selectedTrip } = useAppSelector((state) => state.captainslog);

  if (
    pathLocation.pathname === "/captains-log" &&
    selectedTrip?.planned_coords &&
    selectedTrip?.planned_coords.length > 0
  ) {
    return (
      <Box>
        {selectedTrip?.planned_coords.map((course: any) => (
          <>
            {course.kind === "start_point" && (
              <AdvancedMarker
                key={`start-indicator-${course.id}`}
                icon={StartIndicatorIcon}
                position={{ lat: Number(course.latitude), lng: Number(course.longitude) } as any}
                selected={false}
                onClick={() => null}
              />
            )}
            {course.kind === "end_point" && (
              <AdvancedMarker
                key={`end-indicator-${course.id}`}
                icon={EndIndicator}
                position={{ lat: Number(course.latitude), lng: Number(course.longitude) } as any}
                selected={false}
                onClick={() => null}
              />
            )}
          </>
        ))}
      </Box>
    );
  }

  return null;
}

export default RouteMarkers;
