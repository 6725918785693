import { createSlice } from "@reduxjs/toolkit";

import { Position, TpoisConfigured, IDepthShading, RoutePosition } from "@/types/map";
import { IMapPois } from "@/types/pois";
import { DEFAULT_ZOOM_INIT } from "@/utils/maps";

export interface IMap {
  center: Position;
  currentPosition: Position | undefined;
  currentPositionSetted: boolean;
  zoom: number;
  mapType: string;
  coords: RoutePosition | undefined;
  depthLayerOpacity: number;
  depthLayer: boolean;
  openMapOptions: boolean;
  poisConfigured: TpoisConfigured;
  mapPois: IMapPois[] | null;
  customsShadings: IDepthShading | undefined;
  isLongPress: boolean;
}

const DEFAULT_CENTER = {
  lat: 37.752605,
  lng: -100.017282,
};

const initialState: IMap = {
  center: DEFAULT_CENTER,
  currentPosition: undefined,
  currentPositionSetted: false,
  zoom: DEFAULT_ZOOM_INIT,
  mapPois: null,
  mapType: "roadmap",
  coords: undefined,
  depthLayerOpacity: 150,
  customsShadings: undefined,
  depthLayer: true,
  openMapOptions: false,
  isLongPress: false,
  poisConfigured: {
    ArtificialReef: true,
    "Club/Association": true,
    "Boat Club": true,
    Marina: true,
    "Yacht Club": true,
    "Restaurant/Bar": true,
    Bridge: true,
    Business: true,
    BoatRamp: true,
    CityTown: true,
    Hotel: true,
    Inlet: true,
    Other: true,
    Anchorage: true,
    Lock: true,
    Dam: true,
    Beach: true,
    "Dock Bar/Restaurant": true,
    "Free Dock": true,
    "Fishing Spot": true,
    "Light House": true,
    Mooring: true,
    "Point of Interest": true,
    "Private Dock": true,
    Buoy: true,
    "Restricted area": true,
    noaaLayer: false,
    no_wake_zone: true,
    map_issue: true,
    local_advice: true,
    sos: true,
    police: true,
    weather: true,
    hazard: true,
    marine_life: true,
    rock: true,
    obstruction: true,
    exposed_wreck: true,
    dangerous_wreck: true,
    non_dangerous_wreck: true,
    tide: true,
    current: true,
  },
};

const mapSlice = createSlice({
  name: "mapSlice",
  initialState,
  reducers: {
    addCenter: (state, action) => {
      state.center = action.payload;
    },
    addZoom: (state, action) => {
      state.zoom = action.payload;
    },
    addCurrentPosition: (state, action) => {
      state.currentPosition = action.payload;
    },
    addTypeOfMap: (state, action) => {
      state.mapType = action.payload;
    },
    addPoisConfiguration: (state, action) => {
      state.poisConfigured = action.payload;
    },
    addMapPois: (state, action) => {
      state.mapPois = action.payload;
    },
    addDepthLayerOpacity: (state, action) => {
      state.depthLayerOpacity = action.payload;
    },
    addCustomsShadings: (state, action) => {
      state.customsShadings = action.payload;
    },
    addDepthLayer: (state, action) => {
      state.depthLayer = action.payload;
    },
    addOpenMapOptions: (state, action) => {
      state.openMapOptions = action.payload;
    },
    setCurrentPositionSetted: (state, action) => {
      state.currentPositionSetted = action.payload;
    },
    setCoords: (state, action) => {
      state.coords = action.payload;
    },
    setIsLongPress: (state, action) => {
      state.isLongPress = action.payload;
    },
  },
});

export const {
  addCenter,
  addZoom,
  addCurrentPosition,
  addTypeOfMap,
  addPoisConfiguration,
  addMapPois,
  addDepthLayerOpacity,
  addDepthLayer,
  addCustomsShadings,
  addOpenMapOptions,
  setCurrentPositionSetted,
  setCoords,
  setIsLongPress,
} = mapSlice.actions;

export default mapSlice.reducer;
