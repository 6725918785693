import defaultTheme from "@/themes/theme";

export const styles = {
  dialogHeader: {
    display: "flex",
    height: "180px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    backgroundImage: `url(${"img/premium-bg.png"})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    p: 6,
  },

  formContainer: {
    p: 5,
    [defaultTheme.breakpoints.down("md")]: { p: 0 },
  },

  dialogHeaderIcon: {
    color: "common.white",
    textAlign: "center",
    fontSize: 52,
    mb: 1,
    mt: 1,
  },

  dialogHeaderTitle: {
    color: "common.white",
    textAlign: "center",
    px: 4,
  },
};
