import {
  Stack,
  Box,
  Divider,
  IconButton,
  Typography,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Rating,
} from "@mui/material";
import {
  DeleteOutline,
  EditOutlined,
  MoreVert,
  Place,
  TripOriginOutlined,
  Lock,
  CloseOutlined,
} from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { useUnitSystem, LengthUnits } from "@argonav/unit-system";

import PlaceInfoIconType from "../PlaceInfoIconType";
import { styles } from "../styles";
import { KEY_ADMIN_PRIVILEGE } from "../PlaceInfo";
import FavoriteCheckbox from "../../FavoriteCheckbox";

import usePremiumUser from "@/hooks/usePremiumUser";
import dayjs from "@/config/dayjs";
import { Props } from "@/types/users";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setTempPin } from "@/store/slices/reportpins";
import { addSelectedPoi } from "@/store/slices/search";
import { IMapPois } from "@/types/pois";
import { getDistanceFromLocation } from "@/utils/poi";

const KEY_PLACE_MARINA = "Marina";
const KEY_PLACE_BRIDGE = "Bridge";
const KEY_PLACE_LOCK = "Lock";

function PlaceInfoHeader({
  isDrawer,
  poiSelected,
  open,
  anchorEl,
  position,
  checkedFavorite,
  onClick,
  onClose,
  onOpen,
  onSetFavorite,
  onRemoveFavorite,
  onCreateOpenRoute,
  close,
}: Props) {
  const dispatch = useAppDispatch();
  const { user, logged } = useAppSelector((state) => state.auth);
  const { userInformation } = useAppSelector((state) => state.user);
  const { distanceUnitHandler, coordinatesFormatHandler } = useUnitSystem();
  const isPremium = usePremiumUser();

  const isSubmit = poiSelected?.submit !== undefined;

  const previous: dayjs.Dayjs = dayjs(poiSelected.created_at);

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(poiSelected?.lat),
    longitude: Number(poiSelected?.lng),
  });

  const distanceAway = distanceUnitHandler({
    originalUnit: LengthUnits.METERS,
    originalValue: Number(
      getDistanceFromLocation(position || { lat: 0, lng: 0 }, {
        lat: poiSelected?.lat,
        lng: poiSelected?.lng,
      }),
    ),
  });

  const handleEditPin = (poiSelected: IMapPois | undefined) => {
    if (poiSelected) {
      dispatch(addSelectedPoi(null));
      dispatch(
        setTempPin({
          value: poiSelected?.place_type,
          location: { lat: poiSelected?.lat, lng: poiSelected?.lng },
          editPinInfo: poiSelected,
        }),
      );
    }
  };

  const handleOpenEditPoi = () => {
    dispatch(addSelectedPoi({ ...poiSelected, editMode: true }));
  };

  const selectedToEdit =
    poiSelected?.poi_type === KEY_PLACE_MARINA ||
    poiSelected?.poi_type === KEY_PLACE_BRIDGE ||
    poiSelected?.poi_type === KEY_PLACE_LOCK;

  return (
    <Box>
      {!isDrawer && (
        <Button
          aria-label="delete"
          color="whiteBg"
          size="small"
          sx={styles.closeButton}
          variant="iconButtonContained"
          onClick={close}
        >
          <CloseOutlined />
        </Button>
      )}

      <Stack pb={2} pl={2} pr={2} pt={!isDrawer ? 1 : 2} spacing={1}>
        <Stack alignItems="flex-start" className="titleContainer" spacing={0.5}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            <PlaceInfoIconType poiType={poiSelected?.poi_type || poiSelected?.place_type} />
            {poiSelected?.user_id === userInformation?.id && logged && (
              <Box>
                <IconButton
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  aria-label="more"
                  id="long-button"
                  sx={{ mr: "14px" }}
                  onClick={onClick}
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  id="long-menu"
                  open={open}
                  sx={styles.menuVertContainer}
                  onClose={onClose}
                >
                  <MenuItem onClick={() => handleEditPin(poiSelected)}>
                    <ListItemIcon>
                      <EditOutlined fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={onOpen}>
                    <ListItemIcon>
                      <DeleteOutline fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            )}
            {user?.privilege === KEY_ADMIN_PRIVILEGE && selectedToEdit && (
              <Box>
                {isDrawer ? (
                  <Button color="secondary" startIcon={<EditIcon />} onClick={handleOpenEditPoi}>
                    Edit Info
                  </Button>
                ) : (
                  <IconButton
                    sx={{ mr: 3, width: 28, height: 28, mt: 0.7 }}
                    onClick={handleOpenEditPoi}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            )}
          </Stack>
          <Typography component="h2" sx={styles.poiTitle} variant="h5">
            {poiSelected?.name}
          </Typography>
        </Stack>
        <Stack>
          {poiSelected?.poi_ratings_count ? (
            <Stack alignItems="center" direction="row" mb={0.5} spacing={1}>
              <Rating
                readOnly
                name="rating-place"
                precision={0.5}
                value={poiSelected?.poi_ratings_overall}
              />
              <Typography
                component="h3"
                variant="body1"
              >{`${poiSelected?.poi_ratings_count} reviews`}</Typography>
            </Stack>
          ) : (
            ""
          )}
          {poiSelected?.poi_type !== "Private Dock" && (
            <Typography component="h3" variant="subtitle2">{`${latLngFormatted}`}</Typography>
          )}

          <Stack alignItems="center" direction="row" spacing={1}>
            {isSubmit && (
              <Typography color="text.secondary" component="h3" variant="body2">
                {dayjs(previous).fromNow()}
              </Typography>
            )}
            {((isSubmit && position) ||
              (isSubmit && poiSelected?.submit) ||
              (isSubmit && !poiSelected?.submit)) && (
              <Typography color="text.secondary" component="h3" variant="body2">
                •
              </Typography>
            )}
            {position && (
              <Typography color="text.secondary" component="h3" variant="body2">
                {`${distanceAway.value} ${distanceAway.unit} away  `}
              </Typography>
            )}
            {isSubmit && poiSelected?.submit && (
              <>
                <Typography color="text.secondary" component="h3" variant="body2">
                  •
                </Typography>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <AccessTimeFilledIcon sx={{ width: 16, color: "text.secondary" }} />{" "}
                  <Typography color="text.secondary" component="h3" variant="body2">
                    Under Review
                  </Typography>
                </Stack>
              </>
            )}
            {isSubmit && !poiSelected?.submit && (
              <>
                <Typography color="text.secondary" component="h3" variant="body2">
                  •
                </Typography>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <VisibilityOffIcon sx={{ width: 16, color: "text.secondary" }} />{" "}
                  <Typography color="text.secondary" component="h3" variant="body2">
                    Private
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      {(poiSelected?.description ||
        poiSelected?.number_of_slips ||
        poiSelected?.clean_marina ||
        poiSelected?.assets?.[0]?.url ||
        poiSelected?.assets?.[0]?.download_url) && <Divider />}
      <Stack alignItems="center" direction="row" justifyContent="space-between" p={2}>
        <Stack direction="row" spacing={1}>
          <Button
            className="buttonFrom"
            disabled={!isPremium}
            endIcon={isPremium ? <TripOriginOutlined /> : <Lock />}
            size="medium"
            sx={styles.routeButton}
            variant="outlined"
            onClick={() => onCreateOpenRoute(poiSelected, 1)}
          >
            Route from
          </Button>
          <Button
            className="buttonTo"
            disabled={!isPremium}
            endIcon={isPremium ? <Place /> : <Lock />}
            size="medium"
            sx={styles.routeButton}
            variant="outlined"
            onClick={() => onCreateOpenRoute(poiSelected, 2)}
          >
            Route to
          </Button>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={1}>
          <FavoriteCheckbox
            checked={checkedFavorite}
            disabled={!logged}
            onClick={checkedFavorite ? onRemoveFavorite : onSetFavorite}
          />
        </Stack>
      </Stack>
      <Divider />
    </Box>
  );
}

export default PlaceInfoHeader;
