/* global google */
import React from "react";

import AdvancedMarker from "../AdvancedMarker";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import StartIndicatorIcon from "@/assets/img/markers/location/StartIndicator.png";
import EndIndicator from "@/assets/img/markers/location/EndIndicator.png";
import {
  addInterimPoint,
  setInputsFocus,
  setEditingWaypoint,
  EditWaypointStatus,
  addRouteCreated,
} from "@/store/slices/route";
import { filterPlannedCoords } from "@/utils/waypoints";

interface IRouteMarkers {
  onDrag: (event: google.maps.MapMouseEvent) => void;
  onHandleRemoveWaypointEditLine: () => void;
}

function RouteMarkers({ onDrag, onHandleRemoveWaypointEditLine }: IRouteMarkers) {
  const dispatch = useAppDispatch();
  const { startPoint, endPoint, inputsFocus, waypoints, routeCreated } = useAppSelector(
    (state) => state.route,
  );

  const handleSetFocus = (e: React.MouseEvent<HTMLDivElement> | undefined, type: number) => {
    if (type === 1 || type === 2) {
      const updatedInterimPoints = waypoints.map((point) => ({ ...point, focused: false }));

      dispatch(addInterimPoint(updatedInterimPoints));
    }
    dispatch(setInputsFocus(type));
  };

  const handleDragStart = () => {
    dispatch(setEditingWaypoint(EditWaypointStatus.START));

    const pointSelected = routeCreated?.waypoints.find((point) => point.kind === "start_point");

    const filteredWaypoints = filterPlannedCoords(
      {
        lat: pointSelected ? pointSelected.latitude : 0,
        lng: pointSelected ? pointSelected.longitude : 0,
      } as any,
      routeCreated?.planned_coords,
    );

    dispatch(addRouteCreated({ ...routeCreated, filteredWaypoints }));
  };

  const handleDragStartEndLocation = () => {
    dispatch(setEditingWaypoint(EditWaypointStatus.SIMPLE));

    const pointSelected = routeCreated?.waypoints.find((point) => point.kind === "end_point");

    const filteredWaypoints = filterPlannedCoords(
      {
        lat: pointSelected ? pointSelected.latitude : 0,
        lng: pointSelected ? pointSelected.longitude : 0,
      } as any,
      routeCreated?.planned_coords,
    );

    dispatch(addRouteCreated({ ...routeCreated, filteredWaypoints }));
  };

  const handleDragEnd = () => {
    onHandleRemoveWaypointEditLine();
  };

  return (
    <>
      {startPoint && (
        <AdvancedMarker
          draggable={inputsFocus === 1}
          icon={StartIndicatorIcon}
          position={{ lat: Number(startPoint.lat), lng: Number(startPoint.lng) } as any}
          selected={false}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => handleSetFocus(e, 1)}
          onDrag={onDrag}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
        />
      )}
      {endPoint && (
        <AdvancedMarker
          draggable={inputsFocus === 2}
          icon={EndIndicator}
          position={{ lat: Number(endPoint.lat), lng: Number(endPoint.lng) } as any}
          selected={false}
          onClick={(e: React.MouseEvent<HTMLDivElement>) => handleSetFocus(e, 2)}
          onDrag={onDrag}
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStartEndLocation}
        />
      )}
    </>
  );
}
export default RouteMarkers;
