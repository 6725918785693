/* global google */
import { getPreciseDistance, convertDistance } from "geolib";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ShortLengthUnit } from "@argonav/unit-system";

import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as MarinaIcon } from "&/img/markers/pois/iconFilled/marina_iconFilled.svg";
import { ReactComponent as YachtClubIcon } from "&/img/markers/pois/iconFilled/yachtClub_iconFilled.svg";
import { ReactComponent as RestaurantBarIcon } from "&/img/markers/pois/iconFilled/restaurantBar_iconFilled.svg";
import { ReactComponent as BoatRampIcon } from "&/img/markers/pois/iconFilled/boatRamp_iconFilled.svg";
import { ReactComponent as ClubAssociationIcon } from "&/img/markers/pois/iconFilled/clubAssociation_iconFilled.svg";
import { ReactComponent as AnchorageIcon } from "&/img/markers/pois/iconFilled/anchorage_iconFilled.svg";
import constants from "@/config/constants";
import {
  RoutePosition,
  Position,
  TpoisConfigured,
  TRegionData,
  IDepthShading,
  IDepthShadingData,
} from "@/types/map";

dayjs.extend(duration);

export const DEFAULT_ZOOM = 16;
export const DEFAULT_ZOOM_INIT = 14;
export const CURRENT_POSITION_ZOOM = 14;
export const MAX_ZOOM = 20;
export const MIN_ZOOM = 0;
export const MIN_ZOOM_MAP_LIMIT = 5;
export const USA_CENTER = { lat: 39.8283, lng: -98.5795 };
export const MAP_BOUNDS = {
  latLngBounds: {
    north: 71.357763,
    south: 7.857699,
    west: -176.542968,
    east: -53.613281,
  },
  strictBounds: true,
};

export const resolutions = {
  smallest: {
    width: 1366,
    height: 768,
  },
  middest: {
    width: 1980,
    height: 768,
  },
};

export const mapTypes = [
  {
    id: 1,
    idName: "roadmap",
    alt: "Standard map type",
    image: "img/mapTypes/standard.png",
    name: "Standard",
  },
  {
    id: 2,
    idName: "satellite",
    alt: "Satellite map type",
    image: "img/mapTypes/satellite.png",
    name: "Satellite",
  },
  {
    id: 3,
    idName: "terrain",
    alt: "Terrain map type",
    image: "img/mapTypes/topography.png",
    name: "Terrain",
  },
  {
    id: 4,
    idName: "noaaLayer",
    alt: "NOAA map type",
    image: "img/mapTypes/noaaENC.png",
    name: "NOAA ENC",
  },
];

export const defaultDepthShadingColors = {
  depth_shader_items: [
    {
      color: "51,167,255",
      distance: "0",
    },
    {
      color: "109,192,255",
      distance: "5",
    },
    {
      color: "171,218,255",
      distance: "11",
    },
  ],
};

export const placeItems = [
  {
    id: 1,
    name: "Marina",
    icon: <BaseIcon icon={MarinaIcon} />,
  },
  {
    id: 2,
    name: "Yacht Club",
    icon: <BaseIcon icon={YachtClubIcon} />,
  },
  {
    id: 3,
    name: "Restaurant/Bar",
    icon: <BaseIcon icon={RestaurantBarIcon} />,
  },
  {
    id: 4,
    name: "BoatRamp",
    icon: <BaseIcon icon={BoatRampIcon} />,
  },
  {
    id: 5,
    name: "Club/Association",
    icon: <BaseIcon icon={ClubAssociationIcon} />,
  },
  {
    id: 6,
    name: "Anchorage",
    icon: <BaseIcon icon={AnchorageIcon} />,
  },
];

export const findMapType = (mapType: string) =>
  mapTypes.find((item: any) => item.idName === mapType);

export const defaultMapTypes = {
  idName: "terrain",
  alt: "Terrain map type",
  image: "img/mapTypes/topography.png",
  name: "Terrain",
};

export const findPoiIcon = (poi_type: string) => {
  const result = placeItems.find((item: any) => item.name === poi_type);

  if (!result) return placeItems[2].icon;

  return result?.icon ?? "";
};

/**
 * Get total voyage distance, sum of courseLocations distances.
 * @param {Object} voyage
 */
export const voyageDistance = (voyage: any, unit = ShortLengthUnit.MtString) => {
  let totalDistance = 0;

  if (voyage?.planned_coords) {
    for (let i = 0; i < voyage.planned_coords.length - 1; i += 1) {
      totalDistance += getPreciseDistance(
        voyage.planned_coords[i],
        voyage.planned_coords[i + 1],
        constants.distancePrecision,
      );
    }

    return convertDistance(totalDistance, unit);
  }

  return convertDistance(totalDistance, unit);
};

/**
 * TODO: This is a temporary function that should be removed
 * after we improve the BE response
 * @param color filtered color
 */
const filterDepthColor = (color: string): string[] => {
  const colorArray = color.split(",");

  if (colorArray.length === 4) {
    return colorArray.slice(0, 3);
  }

  return colorArray;
};

/**
 * Get estimated voyage duration based on current vessel speed or default value,
 * in units of hour.
 * @param {Object} voyage
 * @param {number} vesselAverageSpeed in miles per hour.
 */
export const voyageEstimatedDuration = (
  voyage: any,
  vesselAverageSpeed = constants.vessel.averageSpeed,
) => {
  const dist = voyage?.distance
    ? Number(voyage.distance)
    : voyageDistance(voyage, ShortLengthUnit.MiString);

  return dayjs.duration(Number(dist / (vesselAverageSpeed * constants.knotsToMilesPH)), "hours");
};

export const durationToString = (duration: any) => {
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const time = [];

  if (days >= 1) time.push(`${days} day${days > 1 ? "s" : ""}`);
  if (hours >= 1) time.push(`${hours} hr${hours > 1 ? "s" : ""}`);
  if (minutes >= 1) time.push(`${minutes} min`);
  if (minutes < 1 && seconds > 0 && time.length === 0) time.push(`less than a minute`);

  return time.join(" ");
};

/**
 * Get estimated voyage time to arrival.
 * @param {Object} voyage
 */
export const voyageEstimatedTimeArrival = (
  voyage: any,
  vesselAverageSpeed = constants.vessel.averageSpeed,
) => {
  const timeEstimated = voyageEstimatedDuration(voyage, vesselAverageSpeed).asHours();

  const date = dayjs(new Date()).add(Number(timeEstimated), "hour").format("hh:mm A");

  return date;
};

/**
 * Get estimated voyage fuel consumption.
 * @param {Object} voyage
 */
export const voyageEstimatedFuelConsumption = (voyage: any, currentVessel: any) => {
  if (!currentVessel) return "-";
  const timeEstimated = voyageEstimatedDuration(
    voyage,
    currentVessel?.average_speed || constants.vessel.averageSpeed,
  ).asHours();

  const result =
    timeEstimated * (currentVessel?.fuel_consumption || constants.vessel.defaultFuelConsumption);

  return result.toFixed(1);
};

export const getPosition = (callback: any) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(callback); // Passing in a success callback and an error callback fn
  } else {
    alert("Sorry, Geolocation is not supported by this browser."); // Alert is browser does not support geolocation
  }
};
export const fitToRegion = (points: Position[]) => {
  // init first point
  let minLat: number = points[0].lat;
  let maxLat: number = points[0].lat;
  let minLng: number = points[0].lng;
  let maxLng: number = points[0].lng;
  const inset = 1.25;

  // calculate rect
  points.forEach((point: Position) => {
    minLat = Math.min(minLat, point.lat);
    maxLat = Math.max(maxLat, point.lat);
    minLng = Math.min(minLng, point.lng);
    maxLng = Math.max(maxLng, point.lng);
  });

  const midLat = (minLat + maxLat) / 2;
  const midLng = (minLng + maxLng) / 2;

  const deltaLat = (maxLat - minLat) * inset;
  const deltaLng = (maxLng - minLng) * inset;

  return {
    latitude: midLat,
    longitude: midLng,
    latitudeDelta: deltaLat,
    longitudeDelta: deltaLng,
  };
};

export function findBounds(coordinates: Position[]) {
  if (coordinates?.length === 0 || !coordinates) {
    return null; // Devolver null si el array de coordenadas está vacío
  }

  let northmost = coordinates[0];
  let southmost = coordinates[0];
  let eastmost = coordinates[0];
  let westmost = coordinates[0];

  coordinates.forEach((coordinate: Position) => {
    northmost = coordinate.lat > northmost.lat ? coordinate : northmost;
    southmost = coordinate.lat < southmost.lat ? coordinate : southmost;
    eastmost = coordinate.lng > eastmost.lng ? coordinate : eastmost;
    westmost = coordinate.lng < westmost.lng ? coordinate : westmost;
  });

  return {
    northmost,
    southmost,
    eastmost,
    westmost,
  };
}
export const getZoomForRoute = (
  startPoint: RoutePosition | null,
  endPoint: RoutePosition | null,
) => {
  if (startPoint && endPoint) {
    // Calculate the distance in latitude and longitude degrees
    const latDistance = Math.abs(endPoint.lat - startPoint.lat);
    const lngDistance = Math.abs(endPoint.lng - startPoint.lng);

    // Calculate zoom level based on latitude or longitude distance (whichever is larger)
    const zoomByLat = Math.log(360 / latDistance) / Math.LN2;
    const zoomByLng = Math.log(180 / lngDistance) / Math.LN2;
    const zoom = Math.min(zoomByLat, zoomByLng);

    // Adjust the zoom level to fit within a reasonable range
    if (zoom < 2) {
      return 2;
    }
    if (zoom > 20) {
      return 20;
    }

    return zoom;
  }

  return 14; // Default zoom level
};

export const evaluateRoutePaddingByResolution = (width: number) => {
  if (width <= resolutions.smallest.width) {
    return {
      xOffset: 0.2,
      boundsPadding: 100,
    };
  }

  if (width <= resolutions.middest.width) {
    return {
      xOffset: 0.15,
      boundsPadding: 200,
    };
  }

  return {
    xOffset: 0.2,
    boundsPadding: 300,
  };
};

export const setMapType = (
  map: google.maps.Map | undefined,
  poisConfigured: TpoisConfigured,
  currentDepthShading: any,
  mapType: string,
  // unit = "Feet",
) => {
  if (map) {
    const MapTypeDepth = null;

    const MapTypeNoaa = null;

    if (map && mapType) {
      map.overlayMapTypes.clear();
      map.setMapTypeId(mapType);
      if (poisConfigured.noaaLayer) {
        map.mapTypes.set("noaa", MapTypeNoaa);
        map.setMapTypeId("noaa");
      } else {
        map.overlayMapTypes.push(MapTypeDepth);
      }
    }
  }
};

export const getRegionFromBounds = (bounds: google.maps.LatLngBounds): TRegionData => {
  if (bounds) {
    const northEast = bounds?.getNorthEast();
    const southWest = bounds?.getSouthWest();

    return {
      hasRegion: true,
      northEastLat: northEast?.lat(),
      northEastLng: northEast?.lng(),
      southWestLat: southWest?.lat(),
      southWestLng: southWest?.lng(),
    };
  }

  return {
    hasRegion: false,
    northEastLat: undefined,
    northEastLng: undefined,
    southWestLat: undefined,
    southWestLng: undefined,
  };
};

export const getRegion = (mapRef: google.maps.Map | undefined): TRegionData => {
  if (mapRef) {
    const bounds = mapRef.getBounds();

    if (bounds) {
      const northEast = bounds?.getNorthEast();
      const southWest = bounds?.getSouthWest();

      return {
        hasRegion: true,
        northEastLat: northEast?.lat(),
        northEastLng: northEast?.lng(),
        southWestLat: southWest?.lat(),
        southWestLng: southWest?.lng(),
      };
    }
  }

  return {
    hasRegion: false,
    northEastLat: undefined,
    northEastLng: undefined,
    southWestLat: undefined,
    southWestLng: undefined,
  };
};

export const handleGetCurrentDepthShading = (
  depthShadingData: IDepthShadingData,
  shaderOpacity: number,
  isPremiumOrIsPreview: boolean,
) => {
  if (depthShadingData) {
    let currentDepthShading = null;

    depthShadingData?.data?.items?.forEach((item: IDepthShading) => {
      if (isPremiumOrIsPreview) {
        if (item?.current ?? item?.name === "Default") {
          currentDepthShading = {
            ...item,
            depth_shader_items: item?.depth_shader_items?.map((shading) => {
              const colorArr = filterDepthColor(shading?.color);

              colorArr.push(`${shaderOpacity}`);

              const color = colorArr.join();

              return {
                ...shading,
                color,
              };
            }),
          };
        }
      } else if (item?.default_flag) {
        currentDepthShading = {
          ...item,
          depth_shader_items: item?.depth_shader_items?.map((shading) => {
            const colorArr = filterDepthColor(shading?.color);

            colorArr.push(`${shaderOpacity}`);

            const color = colorArr.join();

            return {
              ...shading,
              color,
            };
          }),
        };
      }
    });
    if (currentDepthShading) return currentDepthShading;
  }

  return undefined;
};

/**
 * This function convert ft into m
 * @param distance Distance in ft
 * @returns Distance in meters
 */
export function ftToMeters(distance: string): string {
  return `${(Number(distance) / 3.2808).toFixed(2)}`;
}

export const defaultDepthShading = {
  id: 999999999,
  user_id: 9999999,
  name: "Default",
  current: true,
  default_flag: false,
  preset_flag: false,
  visible: true,
  created_at: "2023-12-01T20:13:34.703Z",
  updated_at: "2024-04-03T16:07:21.255Z",
  depth_shader_items: [
    {
      id: 562487,
      color: "92,255,51,0",
      distance: "0",
      created_at: "2024-03-29T14:36:55.684Z",
      updated_at: "2024-03-29T14:36:55.684Z",
    },
    {
      id: 562488,
      color: "230,168,37,0",
      distance: "5",
      created_at: "2024-03-29T14:36:55.690Z",
      updated_at: "2024-03-29T14:36:55.690Z",
    },
    {
      id: 562489,
      color: "48,36,181,0",
      distance: "11",
      created_at: "2024-03-29T14:36:55.692Z",
      updated_at: "2024-03-29T14:36:55.692Z",
    },
    {
      id: 562490,
      color: "231,66,252,0",
      distance: "20",
      created_at: "2024-03-29T14:36:55.695Z",
      updated_at: "2024-03-29T14:36:55.695Z",
    },
  ],
};
