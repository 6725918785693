import { ListItem, ListItemButton, Divider, Stack, Typography, Box } from "@mui/material";
import dayjs from "dayjs";

import { styles } from "./styles";

import { convertTimeIsoToUs } from "@/utils/globals";
import { useAppDispatch } from "@/hooks/useRedux";
import { voyageFavorite } from "@/store/slices/captainslog/actions";
import FavoriteCheckbox from "@/components/FavoriteCheckbox";

export default function CaptainsLogListItem({
  isSelected,
  onSelectVoyage,
  item,
  trips,
  onSetFavoriteItem,
}: any) {
  const dispatch = useAppDispatch();

  const handleFavorite = async (voyageId: number, favorite: boolean) => {
    const result = await dispatch(voyageFavorite(trips, voyageId, favorite));

    if (result) onSetFavoriteItem(voyageId, favorite);
  };

  return (
    <>
      <ListItem
        disablePadding
        secondaryAction={
          <FavoriteCheckbox
            checked={item.list_favorite}
            size="small"
            sx={styles.favoriteCheckbox}
            onClick={() => handleFavorite(item.id, item.list_favorite)}
          />
        }
        sx={styles.listItem}
      >
        <ListItemButton
          selected={isSelected}
          sx={styles.listItemButton}
          onClick={(e) => onSelectVoyage(item, e)}
        >
          <Box sx={styles.listItemHeader}>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Stack spacing={-0.5}>
                <Typography color="text.secondary" variant="subtitle2">
                  {item.kind === "route"
                    ? ""
                    : `${dayjs(item.created_at).format("dddd")} at ${dayjs(item.created_at).format(
                        "h:mm A",
                      )}`}
                </Typography>
                <Typography color="secondary.main" variant="subtitle1">
                  {item.name}
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Stack sx={styles.listItemDescription}>
            <Typography variant="body1">{item.description}</Typography>
            <Typography color="text.secondary" variant="caption">
              Created on: {convertTimeIsoToUs(item.created_at)}
            </Typography>
          </Stack>
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
}
