import { TPagination } from "./globals";

export type MapType = {
  idName: string;
  alt: string;
  image: string;
  name: string;
};
export type Position = any;
export interface IPositionBridgeAlert {
  latitude: number;
  longitude: number;
  horclr: number;
  verclr: number;
}

export type RoutePosition = Position;

export enum PlannedCoordRouting {
  MANUAL = "manual",
  GENERATED = "generated",
  AUTOMATIC = "automatic",
}

export enum PlannedCoordKind {
  TRACK = "track",
  WAYPOINT = "waypoint",
  END_POINT = "end_point",
  INNERPOINT = "innerpoint",
  START_POINT = "start_point",
}

export type LatLng = {
  latitude: number;
  longitude: number;
};
export interface IWaypoint extends LatLng {
  id: number;
  depth?: number;
  name?: string;
  address?: string;
  referable_type?: "Place" | "Poi" | "ReportPin";
  referable_id?: number;
  kind: PlannedCoordKind;
  routing?: PlannedCoordRouting;
  automatic_status?: "complete" | "fail";
  automatic_message?: string;
}

export interface Route {
  trip_bridges: IPositionBridgeAlert[];
  course_locations: Position[];
  distance: number;
  status: number;
  waypoints: IWaypoint[];
}

export interface Trip {
  id?: number;
  planned_coords: Position[];
  filteredWaypoints?: { initialSegment: Position[]; endSegment: Position[] };
  trip_bridges: any;
  name: string;
  description: string;
  distance: number;
  status: number;
  waypoints: IWaypoint[];
}

export type TpoisConfigured = {
  [key: string]: boolean;
};

export interface ImapType {
  typeOfMap: string;
  poisConfigured: TpoisConfigured;
}

export type depth_shading_items = {
  color: string;
  distance: string | number;
  created_at: string;
  depth_shading_id: number;
  id: number;
  updated_at: string;
};

export type TDepthShadingItemTile = {
  color: string;
  distance: string;
};

export interface ICustomDepthShaderItem {
  id?: number;
  color: string;
  distance: string;
}

export interface IDepthShading {
  id?: number;
  name: string;
  current: boolean;
  preset_flag?: boolean;
  visible?: boolean;
  default_flag?: boolean;
  depth_shader_items: depth_shading_items[];
  depth_shader_items_attributes?: depth_shading_items[];
}

export interface IDepthShadingData {
  status: string;
  data: {
    items: IDepthShading[];
    pagination: TPagination;
  };
}

export type TDDM = {
  degrees: string;
  minutes: string;
};

export type TDMS = {
  degrees: string;
  minutes: string;
  seconds: string;
};

export type TRegionData = {
  hasRegion: boolean;
  northEastLat: number | undefined;
  northEastLng: number | undefined;
  southWestLat: number | undefined;
  southWestLng: number | undefined;
};
