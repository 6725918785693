/* global google */

import { useEffect, useRef, useState } from "react";
import { useMap } from "@vis.gl/react-google-maps";

import MapControls from "../MapControls";
import DeckGlOverlay from "../MapOverlays/DeckGlOverlay";
import Markers from "../Markers";
import { getWaypointLayer } from "../MapLayers/WaypointLayer";

import { Position, PlannedCoordKind } from "@/types/map";
import { useAppSelector } from "@/hooks/useRedux";
import { IMapPois } from "@/types/pois";
import { EditWaypointStatus } from "@/store/slices/route";
import { findKeyWaypoints } from "@/utils/waypoints";

interface IMapContentWrapper {
  clogpage: boolean;
  bounds: google.maps.LatLngBounds | undefined;
  location: Position | any | null;
  mapLayerConfig: any;
  zoomControl: (value: number) => void;
  onCleanListOfResults: () => void;
  setPoi: (poi: IMapPois) => void;
}

function MapContentWrapper({
  clogpage,
  bounds,
  location,
  mapLayerConfig,
  zoomControl,
  onCleanListOfResults,
  setPoi,
}: IMapContentWrapper) {
  const { mapState, zoom } = useAppSelector((state) => state.mapParams);
  const { routeCreated, waypoints, editWaypointStatus, inputsFocus } = useAppSelector(
    (state) => state.route,
  );
  const map = useMap();

  const pointsRef = useRef<number[][]>([]);
  const [, setUpdate] = useState(0);

  useEffect(() => {
    if (routeCreated) {
      const startPoint = routeCreated.waypoints.find(
        (point) => point.kind === PlannedCoordKind.START_POINT && point.latitude && point.longitude,
      );

      const endPoint = routeCreated.waypoints.find(
        (point) => point.kind === PlannedCoordKind.END_POINT && point.latitude && point.longitude,
      );

      const newPoints =
        inputsFocus === 1 && startPoint
          ? findKeyWaypoints(
              [
                {
                  id: "Item 0",
                  value: `${startPoint.latitude}, ${startPoint.longitude}`,
                  focused: true,
                  lat: startPoint.latitude,
                  lng: startPoint.longitude,
                  position: 0,
                },
                ...waypoints,
              ],
              routeCreated,
            )
          : inputsFocus === 2 && endPoint
          ? findKeyWaypoints(
              [
                ...waypoints.filter((point) => point.lat && point.lng),
                {
                  id: `Item ${waypoints.length + 1}`,
                  value: `${endPoint.latitude}, ${endPoint.longitude}`,
                  focused: true,
                  lat: endPoint.latitude,
                  lng: endPoint.longitude,
                  position: waypoints.length + 1,
                },
              ],
              routeCreated,
            )
          : findKeyWaypoints(waypoints, routeCreated);

      if (newPoints) pointsRef.current = newPoints;
    }
  }, [routeCreated, waypoints]);

  const waypointLayer = getWaypointLayer(pointsRef.current, map?.getZoom() || 14);

  const handleDrag = (event: google.maps.MapMouseEvent) => {
    if (!event.latLng) return;

    const newPoint = [event.latLng.lng(), event.latLng.lat()];

    if (editWaypointStatus === EditWaypointStatus.START) {
      pointsRef.current = [newPoint, pointsRef.current[1]];
    } else if (editWaypointStatus === EditWaypointStatus.SIMPLE) {
      pointsRef.current = [pointsRef.current[0], newPoint];
    } else if (editWaypointStatus === EditWaypointStatus.MULTIPLE) {
      pointsRef.current = [pointsRef.current[0], newPoint, pointsRef.current[2] ?? newPoint];
    }
    setUpdate((prev) => prev + 1);
  };

  const handleRemoveWaypointEditLine = () => {
    pointsRef.current = [];
    setUpdate(0);
  };

  return (
    <>
      <MapControls
        clogpage={clogpage}
        zoom={mapState?.zoom || zoom || 14}
        zoomControl={zoomControl}
      />
      <DeckGlOverlay
        layers={[
          ...mapLayerConfig.layers,
          ...(editWaypointStatus !== EditWaypointStatus.NONE ? [waypointLayer] : []),
        ]}
      />
      <Markers
        bounds={bounds}
        clogpage={clogpage}
        setPoi={setPoi}
        userPosition={location}
        zoom={mapState?.zoom || zoom}
        onCleanPoiList={onCleanListOfResults}
        onDrag={handleDrag}
        onHandleRemoveWaypointEditLine={handleRemoveWaypointEditLine}
      />
    </>
  );
}

export default MapContentWrapper;
