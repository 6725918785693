import { Stack, Box, TextField, IconButton } from "@mui/material";
import { SearchOutlined, CloseOutlined, DeleteOutlined, DragIndicator } from "@mui/icons-material";
import React, { useState, useEffect, useRef } from "react";

import { styles } from "./styles";

import { IInterimPoint as IInterimPointProps } from "@/store/slices/route";
import useDebounce from "@/hooks/useDebounce";

interface IWaypointInput {
  point: IInterimPointProps;
  position: number;
  focused: boolean;
  onRemoveInterimPoint: (index: number) => void;
  onSetInterimPoint: (value: string, position: number) => void;
  onSearchInterimPoint: (value: string) => void;
  onFocus: (e: React.FocusEvent<HTMLInputElement>, type: number, position?: number) => void;
  clearRouteInput: (type: number, position?: number) => void;
}

function WaypointInput({
  point,
  position,
  focused,
  onRemoveInterimPoint,
  onSetInterimPoint,
  onSearchInterimPoint,
  onFocus,
  clearRouteInput,
}: IWaypointInput) {
  const [inputValue, setInputValue] = useState(point.value || "");
  const debouncedValue = useDebounce(inputValue, 1000);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (point.value !== undefined && point.value !== inputValue) {
      setInputValue(point.value);
    }
  }, [point.value]);

  useEffect(() => {
    if (debouncedValue.trim() !== point.value?.trim()) {
      onSetInterimPoint(debouncedValue, position);
      onSearchInterimPoint(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (focused && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        inputRef.current.value.length,
        inputRef.current.value.length,
      );
    }
  }, [focused]);

  return (
    <Stack alignItems="center" direction="row">
      <Box>
        <IconButton aria-label="add" size="small" sx={styles.iconButton} onClick={() => null}>
          <DragIndicator />
        </IconButton>
      </Box>
      <Box sx={{ ml: 1, mr: 1 }}>
        <TextField
          InputProps={{
            endAdornment:
              inputValue !== "" && focused ? (
                <IconButton onClick={() => clearRouteInput(3, position)}>
                  <CloseOutlined />
                </IconButton>
              ) : focused ? (
                <IconButton>
                  <SearchOutlined />
                </IconButton>
              ) : undefined,
          }}
          focused={focused}
          inputRef={inputRef}
          label={`Waypoint ${position + 1}`}
          sx={{
            ...styles.textfield,
            "& .MuiSvgIcon-root": {
              opacity: inputValue !== "" && focused ? 1 : 0,
            },
          }}
          type="text"
          value={inputValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
          onFocus={(e: React.FocusEvent<HTMLInputElement>) => onFocus(e, 3, position)}
        />
      </Box>
      <Box>
        <IconButton
          aria-label="add"
          color="primary"
          disabled={inputValue === ""}
          size="small"
          sx={styles.iconButton}
          onClick={() => onRemoveInterimPoint(position + 1)}
        >
          <DeleteOutlined />
        </IconButton>
      </Box>
    </Stack>
  );
}

export default WaypointInput;
