import defaultTheme from "@/themes/theme";

export const styles = {
  alertContent: {
    [defaultTheme.breakpoints.down("md")]: {
      top: "69px",
      left: "93px",
      maxWidth: "297px",
      width: "calc(100% - 105px)",
    },
    zIndex: 50,
    width: "calc(100% - 105px)",
    maxWidth: "384px",
    top: "200px",
    left: "93px",
  },
  title: {
    fontWeight: "bold",
  },
};
