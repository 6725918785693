import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  fabButton: {
    boxShadow: 3,
    backgroundColor: "background.paper",
    color: "primary.light",
    "& .MuiSvgIcon-root": {
      fontSize: 22,
    },
  },

  container: {
    [defaultTheme.breakpoints.down("md")]: {
      mt: "120px",
    },
    mr: "12px",
    mt: "70px",
  },

  paper: {
    [defaultTheme.breakpoints.down("md")]: {
      width: "80%",
    },
    position: "fixed",
    top: 70,
    right: 64,
    width: "408px",
    overflow: "hidden",
    zIndex: 100,
  },

  scrollContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "calc(100vh - 152px)",
  },
};
