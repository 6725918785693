import { IconButton } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { styles } from "./styles";

function NextArrow(props: any) {
  const { className, style, onClick, fullScreen } = props;

  return (
    <IconButton
      className={className}
      size="small"
      sx={
        fullScreen ? { ...style, ...styles.nextArrowFullscreen } : { ...style, ...styles.nextArrow }
      }
      onClick={onClick}
    >
      <ChevronRightIcon color="primary" />
    </IconButton>
  );
}

export default NextArrow;
