import { LocationOn } from "@mui/icons-material";
// import { useEffect } from "react";
// import { useMap } from "@vis.gl/react-google-maps";

import AdvancedMarker from "../AdvancedMarker";
import ContextMenuMapPopover from "../../MapPopovers/ContextMenuMapPopover/ContextMenuMapPopover";

import BaseIcon from "@/components/BaseIcon";
import { useAppSelector } from "@/hooks/useRedux";

interface IContextMenuMarker {
  clogpage?: boolean;
}

function ContextMenuMarker({ clogpage }: IContextMenuMarker) {
  const { creatingRoute } = useAppSelector((state) => state.route);
  const { coords, isLongPress } = useAppSelector((state) => state.map);

  if (!coords || creatingRoute) return null;

  const iconPin = () => (
    <BaseIcon
      icon={LocationOn}
      sx={{
        background: "#fff",
        width: 28,
        height: 28,
        p: 0.5,
        borderRadius: 99,
      }}
    />
  );

  if (clogpage) return null;

  return (
    <AdvancedMarker
      alwaysShown
      icon={iconPin()}
      position={{ lat: Number(coords.lat), lng: Number(coords.lng) }}
      selected={false}
      onClick={() => null}
    >
      <ContextMenuMapPopover coords={coords} isLongPress={isLongPress} />
    </AdvancedMarker>
  );
}

export default ContextMenuMarker;
