import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import axios, { CancelToken } from "axios";
import { useMap } from "@vis.gl/react-google-maps";

import RouteSearchBox from "./RouteSearchBox";
import RouteResultPanel from "./RouteResultPanel";

import { IMapPois } from "@/types/pois";
import { RoutePosition, Position } from "@/types/map";
import { findBounds, evaluateRoutePaddingByResolution } from "@/utils/maps";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import {
  addEndPoint,
  addError,
  addRouteCreated,
  addStartPoint,
  setCancelRouteToken,
  setDepthAlert,
  setInputsFocus,
  addInterimPoint,
  IInterimPoint,
} from "@/store/slices/route";
import { TPOI_TYPE, isLatLng } from "@/utils/poi";
import useSearch from "@/hooks/useSearch";
import { addCenter } from "@/store/slices/map";
import { meterToFeet, vesselDefault, getPriorityCostFactor } from "@/utils/boats";
import {
  ERouteSettings,
  IWaypointRouting,
  ITripStatus,
  ITripKind,
  ITripAccess,
  IWaypointKind,
  ITripMode,
} from "@/types/route";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { CANCEL_ROUTE_CHANGE_MSG, CANCEL_ROUTE_USER_CANCEL_MSG } from "@/utils/route";
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "@/utils/globals";
import tripsService from "@/services/trips";
import useGeoLocation from "@/hooks/useGeoLocation";

const handleCheckRouteName = (value?: string) => {
  if (value && isLatLng(value)) {
    return "Custom Location";
  }

  return value;
};

export function generateWaypoints(
  startPoint: { lat: number; lng: number; name: string },
  endPoint: { lat: number; lng: number; name: string },
  waypoints?: IInterimPoint[],
) {
  return [
    startPoint
      ? {
          kind: IWaypointKind.START,
          latitude: startPoint.lat,
          longitude: startPoint.lng,
          name: handleCheckRouteName(startPoint.name),
          routing: IWaypointRouting.AUTO,
        }
      : null,
    ...(waypoints && waypoints.length > 0
      ? waypoints
          .filter((point) => point.lat && point.lng)
          .map((point) => ({
            kind: IWaypointKind.WAYPOINT,
            latitude: point.lat,
            longitude: point.lng,
            name: handleCheckRouteName(point.name),
            routing: IWaypointRouting.AUTO,
          }))
      : []),
    endPoint
      ? {
          kind: IWaypointKind.END,
          latitude: endPoint.lat,
          longitude: endPoint.lng,
          name: handleCheckRouteName(endPoint.name),
          routing: IWaypointRouting.AUTO,
        }
      : null,
  ].filter(Boolean);
}

interface IRoute {
  onDeleteCategory: (value: TPOI_TYPE) => void;
  onSearchCategory: (value: TPOI_TYPE | undefined, action: boolean, clearAll: any) => void;
  onTakeScreenshot: () => Promise<File | null>;
  onClose: () => void;
}

export default function Route({
  onDeleteCategory,
  onSearchCategory,
  onTakeScreenshot,
  onClose,
}: IRoute) {
  const map = useMap();
  const dispatch = useAppDispatch();
  const routeStore = useAppSelector((state) => state.route);
  const [routeLoading, setRouteLoading] = useState<boolean>(false);
  const [lockedValue, setLockedValue] = useState<boolean>(false);

  const { startPoint, endPoint, routeCreated, inputsFocus, cancelRouteToken, waypoints } =
    routeStore;
  const { tripEditMode } = useAppSelector((state) => state.captainslog);

  const { userInformation, userOptions } = useAppSelector((state) => state.user);

  const { setQuery, setCleanPoiList, setCleanPoiCategory } = useSearch();
  const { location } = useGeoLocation();
  const [startInput, setStartInput] = useState<string>("");
  const [endInput, setEndInput] = useState<string>("");

  const handleSuggestionsOpenPoint = async (
    e: React.ChangeEvent<HTMLInputElement>,
    type: number,
  ) => {
    if (type === 1) {
      if (e.target.value !== "") {
        setStartInput(e.target.value);
      } else {
        setStartInput("");
        dispatch(addStartPoint(null));
        dispatch(addRouteCreated(null));
      }
    } else if (e.target.value !== "") {
      setEndInput(e.target.value);
    } else {
      setEndInput("");
      dispatch(addEndPoint(null));
      dispatch(addRouteCreated(null));
    }

    setQuery(e.target.value);
  };

  const handleSearchInterimPoint = async (interimPoint: string) => {
    dispatch(addRouteCreated(null));
    setQuery(interimPoint);
  };

  const handleSelectPoiRoute = (poi: IMapPois | RoutePosition, inputType: number) => {
    if (inputType === 1) {
      setStartInput("");
      dispatch(
        addStartPoint({
          lat: poi.lat,
          lng: poi.lng,
          name: `${poi.name}`,
        }),
      );
    } else if (inputType === 2) {
      setEndInput("");
      dispatch(
        addEndPoint({
          lat: poi.lat,
          lng: poi.lng,
          name: `${poi.name}`,
        }),
      );
    } else if (inputType === 3) {
      const updatedInterimPoints = waypoints.map((point) =>
        point.focused
          ? {
              ...point,
              focused: false,
              value: poi.name,
              lat: poi.lat,
              lng: poi.lng,
            }
          : point,
      );

      dispatch(
        addInterimPoint([
          ...updatedInterimPoints,
          { id: `Item ${updatedInterimPoints.length + 1}`, value: "", focused: true },
        ]),
      );
    }

    setCleanPoiList();
    setCleanPoiCategory();
    setQuery("");
    dispatch(
      addCenter({
        lat: poi && parseFloat(String(poi.lat)),
        lng: poi && parseFloat(String(poi.lng)),
      }),
    );
  };

  const createRoute = async (
    startPoint: Position | null,
    endPoint: Position | null,
    waypoints: IInterimPoint[],
    cancelToken?: CancelToken,
  ) => {
    if (startPoint && (endPoint || waypoints?.some((point) => point.lat && point.lng))) {
      setRouteLoading(true);
      const prioritycostfactor = getPriorityCostFactor(startPoint, endPoint);
      const avoidOcean = userOptions.route_settings === ERouteSettings.Avoid;
      const avoidInland = userOptions.route_settings === ERouteSettings.Outside;
      const hug_shore =
        userOptions.route_settings === ERouteSettings.Outside ? userOptions.hug_shore : undefined;

      const vessel_trip = {
        id: userInformation?.current_vessel?.id,
        name: userInformation?.current_vessel?.name,
        boat_type: userInformation?.current_vessel?.boat_type,
        manufacturer_model: userInformation?.current_vessel?.manufacturer_model,
        beam: userInformation?.current_vessel?.beam,
        draft: userInformation?.current_vessel?.draft || vesselDefault.defaultDraft,
        buffer: userInformation?.current_vessel?.buffer || vesselDefault.defaultBuffer,
        height: userInformation?.current_vessel?.height || vesselDefault.defaultHeight,
        length: userInformation?.current_vessel?.length,
        average_speed: userInformation?.current_vessel?.average_speed,
        fuel_type: userInformation?.current_vessel?.fuel_type,
        fuel_consumption: userInformation?.current_vessel?.fuel_consumption,
        port: userInformation?.current_vessel?.port,
        port_name: userInformation?.current_vessel?.port_name,
        asset_links: userInformation?.current_vessel?.asset_links,
      };

      try {
        const payloadData = {
          kind: ITripKind.ROUTE,
          name: routeCreated?.name,
          description: routeCreated?.description,
          avoid_ocean: avoidOcean,
          avoid_inland: avoidInland,
          vessel_trip,
          prioritycostfactor,
          hug_shore,
          access: ITripAccess.CANADA,
          publication_status: ITripStatus.DRAFT,
          save_mode: ITripStatus.DRAFT,
          waypoints: generateWaypoints(startPoint, endPoint, waypoints),
        };

        const { status, data: dataTrip } = await tripsService.createTrip(payloadData, cancelToken);

        if (status === 201) {
          setRouteLoading(false);

          return dataTrip.data;
        }
      } catch (error: any) {
        if (error.message !== CANCEL_ROUTE_CHANGE_MSG) setRouteLoading(false);
        dispatch(addError(error.toString()));
        dispatch(
          setSnackBarMsjSucceded({ state: true, type: "error", msj: error.response.data.essage }),
        );
      }
    } else {
      return null;
    }
  };

  const updateRoute = async (
    startPoint: Position | null,
    endPoint: Position | null,
    cancelToken?: CancelToken,
  ) => {
    if (startPoint && (endPoint || waypoints?.some((point) => point.lat && point.lng))) {
      setRouteLoading(true);
      const prioritycostfactor = getPriorityCostFactor(startPoint, endPoint);
      const avoidOcean = userOptions.route_settings === ERouteSettings.Avoid;
      const avoidInland = userOptions.route_settings === ERouteSettings.Outside;
      const hug_shore =
        userOptions.route_settings === ERouteSettings.Outside ? userOptions.hug_shore : undefined;

      const vessel_trip = {
        id: userInformation?.current_vessel?.id,
        name: userInformation?.current_vessel?.name,
        boat_type: userInformation?.current_vessel?.boat_type,
        manufacturer_model: userInformation?.current_vessel?.manufacturer_model,
        beam: userInformation?.current_vessel?.beam,
        draft: userInformation?.current_vessel?.draft || vesselDefault.defaultDraft * meterToFeet,
        buffer:
          userInformation?.current_vessel?.buffer || vesselDefault.defaultBuffer * meterToFeet,
        height:
          userInformation?.current_vessel?.height || vesselDefault.defaultHeight * meterToFeet,
        length: userInformation?.current_vessel?.length,
        average_speed: userInformation?.current_vessel?.average_speed,
        fuel_type: userInformation?.current_vessel?.fuel_type,
        fuel_consumption: userInformation?.current_vessel?.fuel_consumption,
        port: userInformation?.current_vessel?.port,
        port_name: userInformation?.current_vessel?.port_name,
        asset_links: userInformation?.current_vessel?.asset_links,
      };

      try {
        const payloadData = {
          kind: ITripKind.ROUTE,
          name: routeCreated?.name,
          description: routeCreated?.description,
          avoid_ocean: avoidOcean,
          avoid_inland: avoidInland,
          vessel_trip,
          prioritycostfactor,
          hug_shore,
          access: ITripAccess.CANADA,
          publication_status: ITripStatus.PUBLISHED,
          save_mode: ITripMode.DRAFT,
          waypoints: generateWaypoints(startPoint, endPoint, waypoints),
        };

        const { status, data: dataTrip } = await tripsService.updateTrip(
          payloadData,
          routeCreated?.id,
          cancelToken,
        );

        if (status === 200) {
          setRouteLoading(false);

          return dataTrip.data;
        }
      } catch (error: any) {
        if (error.message !== CANCEL_ROUTE_CHANGE_MSG) setRouteLoading(false);
        dispatch(addError(error.toString()));
        dispatch(
          setSnackBarMsjSucceded({ state: true, type: "error", msj: error.response.data.essage }),
        );
      }
    } else {
      return null;
    }
  };

  const handleCenterOnRoute = (route: any) => {
    const bounds = findBounds(route.planned_coords);

    const screenWidth = window.innerWidth;
    const mapBoundsConfig = evaluateRoutePaddingByResolution(screenWidth);

    if (map && bounds) {
      map.fitBounds(
        {
          east: bounds.eastmost.lng,
          north: bounds.northmost.lat,
          south: bounds.southmost.lat,
          west: bounds.westmost.lng,
        },
        mapBoundsConfig.boundsPadding,
      );
    }
  };

  const handleRoute = async (cancelToken?: CancelToken) => {
    if (!tripEditMode && !routeCreated) {
      dispatch(addRouteCreated(null));
      const result = await createRoute(startPoint, endPoint, waypoints, cancelToken);

      if (result) {
        dispatch(addRouteCreated(result));
        handleCenterOnRoute(result);
      }
    }

    if (tripEditMode || routeCreated) {
      const result = await updateRoute(startPoint, endPoint, cancelToken);

      if (result) {
        dispatch(addRouteCreated(result));
        handleCenterOnRoute(result);
      }
    }
  };

  useEffect(() => {
    const storedStartPoint = getFromLocalStorage("startPointLocked");

    if (storedStartPoint && !tripEditMode) {
      setLockedValue(true);
      dispatch(
        addStartPoint({
          lat: storedStartPoint.lat,
          lng: storedStartPoint.lng,
          name: `${storedStartPoint.name}`,
        }),
      );
      dispatch(
        addCenter({
          lat: storedStartPoint && parseFloat(String(storedStartPoint.lat)),
          lng: storedStartPoint && parseFloat(String(storedStartPoint.lng)),
        }),
      );
    }
  }, [location]);

  useEffect(() => {
    const hasFocusedWaypoint = waypoints.some((point) => point.focused);

    if (startPoint) {
      setStartInput(startPoint.name);
      if (!endPoint && !hasFocusedWaypoint && waypoints.length === 0) {
        dispatch(setInputsFocus(2));
      }
    }
    if (endPoint) {
      setEndInput(endPoint.name);
      if (!startPoint && !hasFocusedWaypoint && waypoints.length === 0) {
        dispatch(setInputsFocus(1));
      }
    }

    if (startPoint && (endPoint || waypoints?.some((point) => point.lat && point.lng))) {
      setRouteLoading(false);
      cancelRouteToken?.cancel(CANCEL_ROUTE_CHANGE_MSG);
      const source = axios.CancelToken.source();

      dispatch(setCancelRouteToken(source));
      handleRoute(source.token);
    }

    return () => {
      setStartInput("");
      setEndInput("");
    };
  }, [startPoint, endPoint, userOptions, userInformation, waypoints]);

  const switchLocations = () => {
    dispatch(addStartPoint(endPoint));
    dispatch(addEndPoint(startPoint));
    if (waypoints.length > 0) {
      dispatch(addInterimPoint([...waypoints].reverse()));
    }
  };

  const handleSwitchFocus = (type: number) => {
    if (type === 1) {
      dispatch(setInputsFocus(1));
    } else if (type === 2) {
      dispatch(setInputsFocus(2));
    } else if (type === 3) {
      dispatch(setInputsFocus(3));
    }
  };

  const handleClearRouteInput = (inputNumber: number, position?: number) => {
    if (startPoint && endPoint) {
      cancelRouteToken?.cancel(CANCEL_ROUTE_USER_CANCEL_MSG);
    }
    if (inputNumber === 1) {
      dispatch(addStartPoint(null));
      dispatch(setInputsFocus(1));
      dispatch(addRouteCreated(null));
      setStartInput("");
    }
    if (inputNumber === 2) {
      dispatch(addEndPoint(null));
      dispatch(setInputsFocus(2));
      setEndInput("");
      dispatch(addRouteCreated(null));
    } else if (inputNumber === 3) {
      dispatch(
        addInterimPoint(
          waypoints
            .filter(
              (point) => point.value !== "" && point.lat !== undefined && point.lng !== undefined,
            )
            .map((point, i) =>
              i === position
                ? { ...point, value: "", lat: undefined, lng: undefined, focused: true }
                : point,
            ),
        ),
      );
      dispatch(addRouteCreated({ ...routeCreated, waypoints: [], planned_coords: [] }));
    }

    dispatch(setDepthAlert(false));
    setCleanPoiList();
    setCleanPoiCategory();
  };

  const handleUnlockStartPoint = () => {
    setLockedValue(false);
    removeFromLocalStorage("startPointLocked");
  };

  const handleLockStartPoint = () => {
    setLockedValue(true);
    saveToLocalStorage("startPointLocked", startPoint);
  };

  return (
    <Box>
      <RouteSearchBox
        clearRouteInput={handleClearRouteInput}
        endInput={endInput}
        inputsFocus={inputsFocus}
        lockedValue={lockedValue}
        setFocus={handleSwitchFocus}
        setPoint={handleSuggestionsOpenPoint}
        startInput={startInput}
        switchLocations={switchLocations}
        tripEditMode={tripEditMode}
        onDeleteCategory={onDeleteCategory}
        onLockStartPoint={handleLockStartPoint}
        onSearchCategory={onSearchCategory}
        onSearchInterimPoint={handleSearchInterimPoint}
        onSelectPoiRoute={handleSelectPoiRoute}
        onUnlockStartPoint={handleUnlockStartPoint}
      />
      <Box>
        <RouteResultPanel
          routeLoading={routeLoading}
          onClose={onClose}
          onTakeScreenshot={onTakeScreenshot}
        />
      </Box>
    </Box>
  );
}
