import React, { useEffect, useState } from "react";
import { Typography, Button, Stack } from "@mui/material";
import { MailOutline } from "@mui/icons-material";
import { useGoogleLogin } from "@react-oauth/google";
import { useParams } from "react-router-dom";
import AppleSignin from "react-apple-signin-auth";

import { styles } from "./styles";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as AppleIcon } from "&/img/icons/appleLogo_iconFilled.svg";
import { ReactComponent as GoogleIcon } from "&/img/icons/googleLogo_iconColored.svg";
import { googleAuth, appleAuth, asyncUpdateUserDataAction } from "@/store/slices/auth/actions";
import { Props } from "@/types/users";
import { ELoginMethod } from "@/types/auth";

type TAppleAuthConfig = {
  clientId: string;
  scope: string;
  redirectURI: string;
  state: string;
  nonce: string;
  usePopup: boolean;
};

const AppleAuthConfig: TAppleAuthConfig = {
  clientId: "io.argonav.desktop",
  scope: "name email",
  redirectURI: import.meta.env.VITE_APP_APPLE_REDIRECT_URL,
  state: "state",
  nonce: "nonce",
  usePopup: true,
};

export default function SignInForm({ setOptionValue }: any) {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [showAllOptions, setShowAllOptions] = useState<boolean>(false);
  const { user, logged } = useAppSelector((state) => state.auth);
  const { coordsParams } = useAppSelector((state) => state.mapParams);
  const lastLoginMethod = localStorage.getItem("lastLoginMethod");

  const isMethodLoginHided = import.meta.env.VITE_APP_LOGIN_METHOD_HIDED === "true";

  const callBackGoogle = async (response: Props) => {
    if (response?.access_token) {
      const auth = await dispatch(googleAuth(response.access_token));

      if (auth) localStorage.setItem("lastLoginMethod", ELoginMethod.Google);

      if (auth && id === "us-monthly") {
        window.location.href = "/premium-payment?type_subscription=us-monthly";
      } else if (auth && id === "us-yearly") {
        window.location.href = "/premium-payment?type_subscription=us-yearly";
      } else if (auth && id === "lifetime") {
        window.location.href = "/premium-payment?type_subscription=us-lifetime";
      } else if (auth) {
        if (coordsParams) {
          window.location.href = `/${coordsParams}`;
        } else {
          window.location.href = "/";
        }
      }
    }
  };

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => callBackGoogle(tokenResponse),
  });

  const callBackApple = async (response: Props) => {
    if (response?.authorization?.id_token) {
      const auth = await dispatch(appleAuth(response?.authorization?.id_token));

      if (auth) localStorage.setItem("lastLoginMethod", ELoginMethod.Apple);

      if (auth && id === "us-monthly") {
        window.location.href = "/premium-payment?type_subscription=us-monthly";
      } else if (auth && id === "us-yearly") {
        window.location.href = "/premium-payment?type_subscription=us-yearly";
      } else if (auth && id === "lifetime") {
        window.location.href = "/premium-payment?type_subscription=us-lifetime";
      } else if (auth) {
        window.location.href = "/";
      }
    }
  };

  useEffect(() => {
    dispatch(asyncUpdateUserDataAction());
  }, [user]);

  if (logged && id === "us-monthly") {
    window.location.href = "/premium-payment?type_subscription=us-monthly";
  } else if (logged && id === "us-yearly") {
    window.location.href = "/premium-payment?type_subscription=us-yearly";
  } else if (logged && id === "lifetime") {
    window.location.href = "/premium-payment?type_subscription=us-lifetime";
  } else if (logged) {
    window.location.href = "/";
  }

  return (
    <Stack component="form" spacing={3} sx={{ width: "100%" }}>
      <Stack spacing={1.5}>
        <Typography component="h3" textAlign="center" variant="h6">
          Sign In
        </Typography>
      </Stack>
      <Stack spacing={1.5}>
        {!isMethodLoginHided &&
          (!lastLoginMethod || lastLoginMethod === ELoginMethod.Google || showAllOptions) && (
            <Button
              fullWidth
              color="inherit"
              size="large"
              startIcon={<BaseIcon icon={GoogleIcon} />}
              sx={styles.googleButton}
              variant="contained"
              onClick={() => googleLogin()}
            >
              Sign in with Google
            </Button>
          )}
        {!isMethodLoginHided &&
          (!lastLoginMethod || lastLoginMethod === ELoginMethod.Apple || showAllOptions) && (
            <AppleSignin
              authOptions={AppleAuthConfig}
              buttonExtraChildren="Continue with Apple"
              className="apple-auth-btn"
              iconProp={{ style: { marginTop: "10px" } }}
              noDefaultStyle={false}
              render={(props: any) => (
                <Button
                  fullWidth
                  color="primary"
                  size="large"
                  startIcon={<BaseIcon icon={AppleIcon} />}
                  sx={styles.appleButton}
                  variant="contained"
                  {...props}
                >
                  Sign in with Apple ID
                </Button>
              )}
              skipScript={false}
              uiType="dark"
              onSuccess={callBackApple}
            />
          )}
        {(!lastLoginMethod || lastLoginMethod === ELoginMethod.Email || showAllOptions) && (
          <Button
            fullWidth
            size="large"
            startIcon={<MailOutline />}
            variant="outlined"
            onClick={() => setOptionValue(2)}
          >
            Sign in with Email
          </Button>
        )}
        {lastLoginMethod && !showAllOptions && (
          <Button
            fullWidth
            size="large"
            variant="contained"
            onClick={() => setShowAllOptions(true)}
          >
            Show All Sign In Options
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
