export const styles = {
  paper: {
    width: "254px",
    p: 2,
    pl: 0,
    cursor: "pointer",
  },

  paperToTop: {
    width: "254px",
    p: 2,
    pl: 0,
    cursor: "pointer",
    bottom: 10,
    transformOrigin: "top",
    position: "absolute",
  },
};
