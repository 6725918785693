/* global google */
import { Box } from "@mui/material";
import { useEffect, useState } from "react";

import WaypointMarker from "./WaypointMarker";

import { useAppSelector } from "@/hooks/useRedux";
import { IWaypointKind, IWaypoint } from "@/types/route";
import { IWaypoint as Course, Position } from "@/types/map";

function WaypointMarkers({
  onDrag,
  onHandleRemoveWaypointEditLine,
}: {
  onDrag: (event: google.maps.MapMouseEvent) => void;
  onHandleRemoveWaypointEditLine: () => void;
}) {
  const { routeCreated, waypoints } = useAppSelector((state) => state.route);
  const { selectedTrip } = useAppSelector((state) => state.captainslog);
  const [localWaypoints, setLocalWaypoints] = useState(routeCreated?.waypoints);

  useEffect(() => {
    if (routeCreated) {
      setLocalWaypoints(routeCreated.waypoints);
    } else {
      setLocalWaypoints([]);
    }
  }, [routeCreated]);

  const handleUpdateLocalWaypoint = (
    { lat, lng }: { lat: number; lng: number },
    course: Position,
  ) => {
    const updatedWaypoints = localWaypoints?.map((point) => {
      if (point.latitude === course.latitude && point.longitude === course.longitude) {
        return {
          ...point,
          latitude: Number(lat.toFixed(6)),
          longitude: Number(lng.toFixed(6)),
        };
      }

      return point;
    });

    setLocalWaypoints(updatedWaypoints);
  };

  if (localWaypoints && localWaypoints.length > 0) {
    return (
      <Box>
        {localWaypoints
          .filter((course: Course) => course.kind === (IWaypointKind.WAYPOINT as any))
          .map((course: Course, index: number) => (
            <WaypointMarker
              course={course}
              id={course.id}
              position={index}
              waypoints={waypoints}
              onDrag={onDrag}
              onHandleRemoveWaypointEditLine={onHandleRemoveWaypointEditLine}
              onUpdateLocalWaypoint={handleUpdateLocalWaypoint}
            />
          ))}
      </Box>
    );
  }

  if (selectedTrip?.waypoints && selectedTrip?.waypoints.length > 0) {
    return (
      <Box>
        {selectedTrip?.waypoints
          .filter((course: IWaypoint) => course.kind === IWaypointKind.WAYPOINT)
          .map((course: IWaypoint, index: number) => (
            <WaypointMarker
              course={course}
              id={course.id}
              position={index}
              waypoints={waypoints}
              onDrag={onDrag}
              onHandleRemoveWaypointEditLine={onHandleRemoveWaypointEditLine}
            />
          ))}
      </Box>
    );
  }

  return <Box />;
}

export default WaypointMarkers;
