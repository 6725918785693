import { useState } from "react";
import { Stack, DialogActions, DialogContent, Button, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Edit } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useUnitSystem } from "@argonav/unit-system";

import { styles } from "./styles";

import StartIndicatorIcon from "&/img/markers/location/StartIndicator.png";
import EndIndicatorIcon from "&/img/markers/location/EndIndicator.png";
import { ITrip } from "@/types/route";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { getTrip, setSelectedTrip } from "@/store/slices/captainslog/actions";
import { addVoyages } from "@/store/slices/captainslog";
import Dropzone from "@/components/Dropzone";
import BaseIcon from "@/components/BaseIcon";
import tripsService from "@/services/trips";
import { addRouteCreated } from "@/store/slices/route";

const formSchema = yup.object({
  name: yup.string().required("Name is a required field"),
});

const BYTE_SIZE_LIMIT = 2e6;

interface IEditRouteModal {
  selectedTrip: ITrip | null | undefined;
  onClose: () => void;
  onCloseRouteSaved: () => void;
  onUpdateItem: (value: boolean) => void;
}

type TEditRouteForm = {
  name: string;
  description: string;
  image?: File | string | undefined;
};

function EditRouteModal({
  selectedTrip,
  onClose,
  onCloseRouteSaved,
  onUpdateItem,
}: IEditRouteModal) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { trips } = useAppSelector((state) => state.captainslog);
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const { coordinatesFormatHandler } = useUnitSystem();

  const handleEditLocalVoyages = (items: ITrip[] | undefined, values: TEditRouteForm) =>
    items?.map((item: ITrip) => {
      if (item.id === selectedTrip?.id) {
        return {
          ...item,
          name: values.name,
          description: values.description,
        };
      }

      return item;
    });

  const handleCreateCourseCopy = () => {
    if (selectedTrip) dispatch(addRouteCreated(selectedTrip));
    navigate("/");
  };

  const handleCloseEditRoute = () => {
    onClose();
  };

  const handleForm = async (values: TEditRouteForm) => {
    if (!selectedTrip && trips && trips?.items.length > 0) return;
    onUpdateItem(true);
    setLoading(true);

    try {
      if (values?.image instanceof File && selectedTrip?.id) {
        const formData = new FormData();

        formData.append("assets_attributes", values.image);
        await tripsService.updateTripImage(selectedTrip?.id, formData);
      }
      const { status } = await tripsService.updateTrip(
        { ...values, save_mode: "sync" },
        selectedTrip?.id ?? 0,
      );

      if (status === 200) {
        dispatch(setSelectedTrip({ ...selectedTrip, ...values }));
        dispatch(addVoyages({ ...trips, items: handleEditLocalVoyages(trips?.items, values) }));
      }

      if (selectedTrip?.id) dispatch(getTrip(selectedTrip?.id));
      dispatch(
        setSnackBarMsjSucceded({
          state: true,
          type: "success",
          msj: "Route changes saved.",
        }),
      );

      setLoading(false);
      onCloseRouteSaved();
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      setLoading(false);
      onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: selectedTrip?.name,
      description: selectedTrip?.description,
      image: undefined,
    } as TEditRouteForm,
    validationSchema: formSchema,
    onSubmit: handleForm,
  });

  const handleRouteImage = (files: File[]) => {
    setFiles([...files]);
    const imgFile = files[0];

    if (["image/jpeg", "image/png"].includes(imgFile?.type)) {
      if (imgFile.size < BYTE_SIZE_LIMIT) {
        formik.setFieldValue("image", imgFile);
      }
    } else {
      formik.setFieldValue("image", null);
    }
  };

  const routeCreated = selectedTrip;

  const startPoint = routeCreated?.waypoints?.filter((point) => point.kind === "start_point")[0];

  const latLngFormattedStart = coordinatesFormatHandler({
    latitude: Number(startPoint?.latitude),
    longitude: Number(startPoint?.longitude),
  });

  const endPoint = routeCreated?.waypoints?.filter((point) => point.kind === "end_point")[0];

  const latLngFormattedEnd = coordinatesFormatHandler({
    latitude: Number(endPoint?.latitude),
    longitude: Number(endPoint?.longitude),
  });

  return (
    <>
      <DialogContent sx={{ py: 1 }}>
        <Stack component="form" spacing={2} onSubmit={formik.handleSubmit}>
          <TextField
            defaultValue={formik.values.name}
            id="name"
            label="Name"
            variant="outlined"
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            multiline
            defaultValue={formik.values.description}
            id="description"
            label="Description"
            minRows={3}
            name="description"
            onChange={formik.handleChange}
          />
          <Stack spacing={1}>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Typography color="text.secondary" lineHeight="normal" variant="body2">
                Start & Destination Location
              </Typography>

              <Button color="secondary" startIcon={<Edit />} onClick={handleCreateCourseCopy}>
                Edit
              </Button>
            </Stack>
            <Stack sx={styles.routePointsBox}>
              <Stack alignItems="center" className="routePointsItem" direction="row" spacing={2}>
                <BaseIcon src={StartIndicatorIcon} type="png" />
                <Typography>{startPoint?.address || `${latLngFormattedStart}`}</Typography>
              </Stack>
              <Stack alignItems="center" className="routePointsItem" direction="row" spacing={2}>
                <BaseIcon src={EndIndicatorIcon} type="png" />
                <Typography>{endPoint?.address || `${latLngFormattedEnd}`}</Typography>
              </Stack>
            </Stack>
            <Stack pt={1}>
              <Dropzone action={handleRouteImage} files={files} />
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={styles.dialogActions}>
        <Button onClick={handleCloseEditRoute}>Cancel</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          onClick={() => formik.handleSubmit()}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export default EditRouteModal;
