import {
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Link,
  Stack,
  Chip,
  CircularProgress,
  Button,
} from "@mui/material";
import { Launch } from "@mui/icons-material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { styles } from "./styles";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import AlertDialog from "@/components/AlertDialog";
import useDisclosure from "@/hooks/useDisclosure";
import ServicePremium from "@/services/premium";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { getUserInformation, getUserOptions } from "@/store/slices/user/actions";

interface IPremiumSubscriptionPanel {
  onClose: () => void;
}

export default function PremiumSubscriptionPanel({ onClose }: IPremiumSubscriptionPanel) {
  const dispatch = useAppDispatch();
  const { userInformation } = useAppSelector((state) => state.user);
  const { isOpen, onOpen, onClose: modalOnClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userInformation?.id) {
      // Refresh user information
      dispatch(getUserInformation(userInformation?.id));
      dispatch(getUserOptions());
    }

    return () => {
      if (isLoading) setIsLoading(false);
    };
  }, []);

  const isPaymentType = userInformation?.latest_user_subscription?.kind === "payment";

  const premiumSubscriprionInfo = [
    {
      id: 1,
      label: "Status",
      info:
        userInformation?.latest_user_subscription?.status === "active" ||
        userInformation?.latest_user_subscription?.status === "trialing" ||
        userInformation?.latest_user_subscription?.status === "succeeded" ? (
          <Stack alignItems="flex-end" direction="row" justifyContent="space-between">
            <Typography color="success.main">Active</Typography>
            {userInformation?.latest_user_subscription?.started_with_trial && (
              <Chip label="Free Trial Activated" sx={styles.chipWithIcon} />
            )}
          </Stack>
        ) : (
          <Stack alignItems="flex-end" direction="row" justifyContent="space-between">
            <Typography color="error.main">Cancelled</Typography>
          </Stack>
        ),
    },
    {
      id: 2,
      label: "Last Payment",
      info: `$ ${(
        ((isPaymentType
          ? userInformation?.latest_user_subscription?.ext_data?.amount_received
          : userInformation?.latest_user_subscription?.ext_data?.latest_invoice?.amount_paid) ||
          0) / 100
      ).toFixed(2)} usd`,
    },
    {
      id: 3,
      label: "Purchase Date",
      info: `${dayjs(userInformation?.latest_user_subscription?.membership_start_at).format(
        "MM/DD/YYYY",
      )}`,
    },
    {
      id: 4,
      label: `${
        userInformation?.latest_user_subscription?.started_with_trial ||
        userInformation?.latest_user_subscription?.status === "active" ||
        userInformation?.latest_user_subscription?.status === "trialing" ||
        userInformation?.latest_user_subscription?.status === "succeeded"
          ? `${isPaymentType ? "Valid for" : "Valid until"}`
          : "Renews"
      }`,
      info: `${
        isPaymentType
          ? "Lifetime"
          : dayjs(userInformation?.latest_user_subscription?.membership_end_at).format("MM/DD/YYYY")
      }`,
    },
  ];

  const handleCancelSubscription = async () => {
    if (
      userInformation?.latest_user_subscription?.status === "active" ||
      userInformation?.latest_user_subscription?.status === "trialing" ||
      userInformation?.latest_user_subscription?.status === "succeeded"
    ) {
      setIsLoading(true);
      try {
        const { status } = await ServicePremium.canselSubscription();

        if (status === 200) {
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Subscription canceled successfully",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
        setIsLoading(false);
      }
    }
  };

  const isWebSubscription = userInformation?.latest_user_subscription?.store === "web";

  return (
    <Box>
      <List disablePadding sx={styles.scrollContainer}>
        <Box component="li" sx={styles.accountItemsContainer}>
          <List disablePadding sx={styles.accountItems}>
            {premiumSubscriprionInfo.map((item) => (
              <ListItem key={item.id} divider>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    component: "span",
                    variant: "caption",
                    sx: styles.listItemLabel,
                  }}
                  secondary={item.info}
                  secondaryTypographyProps={{
                    component: "p",
                    variant: "body1",
                    sx: styles.listItemInfo,
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </List>
      <Stack direction="row" justifyContent="end" spacing={1} sx={{ p: 3, pt: 0 }}>
        <Button color="error" type="submit" onClick={onOpen}>
          Cancel Subscription
        </Button>
        <Button type="submit" variant="contained" onClick={onClose}>
          Ok
        </Button>
      </Stack>
      {isWebSubscription ? (
        <AlertDialog
          cancelButtonTitle="Close"
          close={() => modalOnClose()}
          confirm={handleCancelSubscription}
          open={isOpen}
          positiveButtonColor="error"
          positiveButtonDisabled={isLoading}
          positiveButtonTitle={
            isLoading ? (
              <CircularProgress size={20} sx={{ color: "white" }} />
            ) : (
              "Cancel Subscription"
            )
          }
          title="Cancel Subscription"
        >
          <Typography variant="body2">
            Are you sure you want to cancel your subscription? You&apos;ll still be able to use your
            subscription until{" "}
            <span style={{ ...styles.dateExpiration }}>
              {dayjs(userInformation?.latest_user_subscription?.membership_start_at).format(
                "MM/DD/YYYY",
              )}
            </span>
            .
          </Typography>
        </AlertDialog>
      ) : (
        <AlertDialog
          removeCancelButton
          close={() => modalOnClose()}
          confirm={() => modalOnClose()}
          message={
            <>
              <Box mb={2}>
                To unsubscribe from the premium version of Argo, please follow instructions below:
              </Box>
              <Box>
                {userInformation?.latest_user_subscription?.store === "play_store" ? (
                  <Link
                    href={userInformation?.latest_user_subscription?.management_url}
                    sx={styles.link}
                    target="_blank"
                    underline="hover"
                  >
                    <Typography sx={{ fontWeight: 700 }}>Google Play Store Guid </Typography>
                    <Launch sx={{ ml: 1 }} />
                  </Link>
                ) : (
                  <Link
                    href={userInformation?.latest_user_subscription?.management_url}
                    sx={styles.link}
                    target="_blank"
                    underline="hover"
                  >
                    <Typography sx={{ fontWeight: 700 }}>Apple App Store Guide </Typography>
                    <Launch sx={{ ml: 1 }} />
                  </Link>
                )}
              </Box>
            </>
          }
          open={isOpen}
          positiveButtonTitle="Ok"
          title="Cancel Subscription"
        />
      )}
    </Box>
  );
}
