/* global google */
import {
  AdvancedMarker as GoogleAdvancedMarker,
  AdvancedMarkerAnchorPoint,
  CollisionBehavior,
} from "@vis.gl/react-google-maps";
import React, { useCallback, useRef, useState } from "react";
import { Box } from "@mui/material";

import { Position } from "@/types/map";
import { EMarkerPopoverPositions } from "@/types/marker";
import { setPopoverPosition } from "@/utils/globals";
import { styles } from "@/components/FavoriteCheckbox/styles";

interface IAdvancedMarker {
  position: Position | any;
  icon: string | React.ReactNode;
  selected?: boolean;
  children?: React.ReactNode;
  alwaysShown?: boolean;
  draggable?: boolean;
  onClick: (e?: any) => void;
  onDragStart?: (e: any) => void;
  onDragEnd?: (e: any) => void;
  onDrag?: (event: google.maps.MapMouseEvent) => void;
}

const HIGH_ZINDEX = 999;
const LOW_ZINDEX = 1;

function AdvancedMarker({
  position,
  icon,
  selected,
  children,
  alwaysShown,
  draggable,
  onClick,
  onDragStart,
  onDragEnd,
  onDrag,
}: IAdvancedMarker) {
  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);
  const [popoverDirectionOnMap, setPopoverDirectionOnMap] =
    useState<EMarkerPopoverPositions | null>(EMarkerPopoverPositions.Top);
  const enterTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [show, setShow] = useState(alwaysShown || false);

  /**
   * With the popoverDirection this function returns the required style for the popover
   * @param popoverDirection
   * @returns {Object} - The style object
   */
  const handleSetInfowWindowPosition = (popoverDirection: EMarkerPopoverPositions) => {
    if (popoverDirection === EMarkerPopoverPositions.Top) {
      return styles.popOverTop;
    }
    if (popoverDirection === EMarkerPopoverPositions.Bottom) {
      return styles.popOverBottom;
    }
    if (popoverDirection === EMarkerPopoverPositions.TopLeft) {
      return styles.popOverTopLeft;
    }
    if (popoverDirection === EMarkerPopoverPositions.TopRight) {
      return styles.popOverTopRight;
    }
    if (popoverDirection === EMarkerPopoverPositions.BottomLeft) {
      return styles.popOverBottomLeft;
    }
    if (popoverDirection === EMarkerPopoverPositions.BottomRight) {
      return styles.popOverBottomRight;
    }

    return styles.popOverTop;
  };

  const handleMouseClick = () => onClick();

  const handleMouseEnter = useCallback((event: any) => {
    if (enterTimeoutRef.current) {
      clearTimeout(enterTimeoutRef.current);
    }

    const popoverDirection = setPopoverPosition({
      ...event,
      clientX: event.x,
      clientY: event.y,
    });

    setPopoverDirectionOnMap(popoverDirection as EMarkerPopoverPositions);

    if (!alwaysShown) enterTimeoutRef.current = setTimeout(() => setShow(true), 500);
  }, []);

  const handleMouseLeave = () => {
    if (enterTimeoutRef.current) {
      clearTimeout(enterTimeoutRef.current);
    }
    setShow(false);
  };

  /**
   * This function returns if the popover is grown to the top and we should pass this to the popover(children)
   */
  const grownToTop =
    popoverDirectionOnMap === EMarkerPopoverPositions.Top ||
    popoverDirectionOnMap === EMarkerPopoverPositions.TopLeft ||
    popoverDirectionOnMap === EMarkerPopoverPositions.TopRight;

  return (
    <GoogleAdvancedMarker
      ref={markerRef}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
      collisionBehavior={CollisionBehavior.OPTIONAL_AND_HIDES_LOWER_PRIORITY}
      draggable={draggable}
      position={position}
      zIndex={show ? HIGH_ZINDEX : LOW_ZINDEX}
      onClick={() => handleMouseClick()}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={alwaysShown ? undefined : handleMouseLeave}
    >
      <Box>
        <Box
          sx={
            selected
              ? {
                  ...styles.markerContainer,
                  ...styles.markerContainerSelected,
                }
              : show
              ? { pt: 0.5, pr: 3, pl: 3 }
              : styles.markerContainer
          }
        >
          {typeof icon === "string" ? <img alt="Poi" height={36} src={icon} width={36} /> : icon}
        </Box>
        {show && children && (
          <Box sx={styles.popoverWrapper}>
            <Box
              sx={{
                ...styles.popoverContainer,
                ...handleSetInfowWindowPosition(popoverDirectionOnMap as EMarkerPopoverPositions),
              }}
            >
              {React.cloneElement(children as React.ReactElement<any>, {
                grownToTop,
              })}
            </Box>
          </Box>
        )}
      </Box>
    </GoogleAdvancedMarker>
  );
}

export default AdvancedMarker;
