import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  cardMedia: {
    borderRadius: 0.5,
    border: "1px solid",
    borderColor: "divider",
    cursor: "pointer",
  },

  waypointIcon: {
    width: "20px",
    height: "20px",
    background: defaultTheme.palette.grey[100],
    color: defaultTheme.palette.primary.light,
    borderRadius: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 400,
    fontSize: "12px",
  },

  waypointIconContainer: {
    p: "2px",
  },

  voyageDetails: {
    "& .voyageDetailRow div": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },

    "& .voyageDetailRow": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      px: 1.5,
      py: 0.75,
      pt: 1,
      borderRadius: 0.5,
    },

    "& .voyageDetailRow:nth-of-type(odd)": {
      backgroundColor: "grey.100",
    },

    "& .voyageDetailRow h4": {
      lineHeight: "normal",
    },
  },

  voyageDetailsChart: {
    display: "block",
    width: "12px",
    height: "64px",
    my: 0.5,
    flexShrink: 0,
    pointerEvents: "none",
    backgroundColor: "primary.main",
    borderRadius: 2,
    position: "relative",
    "&:before, :after": {
      content: "''",
      position: "absolute",
      display: "block",
      width: "8px",
      height: "8px",
      backgroundColor: "common.white",
      borderRadius: 1,
    },
    "&:before": {
      top: 2,
      left: 2,
    },
    "&:after": {
      bottom: 2,
      left: 2,
    },
  },

  ellipsisOneLine: {
    ...layoutStyles.ellipsisOneLine,
  },

  dataFooter: {
    p: 2,
    backgroundColor: "grey.50",
  },

  iconContent: {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "110%",
      left: "50%",
      width: "1px",
      height: "30px",
      backgroundImage: `linear-gradient(to bottom, ${defaultTheme.palette.grey[400]}, transparent 50%)`,
      backgroundSize: "2px 13px",
      backgroundRepeat: "repeat-y",
      transform: "translateX(-50%)",
    },
  },
};
