export enum ERouteSettings {
  Default = "default",
  Avoid = "avoid",
  Outside = "outside",
}

export type TRoutePoints = {
  depth: number;
  id: number;
  lat: number;
  lng: number;
};

export type LatLng = {
  latitude: number;
  longitude: number;
};

export enum IWaypointKind {
  START = "start_point",
  END = "end_point",
  WAYPOINT = "waypoint",
  INNER = "innerpoint",
  TRACK = "track",
}

export enum IWaypointRouting {
  AUTO = "automatic",
  MANUAL = "manual",
}

export enum ITripKind {
  ROUTE = "route",
}

export enum ITripMode {
  DRAFT = "draft",
  ASYNC = "async",
}

export enum ITripStatus {
  DRAFT = "draft",
  PUBLISHED = "published",
}

export enum ITripAccess {
  CANADA = "canada",
}
export interface IWaypoint extends LatLng {
  id: number;
  depth?: number;
  name?: string;
  address?: string;
  referable_type?: "Place" | "Poi" | "ReportPin";
  referable_id?: number;
  kind: IWaypointKind;
  routing?: IWaypointRouting;
  automatic_status?: "complete" | "fail";
  automatic_message?: string;
}
export interface ICourse {
  id: number;
  trip_bridges: any;
  distance: string;
  start_name: string;
  start_address: string | null;
  start_lat: string;
  start_lng: string;
  end_name: string;
  end_address: string | null;
  end_lat: string;
  end_lng: string;
  start_place: string | null;
  end_place: string | null;
  start_poi: string | null;
  end_poi: string | null;
  voyage_id: number;
  created_at: string;
  updated_at: string;
  course_locations: TRoutePoints[];
}

export interface ITrip {
  id: number;
  description: string;
  name: string;
  voyage_type: string;
  created_at: string;
  updated_at: string;
  distance?: string;
  taken: boolean;
  start_date: string | null;
  end_date: string | null;
  manual_route: boolean;
  favorite: boolean;
  favorite_datetime: string | null;
  saved: boolean;
  draft: number;
  draft_depth: number | null;
  buffer: number;
  buffer_depth: number | null;
  average_speed: number;
  fuel_consumption: string;
  fuel_type: string;
  origin: string;
  offline: boolean;
  start_location_name: string;
  end_location_name: string;
  boat_name: string;
  manufacturer_model: string;
  height: string;
  courses: ICourse[];
  planned_coords: TRoutePoints[];
  waypoints: IWaypoint[];
  vessel: any;
  assets: any;
}

export interface IVoyages {
  items: ITrip[];
  total_items: number;
}
