import React from "react";

import AdvancedMarker from "../AdvancedMarker";
import { getPrivatePlaceIcon, getPrivatePlaceIconUnderReview } from "../Utils/PrivatePlacesIcons";

import PrivatePlacePopover from "@/components/MapPopovers/PrivatePlacePopover";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { IPin } from "@/types/reportpins";
import { addSelectedPoi } from "@/store/slices/search";
import { addStartPoint, addEndPoint, addInterimPoint } from "@/store/slices/route";
import { setPremiumSubscriptionModal } from "@/store/slices/user";
import { setReportPinSelectedDrawer } from "@/store/slices/reportpins";
import { setBoaterInfoDrawer } from "@/store/slices/boaters";
import { IconKeysPrivatePlaces, IconKeysPrivatePlacesUnderReview } from "@/types/markers";

interface IPrivatePlaceMarker {
  place: IPin;
  clogpage?: boolean;
}

function PrivatePlaceMarker({ place, clogpage }: IPrivatePlaceMarker) {
  const dispatch = useAppDispatch();
  const { logged } = useAppSelector((state) => state.auth);
  const { selectedPoi } = useAppSelector((state) => state.search);
  const { creatingRoute, inputsFocus, waypoints } = useAppSelector((state) => state.route);
  const { boaterSelectedInfoDrawer } = useAppSelector((state) => state.boaters);
  const { reportPinSelectedDrawer, tempPin } = useAppSelector((state) => state.reportpins);

  const handleSetPoi = (e: React.MouseEventHandler<HTMLAnchorElement>, item: IPin) => {
    if (logged && !clogpage) {
      if (!creatingRoute && item) {
        if (boaterSelectedInfoDrawer) dispatch(setBoaterInfoDrawer(null));
        if (reportPinSelectedDrawer) dispatch(setReportPinSelectedDrawer(null));
        dispatch(addSelectedPoi(item));
      } else if (creatingRoute) {
        const lat = Number(item.lat);
        const lng = Number(item.lng);

        if (inputsFocus === 1) {
          dispatch(
            addStartPoint({
              lat,
              lng,
              name: item.name,
            }),
          );
        } else if (inputsFocus === 2) {
          dispatch(
            addEndPoint({
              lat,
              lng,
              name: item.name,
            }),
          );
        } else if (inputsFocus === 3) {
          const updatedInterimPoints = waypoints.map((point) =>
            point.focused
              ? {
                  ...point,
                  value: item.name,
                  focused: false,
                  lat,
                  lng,
                }
              : point,
          );

          dispatch(
            addInterimPoint([
              ...updatedInterimPoints,
              { id: `Item ${updatedInterimPoints.length + 1}`, value: "", focused: true },
            ]),
          );
        }
      } else {
        dispatch(setPremiumSubscriptionModal(true));
      }
    }
  };

  const icon = place.submit
    ? getPrivatePlaceIconUnderReview(place.place_type as IconKeysPrivatePlacesUnderReview)
    : getPrivatePlaceIcon(place.place_type as IconKeysPrivatePlaces);

  if (tempPin?.editPinInfo?.id === place?.id) return null;

  return (
    <AdvancedMarker
      key={place.id}
      icon={icon}
      position={{ lat: Number(place.lat), lng: Number(place.lng) }}
      selected={selectedPoi?.id === place.id}
      onClick={(e: React.MouseEventHandler<HTMLAnchorElement>) => handleSetPoi(e, place)}
    >
      {logged && (
        <PrivatePlacePopover
          icon={icon}
          inCaptainsLog={clogpage}
          place={place}
          onClick={(e: React.MouseEventHandler<HTMLAnchorElement>) => handleSetPoi(e, place)}
        />
      )}
    </AdvancedMarker>
  );
}

export default PrivatePlaceMarker;
