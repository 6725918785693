import React, { useState } from "react";
import {
  Box,
  Button,
  CardMedia,
  Divider,
  ListSubheader,
  Stack,
  Typography,
  List,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  ThumbUpAlt,
  ThumbDownAlt,
  RateReview,
  DeleteOutline,
  EditOutlined,
  MoreVert,
  CloseOutlined,
} from "@mui/icons-material";
import { useUnitSystem, LengthUnits } from "@argonav/unit-system";

import BaseModal from "../Modals/BaseModal";
import CommentAddModal from "../Modals/CommentAddModal";
import CommentsViewModal from "../Modals/CommentsViewModal";

import { styles } from "./styles";
import ReportPinCommentListItem from "./ReportPinCommentListItem";

import dayjs from "@/config/dayjs";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { getDistanceFromLocation } from "@/utils/poi";
import { snakeCaseToHumanReadable } from "@/utils/globals";
import { KEY_VOTE_DOWN, KEY_VOTE_UP } from "@/utils/keys";
import { handleCheckVoteDrawer } from "@/store/slices/reportpins/actions";
import { IPin, EKindReportPin } from "@/types/reportpins";
import EmptyState from "@/components/EmptyState";
import { setTempPin, setReportPinSelectedDrawer, setMyPinsDrawer } from "@/store/slices/reportpins";
import useDisclosure from "@/hooks/useDisclosure";
import servicePins from "@/services/pins";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { Props } from "@/types/users";

enum EViewsModalSteps {
  Views = "Views",
  Form = "Form",
}

export default function ReportPinInfo({ close, isDrawer }: Props) {
  const { currentPosition } = useAppSelector((state) => state.map);
  const dispatch = useAppDispatch();
  const { reportPinSelectedDrawer } = useAppSelector((state) => state.reportpins);
  const [openCommentsViewModal, setOpenCommentsViewModal] = useState<boolean>(false);
  const [openCommentAddModal, setCommentAddModal] = useState<boolean>(false);
  const [viewModalSteps, setViewModalSteps] = useState<EViewsModalSteps>(EViewsModalSteps.Views);
  const { distanceUnitHandler, coordinatesFormatHandler } = useUnitSystem();
  const { userInformation } = useAppSelector((state) => state.user);
  const { onOpen, isOpen, onClose } = useDisclosure();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const distanceAway = distanceUnitHandler({
    originalUnit: LengthUnits.METERS,
    originalValue: Number(
      getDistanceFromLocation(currentPosition || { lat: 0, lng: 0 }, {
        lat: reportPinSelectedDrawer?.lat,
        lng: reportPinSelectedDrawer?.lng,
      }),
    ),
  });

  const previous: dayjs.Dayjs = dayjs(reportPinSelectedDrawer.created_at);

  const handleSetModalStep = () => {
    setViewModalSteps(
      viewModalSteps === EViewsModalSteps.Views ? EViewsModalSteps.Form : EViewsModalSteps.Views,
    );
  };

  const handleCloseReviewModal = () => {
    setOpenCommentsViewModal(false);
    setViewModalSteps(EViewsModalSteps.Views);
  };

  const handleSendVote = (reportPinSelected: IPin, voteType: string) => {
    dispatch(handleCheckVoteDrawer(reportPinSelected, voteType));
  };

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(reportPinSelectedDrawer?.lat),
    longitude: Number(reportPinSelectedDrawer?.lng),
  });

  const handleEditPin = (reportPin: any | undefined) => {
    if (reportPin) {
      dispatch(setReportPinSelectedDrawer(null));
      dispatch(
        setTempPin({
          value: reportPin?.kind,
          location: { lat: reportPin?.lat, lng: reportPin?.lng },
          editPinInfo: reportPin,
        }),
      );
    }
  };

  const handleDeleteReportPin = async (reportPinId: number) => {
    if (reportPinId) {
      try {
        const { status } = await servicePins.deleteReportPin(reportPinId);

        if (status === 200) {
          dispatch(setReportPinSelectedDrawer(null));
          dispatch(setTempPin(null));
          dispatch(setMyPinsDrawer({ open: true, type: "reportpins" }));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Report Pin was deleted.",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  const categoryHandled =
    reportPinSelectedDrawer.kind === EKindReportPin.seaLife
      ? "Sea Life"
      : reportPinSelectedDrawer.kind;

  return (
    <Box sx={isDrawer ? styles.mainContainerDrawer : styles.mainContainer}>
      <Stack
        direction="row"
        spacing={0}
        sx={isDrawer ? styles.cornerActionsDrawer : styles.cornerActions}
      >
        {reportPinSelectedDrawer?.user_id === userInformation?.id && (
          <>
            <IconButton
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              aria-label="more"
              id="long-button"
              size="small"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>
            <Menu
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              id="long-menu"
              open={open}
              sx={styles.menuVertContainer}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleEditPin(reportPinSelectedDrawer)}>
                <ListItemIcon>
                  <EditOutlined fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
              <MenuItem onClick={onOpen}>
                <ListItemIcon>
                  <DeleteOutline fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
        {!isDrawer && (
          <Button
            aria-label="delete"
            color="whiteBg"
            size="small"
            sx={{ ml: "1px" }}
            variant="iconButtonContained"
            onClick={close}
          >
            <CloseOutlined />
          </Button>
        )}
      </Stack>

      <Stack pb={2} pl={2} pr={6} pt={!isDrawer ? 1 : 10} px={2} spacing={1.5}>
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          sx={{ position: "relative" }}
        >
          <Stack>
            <Typography component="h2" sx={styles.poiTitle} variant="h5">
              {reportPinSelectedDrawer.subtype
                ? `${snakeCaseToHumanReadable(
                    reportPinSelectedDrawer.subtype,
                  )} ${snakeCaseToHumanReadable(reportPinSelectedDrawer.kind)}`
                : snakeCaseToHumanReadable(categoryHandled)}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {dayjs(previous).fromNow()}{" "}
              {currentPosition && `• ${distanceAway.value} ${distanceAway.unit} away`}
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="body1">
          {reportPinSelectedDrawer.description || "No description"}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {latLngFormatted}
        </Typography>
        <Stack direction="row" spacing={1}>
          <Button
            className="Mui-active"
            color="whiteBg"
            size="small"
            startIcon={<ThumbUpAlt />}
            sx={styles.likeButton}
            variant="contained"
            onClick={() => handleSendVote(reportPinSelectedDrawer, KEY_VOTE_UP)}
          >
            {reportPinSelectedDrawer.pin_votes_up_count}
          </Button>
          <Button
            className="Mui-active"
            color="whiteBg"
            size="small"
            startIcon={<ThumbDownAlt />}
            sx={styles.dislikeButton}
            variant="contained"
            onClick={() => handleSendVote(reportPinSelectedDrawer, KEY_VOTE_DOWN)}
          >
            {reportPinSelectedDrawer.pin_votes_down_count}
          </Button>
        </Stack>
      </Stack>
      {reportPinSelectedDrawer.asset_links.length > 0 && (
        <>
          <Divider />

          <ListSubheader>Photo</ListSubheader>

          <Box pb={3} px={2}>
            <CardMedia
              alt="Satellite map type"
              component="img"
              height="160"
              image={reportPinSelectedDrawer.asset_links[0]}
              sx={styles.cardMedia}
            />
          </Box>
        </>
      )}

      <Divider />

      <List disablePadding sx={styles.list}>
        <ListSubheader>Comments</ListSubheader>
        {reportPinSelectedDrawer?.reviews && reportPinSelectedDrawer?.reviews.length <= 0 ? (
          <EmptyState
            imageURL="img/placeholders/EmptyStateRanking.svg"
            subtitle="There are no comments  yet. Would you like to write a comment for this Report Pin?"
            sx={{ px: 4 }}
            title="No Comments Yet"
          />
        ) : (
          reportPinSelectedDrawer?.reviews
            ?.slice(0, 3)
            .map((item: any) => (
              <ReportPinCommentListItem
                key={item.id}
                comment={item.message}
                time={item.created_at}
                userAvatar={item.user.image}
                userFirstName={item.user.first_name}
                userLastName={item.user.last_name}
              />
            ))
        )}
      </List>

      <Stack p={2} spacing={1}>
        <Button
          fullWidth
          startIcon={<RateReview />}
          variant="outlined"
          onClick={() => setCommentAddModal(true)}
        >
          Write a Comment
        </Button>

        {reportPinSelectedDrawer?.reviews && reportPinSelectedDrawer?.reviews.length >= 3 && (
          <Button fullWidth onClick={() => setOpenCommentsViewModal(true)}>
            See all comments
          </Button>
        )}
      </Stack>

      {openCommentsViewModal && (
        <BaseModal
          ariaDescribedby="Comments"
          ariaLabelledby="Comments"
          open={openCommentsViewModal}
          size="xs"
          title="Comments"
          onClose={handleCloseReviewModal}
        >
          {viewModalSteps === EViewsModalSteps.Views ? (
            <CommentsViewModal
              reportPinSelected={reportPinSelectedDrawer}
              onSetModal={handleSetModalStep}
            />
          ) : (
            <CommentAddModal
              reportPinSelected={reportPinSelectedDrawer}
              onClose={handleCloseReviewModal}
            />
          )}
        </BaseModal>
      )}

      {openCommentAddModal && (
        <BaseModal
          ariaDescribedby="Write a Comment"
          ariaLabelledby="Write a Comment"
          open={openCommentAddModal}
          size="xs"
          title="Write a Comment"
          onClose={() => setCommentAddModal(false)}
        >
          <CommentAddModal
            reportPinSelected={reportPinSelectedDrawer}
            onClose={() => setCommentAddModal(false)}
          />
        </BaseModal>
      )}

      {isOpen && (
        <BaseModal
          ariaDescribedby="Delete Report Pin"
          ariaLabelledby="Delete Report Pin"
          open={isOpen}
          size="xs"
          title="Delete Report Pin"
          onClose={onClose}
        >
          <Stack pb={2} pt={1} px={3} spacing={2}>
            <Typography color="text.primary" variant="body1">
              Are you sure you want to delete this Report pin?
            </Typography>

            <Stack direction="row" justifyContent="end" spacing={1}>
              <Button type="submit" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={() => handleDeleteReportPin(reportPinSelectedDrawer?.id)}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </BaseModal>
      )}
    </Box>
  );
}
