import { Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { styles } from "./styles";

import ArgoMap from "@/components/ArgoMap";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setGreetingModal, setSnackBarMsj } from "@/store/slices/auth/actions";
import CustomizedSnackbar from "@/components/CustomizedSnackbar";
import BaseModal from "@/components/Modals/BaseModal";
import GreetingModal from "@/components/Modals/GreetingModal";
import { KEY_GMODAL_NON_USER, KEY_GMODAL_USER } from "@/utils/keys";
import PromoModal from "@/components/Modals/PromoModal";
import { setPremiumSubscriptionModal, setSignInModal } from "@/store/slices/user";
import AlertDialog from "@/components/AlertDialog/AlertDialog";

function Home() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { snackBarMsj, greetingModal, user, logged } = useAppSelector((state) => state.auth);

  const { userInformation, premiumSubscriptionModal, signInModal } = useAppSelector(
    (state) => state.user,
  );

  const isTrial = id === "freetrial";

  useEffect(() => {
    if (isTrial) {
      if (logged) {
        navigate("/premium-payment");
      }
    }
  }, [id]);

  const handleSnackBarClose = (reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setSnackBarMsj(false, "", ""));
  };

  const handleCloseGreetingModal = () => {
    dispatch(setGreetingModal(false));
  };

  const handleClosePremiumModal = () => {
    dispatch(setPremiumSubscriptionModal(false));
  };

  const handleSetDonShowAgain = () => {
    const gmodalKey = logged && user ? KEY_GMODAL_USER + user.email : KEY_GMODAL_NON_USER;

    localStorage.setItem(gmodalKey, "true");
  };

  const showGreetingModal = () => {
    if (greetingModal) {
      if (logged && userInformation?.user_subscription === "premium") {
        return true;
      }
    }

    return false;
  };

  return (
    <Box sx={styles.mainContainer}>
      <ArgoMap />
      <BaseModal
        ariaDescribedby="Greetings Modal"
        ariaLabelledby="Greetings Modal"
        darkHeader={logged}
        open={showGreetingModal()}
        size="xs"
        onClose={handleCloseGreetingModal}
      >
        <GreetingModal
          action={handleSetDonShowAgain}
          freeTrialUrl={id}
          isLogged={logged}
          isPremium={userInformation?.user_subscription === "premium"}
          onClose={handleCloseGreetingModal}
        />
      </BaseModal>

      <AlertDialog
        cancelButtonTitle="Not Now"
        close={() => dispatch(setSignInModal(false))}
        confirm={() => navigate("/login")}
        message="Signing into Argo unlocks many of the app's features and will allow you to create routes tailored to your boat's draft for safer navigation."
        open={signInModal}
        positiveButtonTitle="Sign In"
        title="Sign In / Create Account"
      />

      {premiumSubscriptionModal && (
        <BaseModal
          darkHeader
          ariaDescribedby="Premium Modal Upgrade"
          ariaLabelledby="Premium Modal Upgrade"
          open={premiumSubscriptionModal}
          size="xs"
          onClose={handleClosePremiumModal}
        >
          <PromoModal />
        </BaseModal>
      )}
      <CustomizedSnackbar
        close={handleSnackBarClose}
        message={snackBarMsj.msj}
        open={snackBarMsj.state}
        severity={snackBarMsj.type}
      />
    </Box>
  );
}

export default Home;
