import { getDistance } from "geolib";

import { Position } from "@/types/map";

export type TBOATTYPE = {
  value: string;
  label: string;
};
export const BOAT_TYPE: TBOATTYPE[] = [
  { value: "power", label: "Power" },
  { value: "sail", label: "Sail" },
  { value: "jet_ski", label: "PWC" },
  { value: "paddle", label: "Paddle" },
  { value: "other", label: "Other" },
];

const priorityRoutes = {
  // Maximal distance from start and end locations to use preferred routes (priority cost factor).
  maxDistance: 402336, // 250 miles in meters
  // Cost factor to use to "disable" preferred routes
  disabledCostFactor: 1.0,
  // Cost factor to prefer priority routes
  enabledCostFactor: 0.5,
};

export const vesselDefault = {
  averageSpeed: 20.0, // knots
  defaultFuelConsumption: 20, // GPH
  defaultBuffer: 1.524, // meters
  defaultDraft: 1.524, // meters
  defaultHeight: 16, // ft
  // knots
  defaultSpeeds: {
    jet_ski: 50,
    power: 20,
    sail: 5,
    paddle: 3,
    other: 10,
  },
};

export const feetToMeter = 0.3048;
export const meterToFeet = 3.28084;

export const getPriorityCostFactor = (start: Position, end: Position) => {
  if (start && end) {
    const distance = getDistance(
      { longitude: Number(start.lng), latitude: Number(start.lat) },
      { longitude: Number(end.lng), latitude: Number(end.lat) },
    );

    return distance <= priorityRoutes.maxDistance
      ? priorityRoutes.enabledCostFactor
      : priorityRoutes.disabledCostFactor;
  }

  return priorityRoutes.disabledCostFactor;
};
