/* global google */
import { useEffect, useState, useRef } from "react";
import { Map, useMap } from "@vis.gl/react-google-maps";
import { useParams, useLocation } from "react-router-dom";

import { getDepthMapOverlay } from "../MapOverlays/DepthMapOverlay";
import { getNOAAMapOverlay } from "../MapOverlays/NOAAMapOverlay";
import { handleLayersController } from "../Utils/Layers";
import { getRouteLayer } from "../MapLayers/RouteLayer";

import BaseMapsConfig from "./BaseMapsConfig";
import MapContentWrapper from "./MapContentWrapper";

import {
  handleGetCurrentDepthShading,
  CURRENT_POSITION_ZOOM,
  USA_CENTER,
  MIN_ZOOM_MAP_LIMIT,
} from "@/utils/maps";
import { argoApi, useGetDepthShadingsQuery, useGetUserOptionsQuery } from "@/store/api/argoApi";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import CenteredCircularProgress from "@/components/CenteredCircularProgress";
import useGeoLocation from "@/hooks/useGeoLocation";
import { setCoords, setIsLongPress, addCenter } from "@/store/slices/map";
import useSearch from "@/hooks/useSearch";
import {
  addStartPoint,
  addEndPoint,
  addInterimPoint,
  EditWaypointStatus,
  IInterimPoint,
  setEditingWaypoint,
  setLockedValue,
} from "@/store/slices/route";
import { setTempPinCopy } from "@/store/slices/reportpins";
import { extractLatLngZoom, getFromLocalStorage } from "@/utils/globals";
import { Position } from "@/types/map";
import { ITrip } from "@/types/route";
import { UserInformation } from "@/types/users";
import { addZoomParam, addCoordsParams, addMapState } from "@/store/slices/mapParams";
import { addSelectedPoiCopy, setShowSearchArea } from "@/store/slices/search";
import { selectDepthShadingURL } from "@/store/slices/depthshading";
import usePremiumUser from "@/hooks/usePremiumUser";
import serviceMap from "@/services/map";
import servicePoi from "@/services/poi";

let clickTimer: string | number | ReturnType<typeof setTimeout> | undefined;

function MainMap() {
  const map = useMap();

  const dispatch = useAppDispatch();
  const isPremium = usePremiumUser();
  const pathLocation = useLocation();
  const { coordsParams, poiId } = useParams();
  const [bounds, setBounds] = useState<google.maps.LatLngBounds | undefined>(undefined);
  const { location } = useGeoLocation();
  const { setCleanPoiList, setCleanPoiCategory, setCleanSelectedPoi, setPoi } = useSearch();
  const { depthShadingDrawerOpen } = useAppSelector((state) => state.depthshading);
  const depthShadingURL = useAppSelector(selectDepthShadingURL);
  const clogpage: boolean = pathLocation.pathname === "/captains-log";
  const isPremiumOrIsPreview = isPremium || (!isPremium && depthShadingDrawerOpen);
  const pressStartTime = useRef<number | null>(null);

  const { logged, user } = useAppSelector((state) => state.auth);
  const {
    userOptions,
    subscriptionInfoModal,
    openAccount,
    openProfile,
    openUserOptions,
    userInformation,
  } = useAppSelector((state) => state.user);
  const { creatingRoute, routeCreated, inputsFocus, waypoints, editWaypointStatus } =
    useAppSelector((state) => state.route);
  const { selectedTrip } = useAppSelector((state) => state.captainslog);
  const { mapState } = useAppSelector((state) => state.mapParams);
  const { tempPinCopy, allowCoordinates, myPinsDrawer } = useAppSelector(
    (state) => state.reportpins,
  );
  const { tripEditMode } = useAppSelector((state) => state.captainslog);

  const { data: userOptionsData } = useGetUserOptionsQuery(undefined);
  const { mapType, openMapOptions } = useAppSelector((state) => state.map);
  const { openAccordion, selectedPoi, selectedPoiCopy, poiList, editPoiCoordinates } =
    useAppSelector((state) => state.search);
  const shaderOpacity = userOptionsData?.data?.depth_shading_enabled
    ? userOptionsData?.data?.shader_opacity
    : 0;
  const unit = userOptions?.length_unit || userOptionsData?.data?.length_unit;
  const contourLines = userOptions?.depth_shading_contour_lines_enabled;
  const { data: depthShadingData, isLoading: isLoadingDepthShadingData } = useGetDepthShadingsQuery(
    { user_id: user?.id },
  );

  const [locationOnWater, setLocationOnWater] = useState<boolean>(false);

  const handleGetDepth = async (coordinates: Position) => {
    try {
      const { data, status } = await serviceMap.getDepth(coordinates);

      if (status === 200) {
        if (Number(data.depth) > 0) {
          setLocationOnWater(true);
        } else {
          setLocationOnWater(false);
        }
      }
    } catch (error: any) {
      setLocationOnWater(false);
    }
  };

  useEffect(() => {
    if (!isPremium && depthShadingData) {
      dispatch(
        argoApi.util.updateQueryData("getDepthShadings", { user_id: user?.id }, (draft) => {
          if (draft?.data?.items?.length > 0) {
            draft.data.items = draft.data.items.map((item: any) => ({
              ...item,
              current: item.default_flag === true,
            }));
          }
        }),
      );
    }
  }, [isPremium, isLoadingDepthShadingData]);

  const zoomControl = (value: number) => {
    const currentZoom = value;
    const currentZoomRounded = Math.round(currentZoom);

    if (currentZoom !== currentZoomRounded) {
      dispatch(addZoomParam(currentZoomRounded));
      if (map) {
        map.setZoom(currentZoomRounded);
      }
    }
  };

  useEffect(() => {
    if (!map) return;
    /** *
     * This function is used to handle long click event on the map
     * @param map
     */
    map.addListener("mousedown", (e: google.maps.MapMouseEvent) => {
      pressStartTime.current = Date.now();
      clickTimer = setTimeout(() => {
        if (
          !openAccordion &&
          !openMapOptions &&
          !openUserOptions &&
          !subscriptionInfoModal &&
          !openAccount &&
          !openProfile &&
          !myPinsDrawer?.open
        ) {
          dispatch(
            setCoords({
              lat: e.latLng?.lat().toFixed(6),
              lng: e.latLng?.lng().toFixed(6),
              name: `${e.latLng?.lat().toFixed(6)}, ${e.latLng?.lng().toFixed(6)}`,
              place_type: "my_location",
            }),
          );
        }
      }, 1000);
    });

    // This is used to remove the timer when the mouse is up
    map.addListener("mouseup", () => {
      if (pressStartTime.current !== null) {
        const pressDuration = Date.now() - pressStartTime.current;

        if (pressDuration > 500) {
          dispatch(setIsLongPress(true));
        } else {
          dispatch(setIsLongPress(false));
        }
      }
      clearTimeout(clickTimer);
    });
    // This is used to remove the timer when the mouse is moved
    map.addListener("mousemove", () => {
      clearTimeout(clickTimer);
    });
  }, [map]);

  const handleGetPoiById = async (poiId: string) => {
    if (poiId) {
      try {
        const { status, data } = await servicePoi.getPoiById(Number(poiId));

        if (status === 200) {
          setPoi(data.data);
        }
      } catch (error) {
        return null;
      }
    }
  };

  useEffect(() => {
    if (!map) return;
    if (poiId) handleGetPoiById(poiId);
    const coordsFromParams = extractLatLngZoom(coordsParams);

    if (mapState) {
      map.moveCamera({ center: mapState.center, zoom: mapState.zoom });
    } else if (coordsFromParams) {
      const center: Position = {
        lat: Number(coordsFromParams.lat),
        lng: Number(coordsFromParams.lng),
      };

      dispatch(addCoordsParams(coordsParams));
      dispatch(addZoomParam(coordsFromParams.zoom));
      map.moveCamera({ center, zoom: coordsFromParams.zoom });
      zoomControl(coordsFromParams.zoom + 0.1);
    } else if (userOptions?.initial_address) {
      const center: Position = {
        lat: Number(userOptions?.initial_lat),
        lng: Number(userOptions?.initial_lng),
      };

      dispatch(addZoomParam(CURRENT_POSITION_ZOOM));
      map.moveCamera({ center, zoom: CURRENT_POSITION_ZOOM });
      zoomControl(CURRENT_POSITION_ZOOM);
    } else if (location) {
      dispatch(addZoomParam(CURRENT_POSITION_ZOOM));
      map.moveCamera({ center: location, zoom: CURRENT_POSITION_ZOOM });
      zoomControl(CURRENT_POSITION_ZOOM);
    } else {
      map.moveCamera({ center: USA_CENTER, zoom: MIN_ZOOM_MAP_LIMIT });
      zoomControl(MIN_ZOOM_MAP_LIMIT);
    }
  }, [map, location, userOptions?.initial_address]);

  useEffect(() => {
    const storedStartPoint = getFromLocalStorage("startPointLocked");

    if (!locationOnWater && location && !storedStartPoint) handleGetDepth(location);
    if (storedStartPoint && !tripEditMode) {
      dispatch(setLockedValue(true));
      dispatch(
        addStartPoint({
          lat: storedStartPoint.lat,
          lng: storedStartPoint.lng,
          name: `${storedStartPoint.name}`,
        }),
      );
      dispatch(
        addCenter({
          lat: storedStartPoint && parseFloat(String(storedStartPoint.lat)),
          lng: storedStartPoint && parseFloat(String(storedStartPoint.lng)),
        }),
      );
    } else if (locationOnWater && location && !tripEditMode) {
      dispatch(
        addStartPoint({
          lat: location.lat,
          lng: location.lng,
          name: `Current Location`,
        }),
      );
      dispatch(
        addCenter({
          lat: parseFloat(String(location.lat)),
          lng: parseFloat(String(location.lng)),
        }),
      );
    }
  }, [location, locationOnWater]);

  /**
   * This function is used to update the url with the current coordinates
   * @param lat latitude
   * @param lng longitude
   * @param z zoom
   */
  const updateUrlWithCoordinates = (lat: number, lng: number, z: number) => {
    const baseUrl = window.location.origin;
    const poi =
      selectedPoi && Object.prototype.hasOwnProperty.call(selectedPoi, "poi_type")
        ? `/poi/${selectedPoi?.id}/`
        : `${baseUrl}/`;
    const newUrl = `${poi}@${lat},${lng},${z}z`;

    window.history.replaceState({}, "", newUrl);
  };

  const handleCleanListOfResults = () => {
    setCleanPoiList();
    setCleanPoiCategory();
  };

  const handleClickMarkerMap = async (eventClick: any) => {
    if (eventClick.detail) {
      const { lat, lng } = eventClick.detail.latLng;

      if (creatingRoute) {
        if (selectedPoi) setCleanSelectedPoi();
        if (inputsFocus === 1) {
          dispatch(
            addStartPoint({
              lat: lat.toFixed(6),
              lng: lng.toFixed(6),
              name: `${lat.toFixed(6)}, ${lng.toFixed(6)}`,
            }),
          );
          dispatch(setEditingWaypoint(EditWaypointStatus.NONE));
        } else if (inputsFocus === 2) {
          dispatch(
            addEndPoint({
              lat: lat.toFixed(6),
              lng: lng.toFixed(6),
              name: `${lat.toFixed(6)}, ${lng.toFixed(6)}`,
            }),
          );
          dispatch(setEditingWaypoint(EditWaypointStatus.NONE));
        } else if (inputsFocus === 3) {
          const updatedInterimPoints = waypoints.map((point: IInterimPoint) =>
            point.focused
              ? {
                  ...point,
                  focused: false,
                  value: `${lat.toFixed(6)}, ${lng.toFixed(6)}`,
                  lat: lat.toFixed(6),
                  lng: lng.toFixed(6),
                }
              : point,
          );

          if (
            editWaypointStatus === EditWaypointStatus.SIMPLE ||
            editWaypointStatus === EditWaypointStatus.MULTIPLE
          ) {
            dispatch(setEditingWaypoint(EditWaypointStatus.NONE));
          } else {
            dispatch(
              addInterimPoint([
                ...updatedInterimPoints,
                { id: `Item ${updatedInterimPoints.length + 1}`, value: "", focused: true },
              ]),
            );
          }
        }
        dispatch(setCoords(undefined));
      } else if (tempPinCopy && allowCoordinates) {
        dispatch(
          setTempPinCopy({
            ...tempPinCopy,
            location: {
              lat: lat.toFixed(6),
              lng: lng.toFixed(6),
            },
          }),
        );
      } else if (selectedPoiCopy?.editMode && editPoiCoordinates) {
        dispatch(
          addSelectedPoiCopy({ ...selectedPoiCopy, lat: lat.toFixed(6), lng: lng.toFixed(6) }),
        );
      }
    }
  };

  const showSearchByAreaButton = () => {
    if (poiList !== null) {
      dispatch(setShowSearchArea(true));
    }
  };

  const handleBoundsChange = (ev: any) => {
    const newBounds = ev.map.getBounds();

    const compareBounds = (
      bounds1: google.maps.LatLngBounds | undefined,
      bounds2: google.maps.LatLngBounds | undefined,
    ) => {
      if (!bounds1 || !bounds2) return false;

      const ne1 = bounds1.getNorthEast();
      const sw1 = bounds1.getSouthWest();
      const ne2 = bounds2.getNorthEast();
      const sw2 = bounds2.getSouthWest();

      const latDiff = Math.abs(ne1.lat() - ne2.lat()) + Math.abs(sw1.lat() - sw2.lat());
      const lngDiff = Math.abs(ne1.lng() - ne2.lng()) + Math.abs(sw1.lng() - sw2.lng());

      const totalDiff = latDiff + lngDiff;

      return totalDiff > 0.1;
    };

    if (!bounds) {
      setBounds(newBounds);

      return;
    }

    if (compareBounds(bounds, newBounds)) {
      setBounds(newBounds);
    }
  };

  const handleDragEnd = (ev: any) => {
    showSearchByAreaButton();
    handleBoundsChange(ev);
  };

  const handleOnIdle = (ev: any) => {
    const center = ev.map.getCenter();
    const zoom = Number(ev.map.getZoom().toFixed(0));

    dispatch(addMapState({ zoom, center: { lat: center?.lat(), lng: center?.lng() } }));

    if (!clogpage) {
      updateUrlWithCoordinates(
        Number(center.lat().toFixed(6)),
        Number(center.lng().toFixed(6)),
        zoom,
      );
    }

    handleBoundsChange(ev);
  };

  const handleRouteLayerCaptainsLog = (
    courses: ITrip | null,
    userInformation: UserInformation | undefined,
    zoom: number,
  ) => {
    if (clogpage && courses) {
      return getRouteLayer(courses as any, userInformation, zoom, dispatch);
    }

    return [];
  };

  if (logged && isLoadingDepthShadingData) return <CenteredCircularProgress />;

  /* This function returns layers depending  selected configuration */
  const mapLayerConfig = handleLayersController(
    [
      getDepthMapOverlay(
        256,
        handleGetCurrentDepthShading(depthShadingData, shaderOpacity, isPremiumOrIsPreview),
        unit,
        shaderOpacity,
        contourLines,
        depthShadingURL,
      ),
      getNOAAMapOverlay(256),
      !routeCreated?.filteredWaypoints &&
        getRouteLayer(routeCreated, userInformation, map?.getZoom() || 14, dispatch),
      routeCreated?.filteredWaypoints &&
        getRouteLayer(
          { ...routeCreated, planned_coords: routeCreated?.filteredWaypoints.initialSegment },
          userInformation,
          map?.getZoom() || 14,
          dispatch,
        ),
      routeCreated?.filteredWaypoints &&
        getRouteLayer(
          { ...routeCreated, planned_coords: routeCreated?.filteredWaypoints.endSegment },
          userInformation,
          map?.getZoom() || 14,
          dispatch,
        ),
      handleRouteLayerCaptainsLog(selectedTrip, userInformation, map?.getZoom() || 14),
    ],
    mapType,
  );

  return (
    <Map
      {...BaseMapsConfig.MainMap}
      mapTypeId={mapLayerConfig.mapType}
      onClick={handleClickMarkerMap}
      onDragend={(ev) => handleDragEnd(ev)}
      onDragstart={() => clearTimeout(clickTimer)}
      onIdle={handleOnIdle}
      onZoomChanged={(ev) => zoomControl(ev.detail.zoom)}
    >
      {map && (
        <MapContentWrapper
          bounds={bounds}
          clogpage={clogpage}
          location={location}
          mapLayerConfig={mapLayerConfig}
          setPoi={setPoi}
          zoomControl={zoomControl}
          onCleanListOfResults={handleCleanListOfResults}
        />
      )}
    </Map>
  );
}

export default MainMap;
