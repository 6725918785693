import React, { useMemo } from "react";
import { Avatar, Box } from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

import AdvancedMarker from "../AdvancedMarker";
import { getNavAidsIcon } from "../Utils/NavAidsIcons";
import NavigationAidPopover from "../../MapPopovers/NavigationAidPopover";

import { addStartPoint, addEndPoint, addInterimPoint } from "@/store/slices/route";
import { setSignInModal } from "@/store/slices/user";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { INavAids } from "@/types/navaids";
import { IconKeysNavAidsBeacons } from "@/types/markers";

interface INavAidsList {
  items: INavAids[];
  zoom?: number;
  clogpage: boolean;
}

interface INavsAidsData {
  [key: string]: boolean;
}

const ZOOM_LEVEL = 12;
const ZOOM_LEVEL_LABELS = 16;
const CHARACTER_LABELS_RESTRICTION = 5;

const ZOOM_LEVEL_NOOA_RESTRICTION = 16;

const StyledBadge = styled(Badge)<any>(({ theme, extraLabels }: any) => ({
  "& .MuiBadge-badge": {
    right: extraLabels ? -12 : -3,
    top: 13,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 3px",
    backgroundColor: "white",
    fontSize: "12px",
    fontWeight: "bold",
  },
}));

function NavAidsList({ items, zoom, clogpage }: INavAidsList) {
  const dispatch = useAppDispatch();
  const { poisConfigured } = useAppSelector((state) => state.map);
  const { creatingRoute, inputsFocus, waypoints } = useAppSelector((state) => state.route);
  const { logged } = useAppSelector((state) => state.auth);

  const navsAidsStatus: INavsAidsData = {
    buoy: poisConfigured.Buoy,
    beacon: poisConfigured.Buoy,
    restricted_area: poisConfigured["Restricted area"],
  };

  /**
   * This function checks the zoom level to show the pois
   * @param key Type string
   * @returns boolean
   */
  const zoomChecks = (key: string) => {
    if (poisConfigured.noaaLayer) {
      if (zoom && zoom > ZOOM_LEVEL) {
        if ((key !== "buoy" && key !== "beacon") || (zoom && zoom >= ZOOM_LEVEL_NOOA_RESTRICTION)) {
          return true;
        }
      }

      return false;
    }

    if (zoom && zoom > ZOOM_LEVEL) {
      return true;
    }

    return false;
  };

  /**
   * This function handles the set of the aid for routes
   * @param item Type INavAids
   */
  const handleSetAid = (item: INavAids) => {
    if (logged && !clogpage) {
      if (creatingRoute) {
        const lat = Number(item.latitude);
        const lng = Number(item.longitude);

        if (inputsFocus === 1) {
          dispatch(
            addStartPoint({
              lat,
              lng,
              name: item.title,
            }),
          );
        } else if (inputsFocus === 2) {
          dispatch(
            addEndPoint({
              lat,
              lng,
              name: item.title,
            }),
          );
        } else if (inputsFocus === 3) {
          const updatedInterimPoints = waypoints.map((point) =>
            point.focused
              ? {
                  ...point,
                  value: item.name,
                  focused: false,
                  lat,
                  lng,
                }
              : point,
          );

          dispatch(
            addInterimPoint([
              ...updatedInterimPoints,
              { id: `Item ${updatedInterimPoints.length + 1}`, value: "", focused: true },
            ]),
          );
        }
      }
    } else {
      dispatch(setSignInModal(true));
    }
  };

  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        const key = item.kind.replace("/", "").replace(" ", "");

        return zoomChecks(key) && navsAidsStatus[key];
      }),
    [items, zoom, navsAidsStatus, poisConfigured],
  );

  return (
    <>
      {filteredItems.map((navaid: INavAids) => (
        <AdvancedMarker
          key={navaid.id}
          icon={
            zoom &&
            zoom >= ZOOM_LEVEL_LABELS &&
            navaid.info &&
            "objname_short" in navaid.info &&
            navaid?.info?.objname_short?.length <= CHARACTER_LABELS_RESTRICTION ? (
              <Box>
                <StyledBadge
                  badgeContent={`${navaid?.info?.objname_short}`}
                  extraLabels={`${navaid?.info?.objname_short}`.length >= 3}
                >
                  <Avatar
                    alt="Navigational Aids"
                    src={getNavAidsIcon(navaid.icon as IconKeysNavAidsBeacons)}
                  />
                </StyledBadge>
              </Box>
            ) : (
              getNavAidsIcon(navaid.icon as IconKeysNavAidsBeacons)
            )
          }
          position={{ lat: Number(navaid.latitude), lng: Number(navaid.longitude) }}
          onClick={() => handleSetAid(navaid)}
        >
          {logged && (
            <NavigationAidPopover
              icon={getNavAidsIcon(navaid.icon as IconKeysNavAidsBeacons)}
              inCaptainsLog={clogpage}
              navAid={navaid}
            />
          )}
        </AdvancedMarker>
      ))}
    </>
  );
}

export default NavAidsList;
