import jwt_decode from "jwt-decode";
import { AxiosResponse, AxiosResponseHeaders } from "axios";

import { AppDispatch } from "@/store/reducers";
import authServices from "@/services/auth";
import { saveDataUser } from "@/utils/users";
import {
  LoginEmail,
  Props,
  UserLoginApple,
  UserLoginGoogle,
  UserRegisterData,
} from "@/types/users";
import { UserData } from "@/types/auth";
import { KEY_GMODAL_NON_USER, KEY_GMODAL_USER } from "@/utils/keys";
import { getUserInformation, getUserOptions } from "@/store/slices/user/actions";
import userService from "@/services/user";

const getAuthStarted = () => ({
  type: "auth/fetchStarted",
});

const getAuthFailed = (error: any) => ({
  type: "auth/fetchFailed",
  error,
});

const getAuthSuccess = (user: UserData) => ({
  type: "auth/fetchSucceded",
  payload: user,
});

export const removeUser = () => ({
  type: "auth/removeUser",
});

const setLoggedSuccess = (logged: boolean) => ({
  type: "auth/setLoggedSucceded",
  payload: logged,
});

export const setSnackBarMsjSucceded = (snackBarMsj: Props) => ({
  type: "auth/setSnackBarMsjSucceded",
  payload: snackBarMsj,
});

export const setLoading = (value: boolean) => ({
  type: "auth/setLoading",
  payload: value,
});

export const setGreetingModal = (value: boolean) => ({
  type: "auth/setGreetingModal",
  payload: value,
});

export const setSnackBarMsj =
  (state: boolean, type: string, msj: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(setSnackBarMsjSucceded({ state, type, msj }));

      return true;
    } catch (error: any) {
      dispatch(getAuthFailed(error.toString()));

      return false;
    }
  };

export const setLoggedUser = (logged: boolean) => async (dispatch: AppDispatch) => {
  dispatch(getAuthStarted());
  try {
    dispatch(setLoggedSuccess(logged));

    return true;
  } catch (error: any) {
    dispatch(getAuthFailed(error.toString()));
    dispatch(setLoading(false));

    return false;
  }
};

export const authenticate = (dataAuth: LoginEmail) => async (dispatch: AppDispatch) => {
  dispatch(getAuthStarted());

  try {
    const response = await authServices.authenticate(dataAuth);
    const { status, data, headers }: AxiosResponse | AxiosResponseHeaders = response;

    if (status === 200) {
      await userService.addLoginHistory(headers);
      const userData = saveDataUser(data.data, headers);

      dispatch(setLoggedSuccess(true));
      dispatch(getAuthSuccess(userData));

      return true;
    }
    dispatch(setLoading(false));

    return false;
  } catch (error: any) {
    const errors = JSON.parse(error.request.response);

    const message = errors.message ? errors.message : errors.errors;

    dispatch(setSnackBarMsj(true, "error", message) as any);

    dispatch(getAuthFailed(error.toString()));
    dispatch(setLoading(false));

    return false;
  }
};

export const emailRegister = (dataAuth: UserRegisterData) => async (dispatch: AppDispatch) => {
  dispatch(getAuthStarted());
  try {
    const response = await authServices.register(dataAuth);
    const { status }: AxiosResponse | AxiosResponseHeaders = response;

    if (status === 200) {
      dispatch(setLoading(false));

      return true;
    }
    dispatch(setLoading(false));

    return false;
  } catch (error: any) {
    dispatch(
      setSnackBarMsj(
        true,
        "error",
        JSON.parse(error.request.response).errors.full_messages[0],
      ) as any,
    );
    dispatch(getAuthFailed(error.toString()));
    dispatch(setLoading(false));

    return false;
  }
};

export const updateUserDataAction = (data: any) => (dispatch: AppDispatch) => {
  dispatch(getAuthStarted());
  try {
    dispatch(getAuthSuccess(data));

    return true;
  } catch (error: any) {
    dispatch(getAuthFailed(error.toString()));
    dispatch(setLoading(false));

    return false;
  }
};

export const asyncUpdateUserDataAction = () => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));

  const dispatchGreetingModal = (greetingModal: boolean) => {
    if (greetingModal) {
      dispatch(setGreetingModal(!greetingModal));
    } else {
      dispatch(setGreetingModal(true));
    }
  };

  try {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      const localDataUser = JSON.parse(storedUser);

      const userInformation = dispatch(getUserInformation(localDataUser.id) as any);

      if (localDataUser) {
        dispatch(getAuthSuccess(localDataUser));
        dispatch(getUserOptions());
        const userKey = localDataUser.email;
        const greetingModalData = localStorage.getItem(KEY_GMODAL_USER + userKey);
        const greetingModal = greetingModalData ? JSON.parse(greetingModalData) : null;

        dispatchGreetingModal(greetingModal);
      } else {
        const greetingModalData = localStorage.getItem(KEY_GMODAL_NON_USER);
        const greetingModal = greetingModalData ? JSON.parse(greetingModalData) : null;

        dispatchGreetingModal(greetingModal);
      }
      dispatch(setLoading(false));
      if (userInformation) {
        return userInformation;
      }
    }
    dispatch(setLoading(false));
  } catch (error: any) {
    dispatch(getAuthFailed(error.toString()));
    dispatch(setLoading(false));

    return null;
  }
};

export const googleAuth = (access_token: string) => async (dispatch: AppDispatch) => {
  dispatch(getAuthStarted());
  try {
    const userReponse: Props = await authServices.googleAuthUserInfo(access_token);

    const userInfo: Props = await userReponse.json();

    const dataUser: UserLoginGoogle = {
      auth_token: access_token,
      email: userInfo.email,
      first_name: userInfo.given_name,
      last_name: userInfo.family_name,
      image: userInfo.picture,
      uid: userInfo.sub,
    };

    const response: Props = await authServices.googleAuth(dataUser);
    const { status, data } = response;

    if (status === 200) {
      await userService.addLoginHistory(response.headers);
      const userData = saveDataUser(data.data, response.headers);

      dispatch(setLoggedSuccess(true));
      dispatch(getAuthSuccess(userData));

      return true;
    }
    dispatch(setLoading(false));

    return false;
  } catch (error: any) {
    dispatch(getAuthFailed(error.toString()));
    dispatch(setLoading(false));

    return false;
  }
};

export const appleAuth = (identityToken: string) => async (dispatch: AppDispatch) => {
  dispatch(getAuthStarted());
  try {
    const idToken: Props = jwt_decode(identityToken);
    const dataUser: UserLoginApple = {
      fullName: "{}",
      user: idToken.sub,
      identityToken,
    };

    const response: Props = await authServices.appleAuth(dataUser);
    const { status, data } = response;

    if (status === 200) {
      await userService.addLoginHistory(response.headers);
      const userData = saveDataUser(data.data, response.headers);

      dispatch(setLoggedSuccess(true));
      dispatch(getAuthSuccess(userData));

      return true;
    }
    dispatch(setLoading(false));

    return false;
  } catch (error: any) {
    dispatch(getAuthFailed(error.toString()));
    dispatch(setLoading(false));

    return false;
  }
};
