import "@fontsource/cabin/400.css";
import "@fontsource/cabin/700.css";
import { createTheme, alpha } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    whiteBg: Palette["primary"];
    neutral: Palette["primary"];
  }

  interface PaletteOptions {
    whiteBg?: PaletteOptions["primary"];
    neutral?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    whiteBg: true;
    neutral: true;
  }
  interface ButtonPropsVariantOverrides {
    iconButtonContained: true;
    iconButtonOutlined: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    whiteBg: true;
  }
  interface IconButtonPropsVariantOverrides {
    contained: true;
    outlined: true;
  }
}

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#0277BD",
      light: "#11ADEA",
      dark: "#08467E",
    },

    secondary: {
      main: "#19787B",
      light: "#22AEB2",
      dark: "#075658",
    },

    error: {
      main: "#D52B1E",
      light: "#EF5350",
      dark: "#951E15",
    },

    warning: {
      main: "#F5A623",
      light: "#FF9800",
      dark: "#AB7418",
    },

    info: {
      main: "#2196F3",
      light: "#64B6F7",
      dark: "#0B79D0",
    },

    success: {
      main: "#3CC52D",
      light: "#63D057",
      dark: "#007D00",
    },
  },
});

const appTheme = createTheme({
  palette: {
    mode: "light",

    primary: {
      main: "#0277BD",
      light: "#1B9FEE",
      dark: "#08467E",
    },

    secondary: {
      main: "#19787B",
      light: "#22AEB2",
      dark: "#075658",
    },

    error: {
      main: "#D52B1E",
      light: "#EF5350",
      dark: "#951E15",
    },

    warning: {
      main: "#F5A623",
      light: "#FF9800",
      dark: "#AB7418",
    },

    info: {
      main: "#2196F3",
      light: "#64B6F7",
      dark: "#0B79D0",
    },

    success: {
      main: "#3CC52D",
      light: "#63D057",
      dark: "#007D00",
    },

    whiteBg: {
      main: "#FFFFFF",
      light: "#FFFFFF",
      dark: "#FFFFFF",
      contrastText: defaultTheme.palette.text.primary,
    },

    neutral: {
      main: defaultTheme.palette.grey[200],
      light: defaultTheme.palette.grey[100],
      dark: defaultTheme.palette.grey[300],
      contrastText: defaultTheme.palette.common.black,
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1024,
      xl: 1920,
    },
  },

  typography: {
    fontFamily: "Cabin, Helvetica, sans-serif",
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 700,
    },
    body1: {
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 700,
    },
    caption: {
      fontWeight: 400,
    },
  },

  shape: {
    borderRadius: 8,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "100px",
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            borderWidth: "2px",
            "&:hover, &.Mui-disabled": {
              borderWidth: "2px",
            },
          },
        },
        {
          props: { variant: "iconButtonContained" },
          style: {
            minWidth: "unset",
          },
        },
        {
          props: { variant: "iconButtonOutlined" },
          style: {
            minWidth: "unset",
          },
        },
        {
          props: { variant: "iconButtonContained", size: "small" },
          style: {
            padding: "4px",
          },
        },
        {
          props: { variant: "iconButtonOutlined", size: "small" },
          style: {
            padding: "4px",
          },
        },
        {
          props: { variant: "iconButtonContained", size: "medium" },
          style: {
            padding: "6px",
          },
        },
        {
          props: { variant: "iconButtonOutlined", size: "medium" },
          style: {
            padding: "6px",
          },
        },
        {
          props: { variant: "iconButtonContained", size: "large" },
          style: {
            padding: "8px",
          },
        },
        {
          props: { variant: "iconButtonOutlined", size: "large" },
          style: {
            padding: "8px",
          },
        },
        {
          props: { variant: "iconButtonContained", color: "whiteBg" },
          style: {
            backgroundColor: defaultTheme.palette.common.white,
            color: defaultTheme.palette.action.active,
            "&:hover": {
              backgroundColor: defaultTheme.palette.grey[50],
            },
          },
        },

        {
          props: { variant: "iconButtonOutlined", color: "primary" },
          style: {
            color: defaultTheme.palette.primary.main,
            border: `2px solid ${alpha(defaultTheme.palette.primary.main, 0.5)}`,
            "&:hover": {
              backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.04)}`,
            },
          },
        },

        {
          props: { variant: "iconButtonOutlined", color: "secondary" },
          style: {
            color: defaultTheme.palette.secondary.main,
            border: `2px solid ${alpha(defaultTheme.palette.secondary.main, 0.5)}`,
            "&:hover": {
              backgroundColor: `${alpha(defaultTheme.palette.secondary.main, 0.04)}`,
            },
          },
        },

        {
          props: { variant: "iconButtonOutlined", color: "whiteBg" },
          style: {
            color: defaultTheme.palette.common.white,
            transition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            border: `2px solid ${alpha(defaultTheme.palette.common.white, 0.5)}`,
            "&:hover": {
              borderColor: defaultTheme.palette.common.white,
            },
          },
        },

        {
          props: { variant: "outlined", color: "neutral" },
          style: {
            color: defaultTheme.palette.text.secondary,
            border: `2px solid ${alpha(defaultTheme.palette.common.black, 0.12)}`,
            "&:hover": {
              border: `2px solid ${alpha(defaultTheme.palette.common.black, 0.3)}`,
              backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.04)}`,
            },
          },
        },
      ],
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.8)}`,
        },
      },
    },

    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "100px",
        },
      },
    },

    MuiToggleButtonGroup: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            backgroundColor: defaultTheme.palette.grey[200],
            borderRadius: "100px",
          },
        },
      ],
    },

    MuiToggleButton: {
      variants: [
        {
          props: { color: "primary" },
          style: {
            borderRadius: "100px !important",
            border: "none !important",
            margin: "3px",
            borderLeft: "none",
            textTransform: "none",
            backgroundColor: defaultTheme.palette.grey[200],
            color: `${alpha(defaultTheme.palette.common.black, 0.3)}`,
            transition: defaultTheme.transitions.create(["all", "transform"], {
              duration: defaultTheme.transitions.duration.standard,
            }),
            "&.Mui-selected": {
              backgroundColor: defaultTheme.palette.common.white,
              boxShadow: defaultTheme.shadows[2],
              "&:hover": {
                color: defaultTheme.palette.primary.main,
                backgroundColor: defaultTheme.palette.common.white,
              },
            },
            "&:hover": {
              color: defaultTheme.palette.common.white,
              backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.12)}`,
            },
          },
        },
        {
          props: { color: "primary", size: "small" },
          style: {
            padding: "5px",
          },
        },
      ],
    },

    MuiDialog: {
      defaultProps: {
        fullScreen: window.innerWidth <= 600,
      },

      styleOverrides: {
        paper: {
          [`@media (max-width: 600px)`]: {
            width: "unset",
            maxWidth: "100%",
            margin: "0",
          },
        },
        paperWidthXs: {
          width: "440px",
        },
        paperWidthSm: {
          width: "600px",
        },
        paperWidthMd: {
          width: "800px",
        },
        paperWidthLg: {
          width: "1000px",
        },
        paperWidthXl: {
          width: "1200px",
        },
      },
    },

    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.MuiIconButton-edgeEnd": {
            marginRight: -1,
          },
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "36px",
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          "&::-webkit-scrollbar": {
            width: "6px",
            backgroundColor: defaultTheme.palette.grey[100],
          },

          "&::-webkit-scrollbar-track": {
            width: "8px",
          },

          "&::-webkit-scrollbar-thumb": {
            backgroundColor: defaultTheme.palette.grey[500],
          },
        },
      },
    },

    MuiListItemAvatar: {
      styleOverrides: {
        root: {
          minWidth: "36px",
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: `${alpha(defaultTheme.palette.common.black, 0.05)}`,
          },
          "&.Mui-selected:hover": {
            backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.12)}`,
          },
        },
      },
    },

    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          color: defaultTheme.palette.common.black,
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "unset",
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: `${alpha(defaultTheme.palette.primary.dark, 0.8)}`,
        },
        arrow: {
          color: `${alpha(defaultTheme.palette.primary.dark, 0.8)}`,
        },
      },
    },
  },
});

export default appTheme;
