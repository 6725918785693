import { CardMedia, Button, Stack, Box } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import Modal from "@mui/material/Modal";

import { styles } from "./styles";

import ImageSlider from "@/components/Slider/ImageSlider";

interface IPhotoModal {
  open: boolean;
  url: string;
  assets?: any[] | null;
  onClose: () => void;
  selectedSlider?: number;
}

function PhotoModal({ open, url, assets, selectedSlider, onClose }: IPhotoModal) {
  return (
    <Modal
      aria-describedby="modal-modal-description"
      aria-labelledby="modal-modal-title"
      open={open}
      onClose={onClose}
    >
      <Box sx={styles.mainContainer}>
        <Button
          aria-label="delete"
          className="closeButton"
          color="whiteBg"
          size="small"
          sx={styles.closeButton}
          variant="iconButtonContained"
          onClick={onClose}
        >
          <CloseOutlined />
        </Button>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          sx={styles.modalContainer}
        >
          {assets && assets?.length > 1 ? (
            <Box sx={styles.sliderContainer}>
              <ImageSlider fullScreen selectedSlider={selectedSlider}>
                {assets?.map((asset: any) => (
                  <CardMedia
                    key={`${asset?.url || asset.download_url}`}
                    alt="Voyage Image"
                    component="img"
                    image={`${asset?.url || asset.download_url}` || "/img/placeholders/Image.png"}
                    sx={styles.cardMediaSlider}
                  />
                ))}
              </ImageSlider>
            </Box>
          ) : (
            <CardMedia
              alt="Voyage Image"
              component="img"
              image={`${url}` || "/img/placeholders/Image.png"}
              sx={styles.cardMedia}
            />
          )}
        </Stack>
      </Box>
    </Modal>
  );
}

export default PhotoModal;
