import { TileLayer } from "@deck.gl/geo-layers";
import { BitmapLayer } from "@deck.gl/layers";

import { ftToMeters, defaultDepthShadingColors } from "@/utils/maps";
import { TDepthShadingItemTile } from "@/types/map";
import { DEFAULT_TILES_URL } from "@/store/slices/depthshading";

export function getDepthMapOverlay(
  tileSize: number,
  currentDepthShading = defaultDepthShadingColors,
  unit = "Feet",
  shaderOpacity = 1,
  contourLines = false,
  depthShadingOrigin = DEFAULT_TILES_URL,
) {
  // const invert = "&filterinvert=1&filternoborder=1";

  // style=1 returns tiles in feet, otherwire, returns in meters.
  const unitStyle = `${unit.includes("Feet") ? "&style=1" : ""}`;

  // // Extra param to enhance tile image quality.
  const extra = "&filterenhance=80";

  const defaultAlphaCutoff = "0.5";

  const defaultParams = {
    imagesize: "512",

    // fonts
    depthlabelfontsize: "24",
    depthlabelborderthickness: "1",
    depthlabelcorecolor: "0,0,0,255", // server defaults to white
    depthlabelbordercolor: "255,255,255,255", // server defaults to black
  };

  const getCustomDepthColorString = (depthShaderObject: TDepthShadingItemTile[]) => {
    // This extra color band is used to set very shallow waters to be transparent.
    // Goes from 0 depth to `config.defaultAlphaCutoff`.
    const firstColorBand = depthShaderObject?.[0]?.color?.split(",").slice(0, -1).join(",");

    const alphaCutoffColorBand = `${ftToMeters(defaultAlphaCutoff)},${firstColorBand},0,`;
    let depthColorString = `&depthcolor=${alphaCutoffColorBand}`;

    for (let i = 0; i < depthShaderObject.length; i += 1) {
      let depth = Number(depthShaderObject[i]?.distance);

      depth += i === 0 ? Number(defaultAlphaCutoff) : 0;

      const colorIndex = contourLines && i > 0 ? i - 1 : i;

      depthColorString += `${ftToMeters(String(depth))},${depthShaderObject[colorIndex]?.color},`;
    }

    depthColorString += contourLines
      ? `${ftToMeters(depthShaderObject[depthShaderObject.length - 1]?.distance)},${
          depthShaderObject[depthShaderObject.length - 1]?.color
        },`
      : "";

    // remove last comma
    return depthColorString.slice(0, -1);
  };

  const depthColorsParam = getCustomDepthColorString(
    currentDepthShading?.depth_shader_items.sort((a, b) => Number(a.distance) - Number(b.distance)),
  );

  const contourParams = contourLines
    ? {
        depthshading: "1",
        depthcontour: "fromdepthcolor",
        depthcontourcolor: `22,22,22,${shaderOpacity}`,
        depthcontourthickness: "0.2",
      }
    : { depthshading: "0" };

  const params: any = { ...defaultParams, ...contourParams };

  const stringParams = Object.keys(params)
    .map((k) => `${k}=${params[k]}`)
    .join("&");

  const tileLayer = new TileLayer<ImageBitmap>({
    id: "depth-layer",
    data: `${depthShadingOrigin}${unitStyle}${extra}${depthColorsParam}&${stringParams}`,
    maxZoom: 18,
    minZoom: 0,
    pickable: true,
    tileSize,
    onTileError: () => null,
    renderSubLayers: (props) => {
      const [[west, south], [east, north]] = props.tile.boundingBox;
      const { data, ...otherProps } = props;

      return [
        new BitmapLayer(otherProps, {
          image: data,
          bounds: [west, south, east, north],
        }),
      ];
    },
  });

  return tileLayer;
}
