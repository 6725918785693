import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  container: {
    overflowY: "auto",
    overflowX: "hidden",

    ...layoutStyles.scroll,
    "& .argoDivider": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.08)}`,
    },
  },

  containerFlex: {
    alignItems: "stretch",
    p: 2,
    pt: "20px",
    pb: "20px",
  },

  trackContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "52px",
  },

  draggableList: {
    margin: "0 !important",
    padding: "0!important",
  },

  iconTrack: {
    height: "45px",
    position: "absolute",
    top: -48,
    left: 8.0,
    width: "2px",
    borderLeft: "2px dashed",
    transform: "translateX(50%)",
    borderColor: "error.main",
    backgroundColor: "transparent",
  },

  waypointTrack: {
    height: "45px",
    // position: "absolute",
    // top: -50,
    // left: 8,
    ml: 1,
    mt: -6,
    mb: 1,
    width: "2px",
    borderLeft: "2px dashed",
    transform: "translateX(50%)",
    borderColor: "primary.main",
    backgroundColor: "transparent",
  },

  pointTrack: {
    height: "20px",
    width: "20px",
    border: "2px solid",
    borderColor: "primary.main",
    borderRadius: "80%",
    color: "primary.main",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: -51,
      left: 6.4,
      width: "2px",
      height: "45px",
      borderLeft: "2px dashed",
      transform: "translateX(50%)",
      borderColor: "primary.main",
      backgroundColor: "transparent",
    },
  },

  pointTrackLine: {
    height: "100%",
    width: "2px",
    backgroundColor: "primary.main",
  },

  textfield: {
    width: "270px",
    minWidth: "270px",
    "& .MuiSvgIcon-root": {
      transition: "all .1s linear",
    },
    "& .Mui-focused": {
      color: "primary.main",
      "& .MuiSvgIcon-root": {
        opacity: 1,
      },
    },
  },

  iconButton: {
    width: "32px",
    height: "32px",
  },
};
