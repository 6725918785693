import { Box, Typography, Stack, Button, Skeleton } from "@mui/material";
import { LengthUnits, VolumeUnits, useUnitSystem } from "@argonav/unit-system";
import { useNavigate } from "react-router-dom";

import SaveRouteForm from "../RouteSaveForm";

import { styles } from "./styles";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import {
  voyageDistance,
  voyageEstimatedDuration,
  durationToString,
  voyageEstimatedTimeArrival,
  voyageEstimatedFuelConsumption,
} from "@/utils/maps";
import { capitalizeWord } from "@/utils/globals";
import useDisclosure from "@/hooks/useDisclosure";
import { addCategory } from "@/store/slices/search";
import { resetRouteParams, initialState } from "@/store/slices/route";
import useSearch from "@/hooks/useSearch";
import tripsService from "@/services/trips";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { setSelectedTrip } from "@/store/slices/captainslog/actions";

interface IRouteResultPanel {
  routeLoading: boolean;
  onTakeScreenshot: () => Promise<File | null>;
  onClose: () => void;
}

export default function RouteResultPanel({
  routeLoading,
  onTakeScreenshot,
  onClose,
}: IRouteResultPanel) {
  const dispatch = useAppDispatch();
  const { onOpen, onClose: onCloseModal, isOpen } = useDisclosure();
  const { distanceUnitHandler } = useUnitSystem();
  const { setCleanPoiList } = useSearch();
  const navigate = useNavigate();
  const { routeCreated, endPoint, startPoint } = useAppSelector((state) => state.route);
  const { logged } = useAppSelector((state) => state.auth);
  const { tripEditMode } = useAppSelector((state) => state.captainslog);
  const { userInformation } = useAppSelector((state) => state.user);
  const fuelUnit = useAppSelector((state) =>
    capitalizeWord(state.user.userOptions.volume_unit?.toLowerCase()),
  );

  const fuelUnitLabel = fuelUnit === VolumeUnits.GALLONS ? "gal" : "L";
  const hasNotFuel =
    userInformation?.current_vessel?.fuel_consumption === "" ||
    userInformation?.current_vessel?.fuel_consumption === null ||
    userInformation?.current_vessel?.fuel_consumption === "0" ||
    Number(userInformation?.current_vessel?.fuel_consumption) === 0 ||
    userInformation?.current_vessel?.fuel_consumption === undefined;

  const isElectric = userInformation?.current_vessel?.fuel_type === "electric";

  const distance = distanceUnitHandler({
    originalUnit: LengthUnits.METERS,
    originalValue: Number(voyageDistance(routeCreated).toFixed(1)),
  });

  const handleCloseRoute = () => {
    setCleanPoiList();
    dispatch(addCategory([]));
    dispatch(
      resetRouteParams({
        ...initialState,
      }),
    );
  };

  const handleSaveRoute = () => {
    if (logged) {
      onOpen();
    } else {
      navigate("/login");
    }
  };

  const handleConfirmNewRoute = async () => {
    if (logged) {
      try {
        const { status } = await tripsService.confirmUpdate(routeCreated?.id);

        if (status === 200) {
          dispatch(setSelectedTrip(routeCreated));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Route points updated.",
            }),
          );
          navigate("/captains-log");
          handleCloseRoute();
        }
      } catch (error: any) {
        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "error",
            msj: error.response.data.message,
          }),
        );
      }
    } else {
      navigate("/login");
    }
  };

  return (
    <Box sx={styles.container}>
      {routeLoading ? (
        <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={1}
            sx={styles.infoContainer}
          >
            <Stack spacing={1}>
              <Skeleton
                animation="wave"
                height="18px"
                sx={{ fontSize: "16px" }}
                variant="rounded"
                width="100px"
              />
              <Skeleton
                animation="wave"
                height="18px"
                sx={{ fontSize: "16px" }}
                variant="rounded"
                width="160px"
              />
            </Stack>
            <Stack alignItems="flex-end" spacing={1}>
              <Skeleton
                animation="wave"
                height="18px"
                sx={{ fontSize: "16px" }}
                variant="rounded"
                width="100px"
              />
              <Skeleton
                animation="wave"
                height="18px"
                sx={{ fontSize: "16px" }}
                variant="rounded"
                width="100px"
              />
            </Stack>
          </Stack>
          <Stack alignItems="center" direction="row" px={2} py={1.5} spacing={1}>
            <Skeleton
              animation="wave"
              height="36px"
              sx={{ fontSize: "16px", borderRadius: "100px" }}
              variant="rounded"
              width="50%"
            />
            <Skeleton
              animation="wave"
              height="36px"
              sx={{ fontSize: "16px", borderRadius: "100px" }}
              variant="rounded"
              width="50%"
            />
          </Stack>
        </>
      ) : routeCreated ? (
        <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={1}
            sx={styles.infoContainer}
          >
            <Stack spacing={0}>
              <Typography variant="subtitle2">
                ETA:{" "}
                {voyageEstimatedTimeArrival(
                  routeCreated,
                  userInformation?.current_vessel?.average_speed,
                )}
              </Typography>
              <Typography variant="body2">
                {hasNotFuel ? undefined : `Est. ${isElectric ? "Power" : "Fuel"} Used:`}{" "}
                {hasNotFuel
                  ? undefined
                  : `${voyageEstimatedFuelConsumption(
                      routeCreated,
                      userInformation?.current_vessel,
                    )} ${
                      userInformation?.current_vessel ? (isElectric ? "kW" : fuelUnitLabel) : ""
                    }`}
              </Typography>
            </Stack>
            <Stack alignItems="flex-end" spacing={0}>
              <Typography variant="body2">
                ~{" "}
                {`${durationToString(
                  voyageEstimatedDuration(
                    routeCreated,
                    userInformation?.current_vessel?.average_speed,
                  ),
                )}`}
              </Typography>
              <Typography variant="body2">{`${distance.value} ${distance.unit}`}</Typography>
            </Stack>
          </Stack>
          <Stack alignItems="center" direction="row" px={2} py={1.5} spacing={1}>
            <Button fullWidth color="neutral" type="button" variant="outlined" onClick={onClose}>
              Discard Route
            </Button>
            {tripEditMode ? (
              <Button fullWidth type="button" variant="contained" onClick={handleConfirmNewRoute}>
                Confirm Change
              </Button>
            ) : (
              <Button
                fullWidth
                disabled={!endPoint && startPoint}
                type="button"
                variant="contained"
                onClick={handleSaveRoute}
              >
                Save to Captain&apos;s Log
              </Button>
            )}
          </Stack>
        </>
      ) : null}
      {isOpen && routeCreated && (
        <SaveRouteForm
          open={isOpen}
          routeCreated={routeCreated}
          onCleanRouteStatus={handleCloseRoute}
          onClose={onCloseModal}
          onTakeScreenshot={onTakeScreenshot}
        />
      )}
    </Box>
  );
}
