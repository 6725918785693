import { Stack, Typography, List, Divider, Button } from "@mui/material";
import { Check } from "@mui/icons-material";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";

import { styles } from "./styles";
import AmenitiesListItem from "./AmenitiesListItem";

import { IMapPois } from "@/types/pois";
import EditAmenitiesModal from "@/components/Modals/EditAmenitiesModal";
import BaseModal from "@/components/Modals/BaseModal";

interface IPlaceInfoAmenities {
  poiSelected: IMapPois;
  logged: boolean;
}

export type TAmenitiesItems = {
  id: number;
  // eslint-disable-next-line no-undef
  icon: JSX.Element;
  primary: string;
  secondary: string | undefined;
  available: boolean | undefined;
  valueName: string;
};
function PlaceInfoAmenities({ poiSelected, logged }: IPlaceInfoAmenities) {
  const [openEditAmenitiesModal, setEditAmenitiesModal] = useState<boolean>(false);
  const amenitiesListItems: TAmenitiesItems[] = [
    {
      id: 1,
      icon: <Check />,
      primary: "Boat Supplies",
      secondary: poiSelected?.supplies_info,
      available: poiSelected?.supplies,
      valueName: "supplies",
    },
    {
      id: 2,
      icon: <Check />,
      primary: "Cable/Satellite",
      secondary: poiSelected?.cable_satellite_info,
      available: poiSelected?.cable_satellite,
      valueName: "cable_satellite",
    },
    {
      id: 3,
      icon: <Check />,
      primary: "Captains Lounge",
      secondary: poiSelected?.captains_lounge_info,
      available: poiSelected?.captains_lounge,
      valueName: "captains_lounge",
    },
    {
      id: 4,
      icon: <Check />,
      primary: "Fitness Center",
      secondary: poiSelected?.fitness_center_info,
      available: poiSelected?.fitness_center,
      valueName: "fitness_center",
    },
    {
      id: 5,
      icon: <Check />,
      primary: "Floating Docks",
      secondary: poiSelected?.floating_docks_info,
      available: poiSelected?.floating_docks,
      valueName: "floating_docks",
    },
    {
      id: 6,
      icon: <Check />,
      primary: "Groceries",
      secondary: poiSelected?.grocery_info,
      available: poiSelected?.grocery,
      valueName: "grocery",
    },
    {
      id: 7,
      icon: <Check />,
      primary: "Haul Out",
      secondary: poiSelected?.haul_out_capabilities_info,
      available: poiSelected?.haul_out_capabilities,
      valueName: "haul_out_capabilities",
    },
    {
      id: 8,
      icon: <Check />,
      primary: "Ice",
      secondary: poiSelected?.ice_info,
      available: poiSelected?.ice,
      valueName: "ice",
    },
    {
      id: 9,
      icon: <Check />,
      primary: "Laundry",
      secondary: poiSelected?.laundry_info,
      available: poiSelected?.laundry,
      valueName: "laundry",
    },
    {
      id: 10,
      icon: <Check />,
      primary: "Maintenance Facility",
      secondary: poiSelected?.repair_capabilities_info,
      available: poiSelected?.repair_capabilities,
      valueName: "repair_capabilities",
    },
    {
      id: 11,
      icon: <Check />,
      primary: "Pet Friendly",
      secondary: poiSelected?.other_pet_info,
      available: poiSelected?.pets_welcome,
      valueName: "pets_welcome",
    },
    {
      id: 12,
      icon: <Check />,
      primary: "Picnic Area",
      secondary: poiSelected?.picnic_area_info,
      available: poiSelected?.picnic_area,
      valueName: "picnic_area",
    },
    {
      id: 13,
      icon: <Check />,
      primary: "Playground",
      secondary: poiSelected?.playground_info,
      available: poiSelected?.playground,
      valueName: "playground",
    },
    {
      id: 14,
      icon: <Check />,
      primary: "Pump Out",
      secondary: poiSelected?.pump_out_info,
      available: poiSelected?.pump_out,
      valueName: "pump_out",
    },
    {
      id: 15,
      icon: <Check />,
      primary: "Restaurant",
      secondary: poiSelected?.restaurant_info,
      available: poiSelected?.restaurant,
      valueName: "restaurant",
    },
    {
      id: 16,
      icon: <Check />,
      primary: "Restrooms",
      secondary: poiSelected?.bathrooms_info,
      available: poiSelected?.bathrooms,
      valueName: "bathrooms",
    },
    {
      id: 17,
      icon: <Check />,
      primary: "Security",
      secondary: poiSelected?.security_info,
      available: poiSelected?.security,
      valueName: "security",
    },
    {
      id: 18,
      icon: <Check />,
      primary: "Showers",
      secondary: poiSelected?.showers_info,
      available: poiSelected?.showers,
      valueName: "showers",
    },
    {
      id: 19,
      icon: <Check />,
      primary: "Swimming Pool",
      secondary: poiSelected?.pool_info,
      available: poiSelected?.pool,
      valueName: "pool",
    },
    {
      id: 20,
      icon: <Check />,
      primary: "Transportation",
      secondary: poiSelected?.other_transportation_info,
      available: poiSelected?.transportation,
      valueName: "transportation",
    },
    {
      id: 21,
      icon: <Check />,
      primary: "Trash",
      secondary: poiSelected?.trash_info,
      available: poiSelected?.trash,
      valueName: "trash",
    },
    {
      id: 22,
      icon: <Check />,
      primary: "WiFi",
      secondary: poiSelected?.wifi_info,
      available: poiSelected?.wifi,
      valueName: "wifi",
    },
  ];

  const amenitiesData = amenitiesListItems.some((item: TAmenitiesItems) => item?.available);

  return (
    <>
      <Stack alignItems="center" direction="row" justifyContent="space-between" px={2} py={1}>
        <Typography component="h4" lineHeight="normal" variant="subtitle1">
          Amenities
        </Typography>

        <Button
          color="secondary"
          disabled={!logged}
          startIcon={!amenitiesData ? <AddIcon /> : <EditIcon />}
          onClick={() => setEditAmenitiesModal(true)}
        >
          {!amenitiesData ? "Add Amenities" : "Edit Amenities"}
        </Button>
      </Stack>
      <List disablePadding sx={amenitiesData ? styles.denseList : null}>
        {amenitiesListItems.map((item) => {
          if (item?.available) {
            return (
              <AmenitiesListItem
                key={item?.id}
                icon={item?.icon}
                primary={item?.primary}
                secondary={item?.secondary}
              />
            );
          }

          return null;
        })}
      </List>
      <Divider />
      {openEditAmenitiesModal && (
        <BaseModal
          ariaDescribedby="Edit Amenities"
          ariaLabelledby="Edit Amenities"
          open={openEditAmenitiesModal}
          title="Edit Amenities"
          onClose={() => setEditAmenitiesModal(false)}
        >
          <EditAmenitiesModal
            amenitiesItems={amenitiesListItems}
            poiSelected={poiSelected}
            onClose={() => setEditAmenitiesModal(false)}
          />
        </BaseModal>
      )}
    </>
  );
}

export default PlaceInfoAmenities;
