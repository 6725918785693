import React, { useMemo } from "react";

import AdvancedMarker from "../AdvancedMarker";
import PlacePopover from "../../MapPopovers/PlacePopover/PlacePopover";
import { getPoiIcon } from "../Utils/PoiIcons";
// import PoiMarker from "./PoiMarker";
// import BridgeMapMarker from "./BridgeMapMarker";

import BridgeMapIcon from "./BridgeMapIcon";

import { addStartPoint, addEndPoint, addInterimPoint } from "@/store/slices/route";
import ServicePoi from "@/services/poi";
import { setReportPinSelectedDrawer } from "@/store/slices/reportpins";
import { setBoaterInfoDrawer } from "@/store/slices/boaters";
import { addSelectedPoi, addSelectedPoiFromMap } from "@/store/slices/search";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { IMapPois } from "@/types/pois";
import { setSignInModal } from "@/store/slices/user";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { IconKeysPois } from "@/types/markers";

interface IPoisList {
  items: IMapPois[];
  zoom?: number;
  clogpage: boolean;
}

interface IPoisStatus {
  [key: string]: boolean;
}

const ZOOM_LEVEL = 10;

const ZOOM_LEVEL_REST_BAR = 15;
const ZOOM_LEVEL_NOOA_RESTRICTION = 16;

const KEY_REST_BAR = "RestaurantBar";
const KEY_BUSINESS = "Business";
const KEY_HOTEL = "Hotel";

function PoisList({ items, zoom, clogpage }: IPoisList) {
  const dispatch = useAppDispatch();
  const { poisConfigured } = useAppSelector((state) => state.map);
  const { creatingRoute, waypoints, inputsFocus } = useAppSelector((state) => state.route);
  const { boaterSelectedInfoDrawer } = useAppSelector((state) => state.boaters);
  const { reportPinSelectedDrawer } = useAppSelector((state) => state.reportpins);
  const { selectedPoi } = useAppSelector((state) => state.search);

  const poisStatus: IPoisStatus = {
    Marina: poisConfigured.Marina,
    BoatRamp: poisConfigured.BoatRamp,
    Anchorage: poisConfigured.Anchorage,
    YachtClub: poisConfigured["Yacht Club"],
    BoatClub: poisConfigured["Boat Club"],
    RestaurantBar: poisConfigured["Restaurant/Bar"],
    ArtificialReef: poisConfigured.ArtificialReef,
    ClubAssociation: poisConfigured["Club/Association"],
    Dam: poisConfigured.Dam,
    Lock: poisConfigured.Lock,
    Bridge: poisConfigured.Bridge,
    Business: poisConfigured.Business,
    CityTown: poisConfigured.CityTown,
    Hotel: poisConfigured.Hotel,
    Inlet: poisConfigured.Inlet,
    Other: poisConfigured.Other,
    Beach: poisConfigured.Beach,
    FreeDock: poisConfigured["Free Dock"],
    Mooring: poisConfigured.Mooring,
    DockBarRestaurant: poisConfigured["Dock Bar/Restaurant"],
    FishingSpot: poisConfigured["Fishing Spot"],
    LightHouse: poisConfigured["Light House"],
    PointofInterest: poisConfigured["Point of Interest"],
    PrivateDock: poisConfigured["Private Dock"],
  };

  /**
   * This function checks the zoom level to show the pois
   * @param key Type string
   * @returns boolean
   */
  const zoomChecks = (key: string) => {
    if (poisConfigured.noaaLayer) {
      if (zoom && zoom > ZOOM_LEVEL) {
        if (zoom && zoom >= ZOOM_LEVEL_NOOA_RESTRICTION) {
          return true;
        }
      }

      return false;
    }

    if (key === KEY_REST_BAR || key === KEY_BUSINESS || key === KEY_HOTEL) {
      if (zoom && zoom >= ZOOM_LEVEL_REST_BAR) {
        return true;
      }

      return false;
    }

    if (zoom && zoom > ZOOM_LEVEL) {
      return true;
    }

    return false;
  };

  /**
   * This function get the poi information by id
   * @param item Type IMapPois
   */
  const handleGetPoiInfo = async (item: IMapPois) => {
    try {
      const { status, data } = await ServicePoi.getPoiById(item.id);

      if (status === 200) {
        if (boaterSelectedInfoDrawer) dispatch(setBoaterInfoDrawer(null));
        if (reportPinSelectedDrawer) dispatch(setReportPinSelectedDrawer(null));
        dispatch(addSelectedPoi(data.data));
        dispatch(addSelectedPoiFromMap(false));
        const { status: statusRaiting, data: dataRaiting } = await ServicePoi.getPoiRatings(
          data?.data?.id,
        );

        if (statusRaiting === 200) {
          dispatch(addSelectedPoi({ ...data.data, all_reviews: dataRaiting }));
        }
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  /**
   * This function set the selected poi for the route or the drawer to show extra information
   * @param item Type IMapPois
   */
  const handleSetPoi = (item: IMapPois) => {
    if (!clogpage) {
      if (!creatingRoute && item) {
        handleGetPoiInfo(item);
      } else if (creatingRoute) {
        const lat = Number(item.latitude);
        const lng = Number(item.longitude);

        if (inputsFocus === 1) {
          dispatch(
            addStartPoint({
              lat,
              lng,
              name: item.name,
            }),
          );
        } else if (inputsFocus === 2) {
          dispatch(
            addEndPoint({
              lat,
              lng,
              name: item.name,
            }),
          );
        } else if (inputsFocus === 3) {
          const updatedInterimPoints = waypoints.map((point) =>
            point.focused
              ? {
                  ...point,
                  value: item.name,
                  focused: false,
                  lat: lat.toFixed(6),
                  lng: lng.toFixed(6),
                }
              : point,
          );

          dispatch(
            addInterimPoint([
              ...updatedInterimPoints,
              { id: `Item ${updatedInterimPoints.length + 1}`, value: "", focused: true },
            ]),
          );
        }
      }
    } else {
      dispatch(setSignInModal(true));
    }
  };

  /**
   * This function evaluate if the icon bridge has clearance or not.
   * @param poi IMapPois
   * @returns React.ReactNode or String
   */
  const evaluateIcons = (poi: IMapPois) => {
    const iconBridgeType =
      poi?.poi_type === "Bridge"
        ? poi?.info?.ver_clr !== null &&
          poi?.info?.ver_clr !== undefined &&
          poi?.info?.ver_clr !== "" &&
          poi?.info?.ver_clr !== "0" &&
          poi?.info?.ver_clr !== "0.0"
          ? IconKeysPois.BridgeClearance
          : IconKeysPois.Bridge
        : null;

    if (iconBridgeType === IconKeysPois.BridgeClearance) {
      return <BridgeMapIcon infoIcon={poi} />;
    }

    return getPoiIcon(poi.poi_type as IconKeysPois);
  };

  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        const key = item.poi_type.replace("/", "").replace(" ", "");

        return zoomChecks(key) && poisStatus[key];
      }),
    [items, zoom, poisStatus, poisConfigured],
  );

  const itemsToRender = selectedPoi?.editMode
    ? filteredItems.filter((poi: IMapPois) => selectedPoi?.id !== poi.id)
    : filteredItems;

  return (
    <>
      {itemsToRender.map((poi: IMapPois) => (
        <AdvancedMarker
          key={poi.id}
          icon={evaluateIcons(poi)}
          position={{ lat: Number(poi.latitude), lng: Number(poi.longitude) }}
          selected={selectedPoi?.id === poi.id}
          onClick={() => handleSetPoi(poi)}
        >
          <PlacePopover
            icon={evaluateIcons(poi)}
            inCaptainsLog={clogpage}
            poi={poi}
            onClick={() => null}
          />
        </AdvancedMarker>
      ))}
    </>
  );
}

export default PoisList;
