import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { SpeedUnits, VolumeUnits, LengthUnits } from "@argonav/unit-system";

import { styles } from "./styles";

import { useAppSelector } from "@/hooks/useRedux";

interface ISettingsModalUnit {
  onSetDepthUnit: (typeUnit: string, depthUnit: string) => void;
}

function SettingsModalUnit({ onSetDepthUnit }: ISettingsModalUnit) {
  const depthUnit = useAppSelector((state) => state.user.userOptions.length_unit);
  const speedUnit = useAppSelector((state) => state.user.userOptions.speed_unit);
  const fuelUnit = useAppSelector((state) => state.user.userOptions.volume_unit);

  const handleDepthChange = (event: SelectChangeEvent) => {
    onSetDepthUnit("depthUnit", event.target.value);
  };

  const handleSpeedChange = (event: SelectChangeEvent) => {
    onSetDepthUnit("speedUnit", event.target.value);
  };

  const handleFuelUsageChange = (event: SelectChangeEvent) => {
    onSetDepthUnit("fuelUnit", event.target.value);
  };

  return (
    <List disablePadding sx={styles.list}>
      <ListItem disablePadding divider sx={styles.listItem}>
        <ListItemText
          primary="Depth"
          primaryTypographyProps={{
            variant: "subtitle1",
          }}
        />
        <ListItemIcon>
          <Select
            defaultValue={depthUnit}
            size="small"
            sx={styles.select}
            onChange={handleDepthChange}
          >
            <MenuItem value={LengthUnits.FEET}>Feet</MenuItem>
            <MenuItem value={LengthUnits.METERS}>Meters</MenuItem>
          </Select>
        </ListItemIcon>
      </ListItem>

      <ListItem disablePadding divider sx={styles.listItem}>
        <ListItemText
          primary="Speed"
          primaryTypographyProps={{
            variant: "subtitle1",
          }}
        />
        <ListItemIcon>
          <Select
            defaultValue={speedUnit}
            size="small"
            sx={styles.select}
            onChange={handleSpeedChange}
          >
            <MenuItem value={SpeedUnits.KNOT}>Knots</MenuItem>
            <MenuItem value={SpeedUnits.MPH}>MPH</MenuItem>
            <MenuItem value={SpeedUnits.KPH}>KPH</MenuItem>
          </Select>
        </ListItemIcon>
      </ListItem>

      <ListItem disablePadding divider sx={styles.listItem}>
        <ListItemText
          primary="Fuel Usage"
          primaryTypographyProps={{
            variant: "subtitle1",
          }}
        />
        <ListItemIcon>
          <Select
            defaultValue={fuelUnit}
            size="small"
            sx={styles.select}
            onChange={handleFuelUsageChange}
          >
            <MenuItem value={VolumeUnits.GALLONS}>Gallons</MenuItem>
            <MenuItem value={VolumeUnits.LITERS}>Liters</MenuItem>
          </Select>
        </ListItemIcon>
      </ListItem>
    </List>
  );
}

export default SettingsModalUnit;
