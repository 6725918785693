import React from "react";
import SlickSlider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box } from "@mui/system";

import { styles } from "./styles";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";

interface ISlider {
  children: React.ReactNode;
  settings?: any;
  fullScreen?: boolean;
  withoutFeatures?: boolean;
  selectedSlider?: number;
}

function ImageSlider(props: ISlider) {
  const { children, settings, fullScreen, withoutFeatures, selectedSlider } = props;

  const defaultSettings = {
    className: fullScreen ? `slider one-time` : `slider`,
    arrows: !(!fullScreen && withoutFeatures),
    dots: !(!fullScreen && withoutFeatures),
    infinite: false,
    slidesToShow: fullScreen ? 1 : 2,
    slidesToScroll: fullScreen ? 1 : 2,
    adaptiveHeight: !!fullScreen,
    swipeToSlide: false,
    swipe: false,
    initialSlide: fullScreen && selectedSlider ? selectedSlider : 0,
    nextArrow: <NextArrow fullScreen={fullScreen} />,
    prevArrow: <PrevArrow fullScreen={fullScreen} />,
  };

  const mergedSettings = { ...defaultSettings, ...settings };

  return (
    <Box sx={styles.container}>
      <SlickSlider {...mergedSettings}>{children}</SlickSlider>
    </Box>
  );
}

export default ImageSlider;
