import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { styles } from "./styles";

function PrevArrow(props: any) {
  const { className, style, onClick, fullScreen } = props;

  return (
    <IconButton
      className={className}
      size="small"
      sx={
        fullScreen ? { ...style, ...styles.prevArrowFullscreen } : { ...style, ...styles.prevArrow }
      }
      onClick={onClick}
    >
      <ChevronLeftIcon color="primary" />
    </IconButton>
  );
}

export default PrevArrow;
