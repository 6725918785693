import { Alert, Snackbar, AlertTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { styles } from "./styles";

interface IViewportMessage {
  onClose: () => void;
}

function ViewportMessage({ onClose }: IViewportMessage) {
  return (
    <Snackbar
      open
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      autoHideDuration={6000}
      sx={styles.alertContent}
    >
      <Alert
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={onClose}>
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        elevation={4}
        severity="info"
      >
        <AlertTitle sx={styles.title}>Oops this is a small screen.</AlertTitle>
        Please switch to a larger screen for a better experience. To get Premium, tap
        &quot;Upgrade&quot; in the lower left bar.
      </Alert>
    </Snackbar>
  );
}

export default ViewportMessage;
