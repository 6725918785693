import axios from "./config";

interface ICreateProps {
  userId?: number;
  vesselId?: number;
  voyageId?: number;
  placepinId?: number;
  reportpinId?: number;
  poiId?: number;
  kind?: string;
  file?: File;
  fileName: string;
}

interface ICreatePropsNewApi {
  userId?: number;
  vesselId?: number;
  voyageId?: number;
  placepinId?: number;
  reportpinId?: number;
  fileName: string;
  file: File;
}

// Social Services
const socialService = {
  createAvatar: async ({ userId, fileName }: ICreateProps) => {
    const { data } = await axios.request({
      method: "post",
      url: `/users/${userId}/assets`,
      data: {
        file_name: fileName,
      },
    });

    return data;
  },

  removeAvatar: async (userId: number) => {
    const response = await axios.request({
      method: "delete",
      url: `/users/${userId}/avatar_image`,
    });

    return response;
  },

  createCover: async ({ userId, fileName }: ICreateProps) => {
    const { data } = await axios.request({
      method: "post",
      url: `/users/${userId}/cover_image`,
      data: {
        file_name: fileName,
      },
    });

    return data;
  },

  removeCover: async (userId: number) => {
    const response = await axios.request({
      method: "delete",
      url: `/users/${userId}/cover_image`,
    });

    return response;
  },

  createBoatImage: async ({ vesselId, fileName }: ICreateProps) => {
    const { data } = await axios.request({
      method: "post",
      url: `/vessels/${vesselId}/assets`,
      data: {
        file_name: fileName,
      },
    });

    return data;
  },

  createVoyageImage: async ({ voyageId, fileName, kind }: ICreateProps) => {
    const { data } = await axios.request({
      method: "post",
      url: `/voyages/${voyageId}/assets`,
      data: {
        file_name: fileName,
        kind,
      },
    });

    return data;
  },

  createPlacePinImage: async ({ placepinId, fileName }: ICreateProps) => {
    const { data } = await axios.request({
      method: "post",
      url: `/places/${placepinId}/assets`,
      data: {
        file_name: fileName,
      },
    });

    return data;
  },

  createPoiImage: async (formData: any) => {
    const { data } = await axios.request({
      method: "post",
      url: `/api/v1/assets`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });

    return data;
  },

  updatePoiImage: async (formData: any) => {
    const { data } = await axios.request({
      method: "put",
      url: `/api/v1/assets`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });

    return data;
  },

  createReportPinImage: async ({ reportpinId, file }: ICreatePropsNewApi) => {
    const formData = new FormData();

    formData.append("report_pin_id", String(reportpinId));
    const blob = new Blob([file], { type: file.type });

    formData.append("attached_file", blob, file.name);

    const { data } = await axios.request({
      method: "post",
      url: `/api/v1/assets`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    });

    return data;
  },
};

export default socialService;
