import { Box, Stack, Container } from "@mui/material";

import { styles } from "./styles";

import PremiumPaymentCard from "@/components/Premium/PremiumPaymentCard";

export default function PremiumPaymentLayout() {
  return (
    <Box sx={styles.wrapper}>
      <Container maxWidth="lg" sx={styles.container}>
        <Box sx={styles.boxColumns}>
          <Stack spacing={3}>
            <PremiumPaymentCard />
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}
