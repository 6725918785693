export const styles = {
  container: {
    background: "white",
    borderTop: "1px solid",
    borderColor: "divider",
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    boxShadow: 8,
  },

  infoContainer: {
    borderBottom: "1px solid",
    borderColor: "divider",
    px: 2,
    py: 1.5,
  },
};
