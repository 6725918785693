import { alpha } from "@mui/material/styles";

import defaultTheme from "@/themes/theme";

const cardMedia = {
  borderRadius: "8px",
  cursor: "pointer",
  height: "184px",
  // border: "1px solid",
  // borderColor: "divider",
  boxShadow: `0 0 0 1px ${alpha(defaultTheme.palette.common.black, 0.12)}`,
  position: "relative",
  transition: defaultTheme.transitions.create(["all", "transform"], {
    duration: defaultTheme.transitions.duration.standard,
  }),
  "&:hover": {
    // borderColor: `${alpha(defaultTheme.palette.common.black, 0.3)}`,
    boxShadow: `0 0 0 2px ${alpha(defaultTheme.palette.primary.main, 0.5)}`,
  },
};

export const styles = {
  container: {
    "& .slick-slider": {
      m: -0.5,
    },

    "& .slick-slide > div:not([class])": {
      p: 0.5,
    },
  },

  cardMedia: {
    ...cardMedia,
  },

  cardMediaSingle: {
    ...cardMedia,
    width: "100%",
  },
};
