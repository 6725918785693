import { CancelToken } from "axios";

import axios from "./config";

export default {
  getTrips: (params: any) =>
    axios.request({
      method: "get",
      url: "api/v1/trips",
      params,
    }),
  getTripById: (tripId: number) =>
    axios.request({
      method: "get",
      url: `api/v1/trips/${tripId}`,
    }),
  deleteTrip: (tripId: number) =>
    axios.request({
      method: "delete",
      url: `api/v1/trips/${tripId}`,
    }),
  createTrip: (data: any, cancelToken?: CancelToken) =>
    axios.request({
      method: "post",
      url: "api/v1/trips",
      data,
      cancelToken,
    }),
  updateTrip: (data: any, routeId?: number, cancelToken?: CancelToken) =>
    axios.request({
      method: "put",
      url: `api/v1/trips/${routeId}`,
      data,
      cancelToken,
    }),
  updateTripImage: (id: number, data: any) =>
    axios.request({
      method: "put",
      url: `api/v1/trips/${id}`,
      headers: { "Content-Type": "multipart/form-data" },
      data,
    }),
  confirmUpdate: (id?: number) =>
    axios.request({
      method: "post",
      url: `api/v1/trips/${id}/confirm_update`,
    }),
};
