import {
  Chip,
  Divider,
  FormControlLabel,
  Radio,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import DoneIcon from "@mui/icons-material/Done";

import { styles } from "./styles";

function createData(name: string, filterOne: string, filterTwo: string, key: string) {
  return { name, filterOne, filterTwo, key };
}

const rows = [
  createData("Name", "A to Z", "Z to A", "voyage_name_"),
  createData("Start Location", "A to Z", "Z to A", "start_location_"),
  createData("End Location", "A to Z", "Z to A", "end_location_"),
  createData("Date Created", "Newest", "Oldest", "created_at_"),
];

interface ICaptainLogsFilters {
  selectedSortFilters: string;
  selectedFavoriteFilter: boolean;
  onSelectFavoriteFilter: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function CaptainLogsFilters({
  selectedSortFilters,
  selectedFavoriteFilter,
  onSelectFavoriteFilter,
  onChange,
}: ICaptainLogsFilters) {
  return (
    <>
      <Stack>
        <Stack pb={3} px={3} spacing={2}>
          <Typography variant="subtitle1">Filter By</Typography>
          <Stack direction="row">
            <Chip
              icon={selectedFavoriteFilter ? <DoneIcon /> : undefined}
              label="Favorites"
              sx={selectedFavoriteFilter ? styles.chip : styles.inactiveChip}
              onClick={onSelectFavoriteFilter}
            />
          </Stack>
        </Stack>
        <Divider />
      </Stack>

      <Stack pt={2} px={3} spacing={1}>
        <Typography variant="subtitle1">Sort By</Typography>

        <Table aria-label="Filters Table">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.key}>
                <TableCell
                  align="left"
                  component="th"
                  scope="row"
                  sx={{ ...styles.tableCell, fontWeight: 900, typography: "subtitle2" }}
                >
                  {row.name}
                </TableCell>
                <TableCell align="left" style={{ width: 100 }} sx={styles.tableCell}>
                  <FormControlLabel
                    checked={
                      row.key === "created_at_"
                        ? selectedSortFilters === `${row.key}desc`
                        : selectedSortFilters === `${row.key}asc`
                    }
                    control={<Radio size="small" onChange={onChange} />}
                    label={<Typography variant="body2">{row.filterOne}</Typography>}
                    name="filters"
                    value={row.key === "created_at_" ? `${row.key}desc` : `${row.key}asc`}
                  />
                </TableCell>
                <TableCell align="left" style={{ width: 100 }} sx={styles.tableCell}>
                  <FormControlLabel
                    checked={
                      row.key === "created_at_"
                        ? selectedSortFilters === `${row.key}asc`
                        : selectedSortFilters === `${row.key}desc`
                    }
                    control={<Radio size="small" onChange={onChange} />}
                    label={<Typography variant="body2">{row.filterTwo}</Typography>}
                    name="filters"
                    value={row.key === "created_at_" ? `${row.key}asc` : `${row.key}desc`}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

export default CaptainLogsFilters;
