export const styles = {
  mainContainer: {
    position: "relative",
    width: "100vw",
    height: "100vh",
  },

  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  cardMedia: {
    width: "initial",
    borderRadius: "8px",
    maxHeight: "calc(100vh - 32px)",
    maxWidth: "calc(100vw - 148px)",
    boxShadow: 4,
  },

  sliderContainer: {
    width: "initial",
    maxHeight: "calc(100vh - 64px)",
    maxWidth: "calc(100vw - 148px)",

    "& .slick-slide": {
      textAlign: "center",
    },

    "& .slick-dots li button::before": {
      color: "grey.800",
    },

    ".slick-dots li.slick-active button:before": {
      color: "primary.light",
    },
  },

  cardMediaSlider: {
    maxHeight: "calc(100vh - 64px)",
    maxWidth: "100%",
    borderRadius: 1,
    boxShadow: 4,
    width: "auto !important",
    height: "auto",
  },

  closeButton: {
    position: "absolute",
    top: 16,
    right: 12,
    width: "40px",
    height: "40px",
    boxShadow: 4,
    "&:hover": {
      color: "primary.main",
    },
  },
};
