import { AlertColor } from "@mui/material";

export interface SnackBar {
  state: boolean;
  msj: string;
  type: AlertColor | undefined;
}
export interface UserData {
  allow_password_change: boolean;
  blocked: boolean;
  cover: any;
  deleted_at?: string;
  privilege?: string;
  depth?: string;
  distance_against_current_user: any;
  email?: string;
  first_name?: string;
  friend_request_id: any;
  friendship_status?: string;
  full_name?: string;
  heading?: string;
  id: number;
  image: null;
  last_name?: string;
  lat?: string;
  lng?: string;
  map_grouped: any;
  mutual_affiliations: any;
  mutual_friends?: number;
  platform_os?: string;
  provider?: string;
  push_token?: string;
  reset_password_token_base: any;
  source?: string;
  uid: string;
  user_subscription?: string;
  user_subscription_last_change?: string;
  username: string;
}

export type AuthState = {
  loading: boolean;
  error: any;
  user: UserData | null;
  logged: boolean;
  greetingModal: boolean;
  snackBarMsj: SnackBar;
};

export type TPasswordReset = {
  password: string;
  passwordConfirm: string;
};

export type TPasswordChange = {
  currentPassword: string;
  password: string;
  passwordConfirm: string;
};

export enum ELoginMethod {
  Google = "google",
  Apple = "apple",
  Email = "email",
}
