import React from "react";
import { Box, Typography } from "@mui/material";

import AdvancedMarker from "../AdvancedMarker";

import { styles } from "./styles";

import { IMapPois } from "@/types/pois";
import LocationMarker from "@/components/MarkersIcon/LocationMarker";
import LocationMarkerActive from "@/components/MarkersIcon/LocationMarkerActive";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { addSelectedPoi, addSelectedPoiFromMap } from "@/store/slices/search";
import { addStartPoint, addEndPoint, addInterimPoint } from "@/store/slices/route";

interface IPoiSearchedMarker {
  poiSearched: IMapPois;
  number: number;
  selectedPoi: IMapPois | null;
  onCleanPoiList: () => void;
  setPoi: (poi: IMapPois) => void;
}

const poiSearchedIcon = (number: number, isActive: boolean) => (
  <Box className={isActive ? "placePinActive" : "placePin"} sx={styles.placePin}>
    {number && (
      <Typography component="span" variant="caption">
        {number}
      </Typography>
    )}
    {isActive ? <LocationMarkerActive /> : <LocationMarker />}
  </Box>
);

function PoisSearchedMarker({
  poiSearched,
  number,
  selectedPoi,
  onCleanPoiList,
  setPoi,
}: IPoiSearchedMarker) {
  const dispatch = useAppDispatch();
  const { creatingRoute, inputsFocus, waypoints } = useAppSelector((state) => state.route);

  const handleSelectPoi = async (poiSearched: IMapPois) => {
    if (!creatingRoute) {
      dispatch(addSelectedPoi(poiSearched));
      setPoi(poiSearched);

      dispatch(addSelectedPoiFromMap(true));
    } else {
      if (inputsFocus === 1) {
        dispatch(
          addStartPoint({
            lat: poiSearched.lat,
            lng: poiSearched.lng,
            name: poiSearched.name,
          }),
        );
      } else if (inputsFocus === 2) {
        dispatch(
          addEndPoint({
            lat: poiSearched.lat,
            lng: poiSearched.lng,
            name: poiSearched.name,
          }),
        );
      } else if (inputsFocus === 3) {
        const updatedInterimPoints = waypoints.map((point) =>
          point.focused
            ? {
                ...point,
                focused: false,
                value: poiSearched.name,
                lat: poiSearched.lat,
                lng: poiSearched.lng,
              }
            : point,
        );

        dispatch(
          addInterimPoint([
            ...updatedInterimPoints,
            { id: `Item ${updatedInterimPoints.length + 1}`, value: "", focused: true },
          ]),
        );
      }
      onCleanPoiList();
    }
  };

  return (
    <AdvancedMarker
      icon={poiSearchedIcon(number, selectedPoi?.id === poiSearched.id)}
      position={{ lat: Number(poiSearched.lat), lng: Number(poiSearched.lng) }}
      selected={false}
      onClick={() => handleSelectPoi(poiSearched)}
    />
  );
}

export default PoisSearchedMarker;
