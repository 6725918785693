import { createSlice } from "@reduxjs/toolkit";

import { TVessel } from "@/types/vessel";
import { Affiliation } from "@/types/affiliation";
import { UserInformation } from "@/types/users";
import { Interest } from "@/types/interest";
import { IMapPois } from "@/types/pois";

export interface UserState {
  loading: boolean;
  error: any;
  searchHistory: IMapPois[];
  favorites: IMapPois[];
  vessels: TVessel[];
  affiliations: Affiliation[];
  interests: Interest[];
  userInformation?: UserInformation;
  subscriptionInfoModal: boolean;
  premiumSubscriptionModal: boolean;
  premiumSubscriptionModalSuccess: boolean;
  signInModal: boolean;
  userOptions: any;
  openUserOptions: boolean;
  openProfile: boolean;
  openAccount: boolean;
  readyToSubscribe: boolean;
}

const initialState: UserState = {
  loading: false,
  error: "",
  searchHistory: [],
  favorites: [],
  vessels: [],
  affiliations: [],
  interests: [],
  userInformation: undefined,
  readyToSubscribe: true,
  subscriptionInfoModal: false,
  premiumSubscriptionModal: false,
  premiumSubscriptionModalSuccess: false,
  signInModal: false,
  userOptions: undefined,
  openUserOptions: false,
  openProfile: false,
  openAccount: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchStarted: (state) => {
      state.loading = true;
    },
    fetchFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchSearchHistorySucceded: (state, action) => {
      state.searchHistory = action.payload;
      state.loading = false;
    },
    fetchFavoritesSucceded: (state, action) => {
      state.favorites = action.payload;
      state.loading = false;
    },
    fetchVesselsSucceded: (state, action) => {
      state.vessels = action.payload;
      state.loading = false;
    },
    fetchAffiliationsSucceded: (state, action) => {
      state.affiliations = action.payload;
      state.loading = false;
    },
    fetchUserSucceded: (state, action) => {
      state.userInformation = action.payload;
      state.loading = false;
    },
    fetchUserInterestsSucceded: (state, action) => {
      state.interests = action.payload;
      state.loading = false;
    },
    editUserSucceded: (state, action) => {
      if (state.userInformation) {
        state.userInformation = {
          ...state.userInformation,
          username: action.payload.username,
          last_name: action.payload.last_name,
          first_name: action.payload.first_name,
        };
      }
      state.loading = false;
    },
    editAvatarSucceded: (state, action) => {
      if (state.userInformation) {
        state.userInformation = {
          ...state.userInformation,
          image: action.payload,
        };
      }
    },
    editCoverSucceded: (state, action) => {
      if (state.userInformation) {
        state.userInformation = {
          ...state.userInformation,
          cover: action.payload,
        };
      }
    },
    setSubscriptionInfoModal: (state, action) => {
      state.subscriptionInfoModal = action.payload;
    },
    setPremiumSubscriptionModal: (state, action) => {
      state.premiumSubscriptionModal = action.payload;
    },
    setPremiumSubscriptionModalSuccess: (state, action) => {
      state.premiumSubscriptionModalSuccess = action.payload;
    },
    setSignInModal: (state, action) => {
      state.signInModal = action.payload;
    },
    setUserOptions: (state, action) => {
      state.userOptions = action.payload;
      state.loading = false;
    },
    addOpenUserOptions: (state, action) => {
      state.openUserOptions = action.payload;
    },
    addOpenProfile: (state, action) => {
      state.openProfile = action.payload;
    },
    addOpenAccount: (state, action) => {
      state.openAccount = action.payload;
    },
    setReadyToSubscribe: (state, action) => {
      state.readyToSubscribe = action.payload;
    },
  },
});

export const {
  fetchUserInterestsSucceded,
  fetchAffiliationsSucceded,
  fetchUserSucceded,
  setSubscriptionInfoModal,
  setPremiumSubscriptionModal,
  setPremiumSubscriptionModalSuccess,
  setSignInModal,
  setUserOptions,
  addOpenUserOptions,
  addOpenProfile,
  addOpenAccount,
  setReadyToSubscribe,
} = userSlice.actions;

export default userSlice.reducer;
