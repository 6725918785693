import React, { useEffect, useState } from "react";
import { Paper, Box, Typography, Stack, Button } from "@mui/material";
import { TripOriginOutlined, Place, Lock } from "@mui/icons-material";
import { useUnitSystem } from "@argonav/unit-system";

import { styles } from "./styles";
import PlacePopoverSkeleton from "./PlacePopoverSkeleton";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import usePremiumUser from "@/hooks/usePremiumUser";
import useSearch from "@/hooks/useSearch";
import { IMapPois } from "@/types/pois";
import { addCreatingRoute, addStartPoint, addEndPoint } from "@/store/slices/route";
import { setPremiumSubscriptionModal } from "@/store/slices/user";
import servicePoi from "@/services/poi";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";

interface IPlacePopover {
  inCaptainsLog?: boolean;
  icon: string | React.ReactNode;
  poi: IMapPois;
  grownToTop?: boolean;
  onClick: any;
}

export default function PlacePopover({
  inCaptainsLog,
  poi,
  icon,
  grownToTop,
  onClick,
}: IPlacePopover) {
  const isPremium = usePremiumUser();
  const { logged } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [poiInfo, setPoiInfo] = useState<IMapPois | null>(null);

  const { coordinatesFormatHandler } = useUnitSystem();
  const { setCleanPoiList, setCleanPoiCategory, setCleanSelectedPoi } = useSearch();

  /**
   * Functionality to get poi info
   *
   * @param {TItem} item - item is a marker on the map with UID, the information in item is reduced.
   */
  const handleGetPoiInfo = async (item: IMapPois) => {
    try {
      const { status, data } = await servicePoi.getPoiById(item?.id);

      if (status === 200) {
        setPoiInfo(data.data);
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  useEffect(() => {
    if (!poi) return;

    handleGetPoiInfo(poi);
  }, []);

  const handleRouteOpen = (
    poi?: IMapPois,
    inputType?: number,
    event?: React.MouseEvent<HTMLElement>,
  ) => {
    event?.stopPropagation();
    if (logged && isPremium) {
      setCleanPoiList();
      setCleanPoiCategory();
      dispatch(addCreatingRoute(true));

      /**
       * In this object we set the necessary params if the selected marker
       * to create a route is a poi or aid.
       */
      const poiByType = {
        lat: poi?.lat,
        lng: poi?.lng,
        name: poi?.name,
      };

      // if (coords) dispatch(setCoords(undefined));
      if (poi) {
        if (inputType === 1) {
          dispatch(
            addStartPoint({
              lat: poiByType.lat,
              lng: poiByType.lng,
              name: `${poiByType.name}`,
            }),
          );
        } else {
          dispatch(
            addEndPoint({
              lat: poiByType.lat,
              lng: poiByType.lng,
              name: `${poiByType.name}`,
            }),
          );
        }
        setCleanSelectedPoi();
      }
    } else {
      dispatch(setPremiumSubscriptionModal(true));
    }
  };

  const latLngFormatted = coordinatesFormatHandler({
    latitude: Number(poi?.latitude),
    longitude: Number(poi?.longitude),
  });

  if (!poiInfo) {
    return (
      <Box sx={styles.container}>
        <Paper
          className="placePopover"
          elevation={6}
          sx={grownToTop ? styles.paperToTop : styles.paper}
        >
          <PlacePopoverSkeleton />
        </Paper>
      </Box>
    );
  }

  return (
    <Box>
      <Paper
        className="placePopover"
        elevation={6}
        sx={grownToTop ? styles.paperToTop : styles.paper}
        onClick={onClick}
      >
        <Stack direction="row" spacing={1.5}>
          <Box sx={styles.image}>
            {typeof icon === "string" ? <img alt="Poi" src={icon} /> : icon}
          </Box>

          <Stack sx={styles.textContainer}>
            <>
              <Typography color="text.primary" sx={styles.title} variant="subtitle1">
                {poiInfo?.name || poiInfo?.NAME || poiInfo?.TYPE}
              </Typography>

              {poiInfo?.poi_type !== "Private Dock" && (
                <Typography gutterBottom variant="body2">
                  {latLngFormatted}
                </Typography>
              )}

              <Typography color="text.secondary" variant="body2">
                {poiInfo?.address}
              </Typography>
            </>

            <Stack
              direction="row"
              mt={1}
              spacing={1}
              sx={!inCaptainsLog ? null : styles.hideButtons}
            >
              <Button
                className="buttonFrom"
                disabled={!isPremium}
                endIcon={isPremium ? <TripOriginOutlined /> : <Lock />}
                size="small"
                sx={styles.routeButton}
                variant="outlined"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleRouteOpen(poiInfo, 1, event)
                }
              >
                Route from
              </Button>
              <Button
                className="buttonTo"
                disabled={!isPremium}
                endIcon={isPremium ? <Place /> : <Lock />}
                size="small"
                sx={styles.routeButton}
                variant="outlined"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleRouteOpen(poiInfo, 2, event)
                }
              >
                Route to
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    </Box>
  );
}
