import { PathLayer } from "@deck.gl/layers";

type BartLine = {
  name: number;
  color: string;
  path: [longitude: number, latitude: number][];
};

export function getWaypointLayer(points: any[] | undefined, zoom: number) {
  const layer = new PathLayer({
    id: "waypoint-layer",
    data: [
      {
        path: points,
        color: [27, 159, 238],
      },
    ],

    getColor: (d) => d.color,
    getPath: (d: BartLine) => d.path,
    getWidth: () => {
      const baseWidth = 100;
      const referenceZoom = 13;
      const width = baseWidth * 2 ** (referenceZoom - zoom);

      return width;
    },
    capRounded: true,
    pickable: true,
  });

  return layer;
}
