import React from "react";
import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import { List } from "@mui/material";

import DraggableListItem from "./DraggableListItem";
import { styles } from "./styles";

import { useAppDispatch } from "@/hooks/useRedux";
import { addInterimPoint, IInterimPoint } from "@/store/slices/route";

export type TDraggableList = {
  items: IInterimPoint[];
  onDragEnd: OnDragEndResponder;
  onSearchInterimPoint: (interimPoint: string) => void;
  onSetFocus: (
    e: React.FocusEvent<HTMLInputElement> | undefined,
    type: number,
    position?: number,
  ) => void;
  clearRouteInput: (inputNumber: number, position?: number) => void;
};

const DraggableList = React.memo(
  ({ items, onDragEnd, onSearchInterimPoint, onSetFocus, clearRouteInput }: TDraggableList) => {
    const dispatch = useAppDispatch();
    const handleRemoveInterimPoint = (position: number) => {
      const updatedInterimPoints = items.filter((point, index) => index + 1 !== position);

      dispatch(addInterimPoint(updatedInterimPoints));
    };

    const handleSetInterimPoint = (value: string, position: number) => {
      const updatedInterimPoints = items.map((point, index) =>
        index === position ? { ...point, value } : point,
      );

      dispatch(addInterimPoint(updatedInterimPoints));
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-list">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps} sx={styles.draggableList}>
              {items?.map((item: any, index: number) => (
                <DraggableListItem
                  key={item.value}
                  clearRouteInput={clearRouteInput}
                  item={item}
                  position={index}
                  onFocus={onSetFocus}
                  onRemoveInterimPoint={handleRemoveInterimPoint}
                  onSearchInterimPoint={onSearchInterimPoint}
                  onSetInterimPoint={handleSetInterimPoint}
                />
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    );
  },
);

export default DraggableList;
