import React, { useState } from "react";
import {
  Avatar,
  ClickAwayListener,
  Paper,
  Box,
  Grow,
  List,
  IconButton,
  Collapse,
} from "@mui/material";
import {
  AccountCircleOutlined,
  AdminPanelSettingsOutlined,
  DirectionsBoatFilledOutlined,
  MapOutlined,
  FeedbackOutlined,
  PhoneIphone,
  BookOutlined,
  ListOutlined,
  SubscriptionsOutlined,
  QuizOutlined,
  Close,
  SettingsOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

import PremiumSubscriptionPanel from "../Premium/PremiumSubscriptionPanel/PremiumSubscriptionPanel";
import ArgoDivider from "../ArgoDivider/ArgoDivider";
import SettingsModal from "../Modals/SettingsModal";

import { styles } from "./styles";

import MenuListItem from "@/components/MenuPanel/MenuListItem";
import MenuInfo from "@/components/MenuPanel/MenuInfo";
import CollapsedProfile from "@/components/CollapsedProfile";
import AccountPanel from "@/components/AccountPanel";
import EditBoatModal from "@/components/CollapsedProfile/EditBoatModal";
import BaseModal from "@/components/Modals/BaseModal";
import FeedbackModal from "@/components/Modals/FeedbackModal";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
// import { getUserInformation, getUserOptions } from "@/store/slices/user/actions";
import useUserInformation from "@/hooks/useUserInformation";
import {
  setSubscriptionInfoModal,
  addOpenUserOptions,
  addOpenProfile,
  addOpenAccount,
} from "@/store/slices/user";
import { setCoords } from "@/store/slices/map";
import { setMyPinsDrawer } from "@/store/slices/reportpins";

export default function MenuPanel({ user }: any) {
  const dispatch = useAppDispatch();

  const { userInformation } = useUserInformation();
  const { subscriptionInfoModal, openUserOptions, openProfile, openAccount } = useAppSelector(
    (state) => state.user,
  );
  const { logged } = useAppSelector((state) => state.auth);
  const { coords } = useAppSelector((state) => state.map);

  const handleCloseUserOptions = () => {
    dispatch(addOpenUserOptions(false));
  };

  const handleToggleUserOptions = () => {
    dispatch(addOpenUserOptions(!openUserOptions));
    if (coords) dispatch(setCoords(undefined));
  };

  const handleProfileClose = () => {
    dispatch(addOpenProfile(false));
  };

  const handleProfileToggle = () => {
    dispatch(addOpenProfile(!openProfile));
  };

  const handleAccountClose = () => {
    dispatch(addOpenAccount(false));
  };

  const handleAccountToggle = () => {
    dispatch(addOpenAccount(!openAccount));
  };

  const handlePremiumSubscriptionClose = () => {
    dispatch(setSubscriptionInfoModal(false));
  };

  // Only to open Mobile Collapsible
  const [openMobileCollapsible, setMobileCollapsible] = useState<boolean>(false);

  const handleMobileCollapsibleToogle = () => {
    setMobileCollapsible(!openMobileCollapsible);
  };

  // Only to open Edit Boat Information
  const [openEditBoatModal, setOpenEditBoatModal] = useState<boolean>(false);

  // Only to open Feedback Modal
  const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(false);

  // Only to open Settings Modal
  const [openSettingsModal, setOpenSettingsModal] = useState<boolean>(false);

  const handleClickAway = () => {
    handleCloseUserOptions();
    handleProfileClose();
    handleAccountClose();
    handlePremiumSubscriptionClose();
  };

  const MenuButtons = [
    {
      id: 1,
      icon: <AccountCircleOutlined />,
      label: "My Profile",
      action: () => {
        handleCloseUserOptions();
        handleProfileToggle();
        handleAccountClose();
      },
      className: "",
      disabled: !user,
    },

    {
      id: 2,
      icon: <DirectionsBoatFilledOutlined />,
      label: "Edit Boat Information",
      action: () => setOpenEditBoatModal(true),
      className: "",
      disabled: !user,
    },
    {
      id: 12,
      icon: <LocationOnOutlinedIcon />,
      label: "My Pins",
      action: () => {
        handleCloseUserOptions();
        dispatch(setMyPinsDrawer({ open: true, type: "places" }));
        handleProfileClose();
      },
      className: "",
      disabled: !user,
    },
    {
      id: 5,
      icon: <SettingsOutlined />,
      label: "Settings",
      action: () => setOpenSettingsModal(true),
      className: "",
      disabled: !user,
    },
    {
      id: 3,
      icon: <AdminPanelSettingsOutlined />,
      label: "Account",
      action: () => {
        handleCloseUserOptions();
        handleAccountToggle();
        handleProfileClose();
      },
      className: "",
      disabled: !user,
    },
    {
      id: 6,
      icon: <MapOutlined />,
      label: "Map Legend",
      action: () =>
        window.open(
          "https://argonav.io/support/tutorial/map-legend/?utm_source=settings-menu&utm_medium=in-app&utm_campaign=none",
        ),
      className: "",
      disabled: false,
    },

    {
      id: 7,
      icon: <FeedbackOutlined />,
      label: "Feedback & Support",
      action: () => {
        if (logged) {
          setOpenFeedbackModal(true);
        } else {
          window.open("https://argonav.io/contact/");
        }
      },
      className: "",
      disabled: false,
    },
  ];

  const MenuMobileButtons = [
    {
      id: 8,
      icon: <BookOutlined />,
      label: "Quick Start Guide",
      action: () => window.open("https://argonav.io/quick-start/"),
      className: "",
    },
    {
      id: 9,
      icon: <ListOutlined />,
      label: "Feature Guide",
      action: () => window.open("https://argonav.io/feature-guide/"),
      className: "",
    },
    {
      id: 10,
      icon: <SubscriptionsOutlined />,
      label: "Tutorials",
      action: () =>
        window.open(
          "https://argonav.io/tutorials/?utm_source=settings-menu&utm_medium=in-app&utm_campaign=none",
        ),
      className: "",
    },
    {
      id: 11,
      icon: <QuizOutlined />,
      label: "FAQs",
      action: () => window.open("https://argonav.io/faqs/"),
      className: "",
    },
  ];

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box sx={{ pointerEvents: "auto" }}>
          <IconButton
            size="small"
            sx={user ? styles.avatarIconButton : styles.burgerMenu}
            onClick={handleToggleUserOptions}
          >
            {user ? (
              <Avatar
                alt={`${user.first_name} ${user.last_name}`}
                src={userInformation?.image}
                sx={styles.avatar}
              />
            ) : openUserOptions ? (
              <Close />
            ) : (
              <MenuIcon />
            )}
          </IconButton>

          <Grow
            unmountOnExit
            in={openUserOptions}
            style={{ transformOrigin: "top right" }}
            timeout="auto"
          >
            <Paper elevation={12} sx={{ ...styles.paper, zIndex: 6 }}>
              <List disablePadding sx={styles.scrollContainer}>
                {MenuButtons.filter((item) => !item.disabled).map((item) => (
                  <MenuListItem
                    key={item.id}
                    action={item.action}
                    className={item.className}
                    hasArrow={false}
                    icon={item.icon}
                    name={item.label}
                  />
                ))}
                <MenuListItem
                  key=""
                  hasArrow
                  action={handleMobileCollapsibleToogle}
                  className={openMobileCollapsible ? "mobileOpen" : "mobileClose"}
                  icon={<PhoneIphone />}
                  name="Mobile App Support"
                />
                <Collapse unmountOnExit in={openMobileCollapsible} timeout="auto">
                  <Box sx={styles.sublevel}>
                    {MenuMobileButtons.map((item) => (
                      <MenuListItem
                        key={item.id}
                        action={item.action}
                        className={item.className}
                        hasArrow={false}
                        icon={item.icon}
                        name={item.label}
                      />
                    ))}
                  </Box>
                </Collapse>
              </List>
              <ArgoDivider />
              <MenuInfo />
            </Paper>
          </Grow>

          <Grow
            unmountOnExit
            in={openProfile}
            style={{ transformOrigin: "top right" }}
            timeout="auto"
          >
            <Paper elevation={12} sx={styles.paper}>
              <Box sx={styles.scrollProfileContainer}>
                <CollapsedProfile
                  edit
                  close={() => {
                    handleCloseUserOptions();
                    handleProfileClose();
                  }}
                />
              </Box>
            </Paper>
          </Grow>

          <Grow
            unmountOnExit
            in={openAccount}
            style={{ transformOrigin: "top right" }}
            timeout="auto"
          >
            <Paper elevation={12} sx={styles.paper}>
              <AccountPanel
                onClose={() => {
                  handleCloseUserOptions();
                  handleAccountClose();
                  handlePremiumSubscriptionClose();
                }}
              />
            </Paper>
          </Grow>

          <BaseModal
            ariaDescribedby="Premium Subscription"
            ariaLabelledby="Premium Subscription"
            open={subscriptionInfoModal}
            title="Premium Subscription"
            onClose={() => {
              handleCloseUserOptions();
              handleAccountClose();
              handlePremiumSubscriptionClose();
            }}
          >
            <PremiumSubscriptionPanel
              onClose={() => {
                handleCloseUserOptions();
                handleAccountClose();
                handlePremiumSubscriptionClose();
              }}
            />
          </BaseModal>
        </Box>
      </ClickAwayListener>

      {openEditBoatModal && (
        <EditBoatModal close={() => setOpenEditBoatModal(false)} open={openEditBoatModal} />
      )}
      {openFeedbackModal && (
        <BaseModal
          ariaDescribedby="Feedback"
          ariaLabelledby="Feedback"
          open={openFeedbackModal}
          title="Feedback & Support"
          onClose={() => setOpenFeedbackModal(false)}
        >
          <FeedbackModal onClose={() => setOpenFeedbackModal(false)} />
        </BaseModal>
      )}
      {openSettingsModal && (
        <SettingsModal open={openSettingsModal} onClose={() => setOpenSettingsModal(false)} />
      )}
    </>
  );
}
