import { Box, Grow } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { FormikProps } from "formik";

import { styles } from "./styles";
import { TPlace } from "./EditPlacesDrawer";

import PanelHeader from "@/components/PanelHeader/PanelHeader";
import SearchCoordinatesExpanded from "@/components/Search/SearchCoordinatesExpanded";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { addSelectedPoi, addSelectedPoiCopy, setEditPoiCoordinates } from "@/store/slices/search";

interface IReportPinCoordinatesPanel {
  isOpen: boolean;
  coordinates?: string;
  formData: FormikProps<TPlace>;
  onClose: () => void;
}

function PlaceCoordinatesPanel({
  isOpen,
  coordinates,
  formData,
  onClose,
}: IReportPinCoordinatesPanel) {
  const dispatch = useAppDispatch();
  const { selectedPoi, selectedPoiCopy } = useAppSelector((state) => state.search);

  const handleConfirmCoordinates = () => {
    if (coordinates) {
      onClose();
      formData.setFieldValue("location", { lat: selectedPoiCopy?.lat, lng: selectedPoiCopy?.lng });
      dispatch(
        addSelectedPoi({
          ...selectedPoi,
          lat: selectedPoiCopy?.lat.toFixed(6),
          lng: selectedPoiCopy?.lng.toFixed(6),
        }),
      );
      dispatch(addSelectedPoiCopy(null));
      dispatch(setEditPoiCoordinates(false));
    }
  };

  return (
    <Grow unmountOnExit in={isOpen} style={{ transformOrigin: "top center" }} timeout="auto">
      <Box sx={styles.container}>
        <PanelHeader
          isPrimaryLight
          close={onClose}
          icon={<MyLocationIcon sx={styles.headerIcon} />}
          title="Edit Coordinates"
        />
        <SearchCoordinatesExpanded
          coordinates={coordinates}
          onConfirmCoordinates={handleConfirmCoordinates}
        />
      </Box>
    </Grow>
  );
}

export default PlaceCoordinatesPanel;
