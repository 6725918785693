import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  paper: {
    overflow: "hidden",
    display: "relative",
  },

  searchBox: {
    px: 0.5,
    height: "53px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    zIndex: 9,
  },

  notPremiumBox: {
    px: 0.5,
    height: "53px",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    zIndex: 10,
  },

  searchBoxOpen: {
    px: 0.5,
    height: "54px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid",
    borderColor: `${alpha(defaultTheme.palette.primary.main, 0.3)}`,
  },

  scrollContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "calc(100vh - 87px)",
    "& .MuiAccordion-root:first-of-type": {
      borderTop: "none",
    },
  },

  drawer: {
    width: 408,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 408,
      ml: "80px",
      boxSizing: "border-box",
      boxShadow: 12,
      borderRight: "none",
      borderRadius: "0 8px 8px 0",
      overflow: "hidden",
      zIndex: 2,
    },
  },

  categoriesInDrawer: {
    mt: "64px",
    "& .searchCategories": {
      pl: 1.5,
    },
  },

  searchCategoriesOnMap: {
    [defaultTheme.breakpoints.up("md")]: {
      top: 8,
      left: 396,
      mx: 2,
      zIndex: 7,
      display: "block",
    },
    position: "absolute",
    width: "200px",
    display: "none",
    left: 0,
    top: 64,
    mx: 2,
    zIndex: 7,
  },
};
