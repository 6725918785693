/* eslint-disable react/jsx-props-no-spreading */
import { Stack, Box, IconButton, TextField, Tooltip, Divider } from "@mui/material";
import {
  TripOriginOutlined,
  Place,
  SwapVertOutlined,
  SearchOutlined,
  CloseOutlined,
  DragIndicator,
} from "@mui/icons-material";
import React, { useEffect, useRef } from "react";
import { useUnitSystem } from "@argonav/unit-system";
import { DropResult } from "react-beautiful-dnd";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import SearchAccordion from "../../Search/SearchAccordion";
import SearchCategoriesCarousel from "../../Search/SearchCategoriesCarousel";

import { styles } from "./styles";
import DraggableList from "./DraggableList";

import SearchResults from "@/components/Search/SearchResults";
import { Props } from "@/types/users";
import { isLatLng, isDMSFormat, isDDMFormat } from "@/utils/poi";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { addInterimPoint } from "@/store/slices/route";
import { reorder } from "@/utils/globals";
import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as LockOpen } from "&/img/icons/LockOpenIcon.svg";
import { ReactComponent as LockClosed } from "&/img/icons/LockClosedIcon.svg";
import { IWaypointKind } from "@/types/route";
import ArgoDivider from "@/components/ArgoDivider";

function InterimPointTrack({ position }: { position: number }) {
  return <Box sx={styles.pointTrack}>{position}</Box>;
}

export default function RouteSearchBox({
  setPoint,
  startInput,
  endInput,
  switchLocations,
  inputsFocus,
  setFocus,
  clearRouteInput,
  lockedValue,
  onUnlockStartPoint,
  onLockStartPoint,
  tripEditMode,
  onSearchInterimPoint,
  onSearchCategory,
  onSelectPoiRoute,
  onDeleteCategory,
}: Props) {
  const dispatch = useAppDispatch();
  const { waypoints, routeCreated } = useAppSelector((state) => state.route);
  const { poiList, selectedCategories } = useAppSelector((state) => state.search);
  const itemRefs = useRef<{ [key: string]: HTMLLIElement | null }>({});

  useEffect(() => {
    if (inputsFocus && itemRefs.current[inputsFocus]) {
      itemRefs.current[inputsFocus]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [inputsFocus]);

  useEffect(() => {
    if (tripEditMode) {
      const hasWaypoint = routeCreated?.waypoints?.some(
        (point) => point.kind === (IWaypointKind.WAYPOINT as any),
      );

      if (hasWaypoint) {
        dispatch(
          addInterimPoint(
            routeCreated?.waypoints
              ?.filter((w) => w.kind === (IWaypointKind.WAYPOINT as any))
              .map((point, i) => ({
                id: `Item ${i + 1}`,
                value: `${point.latitude}, ${point.longitude}`,
                lat: point.latitude,
                lng: point.longitude,
                focused: false,
              })),
          ),
        );
      }
    }
  }, [tripEditMode]);
  const handleSetFocus = (
    e: React.FocusEvent<HTMLInputElement> | undefined,
    type: number,
    position?: number,
  ) => {
    if (e) e.target.select();
    if (type === 1 || type === 2) {
      const updatedInterimPoints = waypoints.map((point) => ({ ...point, focused: false }));

      dispatch(addInterimPoint(updatedInterimPoints));
    } else if (type === 3) {
      const updatedInterimPoints = waypoints.map((point, index) =>
        index === position ? { ...point, focused: true } : { ...point, focused: false },
      );

      dispatch(addInterimPoint(updatedInterimPoints));
    }
    setFocus(type);
  };

  const { coordinatesFormatHandler } = useUnitSystem();

  const extractLatAndLng = (input: string) => {
    const [lat, lng] = input.split(",");

    return { lat, lng };
  };

  let inputStartValue;
  let inputEndValue;

  if (isLatLng(startInput) || isDMSFormat(startInput) || isDDMFormat(startInput)) {
    const startValues = startInput ? extractLatAndLng(startInput) : { lat: "", lng: "" };

    const latLngFormattedStart = coordinatesFormatHandler({
      latitude: Number(startValues?.lat),
      longitude: Number(startValues?.lng),
    });

    inputStartValue = latLngFormattedStart;
  } else {
    inputStartValue = startInput;
  }

  if (isLatLng(endInput) || isDMSFormat(endInput) || isDDMFormat(endInput)) {
    const endValues = endInput ? extractLatAndLng(endInput) : { lat: "", lng: "" };

    const latLngFormattedEnd = coordinatesFormatHandler({
      latitude: Number(endValues?.lat),
      longitude: Number(endValues?.lng),
    });

    inputEndValue = latLngFormattedEnd;
  } else {
    inputEndValue = endInput;
  }

  const handleAddNewInterimPoint = () => {
    handleSetFocus(undefined, 3);
    const updatedInterimPoints = waypoints.map((point) => ({ ...point, focused: false }));

    dispatch(
      addInterimPoint([
        ...updatedInterimPoints,
        { id: `Item ${waypoints.length + 1}`, value: "", focused: true },
      ]),
    );
  };

  const onDragEnd = ({ destination, source }: DropResult) => {
    if (!destination) return;

    const newItems = reorder(waypoints, source.index, destination.index);

    dispatch(addInterimPoint(newItems));
  };

  return (
    <Box
      sx={{
        ...styles.container,
        maxHeight: routeCreated ? "calc(100vh - 185px)" : "calc(100vh - 70px)",
      }}
    >
      <Stack direction="row" spacing={0.5} sx={styles.containerFlex}>
        <Stack direction="column" sx={{ flex: 1, justifyContent: "space-between", pt: 2, pb: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", pt: "2px" }}>
            <TripOriginOutlined color="primary" sx={{ fontSize: 20 }} />
          </Box>
          <Box sx={styles.trackContainer}>
            {waypoints.length > 0 &&
              waypoints.map((point, index) => <InterimPointTrack position={index + 1} />)}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pb: "2px !important",
              position: "relative",
            }}
          >
            <Place color="error" sx={{ fontSize: 20 }} />
            <Box sx={styles.iconTrack} />
          </Box>
        </Stack>
        <Stack spacing={2} sx={{ flex: 1 }}>
          <Stack alignItems="center" direction="row">
            <Box>
              <IconButton
                disabled
                aria-label="add"
                size="small"
                sx={styles.iconButton}
                onClick={() => null}
              >
                <DragIndicator />
              </IconButton>
            </Box>
            <Box sx={{ ml: 1, mr: 1 }}>
              <TextField
                ref={(el: any) => {
                  itemRefs.current[1] = el;

                  return null;
                }}
                InputProps={{
                  endAdornment: startInput ? (
                    <Stack direction="row">
                      {lockedValue && !tripEditMode ? (
                        <IconButton onClick={onUnlockStartPoint}>
                          <BaseIcon icon={LockClosed} />
                        </IconButton>
                      ) : (
                        <>
                          {!tripEditMode && (
                            <Tooltip arrow placement="top" title="Lock Starting Point">
                              <IconButton onClick={onLockStartPoint}>
                                <BaseIcon icon={LockOpen} />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip arrow placement="top" title="Clear">
                            <IconButton onClick={() => clearRouteInput(1)}>
                              <CloseOutlined />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </Stack>
                  ) : inputsFocus === 1 ? (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ) : undefined,
                }}
                disabled={lockedValue}
                focused={inputsFocus === 1}
                label="Choose a starting point"
                sx={{
                  ...styles.textfield,
                  "& .MuiSvgIcon-root": {
                    opacity: startInput ? 1 : 0,
                  },
                }}
                type="text"
                value={inputStartValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoint(e, 1)}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleSetFocus(e, 1)}
              />
            </Box>
            <Box>
              <IconButton
                aria-label="switch"
                color="primary"
                size="small"
                sx={styles.iconButton}
                onClick={switchLocations}
              >
                <SwapVertOutlined />
              </IconButton>
            </Box>
          </Stack>

          {waypoints.length > 0 && (
            <DraggableList
              clearRouteInput={clearRouteInput}
              items={waypoints}
              onDragEnd={onDragEnd}
              onSearchInterimPoint={onSearchInterimPoint}
              onSetFocus={handleSetFocus}
            />
          )}
          <Stack alignItems="center" direction="row">
            <Box>
              <IconButton
                disabled
                aria-label="add"
                size="small"
                sx={styles.iconButton}
                onClick={() => null}
              >
                <DragIndicator />
              </IconButton>
            </Box>
            <Box sx={{ ml: 1, mr: 1 }}>
              <TextField
                ref={(el: any) => {
                  itemRefs.current[2] = el;

                  return null;
                }}
                InputProps={{
                  endAdornment: endInput ? (
                    <IconButton onClick={() => clearRouteInput(2)}>
                      <CloseOutlined />
                    </IconButton>
                  ) : inputsFocus === 2 ? (
                    <IconButton>
                      <SearchOutlined />
                    </IconButton>
                  ) : undefined,
                }}
                focused={inputsFocus === 2 || lockedValue}
                label="Choose a destination"
                sx={{
                  ...styles.textfield,
                  "& .MuiSvgIcon-root": {
                    opacity: endInput ? 1 : 0,
                  },
                }}
                type="text"
                value={inputEndValue}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPoint(e, 2)}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => handleSetFocus(e, 2)}
              />
            </Box>
            <Box>
              <Tooltip arrow placement="top" title="Add Waypoint">
                <IconButton
                  aria-label="add"
                  color="primary"
                  disabled={startInput === "" || waypoints.length > 0}
                  size="small"
                  sx={styles.iconButton}
                  onClick={handleAddNewInterimPoint}
                >
                  <AddCircleIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <ArgoDivider />
      {!poiList && (
        <>
          <SearchAccordion
            inputType={inputsFocus}
            selectedCategories={selectedCategories}
            setPoiToRoute={onSelectPoiRoute}
            onSetInputValue={onSearchCategory}
          />
          <Divider />
        </>
      )}
      {selectedCategories.length > 0 && (
        <>
          <SearchCategoriesCarousel
            selectedCategories={selectedCategories}
            onDeleteCategory={onDeleteCategory}
            onSetInputValue={onSearchCategory}
          />
          <Divider />
        </>
      )}
      <SearchResults inputType={inputsFocus} setPoiToRoute={onSelectPoiRoute} />
    </Box>
  );
}
