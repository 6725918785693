import * as React from "react";
import {
  Tab,
  Tabs,
  DialogContent,
  DialogActions,
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {
  EditLocationAlt,
  LocationOn,
  Straighten,
  Close,
  AdminPanelSettings,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { CoordinatesFormats } from "@argonav/unit-system";

import { styles } from "./styles";
import SettingsModalRoute from "./SettingsModalRoute";
import SettingsModalCoordinate from "./SettingsModalCoordinate";
import SettingsModalLocation from "./SettingsModalLocation";
import SettingsModalUnit from "./SettingsModalUnit";
import SettingsModalAdvancedOptions from "./SettingsModalAdvancedOptions";

import BaseIcon from "@/components/BaseIcon";
import { ReactComponent as EditRouteIcon } from "&/img/icons/edit-route.svg";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import userService from "@/services/user";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { setUserOptions } from "@/store/slices/user";
import { ERouteSettings } from "@/types/route";
import { IMapPois } from "@/types/pois";

interface ISettingsModal {
  open: boolean;
  onClose: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      aria-labelledby={`vertical-tab-${index}`}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      role="tabpanel"
      {...other}
      sx={{ width: "100%" }}
    >
      {value === index && <Box sx={{ pl: 3 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function SettingsModal({ onClose, open }: ISettingsModal) {
  const [value, setValue] = React.useState(0);
  const dispatch = useAppDispatch();
  const { userOptions } = useAppSelector((state) => state.user);
  const [routeSettings, setRouteSettings] = useState<ERouteSettings>(userOptions?.route_settings);
  const [hugShore, setHugShore] = useState<boolean>(userOptions?.hug_shore || false);
  const [coordinateSettings, setCoordinatesSettings] = useState(userOptions.coordinate_format);
  const [currentLocationSelected, setCurrentLocationSelected] = useState({
    initial_address: userOptions?.initial_address,
    initial_lat: userOptions?.initial_lat,
    initial_lng: userOptions?.initial_lng,
  } as any);
  const [unitSettings, setUnitSettings] = useState({
    depthUnit: userOptions?.length_unit,
    speedUnit: userOptions?.speed_unit,
    fuelUnit: userOptions?.volume_unit,
  });

  const handleChangeUnitSettings = (type: string, value: string) => {
    setUnitSettings({
      ...unitSettings,
      [type]: value,
    });
  };

  const handleChangeCoordinatesSettings = (value: CoordinatesFormats) => {
    setCoordinatesSettings(value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeSettings = (value: ERouteSettings) => {
    setRouteSettings(value);
    setHugShore(false);
  };

  const handleSetCurrentLocation = (place: IMapPois | undefined) => {
    setCurrentLocationSelected({
      initial_address: place?.address || "",
      initial_lat: place?.lat || "",
      initial_lng: place?.lng || "",
    });
  };

  const handleSubmitSettings = async () => {
    // setLoading(true);
    const userOptionsEdited = {
      ...userOptions,
      route_settings: routeSettings,
      length_unit: unitSettings?.depthUnit,
      speed_unit: unitSettings?.speedUnit,
      volume_unit: unitSettings?.fuelUnit,
      coordinate_format: coordinateSettings,
      initial_address: currentLocationSelected.initial_address,
      initial_lat: currentLocationSelected.initial_lat,
      initial_lng: currentLocationSelected.initial_lng,
      hug_shore: routeSettings !== ERouteSettings.Outside ? false : hugShore,
    };

    try {
      const { status } = await userService.editUserOptions({
        route_settings: routeSettings,
        length_unit: unitSettings?.depthUnit,
        speed_unit: unitSettings?.speedUnit,
        volume_unit: unitSettings?.fuelUnit,
        coordinate_format: coordinateSettings,
        initial_address: currentLocationSelected.initial_address,
        initial_lat: currentLocationSelected.initial_lat,
        initial_lng: currentLocationSelected.initial_lng,
        hug_shore: routeSettings !== ERouteSettings.Outside ? false : hugShore,
      });

      if (status === 200) {
        switch (routeSettings) {
          case ERouteSettings.Avoid: {
            dispatch(setUserOptions(userOptionsEdited));
            break;
          }
          case ERouteSettings.Outside: {
            dispatch(setUserOptions(userOptionsEdited));
            break;
          }
          default: {
            dispatch(setUserOptions(userOptionsEdited));
          }
        }

        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "success",
            msj: "Your settings were saved.",
          }),
        );
      }
    } catch (error: any) {
      dispatch(
        setSnackBarMsjSucceded({
          state: true,
          type: "error",
          msj: error.toString(),
        }),
      );
    }
  };

  useEffect(() => {
    if (userOptions?.route_settings === ERouteSettings.Avoid) {
      setRouteSettings(ERouteSettings.Avoid);
    } else if (userOptions?.route_settings === ERouteSettings.Outside) {
      setRouteSettings(ERouteSettings.Outside);
    } else {
      setRouteSettings(ERouteSettings.Default);
    }
  }, [userOptions]);

  const handleApplySettings = () => {
    handleSubmitSettings();
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        sx: { overflow: "unset" },
      }}
      aria-describedby="edit-boat-dialog-description"
      aria-labelledby="edit-boat-dialog-title"
      maxWidth="md"
      open={open}
      scroll="body"
    >
      <DialogTitle id="alert-dialog-title" sx={styles.dialogTitle}>
        Settings
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <Box sx={styles.container}>
          <Tabs
            aria-label="Vertical tabs example"
            orientation="vertical"
            sx={styles.tabs}
            value={value}
            onChange={handleChange}
          >
            <Tab
              icon={<LocationOn />}
              iconPosition="start"
              label="User Location Settings"
              {...a11yProps(0)}
              sx={styles.tab}
            />
            <Tab
              icon={<BaseIcon icon={EditRouteIcon} />}
              iconPosition="start"
              label="Route Settings"
              {...a11yProps(1)}
              sx={styles.tab}
            />
            <Tab
              icon={<Straighten />}
              iconPosition="start"
              label="Unit Settings"
              {...a11yProps(2)}
              sx={styles.tab}
            />
            <Tab
              icon={<EditLocationAlt />}
              iconPosition="start"
              label="Coordinate Settings"
              {...a11yProps(3)}
              sx={styles.tab}
            />
            {userOptions?.user?.privilege === "admin" && (
              <Tab
                icon={<AdminPanelSettings />}
                iconPosition="start"
                label="Advanced Options"
                {...a11yProps(4)}
                sx={styles.tab}
              />
            )}
          </Tabs>
          <TabPanel index={0} value={value}>
            <SettingsModalLocation
              currentLocationSelected={currentLocationSelected}
              onSetCurrentLocation={handleSetCurrentLocation}
            />
          </TabPanel>
          <TabPanel index={1} value={value}>
            <SettingsModalRoute
              hugShore={hugShore}
              routeSettings={routeSettings}
              setHugShore={setHugShore}
              onChangeSettings={handleChangeSettings}
            />
          </TabPanel>
          <TabPanel index={2} value={value}>
            <SettingsModalUnit onSetDepthUnit={handleChangeUnitSettings} />
          </TabPanel>
          <TabPanel index={3} value={value}>
            <SettingsModalCoordinate
              coordinateSettings={coordinateSettings}
              onSetCoordinatesSettings={handleChangeCoordinatesSettings}
            />
          </TabPanel>
          <TabPanel index={4} value={value}>
            <SettingsModalAdvancedOptions />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button autoFocus variant="contained" onClick={handleApplySettings}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SettingsModal;
