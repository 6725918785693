import axios, { AxiosResponseHeaders } from "axios";
import dayjs from "dayjs";

import apiConfig from "@/config/api";
import userService from "@/services/user";

let isLoggingOut = false;

const instance = axios.create({
  baseURL: apiConfig.apiBaseURL,
  timeout: apiConfig.timeout,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    Accept: "application/vnd.argo.v2+json",
  },
});

const logout = (userInfo?: AxiosResponseHeaders) => {
  if (!isLoggingOut && userInfo) {
    userService.setLoginHistory(userInfo?.id);
    localStorage.removeItem("user");
    localStorage.removeItem("auth");

    setTimeout(() => {
      isLoggingOut = false;
      window.location.href = "/home";
    }, 0);
  }
};

instance.interceptors.request.use(
  async (config) => {
    const userAuth = localStorage.getItem("auth");
    const userInfoStorage = localStorage.getItem("user");
    const auth: AxiosResponseHeaders = userAuth ? JSON.parse(userAuth) : null;
    const userInfo: AxiosResponseHeaders = userInfoStorage ? JSON.parse(userInfoStorage) : null;

    if (auth && config.headers) {
      const currentTime = dayjs();
      const expirationTime = dayjs.unix(auth.expiry);
      const expired = auth.expiry ? currentTime.isAfter(expirationTime) : true;

      if (expired) {
        try {
          const { status, headers } = await axios.request({
            method: "get",
            url: `${import.meta.env.VITE_APP_URL_BASE}friend_requests`,
            headers: {
              uid: auth.uid,
              client: auth.client,
              "access-token": auth["access-token"],
            },
          });

          if (status === 200) {
            localStorage.setItem(
              "auth",
              JSON.stringify({
                client: headers.client,
                uid: headers.uid,
                expiry: headers.expiry,
                "access-token": headers["access-token"],
              }),
            );
            config.headers["access-token"] = headers["access-token"];
            config.headers.client = headers.client;
            config.headers.uid = headers.uid;
            config.headers.expiry = headers.expiry;
          }
          if (status === 401) {
            logout(userInfo);
          }
        } catch (error: any) {
          logout(userInfo);
        }
      } else {
        config.headers["access-token"] = auth["access-token"];
        config.headers.client = auth.client;
        config.headers.uid = auth.uid;
        config.headers.expiry = auth.expiry;
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export default instance;
