import { CancelToken } from "axios";

import axios from "./config";

import { Props } from "@/types/users";
import { IDepthShading, Position } from "@/types/map";

export default {
  searchMapPois: (data: Props) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "get",
      url: "/search",
      params: data,
    }),
  createRoute: (data: any, cancelToken?: CancelToken) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "get",
      url: "/route",
      params: data,
      cancelToken,
    }),
  createVoyage: (data: any) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v2+json" },
      method: "post",
      url: "/voyages",
      data,
    }),
  editVoyage: (id: number | undefined, data: any) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v2+json" },
      method: "put",
      url: `/voyages/${id}`,
      data,
    }),
  saveRouteCourses: (data: any) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v2+json" },
      method: "post",
      url: `/courses/auto`,
      data,
    }),
  deleteRouteCourses: (voyageId: number, courseId: number) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "delete",
      url: `/voyages/${voyageId}/courses/${courseId}`,
    }),
  saveDepthShadingList: (data: IDepthShading) =>
    axios.request({
      method: "put",
      url: `/api/v1/depth_shadings/${data.id}`,
      data,
    }),
  getDepthShadingList: (params: any) =>
    axios.request({
      method: "get",
      url: "/api/v1/depth_shadings",
      params,
    }),
  updateDepthShadingList: (data: IDepthShading) =>
    axios.request({
      headers: { Accept: "application/vnd.argo.v3+json" },
      method: "put",
      url: `/depth_shadings/${data?.id}`,
      params: {
        name: data.name,
      },
      data,
    }),
  getTideAndCurrents: (params: any) =>
    axios.request({
      method: "get",
      url: "/api/v1/tide_currents",
      params,
    }),
  getDepth: (coords: Position) =>
    axios.request({
      url: `/depth`,
      method: "get",
      headers: { Accept: "application/vnd.search.v2+json" },
      params: {
        lat: coords?.lat,
        lng: coords?.lng,
      },
    }),
};
