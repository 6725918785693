import React, { useState } from "react";
import {
  IconButton,
  TextField,
  Typography,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Button,
  Stack,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { authenticate } from "@/store/slices/auth/actions";
import { LoginEmail } from "@/types/users";
import { ELoginMethod } from "@/types/auth";

const formSchema = yup.object({
  email: yup
    .string()
    .required("Email is a required field")
    .email("Valid email is a required field"),
  password: yup
    .string()
    .required("Password is a required field")
    .min(8, "Password must be 8 characters long"),
});

export default function EmailSignInForm({ setOptionValue }: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading } = useAppSelector((state) => state.auth);
  const { coordsParams } = useAppSelector((state) => state.mapParams);

  const [showPwd, setShowPwd] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPwd(!showPwd);
  };

  const handleSubmit = async (values: LoginEmail) => {
    const res = await dispatch(authenticate(values));

    if (res) localStorage.setItem("lastLoginMethod", ELoginMethod.Email);

    if (res && id === "us-monthly") {
      window.location.href = "/premium-payment?type_subscription=us-monthly";
    } else if (res && id === "us-yearly") {
      window.location.href = "/premium-payment?type_subscription=us-yearly";
    } else if (res && id === "lifetime") {
      window.location.href = "/premium-payment?type_subscription=us-lifetime";
    } else if (res) {
      if (coordsParams) {
        window.location.href = `/${coordsParams}`;
      } else {
        window.location.href = "/";
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    } as LoginEmail,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      handleSubmit({
        email: values.email,
        password: values.password,
      } as LoginEmail);
    },
  });

  return (
    <Stack component="form" spacing={3} sx={{ width: "100%" }} onSubmit={formik.handleSubmit}>
      <Stack spacing={1.5}>
        <Typography component="h3" textAlign="center" variant="h6">
          Email Sign In
        </Typography>
        <TextField
          fullWidth
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <FormControl
          fullWidth
          error={formik.touched.password && Boolean(formik.errors.password)}
          variant="outlined"
        >
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowPassword}
                >
                  {showPwd ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            id="password"
            label="Password"
            name="password"
            type={showPwd ? "text" : "password"}
            onChange={formik.handleChange}
          />
          <FormHelperText id="password-text">
            {formik.touched.password && formik.errors.password}
          </FormHelperText>
        </FormControl>
      </Stack>
      <Stack spacing={1.5}>
        <LoadingButton
          fullWidth
          endIcon={<ArrowForwardIcon />}
          loading={loading}
          size="large"
          type="submit"
          variant="contained"
        >
          Sign In
        </LoadingButton>
        <Button fullWidth size="large" variant="outlined" onClick={() => setOptionValue(3)}>
          Create an Account
        </Button>
        <Button
          fullWidth
          size="large"
          variant="text"
          onClick={() => navigate("/recovery-password")}
        >
          Forgot Your Password?
        </Button>
      </Stack>
    </Stack>
  );
}
