import { createSlice } from "@reduxjs/toolkit";

import { IDepthShading } from "@/types/map";

export interface IDrawersState {
  depthShadingDrawerOpen: boolean;
  depthShadingToEdit: IDepthShading | null;
  depthShadingOrigin: string;
}

const DEFAULT_BASE_DOMAIN = "https://tileserver-cf.argonav.io";
const TILES_PARAMS = "/tile?z={z}&x={x}&y={y}";

export const DEFAULT_TILES_URL = `${DEFAULT_BASE_DOMAIN}${TILES_PARAMS}`;

const STORAGE_KEY = "depthShadingDomain";
const storedURL = localStorage.getItem(STORAGE_KEY) || DEFAULT_BASE_DOMAIN;

const initialState: IDrawersState = {
  depthShadingDrawerOpen: false,
  depthShadingToEdit: null,
  depthShadingOrigin: storedURL,
};

export const depthshadingSlice = createSlice({
  name: "depthshading",
  initialState,
  reducers: {
    setDepthShadingDrawerOpen: (state, action) => {
      state.depthShadingDrawerOpen = action.payload;
    },
    setDepthShadingToEdit: (state, action) => {
      state.depthShadingToEdit = action.payload;
    },
    setDepthShadingOrigin: (state, action) => {
      state.depthShadingOrigin = action.payload ? action.payload : DEFAULT_BASE_DOMAIN;
      localStorage.setItem(STORAGE_KEY, state.depthShadingOrigin);
    },
  },
});

export const selectDepthShadingURL = (state: { depthshading: IDrawersState }) =>
  `${state.depthshading.depthShadingOrigin}${TILES_PARAMS}`;

export const { setDepthShadingDrawerOpen, setDepthShadingToEdit, setDepthShadingOrigin } =
  depthshadingSlice.actions;

export default depthshadingSlice.reducer;
