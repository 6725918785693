import { createSlice } from "@reduxjs/toolkit";

import { IVoyages, ITrip } from "@/types/route";
import { Position } from "@/types/map";
import { DEFAULT_ZOOM } from "@/utils/maps";

export interface IRoutes {
  center: Position;
  zoom: number;
  isLoading: boolean;
  trips: IVoyages | null;
  selectedTrip: ITrip | null;
  tripEditMode: boolean;
  error: string | null;
}

const DEFAULT_CENTER = {
  lat: 37.783,
  lng: -122.403,
};

export const initialState: IRoutes = {
  center: DEFAULT_CENTER,
  zoom: DEFAULT_ZOOM,
  isLoading: false,
  trips: null,
  selectedTrip: null,
  tripEditMode: false,
  error: null,
};

const captainsLogSlice = createSlice({
  name: "captainsLog",
  initialState,
  reducers: {
    fetchStarted: (state, action) => {
      state.isLoading = action.payload;
    },
    addVoyages: (state, action) => {
      state.trips = action.payload;
    },
    addSelectedTrip: (state, action) => {
      state.selectedTrip = action.payload;
    },
    setTripEditMode: (state, action) => {
      state.tripEditMode = action.payload;
    },
    addCenter: (state, action) => {
      state.center = action.payload;
    },
    addZoom: (state, action) => {
      state.zoom = action.payload;
    },
  },
});

export const { addVoyages, addSelectedTrip, addCenter, addZoom, setTripEditMode } =
  captainsLogSlice.actions;

export default captainsLogSlice.reducer;
