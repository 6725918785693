import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  container: {
    px: 2,
    pb: 2,
    pt: 3,
  },

  mainContainer: {
    "& .coverImage": {
      position: "relative",
      minHeight: 244,
      backgroundSize: "cover",
      backgroundPosition: "center",
      "&:before": {
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        background: `${alpha(defaultTheme.palette.common.black, 0.3)}`,
      },
    },
  },

  userFirstName: {
    ...layoutStyles.ellipsisOneLine,
    lineHeight: "normal",
  },

  mainContainerDrawer: {
    "& .coverImage": {
      position: "relative",
      minHeight: 306,
      backgroundSize: "cover",
      backgroundPosition: "center",
      "&:before": {
        content: "''",
        width: "100%",
        height: "100%",
        top: 0,
        position: "absolute",
        background: `${alpha(defaultTheme.palette.common.black, 0.3)}`,
      },
    },
  },

  header: {
    px: 2,
    py: 1.5,
    "& .closeButton": {
      boxShadow: 4,
    },
  },

  infoContainer: {
    mt: "-48px",
    px: 2,
  },

  avatar: {
    border: "4px solid",
    borderColor: "background.paper",
    boxShadow: 12,
    width: 120,
    height: 120,
  },

  premiumIcon: {
    width: 14,
    height: 14,
  },

  boatinfoCardContent: {
    "&:last-of-type": { pb: 2 },
  },

  boatInfo: {
    "& .boatInfoRow div": {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },

    "& .boatInfoRow": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      px: 1.5,
      py: 0.75,
      pb: 1,
      borderRadius: 0.5,
    },

    "& .boatInfoRow:nth-of-type(odd)": {
      backgroundColor: "grey.100",
    },

    "& .boatInfoRow h4": {
      lineHeight: "normal",
    },
  },

  searchBox: {
    position: "absolute",
    width: "calc(100% - 24px)",
    zIndex: 2,
    mt: 1,
    mx: 1.5,
  },

  chipWithIcon: {
    pl: 0.5,
    "& .MuiSvgIcon-root": {
      color: "action.active",
    },
    "& .MuiChip-label": {
      pl: 0.25,
    },
  },
};
