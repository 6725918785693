import { Card, Box, Typography } from "@mui/material";
import { Verified } from "@mui/icons-material";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { styles } from "./styles";

import ServicePremium from "@/services/premium";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { useAppSelector } from "@/hooks/useRedux";
import { SubscriptionOptions } from "@/types/premium";

const stripePromise = loadStripe(import.meta.env.VITE_APP_STRIPE_PUBLISHABLE_KEY);

export const stripe_sub_name_us_yearly = "1_year_of_argo_premium:yearly";
export const stripe_sub_name_us_monthly = "1_year_of_argo_premium:monthly";
export const membership_name_yearly = "Argo Premium (Yearly)";
export const membership_name_monthly = "Argo Premium (Monthly)";

export default function PremiumPaymentCard() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [secretValue, setSecretValue] = useState<string | null>(null);
  const { userInformation, readyToSubscribe } = useAppSelector((state) => state.user);
  const subscriptionType = searchParams.get("type_subscription");

  const handleGetClientSecret = async () => {
    let subscriptionParams: {
      platform: string;
      product_name?: string;
      started_with_trial?: boolean;
      email: string | null;
      localhost?: boolean;
      checkout_mode: string;
    } = {
      platform: "stripe",
      checkout_mode: "subscription",
      product_name:
        subscriptionType === SubscriptionOptions.USMonthly
          ? stripe_sub_name_us_monthly
          : stripe_sub_name_us_yearly,
      started_with_trial: true,
      email: null,
    };

    if (import.meta.env.VITE_APP_STRIPE_LOCALHOST) {
      subscriptionParams = { ...subscriptionParams, localhost: true };
    }

    try {
      const { status, data } = await ServicePremium.setSubscriptionIntent(subscriptionParams);

      if (status === 201 && data) {
        setSecretValue(data.data.ext_data.client_secret);
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  useEffect(() => {
    handleGetClientSecret();
  }, [userInformation, readyToSubscribe]);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: secretValue,
  };

  if (!secretValue) return null;

  return (
    <Card>
      <Box sx={styles.dialogHeader}>
        <Verified sx={styles.dialogHeaderIcon} />
        <Typography sx={styles.dialogHeaderTitle} variant="h5">
          PREMIUM SUBSCRIPTION
        </Typography>
      </Box>
      <Box id="checkout" sx={styles.formContainer}>
        {secretValue && (
          <EmbeddedCheckoutProvider options={options} stripe={stripePromise}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </Box>
    </Card>
  );
}
