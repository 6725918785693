import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Stack,
  Grid,
  Chip,
  IconButtonProps,
  IconButton,
  styled,
  Collapse,
} from "@mui/material";
import { CloseOutlined, EditOutlined, ExpandMoreOutlined } from "@mui/icons-material";
import { useUnitSystem, LengthUnits, VolumeUnits, SpeedUnits } from "@argonav/unit-system";

import EmptyState from "../EmptyState/EmptyState";

import ProfileSubtitle from "./ProfileSubtitle";
import { styles } from "./styles";
import EditProfileModal from "./EditProfileModal";
import EditBoatModal from "./EditBoatModal";
import EditAffiliationsModal from "./EditAffiliationsModal";
import EditInterestsModal from "./EditInterestsModal";

import useDisclosure from "@/hooks/useDisclosure";
import {
  getAffiliations,
  getUserInformation,
  getUserOptions,
  getInterests,
  getVessels,
} from "@/store/slices/user/actions";
import { TVessel } from "@/types/vessel";
import { fromAffiliationsToAffiliationItems } from "@/utils/affiliations";
import { IAffiliationItem } from "@/types/affiliation";
import { Interest } from "@/types/interest";
import useUserInformation from "@/hooks/useUserInformation";
import useUserAffiliations from "@/hooks/useUserAffiliations";
import useUserInterests from "@/hooks/useUserInterests";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean | string;
}

const handleBoatTypeName = (value: string | undefined) => {
  if (value === "power") return "Power";
  if (value === "jet_ski") return "Jet Ski";
  if (value === "sail") return "Sail";
  if (value === "paddle") return "Paddle";
  if (value === "other") return "Other";

  return undefined;
};

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { ...other } = props;

  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const nullValue = "No Data";

const LIFETIME_TAG = "argo_premium_lifetime";

export default function CollapsedProfile({ edit, close, isDrawer }: any) {
  const { onToggle, isOpen } = useDisclosure();
  const dispatch = useAppDispatch();
  const { logged } = useAppSelector((state) => state.auth);
  const { userInterests: interests } = useUserInterests();
  const { userAffiliations: affiliations } = useUserAffiliations();
  const { userInformation } = useUserInformation();
  const [affiliationItems, setAffiliationItems] = useState<IAffiliationItem[]>([]);
  const { lengthUnitHandler, consumptionUnitHandler, speedUnitHandler } = useUnitSystem();

  /**
   * When we receive affiliations we convert them to Items so they can be displayed
   */
  useEffect(() => {
    if (affiliations) {
      setAffiliationItems(fromAffiliationsToAffiliationItems(affiliations));
    }
  }, [affiliations]);

  /**
   * When we detect the login state we dispatch
   * these API calls to retrieve the user stuff
   */
  useEffect(() => {
    if (logged) {
      // Just refresh the user information just in case
      dispatch(getVessels());
      dispatch(getAffiliations());
      dispatch(getUserInformation(userInformation?.id));
      dispatch(getUserOptions());
      dispatch(getInterests());
    }
  }, [logged]);

  // Only to open Edit Modal
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  // Only to open Edit Modal
  const [openEditModal, setOpenEditModal] = React.useState(false);

  const handleEditModalOpen = () => {
    setOpenEditModal(true);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  // Only to open Affiliations Modal
  const [openAffiliationsModal, setOpenAffiliationsModal] = React.useState(false);

  const handleAffiliationsModalOpen = () => {
    setOpenAffiliationsModal(true);
  };

  const handleAffiliationsModalClose = () => {
    setOpenAffiliationsModal(false);
  };

  // Only to open Interests Modal
  const [openInterestsModal, setOpenInterestsModal] = React.useState(false);

  const handleInterestsModalOpen = () => {
    setOpenInterestsModal(true);
  };

  const handleInterestsModalClose = () => {
    setOpenInterestsModal(false);
  };

  const vessel: TVessel | null = userInformation?.current_vessel || null;
  const hasAffiliations = affiliationItems && affiliationItems.length > 0;
  const hasInterests = interests && interests.length > 0;
  const avatarFallbackSrc = "/img/placeholders/defaultUserMarkerSquare.png";
  const coverFallbackSrc = "/img/placeholders/argoBG.jpg";
  const lifetimePremium = "/img/icons/CheckIcon.svg";

  const boatBeam = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.beam || 0,
  });
  const boatDraft = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.draft || 0,
  });
  const boatBuffer = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.buffer || 0,
  });
  const boatLength = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.length || 0,
  });
  const boatHeight = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: vessel?.height || 0,
  });
  const boatAvgSpeed = speedUnitHandler({
    originalUnit: SpeedUnits.KNOT,
    originalValue: vessel?.average_speed || 0,
  });
  const boatFuelConsumption = consumptionUnitHandler({
    originalUnit: VolumeUnits.GALLONS,
    originalValue: Number(vessel?.fuel_consumption) || 0,
  });

  return (
    <Box sx={isDrawer ? styles.mainContainerDrawer : styles.mainContainer}>
      <Box
        className="coverImage"
        sx={{
          backgroundImage: `url(${
            userInformation?.cover ? userInformation?.cover : coverFallbackSrc
          })`,
        }}
      >
        {!isDrawer && (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            spacing={1.5}
            sx={styles.header}
          >
            <Stack alignItems="center" direction="row" spacing={1.5}>
              {edit && (
                <Button
                  color="whiteBg"
                  size="small"
                  startIcon={<EditOutlined />}
                  variant="outlined"
                  onClick={handleEditOpen}
                >
                  Edit Profile
                </Button>
              )}
              <Button
                aria-label="delete"
                className="closeButton"
                color="whiteBg"
                size="small"
                variant="iconButtonContained"
                onClick={close}
              >
                <CloseOutlined />
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
      <Stack alignItems="flex-end" direction="row" spacing={2} sx={styles.infoContainer}>
        <Avatar
          alt={`${userInformation?.first_name || ""} ${userInformation?.last_name || ""}`}
          src={userInformation?.image ? userInformation?.image : avatarFallbackSrc}
          sx={styles.avatar}
        />
        <Box>
          <Typography sx={styles.userFirstName} variant="h5">
            {`${userInformation?.first_name || ""} ${userInformation?.last_name || ""}`}
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography color="text.secondary" variant="subtitle2">
              {userInformation?.username || ""}
            </Typography>
            {userInformation?.latest_user_subscription?.membership_tag === LIFETIME_TAG && (
              <Stack alignItems="center" direction="row" spacing={1}>
                <Typography color="text.secondary" variant="subtitle2">
                  •
                </Typography>
                <Avatar alt="Lifetime" src={lifetimePremium} sx={styles.premiumIcon} />
                <Typography color="primary" fontSize={12} variant="subtitle2">
                  Lifetime Member
                </Typography>
              </Stack>
            )}
          </Stack>
        </Box>
      </Stack>
      <Stack spacing={3} sx={styles.container}>
        <Box>
          <ProfileSubtitle action={handleEditModalOpen} edit={edit} title="Boat Information" />
          <Card variant="outlined">
            {vessel?.assets[0]?.download_url ? (
              <CardMedia
                alt={vessel?.manufacturer_model || "boat model"}
                component="img"
                height="160"
                image={vessel?.assets[0]?.download_url}
              />
            ) : (
              <EmptyState
                imageURL="img/placeholders/EmptyStateBoat.svg"
                sx={{ width: "100%", height: "160px", backgroundColor: "grey.50" }}
                title="No Boat Image Yet"
              />
            )}
            <CardContent sx={styles.boatinfoCardContent}>
              <Stack alignItems="center" direction="row" justifyContent="space-between">
                <Box>
                  <Typography variant="subtitle1">{vessel?.name || nullValue}</Typography>
                  <Typography color="text.secondary" variant="body2">
                    {vessel?.manufacturer_model}
                  </Typography>
                </Box>
                <ExpandMore
                  aria-expanded={isOpen}
                  aria-label="show more"
                  expand={isOpen}
                  size="small"
                  onClick={onToggle}
                >
                  <ExpandMoreOutlined />
                </ExpandMore>
              </Stack>
              <Collapse unmountOnExit in={isOpen} timeout="auto">
                <Stack mt={2} spacing={0} sx={styles.boatInfo}>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Boat Type
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {handleBoatTypeName(vessel?.boat_type) || nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Home Port
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.port_name || nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Draft
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.draft ? `${boatDraft.value} ${boatDraft.unit}` : nullValue}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Buffer
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.buffer ? `${boatBuffer.value} ${boatBuffer.unit}` : nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Length
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.length ? `${boatLength.value} ${boatLength.unit}` : nullValue}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Beam
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.beam ? `${boatBeam.value} ${boatBeam.unit}` : nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Height
                      </Typography>
                      <Typography component="h4" variant="body2">
                        {vessel?.height ? `${boatHeight.value} ${boatHeight.unit}` : nullValue}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Est. Avg. Speed
                      </Typography>
                      <Typography variant="body2">
                        {vessel?.average_speed
                          ? `${boatAvgSpeed.value} ${boatAvgSpeed.unit}`
                          : nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack className="boatInfoRow">
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Fuel/Power
                      </Typography>
                      <Typography variant="body2">
                        {vessel?.fuel_type ? `${boatFuelConsumption.value}` : nullValue}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography color="text.secondary" variant="caption">
                        Fuel/Power Usage
                      </Typography>
                      <Typography variant="body2">
                        {vessel?.fuel_consumption
                          ? `${boatFuelConsumption.value} ${
                              vessel?.fuel_type === "electric" ? "kWh" : boatFuelConsumption.unit
                            }`
                          : nullValue}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Collapse>
            </CardContent>
          </Card>
        </Box>
        <Box>
          <ProfileSubtitle
            divider
            action={handleAffiliationsModalOpen}
            edit={edit}
            title="Affiliations"
          />
          {hasAffiliations && (
            <Grid container columnSpacing={1.25} rowSpacing={1}>
              {affiliationItems.map((item) => (
                <Grid key={item.label} item xs="auto">
                  <Chip icon={item.icon} label={item.label} sx={styles.chipWithIcon} />
                </Grid>
              ))}
            </Grid>
          )}
          {!hasAffiliations && <Typography>No affiliations to show</Typography>}
        </Box>
        <Box>
          <ProfileSubtitle
            divider
            action={handleInterestsModalOpen}
            edit={edit}
            title="Interests"
          />
          {hasInterests && (
            <Grid container columnSpacing={1.5} rowSpacing={1}>
              {interests.map((interest: Interest) => (
                <Grid key={interest.id} item xs="auto">
                  <Chip label={interest.name} />
                </Grid>
              ))}
            </Grid>
          )}
          {!hasInterests && <Typography>No interests to show</Typography>}
        </Box>
      </Stack>
      {openEdit && <EditProfileModal close={handleEditClose} open={openEdit} />}
      {openEditModal && <EditBoatModal close={handleEditModalClose} open={openEditModal} />}
      {openAffiliationsModal && (
        <EditAffiliationsModal close={handleAffiliationsModalClose} open={openAffiliationsModal} />
      )}
      {openInterestsModal && (
        <EditInterestsModal close={handleInterestsModalClose} open={openInterestsModal} />
      )}
    </Box>
  );
}
