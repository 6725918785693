/* global GeolocationPosition */
import { Box, Typography, Stack, Button, List } from "@mui/material";
import {
  LocationOn,
  Phone,
  PublicOutlined,
  Mail,
  AttachMoneyOutlined,
  AccessTimeOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState, useRef } from "react";

import BaseModal from "../Modals/BaseModal";

import { styles } from "./styles";
import PlaceInfoListItem from "./PlaceInfoListItem";
import PlaceInfoDescription from "./PlaceInfoSections/PlaceInfoDescription";
import PlaceInfoFuel from "./PlaceInfoSections/PlaceInfoFuel";
import PlaceInfoTransient from "./PlaceInfoSections/PlaceInfoTransient";
import PlaceInfoAmenities from "./PlaceInfoSections/PlaceInfoAmenities";
import PlaceInfoApproach from "./PlaceInfoSections/PlaceInfoApproach";
import PlaceInfoPrivateDock from "./PlaceInfoSections/PlaceInfoPrivateDock";
import PlaceInfoHeader from "./PlaceInfoHeader";
import PlaceInfoSkeleton from "./PlaceInfoSkeleton";

import PlaceInfoReviews from "@/components/PlaceInfo/PlaceInfoSections/PlaceInfoReviews";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { getPosition } from "@/utils/maps";
import { Props } from "@/types/users";
import { Position } from "@/types/map";
import { amenitiesTransientVisibility, fuelVisibility } from "@/utils/poi";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import ServiceUser from "@/services/user";
import { getFavoritesSuccess } from "@/store/slices/user/actions";
import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as VHFIcon } from "&/img/icons/VHF_iconFilled.svg";
import { ReactComponent as CalendarAltIcon } from "&/img/icons/CalendarAlt_iconFilled.svg";
import { ReactComponent as DepthIcon } from "&/img/icons/Depth_iconFilled.svg";
import { ReactComponent as BridgeIcon } from "&/img/icons/bridge_iconFilled.svg";
import { ReactComponent as VerticalIcon } from "&/img/icons/vertical_iconFilled.svg";
import { ReactComponent as InfoIcon } from "&/img/icons/info_iconFilled.svg";
import { setTempPin } from "@/store/slices/reportpins";
import { addSelectedPoi } from "@/store/slices/search";
import useDisclosure from "@/hooks/useDisclosure";
import servicePins from "@/services/pins";
import { useGetPlacesQuery } from "@/store/api/argoApi";

const PLACE_LOCATION_TYPE = "my_location";

export const KEY_ADMIN_PRIVILEGE = "admin";

export type TParamsLocation = {
  poi_id?: number;
  place_id?: number;
  name?: string;
  pin_type?: string;
  lat?: string;
  lng?: string;
  description?: string;
};

type TPaymenTAvailable = {
  credit_cards: string | null;
  checks: string | null;
  payment_discount: string | null;
};

export default function PlaceInfo({ close, isDrawer, poiSelected, onCreateOpenRoute }: Props) {
  const dispatch = useAppDispatch();
  const [position, setPosition] = useState<Position | null>(null);
  const [checkedFavorite, setCheckedFavorite] = useState<boolean>(false);
  const [recentGpsPoi, setRecentGpsPoi] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const { logged } = useAppSelector((state) => state.auth);

  const { favorites } = useAppSelector((state) => state.user);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { refetch: refetchPlaces } = useGetPlacesQuery({});
  const { onOpen, isOpen, onClose } = useDisclosure();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isFuelVisible = fuelVisibility(poiSelected);
  const isAmenitiesTransientVisible = amenitiesTransientVisibility(poiSelected);

  const showPosition = (pos: GeolocationPosition) => {
    setPosition({
      lat: pos.coords.latitude,
      lng: pos.coords.longitude,
    });
    setLoading(false);
  };
  const [height, setHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateHeight = () => {
      if (headerRef.current) {
        setHeight(headerRef.current?.offsetHeight);
      }
    };

    updateHeight();

    const resizeObserver = new ResizeObserver(updateHeight);

    if (headerRef.current) {
      resizeObserver.observe(headerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [poiSelected]);

  const handleCheckFavorite = () => {
    if (poiSelected) {
      if (poiSelected?.place_type) {
        if (poiSelected?.favorite) {
          setCheckedFavorite(true);
        } else {
          setCheckedFavorite(false);
        }
      } else if (favorites.some((item) => item?.id === poiSelected?.id)) {
        setCheckedFavorite(true);
      } else {
        setCheckedFavorite(false);
      }
    }
  };

  useEffect(() => {
    handleCheckFavorite();
  }, [favorites, poiSelected]);

  useEffect(() => {
    if (!position) {
      getPosition(showPosition);
    }
    setLoading(false);
  }, [poiSelected]);

  const handleCheckPaymentMode = () => {
    let paymentAvailable: TPaymenTAvailable = {
      credit_cards: null,
      checks: null,
      payment_discount: null,
    };

    if (poiSelected?.payment_discount || poiSelected?.checks || poiSelected?.credit_cards) {
      if (poiSelected?.credit_cards) {
        paymentAvailable = {
          ...paymentAvailable,
          credit_cards: `Credit Cards`,
        };
      }

      if (poiSelected?.checks) {
        paymentAvailable = {
          ...paymentAvailable,
          checks: `Checks`,
        };
      }
      if (poiSelected?.payment_discount) {
        paymentAvailable = {
          ...paymentAvailable,
          payment_discount: poiSelected?.payment_discount,
        };
      }

      return paymentAvailable;
    }

    return null;
  };

  const PlaceInfoListItems = [
    {
      id: 1,
      icon: <LocationOn />,
      label: poiSelected?.address,
    },
    {
      id: 2,
      icon: <BaseIcon icon={BridgeIcon} />,
      label: poiSelected?.bridge_type,
    },
    {
      id: 3,
      icon: <BaseIcon icon={VerticalIcon} />,
      label:
        poiSelected?.ver_clr || poiSelected?.open_ver_clr || poiSelected?.hor_clr ? (
          <Stack>
            {poiSelected?.ver_clr && (
              <Typography>Vertical Clearance: {poiSelected?.ver_clr} ft</Typography>
            )}
            {poiSelected?.open_ver_clr && (
              <Typography>Open Vertical Clearance: {poiSelected?.open_ver_clr} ft</Typography>
            )}
            {poiSelected?.hor_clr && (
              <Typography>Horizontal Clearance: {poiSelected?.hor_clr} ft</Typography>
            )}
          </Stack>
        ) : null,
    },
    {
      id: 4,
      icon: <BaseIcon icon={VerticalIcon} />,
      label:
        poiSelected?.number_of_chamber || poiSelected?.lock_size ? (
          <Stack>
            {poiSelected?.number_of_chamber && (
              <Typography>Number of Chambers: {poiSelected?.number_of_chamber}</Typography>
            )}
            {poiSelected?.lock_size && <Typography>Size: {poiSelected?.lock_size}</Typography>}
          </Stack>
        ) : null,
    },
    {
      id: 5,
      icon: <AccessTimeOutlined />,
      label: poiSelected?.operation_hour ? (
        <Stack>
          <Typography>{`${poiSelected?.operation_hour}, ${poiSelected?.operation}`}</Typography>
          <Typography>After hours: {poiSelected?.after_hours_contact}</Typography>
        </Stack>
      ) : null,
    },
    {
      id: 6,
      icon: <Phone />,
      label: poiSelected?.phone,
    },
    {
      id: 7,
      icon: <BaseIcon icon={VHFIcon} />,
      label: poiSelected?.vhf_channel,
    },
    {
      id: 8,
      icon: <PublicOutlined />,
      label: poiSelected?.website,
    },
    {
      id: 9,
      icon: <Mail />,
      label: poiSelected?.email,
    },
    {
      id: 10,
      icon: <AttachMoneyOutlined />,
      label: (poiSelected?.credit_cards ||
        poiSelected?.checks ||
        poiSelected?.payment_discount) && (
        <Stack>
          <Typography>
            {handleCheckPaymentMode()?.credit_cards || ""}
            {handleCheckPaymentMode()?.credit_cards && handleCheckPaymentMode()?.checks ? ", " : ""}
            {handleCheckPaymentMode()?.checks || ""}
          </Typography>
          <Typography>{handleCheckPaymentMode()?.payment_discount}</Typography>
        </Stack>
      ),
    },
    {
      id: 11,
      icon: <BaseIcon icon={CalendarAltIcon} />,
      label: poiSelected?.date_deployed ? `Date Deployed: ${poiSelected?.date_deployed}` : null,
    },
    {
      id: 12,
      icon: <BaseIcon icon={DepthIcon} />,
      label:
        poiSelected?.poi_type === "Artificial Reef" && poiSelected?.depth
          ? `Depth: ${poiSelected?.depth}`
          : null,
    },
    {
      id: 13,
      icon: <BaseIcon icon={CalendarAltIcon} />,
      label: poiSelected?.bridge_schedule || poiSelected?.lock_schedule,
    },
    {
      id: 14,
      icon: <BaseIcon icon={InfoIcon} />,
      label: poiSelected?.bridge_other_info || poiSelected?.lock_other_info,
    },
  ];

  const handleSetFavorite = async () => {
    let params: TParamsLocation = {};

    if (!poiSelected?.id && poiSelected?.place_type === PLACE_LOCATION_TYPE) {
      try {
        const dataBody = {
          name: poiSelected?.name,
          lat: poiSelected?.lat,
          lng: poiSelected?.lng,
          pin_type: "place",
          place_type: "gps_location",
        };

        const { status, data: createdPinData } = await servicePins.createPin(dataBody);

        if (status === 201) {
          refetchPlaces();

          params = {
            place_id: createdPinData.data?.id,
          };

          const { status: favoritedStatus, data: favoritedData } = await ServiceUser.setFavorite(
            params,
          );

          if (favoritedStatus === 200) {
            if (favoritedData?.place_type) {
              dispatch(addSelectedPoi({ ...createdPinData.data, favorite: true }));
            }
            setRecentGpsPoi(favoritedData);
            dispatch(getFavoritesSuccess([...favorites, poiSelected]));
            dispatch(
              setSnackBarMsjSucceded({
                state: true,
                type: "success",
                msj: "Place added to favorites.",
              }),
            );
          }
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    } else {
      try {
        if (poiSelected?.id) {
          if (poiSelected?.place_type) {
            params = {
              place_id: poiSelected?.id,
            };
          } else {
            params = {
              poi_id: poiSelected?.id,
            };
          }
        } else {
          params = {
            name: poiSelected?.name,
            pin_type: PLACE_LOCATION_TYPE,
            lat: poiSelected?.lat,
            lng: poiSelected?.lng,
            description: "",
          };
        }

        const { status, data } = await ServiceUser.setFavorite(params);

        if (status === 200) {
          if (poiSelected?.place_type) {
            dispatch(addSelectedPoi({ ...poiSelected, favorite: true }));
          }
          setRecentGpsPoi(data);
          dispatch(getFavoritesSuccess([...favorites, poiSelected]));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Place added to favorites.",
            }),
          );
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  const handleRemoveFavorite = async () => {
    try {
      let params = {};

      if (poiSelected.id && poiSelected.place_type !== PLACE_LOCATION_TYPE) {
        if (poiSelected?.place_type) {
          params = {
            place_id: poiSelected?.id,
          };
        } else {
          params = {
            poi_id: poiSelected?.id,
          };
        }
      } else {
        params = {
          place_id: poiSelected.id || recentGpsPoi.id,
        };
      }

      const { status } = await ServiceUser.removeFavorite(params);

      if (status === 200) {
        if (poiSelected?.place_type) {
          dispatch(addSelectedPoi({ ...poiSelected, favorite: false }));
        }
        dispatch(getFavoritesSuccess(favorites.filter((item) => item.id !== poiSelected.id)));
        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "success",
            msj: "Place removed from favorites.",
          }),
        );
      }
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const handleDeletePlacePin = async (placePinId: number) => {
    if (placePinId) {
      try {
        const { status } = await servicePins.deletePlace(placePinId);

        if (status === 200) {
          refetchPlaces();
          dispatch(addSelectedPoi(null));
          dispatch(setTempPin(null));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Place Pin was deleted.",
            }),
          );
          onClose();
        }
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    }
  };

  return (
    <Box sx={isDrawer ? styles.mainContainerDrawer : styles.mainContainer}>
      {loading ? (
        <PlaceInfoSkeleton />
      ) : (
        <>
          <Box ref={headerRef} sx={isDrawer ? null : styles.headerContainer}>
            <PlaceInfoHeader
              anchorEl={anchorEl}
              checkedFavorite={checkedFavorite}
              close={close}
              isDrawer={isDrawer}
              open={open}
              poiSelected={poiSelected}
              position={position}
              onClick={handleClick}
              onClose={handleClose}
              onCreateOpenRoute={onCreateOpenRoute}
              onOpen={onOpen}
              onRemoveFavorite={handleRemoveFavorite}
              onSetFavorite={handleSetFavorite}
            />
          </Box>
          <Box
            sx={
              isDrawer
                ? null
                : {
                    marginTop: `${height}px`,
                  }
            }
          >
            {(poiSelected?.description ||
              poiSelected?.number_of_slips ||
              poiSelected?.clean_marina ||
              poiSelected?.assets?.[0]?.url ||
              poiSelected?.assets?.[0]?.download_url) && (
              <PlaceInfoDescription poiSelected={poiSelected} />
            )}

            <List disablePadding>
              {PlaceInfoListItems.map((item) => {
                if (item?.label) {
                  return (
                    <PlaceInfoListItem
                      key={item?.id}
                      divider
                      icon={item?.icon}
                      primary={item?.label}
                    />
                  );
                }

                return null;
              })}
            </List>
            {/* Private Dock */}
            {poiSelected?.poi_type === "Private Dock" && (
              <PlaceInfoPrivateDock poiSelected={poiSelected} />
            )}
            {/* Fuel */}
            {isFuelVisible && <PlaceInfoFuel logged={logged} poiSelected={poiSelected} />}

            {/* Amenities */}
            {isAmenitiesTransientVisible && (
              <PlaceInfoAmenities logged={logged} poiSelected={poiSelected} />
            )}

            {/* Transient Slips/Moorings */}
            {isAmenitiesTransientVisible && (
              <PlaceInfoTransient logged={logged} poiSelected={poiSelected} />
            )}

            {/* Approach */}
            {isAmenitiesTransientVisible && (
              <PlaceInfoApproach logged={logged} poiSelected={poiSelected} />
            )}

            {/* Component to show reviews for POI'S */}
            {!("place_type" in poiSelected) && (
              <PlaceInfoReviews logged={logged} poiSelected={poiSelected} />
            )}
          </Box>
        </>
      )}

      {isOpen && (
        <BaseModal
          ariaDescribedby="Delete Place Pin"
          ariaLabelledby="Delete Place Pin"
          open={isOpen}
          size="xs"
          title="Delete Place Pin"
          onClose={onClose}
        >
          <Stack pb={2} pt={1} px={3} spacing={2}>
            <Typography color="text.primary" variant="body1">
              Are you sure you want to delete this place pin?
            </Typography>

            <Stack direction="row" justifyContent="end" spacing={1}>
              <Button type="submit" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                onClick={() => handleDeletePlacePin(poiSelected?.id)}
              >
                Delete
              </Button>
            </Stack>
          </Stack>
        </BaseModal>
      )}
    </Box>
  );
}
