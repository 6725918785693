import { Box, ClickAwayListener } from "@mui/material";
import React, { useRef } from "react";

import { styles } from "./styles";

import ContextMenuMap from "@/components/ContextMenu/ContextMenuMap";
import useSearch from "@/hooks/useSearch";
import { setCoords } from "@/store/slices/map";
import { addStartPoint, addEndPoint, addCreatingRoute } from "@/store/slices/route";
import { useAppDispatch } from "@/hooks/useRedux";

interface RoutePosition {
  lat: number;
  lng: number;
  name: string;
}

interface IContextMenuMapPopover {
  coords: RoutePosition | undefined;
  isLongPress: boolean;
  grownToTop?: boolean;
}

function ContextMenuMapPopover({ coords, grownToTop, isLongPress }: IContextMenuMapPopover) {
  const dispatch = useAppDispatch();
  const { setCleanPoiList, setCleanPoiCategory } = useSearch();
  const isFirstRender = useRef(true);

  const handleSetRoute = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    inputType: number,
  ) => {
    e.stopPropagation();
    if (coords) {
      setCleanPoiList();
      setCleanPoiCategory();
      dispatch(addCreatingRoute(true));
      if (inputType === 1) {
        dispatch(
          addStartPoint({
            lat: coords.lat,
            lng: coords.lng,
            name: `${coords.name}`,
          }),
        );
      } else {
        dispatch(
          addEndPoint({
            lat: coords.lat,
            lng: coords.lng,
            name: `${coords.name}`,
          }),
        );
      }
      dispatch(setCoords(undefined));
    }
  };

  const handleClickAway = () => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return;
    }
    if (isLongPress) {
      return;
    }
    dispatch(setCoords(undefined));
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className="placePopover" sx={grownToTop ? styles.paperToTop : styles.paper}>
        <ContextMenuMap
          action={handleSetRoute}
          pointData={coords}
          setCoords={(coords: RoutePosition | undefined) => dispatch(setCoords(coords))}
        />
      </Box>
    </ClickAwayListener>
  );
}

export default ContextMenuMapPopover;
