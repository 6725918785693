import React, { useEffect, useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import { ListItem } from "@mui/material";

import WaypointInput from "./WaypointInput";

import { IInterimPoint } from "@/store/slices/route";

export type TDraggableListItem = {
  item: IInterimPoint;
  position: number;
  clearRouteInput: (inputNumber: number, position?: number) => void;
  onFocus: (
    e: React.FocusEvent<HTMLInputElement> | undefined,
    type: number,
    position?: number,
  ) => void;
  onRemoveInterimPoint: (position: number) => void;
  onSearchInterimPoint: (interimPoint: string) => void;
  onSetInterimPoint: (value: string, position: number) => void;
};

function DraggableListItem({
  item,
  position,
  clearRouteInput,
  onFocus,
  onRemoveInterimPoint,
  onSearchInterimPoint,
  onSetInterimPoint,
}: TDraggableListItem) {
  const itemRefs = useRef<{ [key: string]: HTMLLIElement | null }>({});

  useEffect(() => {
    if (item.focused && itemRefs.current[item.id]) {
      itemRefs.current[item.id]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [item]);

  return (
    <Draggable
      ref={(el: any) => {
        itemRefs.current[item.id] = el;

        return null;
      }}
      draggableId={item.id}
      index={position}
    >
      {(provided, snapshot) => (
        <ListItem
          ref={(el: any) => {
            itemRefs.current[item.id] = el;
            provided.innerRef(el);

            return null;
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={snapshot.isDragging ? { background: "white", p: 0, mt: 2 } : { p: 0, mt: 2 }}
        >
          <WaypointInput
            key={item.value}
            clearRouteInput={clearRouteInput}
            focused={item.focused}
            point={item}
            position={position}
            onFocus={onFocus}
            onRemoveInterimPoint={onRemoveInterimPoint}
            onSearchInterimPoint={onSearchInterimPoint}
            onSetInterimPoint={onSetInterimPoint}
          />
        </ListItem>
      )}
    </Draggable>
  );
}

export default DraggableListItem;
