import defaultTheme from "@/themes/theme";

export const styles = {
  typesContainer: {
    [defaultTheme.breakpoints.down("md")]: {
      p: 0,
      alignItems: "flex-start",
      pt: 1,
      pb: 1,
    },
    p: 2,
  },
};
