import { useState } from "react";

import AdvancedMarker from "../AdvancedMarker";
import ReportPinPopover from "../../MapPopovers/ReportPinPopover";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setReportPinSelectedDrawer } from "@/store/slices/reportpins";
import { IPin } from "@/types/reportpins";
import { Position } from "@/types/map";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import servicePins from "@/services/pins";
import { handleCheckVote } from "@/store/slices/reportpins/actions";
import { addSelectedPoi } from "@/store/slices/search";
import { setBoaterInfoDrawer } from "@/store/slices/boaters";
import { addStartPoint, addEndPoint, addInterimPoint } from "@/store/slices/route";
import { snakeCaseToHumanReadable } from "@/utils/globals";
import { setSignInModal } from "@/store/slices/user";

interface IReportPinMarker {
  userPosition?: Position | null;
  icon: string;
  pin: IPin;
  clogpage: boolean;
}

function ReportPinMarker({ icon, pin, userPosition, clogpage }: IReportPinMarker) {
  const dispatch = useAppDispatch();
  const { logged } = useAppSelector((state) => state.auth);
  const { tempPin, reportPinSelectedDrawer } = useAppSelector((state) => state.reportpins);
  const { creatingRoute, inputsFocus, waypoints } = useAppSelector((state) => state.route);
  const { selectedPoi } = useAppSelector((state) => state.search);
  const [pinEdited, setPinEdited] = useState<IPin | null>(null);

  const { boaterSelectedInfoDrawer } = useAppSelector((state) => state.boaters);

  const handleGetPinById = async (id: number) => {
    try {
      const { data, status } = await servicePins.getReportPinById(id);

      if (status === 200) {
        return data.data;
      }

      return null;
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));

      return null;
    }
  };

  const handleGetPinReviewsById = async (id: number) => {
    try {
      const { data, status } = await servicePins.getReportPinReviewsById(id);

      if (status === 200) {
        return data.data.items;
      }

      return null;
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));

      return null;
    }
  };

  const handleShowFullPinInformation = async (pin: IPin) => {
    if (logged && !clogpage) {
      if (!creatingRoute && pin) {
        const pinReviews = await handleGetPinReviewsById(pin.id);
        const reportPinUpdated = await handleGetPinById(pin.id);

        if (selectedPoi) dispatch(addSelectedPoi(null));
        if (boaterSelectedInfoDrawer) dispatch(setBoaterInfoDrawer(null));
        dispatch(setReportPinSelectedDrawer({ reviews: pinReviews, ...reportPinUpdated }));
      } else if (creatingRoute) {
        const lat = Number(pin.lat);
        const lng = Number(pin.lng);

        const categoryHandled = pin?.kind === "Sea Life" ? "Sea Life" : pin?.kind;

        const name = pin.subtype
          ? `${snakeCaseToHumanReadable(pin.subtype)} ${snakeCaseToHumanReadable(pin.kind)}`
          : snakeCaseToHumanReadable(categoryHandled);

        if (inputsFocus === 1) {
          dispatch(
            addStartPoint({
              lat,
              lng,
              name,
            }),
          );
        } else if (inputsFocus === 2) {
          dispatch(
            addEndPoint({
              lat,
              lng,
              name,
            }),
          );
        } else if (inputsFocus === 3) {
          const updatedInterimPoints = waypoints.map((point) =>
            point.focused
              ? {
                  ...point,
                  value: name,
                  focused: false,
                  lat,
                  lng,
                }
              : point,
          );

          dispatch(
            addInterimPoint([
              ...updatedInterimPoints,
              { id: `Item ${updatedInterimPoints.length + 1}`, value: "", focused: true },
            ]),
          );
        }
      }
    } else if (!logged) {
      dispatch(setSignInModal(true));
    }
  };

  const handleSendVote = async (reportPinSelected: IPin, voteType: string) => {
    const result = await dispatch(handleCheckVote(reportPinSelected, voteType));

    setPinEdited(result);
  };

  if (tempPin?.editPinInfo?.id === pin?.id) return null;

  return (
    <AdvancedMarker
      key={pin.id}
      icon={icon}
      position={{ lat: Number(pin.lat), lng: Number(pin.lng) }}
      selected={reportPinSelectedDrawer?.id === pin.id}
      onClick={() => handleShowFullPinInformation(pin)}
    >
      {logged && (
        <ReportPinPopover
          icon={icon}
          inCaptainsLog={clogpage}
          pin={pinEdited || pin}
          showFullPinInformation={() => handleShowFullPinInformation(pin)}
          userPosition={userPosition}
          onAddReportPinVote={handleSendVote}
        />
      )}
    </AdvancedMarker>
  );
}

export default ReportPinMarker;
