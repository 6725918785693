/* global google */
import { useEffect, useState } from "react";

import ReportPinsList from "./ReportPinsList";

import { Position, TRegionData } from "@/types/map";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import servicePins from "@/services/pins";
import { IPin } from "@/types/reportpins";
import { getRegionFromBounds } from "@/utils/maps";

interface IReportPins {
  bounds: google.maps.LatLngBounds;
  userPosition?: Position | null;
  clogpage: boolean;
}

function ReportPins({ bounds, userPosition, clogpage }: IReportPins) {
  const dispatch = useAppDispatch();
  const [reportPinsItems, setReportPinsItems] = useState<IPin[]>([]);
  const { poisConfigured } = useAppSelector((state) => state.map);
  const { congratsModal, reportPinSelectedDrawer } = useAppSelector((state) => state.reportpins);

  useEffect(() => {
    const getReportPinsByZone = async (bounds: google.maps.LatLngBounds) => {
      try {
        const region: TRegionData = getRegionFromBounds(bounds);

        const { status, data } = await servicePins.getReportPinsByZone({
          sw_latitude: region.southWestLat,
          sw_longitude: region.southWestLng,
          ne_latitude: region.northEastLat,
          ne_longitude: region.northEastLng,
          page_total: 100,
        });

        if (status === 200)
          setReportPinsItems(data.data.items.map((item: IPin) => ({ ...item, show: true })));
      } catch (error: any) {
        dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
      }
    };

    getReportPinsByZone(bounds);
  }, [congratsModal?.open, reportPinSelectedDrawer, bounds]);

  if (reportPinsItems.length <= 0) return null;

  return (
    <ReportPinsList
      clogpage={clogpage}
      items={reportPinsItems}
      poisConfigured={poisConfigured}
      userPosition={userPosition}
    />
  );
}

export default ReportPins;
