import { useEffect } from "react";
import { Box, Divider, ListSubheader, Typography, Stack, CardMedia } from "@mui/material";
import {
  useUnitSystem,
  VolumeUnits,
  SpeedUnits,
  LengthUnits,
  ShortVolumeUnits,
} from "@argonav/unit-system";

import { styles } from "./styles";

import PhotoModal from "@/components/Modals/PhotoModal";
import { convertTimeIsoToUs, capitalizeWord } from "@/utils/globals";
import { ITrip } from "@/types/route";
import {
  voyageDistance,
  voyageEstimatedDuration,
  durationToString,
  voyageEstimatedFuelConsumption,
} from "@/utils/maps";
import { getVesselName } from "@/utils/route";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { getTrip } from "@/store/slices/captainslog/actions";
import useDisclosure from "@/hooks/useDisclosure";
import { vesselDefault, meterToFeet } from "@/utils/boats";
import StartIndicatorIcon from "@/assets/img/markers/location/StartIndicator.png";
import EndIndicator from "@/assets/img/markers/location/EndIndicator.png";
import { PlannedCoordKind } from "@/types/map";

interface IVoyageInfo {
  voyage: ITrip;
}

export default function VoyageInfo({ voyage }: IVoyageInfo) {
  const dispatch = useAppDispatch();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { trips, selectedTrip } = useAppSelector((state) => state.captainslog);
  const {
    speedUnitHandler,
    consumptionUnitHandler,
    lengthUnitHandler,
    distanceUnitHandler,
    coordinatesFormatHandler,
  } = useUnitSystem();
  const fuelUnit = useAppSelector((state) =>
    capitalizeWord(state.user.userOptions.volume_unit?.toLowerCase()),
  );
  const fuelUnitLabel = fuelUnit === VolumeUnits.GALLONS ? "gal" : "L";
  const fuelUnitLabelBoat =
    fuelUnit === VolumeUnits.GALLONS ? ShortVolumeUnits.GPHString : ShortVolumeUnits.LPHString;

  useEffect(() => {
    if (!selectedTrip) dispatch(getTrip(voyage?.id));
  }, [trips]);

  const boatDraft = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: voyage?.draft_depth || vesselDefault.defaultDraft * meterToFeet,
  });
  const boatBuffer = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: voyage?.buffer_depth || vesselDefault.defaultBuffer * meterToFeet,
  });
  const boatHeight = lengthUnitHandler({
    originalUnit: LengthUnits.FEET,
    originalValue: Number(voyage?.height) || vesselDefault.defaultHeight,
  });

  const avrSpeed = speedUnitHandler({
    originalUnit: SpeedUnits.KNOT,
    originalValue: Number(voyage?.average_speed),
  });

  const estFuelUsed = consumptionUnitHandler({
    originalUnit: VolumeUnits.GALLONS,
    originalValue: Number(voyage?.fuel_consumption),
  });

  const routeCreated = voyage;
  const isElectric = voyage.fuel_type === "electric";

  const distance = distanceUnitHandler({
    originalUnit: LengthUnits.METERS,
    originalValue: Number(voyageDistance(routeCreated).toFixed(1)),
  });

  const latLngFormatted = (latitude: number | string, longitude: number | string) =>
    coordinatesFormatHandler({
      latitude: Number(latitude),
      longitude: Number(longitude),
    });

  const evaluateWaypointIcon = (kind: PlannedCoordKind, index: number) => {
    if (kind === PlannedCoordKind.START_POINT) {
      return <CardMedia alt="Start Point" component="img" height="25" image={StartIndicatorIcon} />;
    }
    if (kind === PlannedCoordKind.END_POINT) {
      return <CardMedia alt="End Point" component="img" height="25" image={EndIndicator} />;
    }

    return (
      <Box sx={styles.waypointIconContainer}>
        <Box sx={styles.waypointIcon}>{index}</Box>
      </Box>
    );
  };

  return (
    <Box>
      {voyage.description && (
        <Stack px={2} py={2} spacing={1}>
          <Typography variant="body1">{voyage.description}</Typography>
        </Stack>
      )}

      <ListSubheader sx={{ color: "primary.main" }}>Overview</ListSubheader>

      <Stack pb={2} px={2} spacing={0} sx={styles.voyageDetails}>
        <Stack className="voyageDetailRow">
          <Box>
            <Typography color="text.secondary" variant="caption">
              Distance
            </Typography>
            <Typography component="h4" variant="body2">
              {distance.value} {distance.unit}
            </Typography>
          </Box>
          <Box>
            <Typography color="text.secondary" variant="caption">
              Est. Duration
            </Typography>
            <Typography component="h4" variant="body2">
              {`${durationToString(
                voyageEstimatedDuration(routeCreated, voyage?.average_speed || undefined),
              )}`}
            </Typography>
          </Box>
        </Stack>
        <Stack className="voyageDetailRow">
          <Box>
            <Typography color="text.secondary" variant="caption">
              Estimated Fuel Used
            </Typography>
            <Typography component="h4" variant="body2">
              {`${voyageEstimatedFuelConsumption(
                routeCreated,
                !voyage?.fuel_consumption || !voyage?.average_speed
                  ? null
                  : {
                      fuel_consumption: voyage?.fuel_consumption,
                      average_speed: voyage?.average_speed,
                    },
              )} ${
                voyage?.fuel_consumption || voyage?.average_speed
                  ? isElectric
                    ? "kW"
                    : fuelUnitLabel
                  : ""
              }`}
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Divider />

      <ListSubheader sx={{ color: "primary.main" }}>Boat Details</ListSubheader>

      <Stack pb={2} px={2} spacing={0} sx={styles.voyageDetails}>
        <Stack className="voyageDetailRow">
          <Box>
            <Typography color="text.secondary" variant="caption">
              Boat Name / Model
            </Typography>
            <Typography component="h4" variant="body2">
              {getVesselName({
                name: voyage.boat_name,
                manufacturer_model: voyage.manufacturer_model,
              })}
            </Typography>
          </Box>
        </Stack>
        <Stack className="voyageDetailRow">
          <Box>
            <Typography color="text.secondary" variant="caption">
              Draft
            </Typography>
            <Typography component="h4" variant="body2">
              {boatDraft && `${boatDraft.value} ${boatDraft.unit}`}
            </Typography>
          </Box>
          <Box>
            <Typography color="text.secondary" variant="caption">
              Buffer
            </Typography>
            <Typography component="h4" variant="body2">
              {boatBuffer && `${boatBuffer.value} ${boatBuffer.unit}`}
            </Typography>
          </Box>
          <Box>
            <Typography color="text.secondary" variant="caption">
              Height
            </Typography>
            <Typography component="h4" variant="body2">
              {boatHeight && `${boatHeight.value} ${boatHeight.unit}`}
            </Typography>
          </Box>
        </Stack>
        <Stack className="voyageDetailRow">
          <Box>
            <Typography color="text.secondary" variant="caption">
              Est. Avg. Speed
            </Typography>
            <Typography component="h4" variant="body2">
              {voyage?.average_speed ? `${avrSpeed.value} ${avrSpeed.unit}` : "-"}
            </Typography>
          </Box>
          <Box>
            <Typography color="text.secondary" variant="caption">
              Est. Fuel / Power Usage
            </Typography>
            <Typography component="h4" variant="body2">
              {Number(voyage?.fuel_consumption) !== 0
                ? `${isElectric ? Number(voyage.fuel_consumption).toFixed(1) : estFuelUsed.value} ${
                    isElectric ? "kWH" : fuelUnitLabelBoat
                  }`
                : "-"}
            </Typography>
          </Box>
        </Stack>
      </Stack>

      <Divider />

      <ListSubheader sx={{ color: "primary.main" }}>Route Details</ListSubheader>

      <Stack direction="column" pb={2} px={2} spacing={2}>
        {voyage?.waypoints
          ?.filter((waypoint) => !(!waypoint.referable_type && waypoint.kind === "waypoint"))
          ?.map((waypoint, index) => (
            <Stack
              key={`waypoint-${waypoint.latitude}-${waypoint.longitude}`}
              alignItems="center"
              direction="row"
              spacing={2}
            >
              <Box
                sx={
                  (waypoint.kind as any) === PlannedCoordKind.END_POINT ? null : styles.iconContent
                }
              >
                {evaluateWaypointIcon(waypoint.kind as any, index)}
              </Box>
              <Box>
                <Typography color="text.secondary" variant="caption">
                  {waypoint?.name || waypoint?.address || "Custom Location"}
                </Typography>
                <Typography
                  component="h4"
                  lineHeight="normal"
                  sx={styles.ellipsisOneLine}
                  variant="body2"
                >
                  {waypoint?.address ||
                    `${latLngFormatted(waypoint?.latitude, waypoint?.longitude)}`}
                </Typography>
              </Box>
            </Stack>
          ))}
      </Stack>
      {voyage?.assets?.[0]?.download_url && (
        <>
          <Divider />
          <ListSubheader sx={{ color: "primary.main" }}>Photo</ListSubheader>
          <Stack p={2} pt={0} spacing={1}>
            <CardMedia
              alt={`${voyage.name}`}
              component="img"
              height="180"
              image={`${voyage?.assets?.[0]?.download_url}` || "/img/placeholders/Image.png"}
              sx={styles.cardMedia}
              onClick={onOpen}
            />
          </Stack>
        </>
      )}
      <Divider />
      <Stack sx={styles.dataFooter}>
        <Typography gutterBottom color="text.secondary" variant="caption">
          <b>Date Created:</b> {convertTimeIsoToUs(voyage.created_at)}
        </Typography>
        <Typography color="text.secondary" variant="caption">
          <b>Last Updated:</b> {convertTimeIsoToUs(voyage.updated_at)}
        </Typography>
      </Stack>
      <PhotoModal open={isOpen} url={voyage?.assets?.[0]?.download_url} onClose={onClose} />
    </Box>
  );
}
