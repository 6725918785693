import {
  SpeedUnits,
  LengthUnits,
  VolumeUnits,
  TemperatureUnits,
  CoordinatesFormats,
  UnitSystemProviderWrapper,
} from "@argonav/unit-system";

import { useAppSelector } from "./hooks/useRedux";
import App from "./App";

function ContainerApp() {
  const depthUnit = useAppSelector((state) => state.user.userOptions?.length_unit);
  const speedUnit = useAppSelector((state) => state.user.userOptions?.speed_unit);
  const fuelUnit = useAppSelector((state) => state.user.userOptions?.volume_unit);
  const coordinatesFormat = useAppSelector((state) => state.user.userOptions?.coordinate_format);

  const units = {
    speedUnit: (speedUnit as SpeedUnits) || SpeedUnits.KNOT,
    depthUnit: (depthUnit as LengthUnits) || LengthUnits.FEET,
    fuelUnit: (fuelUnit as VolumeUnits) || VolumeUnits.GALLONS,
    temperatureUnit: TemperatureUnits.FAHRENHEIT,
    coordinatesFormat: (coordinatesFormat as CoordinatesFormats) || CoordinatesFormats.DD,
  };

  return (
    <UnitSystemProviderWrapper units={units}>
      <App />
    </UnitSystemProviderWrapper>
  );
}

export default ContainerApp;
