import { CloseOutlined } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  Typography,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Box,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";

import { styles } from "./styles";

import useDisclosure from "@/hooks/useDisclosure";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setDepthShadingOrigin } from "@/store/slices/depthshading";
import useDebounce from "@/hooks/useDebounce";

function SettingsModalAdvancedOptions() {
  const { depthShadingOrigin } = useAppSelector((state) => state.depthshading);
  const dispatch = useAppDispatch();
  const [value, setValue] = useState<string>("");
  const { onOpen, onClose } = useDisclosure();
  const debouncedValue = useDebounce(value, 1000);

  const handleCleanInput = () => {
    dispatch(setDepthShadingOrigin(undefined));
    setValue("");
    onClose();
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    if (debouncedValue) {
      dispatch(setDepthShadingOrigin(debouncedValue));
    }
  }, [debouncedValue]);

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Stack>
        <Typography variant="subtitle1">Advanced Options</Typography>
        <Typography color="text.secondary" variant="body1">
          Admin-only settings.
        </Typography>
      </Stack>
      <Box sx={styles.searchBox}>
        <FormControl fullWidth>
          <OutlinedInput
            endAdornment={
              value.length > 0 && (
                <InputAdornment position="end">
                  <IconButton aria-label="directions" color="primary" onClick={handleCleanInput}>
                    <CloseOutlined />
                  </IconButton>
                </InputAdornment>
              )
            }
            id="search"
            placeholder={depthShadingOrigin}
            value={value}
            onChange={handleInputChange}
            onKeyDown={onOpen}
          />
        </FormControl>
      </Box>
    </Stack>
  );
}

export default SettingsModalAdvancedOptions;
