import PoisSearchedMarker from "./PoisSearchedMarker";

import { IMapPois } from "@/types/pois";

interface IPoisSearched {
  poiList: IMapPois[];
  selectedPoi: IMapPois | null;
  onCleanPoiList: () => void;
  setPoi: (poi: IMapPois) => void;
}

function PoisSearched({ poiList, selectedPoi, onCleanPoiList, setPoi }: IPoisSearched) {
  if (!poiList) return null;

  return (
    <>
      {poiList.slice(0, 20).map((item, index: number) => (
        <PoisSearchedMarker
          key={item.id}
          number={index + 1}
          poiSearched={item}
          selectedPoi={selectedPoi}
          setPoi={setPoi}
          onCleanPoiList={onCleanPoiList}
        />
      ))}
    </>
  );
}

export default PoisSearched;
