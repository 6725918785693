import { IconButton, Box, Stack, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";

import { styles } from "./styles";

import DepthIndicatorLayout from "@/components/DepthShading/DepthIndicatorLayout";
import BaseModal from "@/components/Modals/BaseModal";
import useDisclosure from "@/hooks/useDisclosure";
import DepthShadingModal from "@/components/Modals/DepthShadingModal";
import { IDepthShadingData } from "@/types/map";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setDepthShadingDrawerOpen } from "@/store/slices/depthshading";
import { addOpenMapOptions } from "@/store/slices/map";
import usePremiumUser from "@/hooks/usePremiumUser";
import useSearch from "@/hooks/useSearch";
import {
  addSelectedPoiFromMap,
  setOpenAccordion,
  setShowSearchArea,
  addCategory,
} from "@/store/slices/search";
import {
  setReportPinSelectedDrawer,
  setTempPin,
  setTempPinCopy,
  setAllowCoordinates,
  setMyPinsDrawer,
} from "@/store/slices/reportpins";
import { setBoaterInfoDrawer } from "@/store/slices/boaters";
import { resetRouteParams, initialState } from "@/store/slices/route";

interface IDepthIndicator {
  disabled?: boolean;
  depthShadingData: IDepthShadingData;
}

function DepthIndicator({ depthShadingData, disabled = false }: IDepthIndicator) {
  const dispatch = useAppDispatch();
  const { isOpen, onClose } = useDisclosure();
  const isPremium = usePremiumUser();
  const { showSearchArea } = useAppSelector((state) => state.search);
  const { boaterSelectedInfoDrawer } = useAppSelector((state) => state.boaters);
  const { reportPinSelectedDrawer, tempPin, tempPinCopy, allowCoordinates, myPinsDrawer } =
    useAppSelector((state) => state.reportpins);
  const { poiList, selectedCategories } = useAppSelector((state) => state.search);
  const { setCleanSelectedPoi, setCleanPoiCategory, setCleanPoiList } = useSearch();

  const handleOpenDepthShadingDrawer = () => {
    dispatch(setDepthShadingDrawerOpen(true));
    dispatch(addOpenMapOptions(false));
    dispatch(setOpenAccordion(false));
    setCleanSelectedPoi();

    dispatch(addCategory([]));
    dispatch(
      resetRouteParams({
        ...initialState,
      }),
    );
    if (showSearchArea) dispatch(setShowSearchArea(false));

    dispatch(addSelectedPoiFromMap(false));
    if (poiList) setCleanPoiList();
    if (selectedCategories) setCleanPoiCategory();
    if (showSearchArea) dispatch(setShowSearchArea(false));

    if (reportPinSelectedDrawer) dispatch(setReportPinSelectedDrawer(null));
    if (boaterSelectedInfoDrawer) dispatch(setBoaterInfoDrawer(null));
    if (tempPin) dispatch(setTempPin(undefined));
    if (tempPinCopy) dispatch(setTempPinCopy(undefined));
    if (allowCoordinates) dispatch(setAllowCoordinates(false));
    if (myPinsDrawer?.open) dispatch(setMyPinsDrawer({ open: false, type: "places" }));
    if (myPinsDrawer?.open) dispatch(setMyPinsDrawer({ open: false, type: "places" }));
  };

  return (
    <Box sx={disabled ? styles.depthIndicatorDisabled : styles.depthIndicator}>
      <Stack className="overlay" direction="row" sx={styles.overlay}>
        {depthShadingData.data.items
          .filter((item) => {
            if (!isPremium) {
              return item.default_flag;
            }

            return item.current;
          })
          .map((item) => (
            <DepthIndicatorLayout key={item.id} shaderItems={item.depth_shader_items} />
          ))}

        {depthShadingData.data.items.length > 1 && (
          <Tooltip arrow placement="top" title="Change Depth Shading">
            <IconButton
              className="editButton"
              color="secondary"
              size="small"
              sx={styles.editButton}
              onClick={handleOpenDepthShadingDrawer}
            >
              <Edit sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
        )}

        <BaseModal open={isOpen} size="xs" title="Depth Shading" onClose={onClose}>
          <DepthShadingModal shadingItems={depthShadingData.data.items} onClose={onClose} />
        </BaseModal>
      </Stack>
    </Box>
  );
}

export default DepthIndicator;
