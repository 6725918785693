import { Box, Divider, Stack, Typography, Avatar, Button } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect } from "react";
import dayjs from "dayjs";

import PinsModal from "../PinsModal";

import { styles } from "./styles";
import CreatePinForm from "./CreatePinForm";

import useDisclosure from "@/hooks/useDisclosure";
import { useAppSelector, useAppDispatch } from "@/hooks/useRedux";
import { Position } from "@/types/map";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import servicePins from "@/services/pins";
import useUserInformation from "@/hooks/useUserInformation";
import {
  setAllowCoordinates,
  setCongratsModal,
  setTempPin,
  setMyPinsDrawer,
} from "@/store/slices/reportpins";
import { PLACE_KEY, PLACES_KEY, PLACES_TYPE, REPORTPINS_KEY, REPORTPINS_TYPE } from "@/utils/poi";
import { useGetPlacesQuery, useGetQuickPinsQuery } from "@/store/api/argoApi";
import { IMapPois } from "@/types/pois";
import BaseDrawer from "@/components/Drawers/BaseDrawer";

const formSchema = yup.object().shape({
  name: yup.string().when(["pinType", "type"], {
    is: (pinType: string, type: string) => pinType === "places" && type === "public",
    then: yup.string().required("Name is a required field for public places"),
    otherwise: yup.string(),
  }),
});

export type TTempPin = {
  quickPinId: number | undefined;
  name: string;
  description: string;
  type: string;
  pinType: string;
  valueType: string;
  subtype: undefined | string;
  location: Position;
  image: File | null;
  editPinInfo: IMapPois | undefined;
};

interface IReportPinCreationInfo {
  open: boolean;
}

export default function ReportPinCreationInfo({ open }: IReportPinCreationInfo) {
  const { tempPin, allowCoordinates, myPinsDrawer } = useAppSelector((state) => state.reportpins);
  const { refetch: refetchPlaces } = useGetPlacesQuery({});
  const { refetch: refetchQuickPins } = useGetQuickPinsQuery({});
  const dispatch = useAppDispatch();
  const {
    isOpen: isOpenPinsModal,
    onClose: onClosePinsModal,
    onOpen: onOpenPinsModal,
  } = useDisclosure();
  const { uploadPlacePinImage, uploadReportPinImage } = useUserInformation();

  const handleCreatePin = async (values: TTempPin) => {
    try {
      if (values.quickPinId) {
        const { status } = await servicePins.deleteQuickPin(values?.quickPinId);

        if (status === 200) {
          refetchQuickPins();
        }
      }

      if (values.pinType === PLACES_KEY) {
        const dataBody = {
          name: values.name || `${values.valueType} ${dayjs().format("YYYY-MM-DD hh:mm:ss A")}`,
          description: values.description,
          lat: values.location.lat,
          lng: values.location.lng,
          submit: values.type === "public",
          pin_type: "place",
          place_type: values.valueType,
        };

        const { status, data } = await servicePins.createPin(dataBody);

        if (status === 201) {
          if (values?.image instanceof File && data?.data?.id) {
            await uploadPlacePinImage(values.image, data?.data?.id);
          }
          if (myPinsDrawer?.open) dispatch(setMyPinsDrawer({ open: true, type: "places" }));
          if (tempPin) dispatch(setTempPin(undefined));
          if (allowCoordinates) dispatch(setAllowCoordinates(false));
          if (values.type === "public") {
            dispatch(setCongratsModal({ open: true, type: "places" }));
          } else {
            dispatch(
              setSnackBarMsjSucceded({
                state: true,
                type: "success",
                msj: "Place pin successfully created.",
              }),
            );
          }
        }
      } else {
        const dataBody = {
          name: values.name,
          description: values.description,
          lat: values.location.lat,
          lng: values.location.lng,
          kind: values.valueType,
          subtype: values.subtype,
        };

        const { status, data } = await servicePins.createReportPin(dataBody);

        if (status === 201) {
          if (values?.image instanceof File && data?.data?.id) {
            await uploadReportPinImage(values.image, data?.data?.id);
          }
          if (tempPin) dispatch(setTempPin(undefined));
          if (allowCoordinates) dispatch(setAllowCoordinates(false));
          dispatch(setCongratsModal({ open: true, type: "reportpins" }));
        }
      }
      refetchPlaces();
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const handleEditPin = async (values: TTempPin) => {
    try {
      if (values.pinType === PLACES_KEY) {
        const dataBody = {
          name: values.name,
          description: values.description,
          lat: values.location.lat,
          lng: values.location.lng,
          submit: values.type === "public",
          pin_type: "place",
          place_type: values.valueType,
        };

        const { status, data } = await servicePins.editPin(dataBody, values?.editPinInfo?.id);

        if (status === 200) {
          if (values?.image instanceof File && data?.id) {
            await uploadPlacePinImage(values.image, data.id);
          }
          if (tempPin) dispatch(setTempPin(undefined));
          if (allowCoordinates) dispatch(setAllowCoordinates(false));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: `${values.type === "public" ? "Public" : "Private"} place successfully updated.`,
            }),
          );
        }
      } else {
        const dataBody = {
          name: values.name,
          description: values.description,
          lat: values.location.lat,
          lng: values.location.lng,
          kind: values.valueType,
          subtype: values.subtype,
        };

        const { status, data } = await servicePins.editReportPin(dataBody, values?.editPinInfo?.id);

        if (status === 200) {
          if (values?.image instanceof File && data?.data?.id) {
            await uploadReportPinImage(values.image, data?.data?.id);
          }
          if (tempPin) dispatch(setTempPin(undefined));
          if (allowCoordinates) dispatch(setAllowCoordinates(false));
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Report pin successfully updated.",
            }),
          );
        }
      }
      refetchPlaces();
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const handleForm = async (values: TTempPin) => {
    if (values.editPinInfo) {
      handleEditPin(values);
    } else {
      handleCreatePin(values);
    }
  };

  const formik = useFormik({
    initialValues: {
      quickPinId: tempPin?.quickpinId || undefined,
      name:
        tempPin?.editPinInfo?.name || tempPin?.type === PLACES_KEY
          ? `${tempPin?.value} ${dayjs().format("YYYY-MM-DD hh:mm:ss A")}`
          : "",
      description: tempPin?.editPinInfo?.description || "",
      type: tempPin?.editPinInfo?.submit ? "public" : "private",
      pinType: tempPin?.editPinInfo?.place_type ? "places" : tempPin?.type,
      valueType: tempPin?.editPinInfo?.place_type || tempPin?.value,
      subtype: tempPin?.editPinInfo?.subtype || undefined,
      location: tempPin?.location || { lat: 0, lng: 0 },
      image: null,
      editPinInfo: tempPin?.editPinInfo || undefined,
    } as TTempPin,
    validationSchema: formSchema,
    onSubmit: handleForm,
  });

  useEffect(() => {
    formik.setFieldValue("valueType", tempPin?.value);
    formik.setFieldValue("location", tempPin?.location || { lat: 0, lng: 0 });
  }, [tempPin]);

  const iconUrl =
    tempPin?.editPinInfo?.place_type || tempPin?.type === PLACES_KEY ? "pois" : "reportpins";

  const editPinLabel = PLACES_TYPE.find((type) => type.value === tempPin?.editPinInfo?.place_type);
  const editReportPinLabel = REPORTPINS_TYPE.find(
    (type) => type.value === tempPin?.editPinInfo?.kind,
  );

  const handleSetCurrentPinType = () => {
    if (tempPin?.editPinInfo) {
      if (tempPin?.editPinInfo?.place_type) {
        return {
          ...tempPin,
          label: editPinLabel?.label,
          type: "places",
        } as any;
      }

      return {
        ...tempPin,
        label: editReportPinLabel?.label,
        type: "reportpins",
      } as any;
    }

    return tempPin;
  };

  return (
    <Box>
      <BaseDrawer open={open} styles={styles.drawer}>
        <Stack pb={2} pt={10} px={2} spacing={1.5}>
          <Stack alignItems="center" direction="row" spacing={1.5}>
            <Avatar
              src={`/img/markers/${iconUrl}/private/${
                tempPin?.editPinInfo?.place_type
                  ? tempPin?.editPinInfo?.place_type.replace("/", "")
                  : tempPin?.value.replace("/", "")
              }.svg`}
              sx={{ width: 48, height: 48 }}
            />
            <Stack alignItems="flex-start" sx={{ width: "100%" }}>
              <Typography component="h2" sx={styles.poiTitle} variant="h5">
                {tempPin?.editPinInfo?.place_type
                  ? editPinLabel?.label
                  : editReportPinLabel?.label || tempPin?.label}
              </Typography>
              <Button
                disableRipple
                color="secondary"
                size="small"
                sx={{ p: 0 }}
                onClick={onOpenPinsModal}
              >
                Change Pin Type
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Divider />

        <CreatePinForm
          formData={formik}
          placeType={tempPin?.type === PLACES_KEY || tempPin?.editPinInfo?.category === PLACE_KEY}
        />
        <Divider />
        <Stack direction="row" justifyContent="flex-end" sx={{ p: 2, width: "100%" }}>
          <Button
            autoFocus
            disabled={allowCoordinates}
            sx={{ width: "fit-content" }}
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            Save
          </Button>
        </Stack>
      </BaseDrawer>
      {isOpenPinsModal && (
        <PinsModal
          currentPinType={handleSetCurrentPinType()}
          isOpen={isOpenPinsModal}
          pinsType={
            tempPin?.editPinInfo?.place_type
              ? PLACES_KEY
              : tempPin?.type === PLACES_KEY
              ? PLACES_KEY
              : REPORTPINS_KEY
          }
          title="Change Pin Type"
          onClose={onClosePinsModal}
        />
      )}
    </Box>
  );
}
