import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import UserBar from "@/components/Common/UserBar";
import SideMenu from "@/components/Common/SideMenu";

interface ILayout {
  children: React.ReactElement;
}

function Layout({ children }: ILayout) {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };

    setHeight(window.innerHeight);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        height,
        minHeight: height,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box component="header">
        <UserBar />
      </Box>
      <Box component="main" sx={{ display: "flex", height: "100%" }}>
        <Box component="section" sx={{ display: "flex", height: "100%" }}>
          <SideMenu />
        </Box>
        <Box component="section" sx={{ display: "flex", height: "100%", width: "100%" }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default Layout;
