import { IInterimPoint } from "@/store/slices/route";
import { Trip, Position } from "@/types/map";

export function findKeyWaypoints(waypoints: IInterimPoint[], routeCreated: Trip | null) {
  if (routeCreated === null) return undefined;

  const focusedPoint = waypoints?.find((point) => point.focused && point.lat && point.lng);

  const indexFocused = routeCreated.waypoints.findIndex(
    (point) =>
      point.latitude === Number(focusedPoint?.lat) && point.longitude === Number(focusedPoint?.lng),
  );

  if (indexFocused !== -1) {
    const matchedPoint = routeCreated.waypoints[indexFocused];
    const prevPoint = indexFocused > 0 ? routeCreated.waypoints[indexFocused - 1] : null;
    const nextPoint =
      indexFocused < routeCreated.waypoints.length - 1
        ? routeCreated.waypoints[indexFocused + 1]
        : null;

    const newPoints = [
      ...(prevPoint ? [[prevPoint.longitude, prevPoint.latitude]] : []),
      [matchedPoint.longitude, matchedPoint.latitude],
      ...(nextPoint ? [[nextPoint.longitude, nextPoint.latitude]] : []),
    ];

    return newPoints;
  }

  return undefined;
}

export function filterPlannedCoords(
  pointSelected: IInterimPoint | undefined,
  plannedCoords: Position[] | undefined,
) {
  if (!pointSelected || !plannedCoords) return plannedCoords;
  const { lat, lng } = pointSelected;
  const targetIndex = plannedCoords.findIndex(
    (p) => p.latitude === Number(lat) && p.longitude === Number(lng),
  );

  if (targetIndex === -1) return plannedCoords;

  let startIndex = targetIndex;

  while (startIndex > 0) {
    const prev = plannedCoords[startIndex - 1];

    if (prev.kind === "waypoint" || prev.kind === "start_point") break;
    startIndex -= 1;
  }

  const filteredStart = plannedCoords.slice(0, startIndex);

  let endIndex = targetIndex;

  while (endIndex < plannedCoords.length - 1) {
    const next = plannedCoords[endIndex + 1];

    if (next.kind === "waypoint" || next.kind === "end_point") break;
    endIndex += 1;
  }

  const filteredEnd = plannedCoords.slice(endIndex + 1);

  return { initialSegment: filteredStart, endSegment: filteredEnd };
}
