import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import userService from "@/services/user";
import apiConfig from "@/config/api";

const logout = () => {
  const storedAuth = localStorage.getItem("auth");

  if (storedAuth) {
    const auth = JSON.parse(storedAuth);

    userService.setLoginHistory(auth.uid);
    localStorage.removeItem("user");
    localStorage.removeItem("auth");
    setTimeout(() => {
      window.location.href = "/home";
    }, 0);
  }
};

export const customBaseQuery: BaseQueryFn<string | FetchArgs, any, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: apiConfig.apiBaseURL,
    prepareHeaders: (headers) => {
      const storedAuth = localStorage.getItem("auth");

      if (storedAuth) {
        const auth = JSON.parse(storedAuth);

        headers.set("uid", auth.uid);
        headers.set("client", auth.client);
        headers.set("access-token", auth["access-token"]);

        return headers;
      }
    },
  });

  const result = await baseQuery(args, api, extraOptions);

  if (result.error?.status === 401) {
    logout();
  }

  // !TODO: RTK Query should set the new token as well. Needs to be implemented.
  // const newTokenInfo = {
  //   "access-token": result?.meta?.response?.headers?.get("access-token"),
  //   expiry: result?.meta?.response?.headers?.get("expiry"),
  // };

  // await api.dispatch(setNewTokenExpiry(newTokenInfo));

  return result;
};
