/* eslint-disable no-underscore-dangle */
import { Box, Divider, Stack, Typography, Avatar } from "@mui/material";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useState } from "react";

import { styles } from "./styles";
import EditPlacesForm from "./EditPlacesForm";

import { getPoiIcon } from "@/components/ArgoMap/Markers/Utils/PoiIcons";
import BridgeMapIcon from "@/components/ArgoMap/Markers/Pois/BridgeMapIcon";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { Position } from "@/types/map";
import { IMapPois } from "@/types/pois";
import BaseDrawer from "@/components/Drawers/BaseDrawer";
import { IconKeysPois } from "@/types/markers";
import servicePoi from "@/services/poi";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { addSelectedPoi, setEditedPlace, setEditPoiCoordinates } from "@/store/slices/search";

export type TPlace = {
  id: number;
  name: string;
  description: string;
  location: Position;
  address: string;
  phone: string;
  email: string;
  website: string;
  number_of_slips: string;
  clean_marina: boolean;
  bridge_type?: string;
  ver_clr?: number;
  open_hor_clr?: number;
  open_ver_clr?: number;
  vhf_channel?: string;
  after_hours_contact?: string;
  bridge_schedule?: string;
  lock_schedule?: string;
  bridge_other_info?: string;
  lock_other_info?: string;
  number_of_chamber?: string;
  lock_size?: string;
  assets_attributes: File[] | any | null;
};

interface IEditPlacesDrawer {
  selectedPoi: IMapPois;
  selectedPoiCopy: IMapPois | null;
  open: boolean;
}

export default function EditPlacesDrawer({
  open,
  selectedPoi,
  selectedPoiCopy,
}: IEditPlacesDrawer) {
  const dispatch = useAppDispatch();
  const { editPoiCoordinates } = useAppSelector((state) => state.search);
  const [loading, setLoading] = useState<boolean>(false);

  const handleForm = async (values: TPlace) => {
    try {
      setLoading(true);
      if (values?.assets_attributes && values?.assets_attributes?.length > 0 && selectedPoi.id) {
        const formData = new FormData();
        let hasAssetsChanges = false;

        formData.append("poi_id", String(selectedPoi.id));

        values?.assets_attributes
          .filter((file: any) => !(file.id && !file._destroy))
          .forEach((file: any, index: number) => {
            if (file._destroy) {
              formData.append(`assets_attributes[${index}][id]`, file.id);
              formData.append(`assets_attributes[${index}][_destroy]`, file._destroy);
              hasAssetsChanges = true;
            } else {
              formData.append(`assets_attributes[${index}][attached_file]`, file);
              formData.append(
                `assets_attributes[${index}][file_name]`,
                file.name || file.file_name,
              );
              hasAssetsChanges = true;
            }
          });

        if (hasAssetsChanges) {
          await servicePoi.updatePoiInfo(values?.id, formData);
        }
      }

      const { status, data } = await servicePoi.updatePoiInfo(values?.id, {
        ...values,
        lat: values.location.lat,
        lng: values.location.lng,
      });

      if (status === 200) {
        dispatch(addSelectedPoi(data.data));
        dispatch(setEditedPlace(true));
        dispatch(
          setSnackBarMsjSucceded({
            state: true,
            type: "success",
            msj: `Place successfully updated.`,
          }),
        );
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const formik = useFormik({
    initialValues: {
      id: selectedPoi.id,
      name: selectedPoi.name,
      description: selectedPoi.description,
      location: { lat: selectedPoi.lat, lng: selectedPoi.lng },
      address: selectedPoi.address,
      phone: selectedPoi.phone,
      email: selectedPoi.email,
      website: selectedPoi.website,
      number_of_slips: selectedPoi.number_of_slips,
      clean_marina: selectedPoi.clean_marina,
      assets_attributes: selectedPoi.assets,
      bridge_type: selectedPoi?.bridge_type,
      ver_clr: selectedPoi?.ver_clr,
      open_hor_clr: selectedPoi?.open_hor_clr,
      open_ver_clr: selectedPoi?.open_ver_clr,
      vhf_channel: selectedPoi?.vhf_channel,
      after_hours_contact: selectedPoi?.after_hours_contact,
      bridge_schedule: selectedPoi?.bridge_schedule,
      lock_schedule: selectedPoi?.lock_schedule,
      bridge_other_info: selectedPoi?.bridge_other_info,
      lock_other_info: selectedPoi?.lock_other_info,
      number_of_chamber: selectedPoi?.number_of_chamber,
      lock_size: selectedPoi?.lock_size,
    } as TPlace,
    onSubmit: handleForm,
  });

  /**
   * This function evaluate if the icon bridge has clearance or not.
   * @param poi IMapPois
   * @returns React.ReactNode or String
   */
  const evaluateIcons = (poi: IMapPois) => {
    const iconBridgeType =
      poi?.poi_type === "Bridge"
        ? poi?.info?.ver_clr !== null &&
          poi?.info?.ver_clr !== undefined &&
          poi?.info?.ver_clr !== "" &&
          poi?.info?.ver_clr !== "0" &&
          poi?.info?.ver_clr !== "0.0"
          ? IconKeysPois.BridgeClearance
          : IconKeysPois.Bridge
        : null;

    if (iconBridgeType === IconKeysPois.BridgeClearance) {
      return <BridgeMapIcon infoIcon={poi} />;
    }

    return getPoiIcon(poi.poi_type as IconKeysPois);
  };

  const handleOnEditCoordinates = (value: boolean) => {
    dispatch(setEditPoiCoordinates(value));
  };

  return (
    <Box>
      <BaseDrawer open={open} styles={styles.drawer}>
        <Stack pb={2} pt={10} px={2} spacing={1.5}>
          <Stack alignItems="center" direction="row" spacing={1.5}>
            <Avatar src={evaluateIcons(selectedPoi) as string} sx={{ width: 48, height: 48 }} />
            <Stack alignItems="flex-start" sx={{ width: "100%" }}>
              <Typography component="h2" sx={styles.poiTitle} variant="h5">
                <Typography component="h2" sx={styles.poiTitle} variant="h5">
                  {selectedPoi?.name}
                </Typography>
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <EditPlacesForm
          editCoordinates={editPoiCoordinates}
          formData={formik}
          placeType={selectedPoi?.poi_type}
          selectedPoi={selectedPoi}
          selectedPoiCopy={selectedPoiCopy}
          onEditCoordinates={handleOnEditCoordinates}
        />
        <Divider />
        <Stack direction="row" justifyContent="flex-end" sx={{ p: 2, width: "100%" }}>
          <LoadingButton
            autoFocus
            loading={loading}
            variant="contained"
            onClick={() => formik.handleSubmit()}
          >
            Save
          </LoadingButton>
        </Stack>
      </BaseDrawer>
    </Box>
  );
}
