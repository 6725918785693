import {
  Stack,
  List,
  Paper,
  IconButton,
  Input,
  Divider,
  Button,
  Box,
  DialogContent,
} from "@mui/material";
import {
  SearchOutlined,
  FilterList,
  SimCardDownloadOutlined,
  FileDownload,
} from "@mui/icons-material";
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useState, useMemo, useEffect } from "react";
import dayjs from "dayjs";

import { styles } from "./styles";
import CaptainsLogListItem from "./CaptainsLogListItem";
import CaptainsLogListSkeleton from "./CaptainsLogListSkeleton";
import CaptainLogsFilters from "./CaptainLogsFilters";
import ReportPeriodFilter from "./CaptainLogsFilters/ReportPeriodFilter";
import CaptainLogsReportPdf from "./CaptainLogsReportPdf";

import { ITrip, IVoyages } from "@/types/route";
import useDisclosure from "@/hooks/useDisclosure";
import BaseModal from "@/components/Modals/BaseModal";
import EmptyState from "@/components/EmptyState/EmptyState";
import voyagesServices from "@/services/voyages";
import { setSnackBarMsj } from "@/store/slices/auth/actions";
import { useAppDispatch } from "@/hooks/useRedux";

interface ICaptainsLogList {
  isSelected: ITrip | null;
  getMoreVoyages: any;
  trips: IVoyages | null;
  items: ITrip[] | null;
  hasMore: boolean;
  sortFilters: string;
  favoritesFilter: boolean;
  loadingItems: boolean;
  action: (item: ITrip | null) => void;
  onSetFavoriteItem: (voyageId: number, favorite: boolean, e: any) => void;
  onGetVoyageByKeyword: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSortFilters: (value: string) => void;
  onGetItemsByFilters: (filter: string, favorites: boolean) => void;
  onSetFavoritesFilter: (value: boolean) => void;
}

export enum EPeriodFilter {
  Last3Days = "Last 3 days",
  LastWeek = "Last week",
  LastMonth = "Last month",
  CustomPeriod = "Custom Period",
}

type TCustomDate = {
  date_from: Date;
  date_to: Date;
};

export default function CaptainsLogList({
  isSelected,
  trips,
  getMoreVoyages,
  hasMore,
  items,
  sortFilters,
  favoritesFilter,
  loadingItems,
  action,
  setSortFilters,
  onSetFavoriteItem,
  onGetVoyageByKeyword,
  onGetItemsByFilters,
  onSetFavoritesFilter,
}: ICaptainsLogList) {
  const dispatch = useAppDispatch();
  const { onClose, isOpen, onOpen } = useDisclosure();
  const { onClose: crOnClose, isOpen: crIsOpen, onOpen: crOnOpen } = useDisclosure();
  const [selectedSortFilters, setSelectedSortFilters] = useState<string>(sortFilters);
  const [selectedFavoriteFilter, setSelectedFavoriteFilter] = useState<boolean>(favoritesFilter);
  const [reportPeriodFilter, setReportPeriodFilter] = useState<string>(EPeriodFilter.Last3Days);
  const [reportFavoriteFilter, setReportFavoriteFilter] = useState<boolean>(false);
  const [reportSortFilters, setReportSortFilters] = useState<string>("created_at_");
  const [showReportScreen, setShowReportScreen] = useState<boolean>(false);
  const [fetchingReport, setFetchingReport] = useState<boolean>(false);
  const [reportVoyagesTotalItems, setReportVoyagesTotalItems] = useState<number>(0);
  const [reportArrayBuffer, setReportArrayBuffer] = useState<ArrayBuffer | null>(null);
  const [customDate, setCustomDate] = useState<TCustomDate>({
    date_from: dayjs(new Date()).toDate(),
    date_to: dayjs(new Date()).toDate(),
  });

  const handleSetCustomDate = (date: Date, dateType: string) => {
    setCustomDate((prev) => ({ ...prev, [dateType]: dayjs(date).toDate() }));
  };

  const handleEvaluatePeriodFilter = () => {
    switch (reportPeriodFilter) {
      case EPeriodFilter.Last3Days:
        return {
          date_from: dayjs().subtract(3, "day").format("YYYY-MM-DD"),
          date_to: dayjs().format("YYYY-MM-DD"),
        };
      case EPeriodFilter.LastWeek:
        return {
          date_from: dayjs().subtract(1, "week").format("YYYY-MM-DD"),
          date_to: dayjs().format("YYYY-MM-DD"),
        };
      case EPeriodFilter.LastMonth:
        return {
          date_from: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
          date_to: dayjs().format("YYYY-MM-DD"),
        };
      case EPeriodFilter.CustomPeriod:
        return {
          date_from: dayjs(customDate.date_from).format("YYYY-MM-DD"),
          date_to: dayjs(customDate.date_to).format("YYYY-MM-DD"),
        };
      default:
        return undefined;
    }
  };

  const handleReportVoyageItems = async () => {
    const params = {
      publication_status: "published",
      order: reportSortFilters,
      favorites: reportFavoriteFilter,
      date_from: handleEvaluatePeriodFilter()?.date_from,
      date_to: handleEvaluatePeriodFilter()?.date_to,
    };

    try {
      const { status, data } = await voyagesServices.getVoyages(params);

      if (status === 200) {
        setReportVoyagesTotalItems(data.total_items);
      }
    } catch (error: any) {
      dispatch(setSnackBarMsj(true, "error", error.toString()));
    }
  };

  useEffect(() => {
    handleReportVoyageItems();
  }, [reportPeriodFilter, reportFavoriteFilter, reportSortFilters, customDate]);

  const handleReportFavoriteFilter = () => {
    setReportFavoriteFilter(!reportFavoriteFilter);
  };

  const handleReportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportSortFilters(event.target.value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedSortFilters(event.target.value);
  };

  const handleSelectFavoriteFilter = () => {
    setSelectedFavoriteFilter(!selectedFavoriteFilter);
  };

  const handleClose = () => {
    onClose();
    setSelectedSortFilters(sortFilters);
    setSelectedFavoriteFilter(favoritesFilter);
  };

  const handleOpenCr = () => {
    setShowReportScreen(false);
    crOnOpen();
  };

  const handleCrClose = () => {
    crOnClose();
    setFetchingReport(false);
    setSelectedSortFilters(sortFilters);
    setSelectedFavoriteFilter(favoritesFilter);
  };

  const handleSetFilters = () => {
    onClose();
    setSortFilters(selectedSortFilters);
    onSetFavoritesFilter(selectedFavoriteFilter);
    onGetItemsByFilters(selectedSortFilters, selectedFavoriteFilter);
  };

  const handlePrepareReport = async () => {
    setFetchingReport(true);
    setShowReportScreen(true);
    try {
      const params = {
        publication_status: "published",
        order: reportSortFilters,
        favorites: reportFavoriteFilter,
        date_from: handleEvaluatePeriodFilter()?.date_from,
        date_to: handleEvaluatePeriodFilter()?.date_to,
      };

      const { status, data } = await voyagesServices.getVoyagesReport(params);

      if (status === 200) {
        setFetchingReport(false);
        setReportArrayBuffer(data);
      }
    } catch (error: any) {
      setFetchingReport(false);
      dispatch(setSnackBarMsj(true, "error", error.toString()));
    }
  };

  const handleDownloadReport = (arrayBuffer: ArrayBuffer | null) => {
    if (arrayBuffer === null) return;
    const blob = new Blob([arrayBuffer], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = "captains-log-report.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const memorizedItems = useMemo(
    () =>
      items?.map((item: ITrip) => (
        <CaptainsLogListItem
          key={`cl-route-${item.id}`}
          isSelected={isSelected?.id === item?.id}
          item={item}
          trips={trips}
          onSelectVoyage={() => action(item)}
          onSetFavoriteItem={onSetFavoriteItem}
        />
      )),
    [items, isSelected, trips, action, onSetFavoriteItem],
  );

  return (
    <Stack component="div" spacing={0} sx={styles.mainStack}>
      <Stack spacing={0}>
        <Stack alignItems="center" direction="row" spacing={1.5}>
          <Paper elevation={3} sx={styles.paper}>
            <Stack direction="row" sx={styles.searchBox}>
              <Input
                placeholder="Search"
                size="medium"
                startAdornment={<SearchOutlined />}
                sx={styles.input}
                type="text"
                onChange={onGetVoyageByKeyword}
              />
              <Divider orientation="vertical" sx={{ height: 32, m: 0.5 }} />
              <IconButton
                aria-label="open"
                color={isOpen ? "primary" : "default"}
                edge="end"
                onClick={onOpen}
              >
                <FilterList />
              </IconButton>
            </Stack>
          </Paper>
        </Stack>
        <Box sx={styles.buttonContainer}>
          <Button startIcon={<SimCardDownloadOutlined />} variant="outlined" onClick={handleOpenCr}>
            Create Report
          </Button>
        </Box>
      </Stack>
      <Paper elevation={0} sx={styles.list} variant="outlined">
        {!loadingItems ? (
          <List disablePadding id="scrollableDiv">
            <InfiniteScroll
              dataLength={items?.length || 0}
              hasMore={hasMore}
              loader={<CaptainsLogListSkeleton />}
              next={getMoreVoyages}
              scrollableTarget="scrollableDiv"
            >
              {memorizedItems}
            </InfiniteScroll>
          </List>
        ) : (
          <CaptainsLogListSkeleton />
        )}
        {items && items?.length <= 0 && !loadingItems && (
          <Box sx={{ mt: 5 }}>
            <EmptyState
              imageURL="img/placeholders/EmptyStateNoResults.svg"
              subtitle="We couldn't find any matching results. Please check your search criteria and try again."
              sx={{ height: "calc(100% - 125px)", px: 3 }}
              title="No Results Found"
            />
          </Box>
        )}
      </Paper>
      <BaseModal
        ariaDescribedby="Filters & Sorting"
        ariaLabelledby="Filters & Sorting"
        open={isOpen}
        size="xs"
        title="Filters & Sorting"
        onClose={handleClose}
      >
        <CaptainLogsFilters
          selectedFavoriteFilter={selectedFavoriteFilter}
          selectedSortFilters={selectedSortFilters}
          onChange={handleChange}
          onSelectFavoriteFilter={handleSelectFavoriteFilter}
        />
        <Stack direction="row" justifyContent="flex-end" p={3} spacing={2}>
          <Button type="button" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="button" variant="contained" onClick={handleSetFilters}>
            Apply
          </Button>
        </Stack>
      </BaseModal>
      <BaseModal
        ariaDescribedby="Create Report"
        ariaLabelledby="Create Report"
        open={crIsOpen}
        size={showReportScreen ? "xl" : "sm"}
        title={showReportScreen ? "Report Preview" : "Create Report"}
        onClose={handleCrClose}
      >
        <DialogContent sx={{ p: 0 }}>
          {showReportScreen ? (
            <CaptainLogsReportPdf
              fetchingReport={fetchingReport}
              reportArrayBuffer={reportArrayBuffer}
            />
          ) : (
            <>
              <ReportPeriodFilter
                customDate={customDate}
                filter={reportPeriodFilter}
                onSelectPeriodFilter={setReportPeriodFilter}
                onSetCustomDate={handleSetCustomDate}
              />
              <CaptainLogsFilters
                selectedFavoriteFilter={reportFavoriteFilter}
                selectedSortFilters={reportSortFilters}
                onChange={handleReportChange}
                onSelectFavoriteFilter={handleReportFavoriteFilter}
              />
            </>
          )}
        </DialogContent>
        <Stack direction="row" justifyContent="flex-end" p={3} spacing={2}>
          <Button type="button" onClick={handleCrClose}>
            Cancel
          </Button>
          {showReportScreen ? (
            <Button
              disabled={!reportArrayBuffer}
              startIcon={<FileDownload />}
              type="button"
              variant="contained"
              onClick={() => handleDownloadReport(reportArrayBuffer)}
            >
              Download
            </Button>
          ) : (
            <Button
              disabled={reportVoyagesTotalItems <= 0}
              type="button"
              variant="contained"
              onClick={handlePrepareReport}
            >
              Create Report {reportVoyagesTotalItems > 0 && `(${reportVoyagesTotalItems})`}
            </Button>
          )}
        </Stack>
      </BaseModal>
    </Stack>
  );
}
