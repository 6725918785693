import { alpha } from "@mui/material/styles";

import { layoutStyles } from "@/themes/layoutStyles";
import defaultTheme from "@/themes/theme";

export const styles = {
  mainContainer: {
    display: "flex",
    height: "100%",
    minHeight: "100%",
    width: "100vw",
    alignItems: "center",
    position: "relative",
    flexDirection: "column",
  },

  confirmLocationButton: {
    position: "absolute",
    width: "calc(100% - 488px)",
    top: "64px",
    left: 488,
    display: "flex",
    justifyContent: "center",
    zIndex: 10,
    "& .MuiButton-root": {
      boxShadow: 4,
      typography: "subtitle2",
      px: 2,
    },
  },

  mapContent: {
    left: "0",
    bottom: "0",
    height: "100%",
    position: "absolute",
    zIndex: "1",
    "& .cluster:hover div:not(img)": {
      transition: "all .1s linear",
      opacity: 1,
      transform: "translateY(0)",
    },
    "& .cluster:hover img": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0.65)}`,
      transition: "all .3s linear",
    },
    "& .cluster img": {
      backgroundColor: `${alpha(defaultTheme.palette.primary.main, 0)}`,
      borderRadius: "20px",
      p: "2px",
    },
    "& .cluster div:not(img)": {
      backgroundColor: "primary.dark",
      color: `${defaultTheme.palette.common.white} !important`,
      borderRadius: "4px",
      height: "18px !important",
      width: "18px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bottom: "100% !important",
      left: "30px !important",
      boxShadow: 1,
      opacity: 0,
      transform: "translateY(16px)",
    },
  },

  GoogleMapControls: {
    p: "20px",
    zIndex: "1",
    position: "absolute",
    bottom: 0,
    // opacity: 0,
  },

  searchBox: {
    [defaultTheme.breakpoints.up("md")]: {
      minWidth: 384,
    },
    maxWidth: 384,
    position: "absolute",
    top: 0,
    left: 80,
    mt: 1,
    mb: 1,
    mx: 1.5,
    zIndex: 7,
  },

  notiButton: {
    position: "absolute",
    top: 344,
    right: 0,
    p: 2,
    zIndex: 2,
  },

  mapOverlays: {
    position: "absolute",
    top: 64,
    right: 0,
    p: 2,
    display: "flex",
    zIndex: 2,
  },

  rightControls: {
    zIndex: 3,
    position: "absolute",
    bottom: 0,
    right: 0,
    height: "calc(100vh - 70px)",
    p: 1.5,
    pb: 3,
    pt: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    pointerEvents: "none",
    "& .MuiButtonBase-root:not(.Mui-disabled), .MuiSlider-thumb:not(.Mui-disabled)": {
      pointerEvents: "auto",
    },
  },

  drawer: {
    width: 408,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: 408,
      ml: "80px",
      boxSizing: "border-box",
      boxShadow: 12,
      borderRight: "none",
      // borderRadius: "0 8px 8px 0",
      overflow: "hidden",
      zIndex: 6,
    },
  },

  scrollContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "100vh",
    height: "100%",
  },

  searchBoxDrawer: {
    position: "relative",
    py: 1,
    px: 1.5,
  },

  categoriesInDrawer: {
    mt: "64px",
    "& .searchCategories": {
      pl: 1.5,
    },
  },

  placePanel: {
    m: 1.5,
    mt: "68px",
    top: 0,
    left: "488px",
    zIndex: 6,
    position: "absolute",
    height: "calc(100vh - 92px)",
    overflow: "hidden",
    width: 408,
  },

  completeRouteButton: {
    position: "absolute",
    zIndex: 9999,
    left: "calc(50%)",
    bottom: 40,
  },
};
