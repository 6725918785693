import getDistance from "geolib/es/getDistance";

import { ISearchResultListItem, IMapPois, ENavAids, TFuelFormmatedData } from "@/types/pois";
import { Position } from "@/types/map";
import BaseIcon from "@/components/BaseIcon/BaseIcon";
import { ReactComponent as MarinaIcon } from "&/img/markers/pois/iconFilled/marina_iconFilled.svg";
import { ReactComponent as YachtClubIcon } from "&/img/markers/pois/iconFilled/yachtClub_iconFilled.svg";
import { ReactComponent as RestaurantBarIcon } from "&/img/markers/pois/iconFilled/restaurantBar_iconFilled.svg";
import { ReactComponent as BoatRampIcon } from "&/img/markers/pois/iconFilled/boatRamp_iconFilled.svg";
import { ReactComponent as ClubAssociationIcon } from "&/img/markers/pois/iconFilled/clubAssociation_iconFilled.svg";
import { ReactComponent as AnchorageIcon } from "&/img/markers/pois/iconFilled/anchorage_iconFilled.svg";
import { ReactComponent as ArtificialReefIcon } from "&/img/markers/pois/iconFilled/artificialReef_iconFilled.svg";
import { ReactComponent as BridgeIcon } from "&/img/markers/pois/iconFilled/bridge_iconFilled.svg";
import { ReactComponent as BusinessIcon } from "&/img/markers/pois/iconFilled/business_iconFilled.svg";
import { ReactComponent as CityTownIcon } from "&/img/markers/pois/iconFilled/cityTown_iconFilled.svg";
import { ReactComponent as DamIcon } from "&/img/markers/pois/iconFilled/dam_iconFilled.svg";
import { ReactComponent as HotelIcon } from "&/img/markers/pois/iconFilled/hotel_iconFilled.svg";
import { ReactComponent as InletIcon } from "&/img/markers/pois/iconFilled/inlet_iconFilled.svg";
import { ReactComponent as LockIcon } from "&/img/markers/pois/iconFilled/lock_iconFilled.svg";
import { ReactComponent as OtherIcon } from "&/img/markers/pois/iconFilled/other_iconFilled.svg";
import { ReactComponent as DockBarIcon } from "&/img/markers/pois/iconFilled/dockBar_iconFilled.svg";
import { ReactComponent as FreeDockIcon } from "&/img/markers/pois/iconFilled/docking_iconFilled.svg";
import { ReactComponent as PrivateDockIcon } from "&/img/markers/pois/iconFilled/privateDock_iconFilled.svg";
import { ReactComponent as FishingSpotIcon } from "&/img/markers/pois/iconFilled/fishingSpot_iconFilled.svg";
import { ReactComponent as LightHouseIcon } from "&/img/markers/pois/iconFilled/lightHouse_iconFilled.svg";
import { ReactComponent as MooringIcon } from "&/img/markers/pois/iconFilled/mooring_iconFilled.svg";
import { ReactComponent as PoiIcon } from "&/img/markers/pois/iconFilled/pointOfInterest_iconFilled.svg";
import { ReactComponent as Beach } from "&/img/markers/pois/iconFilled/beach_iconFilled.svg";
import { ReactComponent as BoatClubIcon } from "&/img/markers/pois/iconFilled/boatClub_iconFilled.svg";
import {
  degreesMinutesSecondsFormatRegex,
  degreesDecimalsMinutesFormatRegex,
} from "@/utils/globals";

interface PlaceItem {
  name: string;
  // eslint-disable-next-line no-undef
  icon: JSX.Element;
}

export type TPOI_TYPE = {
  value: string;
  label: string;
  type: string;
};

export interface ITempPin extends TPOI_TYPE {
  location: Position;
  editPinInfo?: IMapPois | any;
  quickpinId?: number;
}

export const PLACES_KEY = "places";
export const PLACE_KEY = "Place";
export const REPORTPINS_KEY = "reportpins";
export const AMENITIES_KEY = "amenities";
export const NAVIGATION_AIDS_KEY = "aids";
export const PARTNERS_KEY = "partners";
export const FAVORITES_KEY = "favorites";

export const REPORTPINS_TYPE = [
  {
    type: REPORTPINS_KEY,
    label: "Hazards",
    value: "hazard",
  },
  {
    type: REPORTPINS_KEY,
    label: "Local Advice",
    value: "local_advice",
  },
  {
    type: REPORTPINS_KEY,
    label: "Map Issues",
    value: "map_issue",
  },
  {
    type: REPORTPINS_KEY,
    label: "Marine Life",
    value: "marine_life",
  },
  {
    type: REPORTPINS_KEY,
    label: "Police",
    value: "police",
  },
  {
    type: REPORTPINS_KEY,
    label: "Weather",
    value: "weather",
  },
  {
    type: REPORTPINS_KEY,
    label: "No Wake Zone",
    value: "no_wake_zone",
  },
  {
    type: REPORTPINS_KEY,
    label: "SOS",
    value: "sos",
  },
];

export const REPORTPINS_HAZARD_SUB_TYPE = [
  {
    type: REPORTPINS_KEY,
    label: "Floating",
    value: "Floating",
  },
  {
    type: REPORTPINS_KEY,
    label: "Protruding",
    value: "Protruding",
  },
  {
    type: REPORTPINS_KEY,
    label: "Submerged",
    value: "Submerged",
  },
  {
    type: REPORTPINS_KEY,
    label: "Other",
    value: "OtherHazard",
  },
];

export const REPORTPINS_SOS_SUB_TYPE = [
  {
    type: REPORTPINS_KEY,
    label: "Out of Fuel",
    value: "Out of Fuel",
  },
  {
    type: REPORTPINS_KEY,
    label: "Engine Trouble",
    value: "Engine Trouble",
  },
  {
    type: REPORTPINS_KEY,
    label: "Ran Aground",
    value: "Ran Aground",
  },
  {
    type: REPORTPINS_KEY,
    label: "Accident/Collision",
    value: "Accident/Collision",
  },
  {
    type: REPORTPINS_KEY,
    label: "Boat Fire",
    value: "Boat Fire",
  },
  {
    type: REPORTPINS_KEY,
    label: "Medical Emergency",
    value: "Medical Emergency",
  },
  {
    type: REPORTPINS_KEY,
    label: "Man Overboard",
    value: "Man Overboard",
  },
  {
    type: REPORTPINS_KEY,
    label: "Other",
    value: "OtherSos",
  },
];

export const PLACES_TYPE: TPOI_TYPE[] = [
  {
    value: "Anchorage",
    label: "Anchorage",
    type: PLACES_KEY,
  },
  {
    value: "Artificial Reef",
    label: "Artificial Reef",
    type: PLACES_KEY,
  },
  {
    value: "Beach",
    label: "Beach",
    type: PLACES_KEY,
  },
  {
    value: "Boat Club",
    label: "Boat Club",
    type: PLACES_KEY,
  },
  {
    value: "BoatRamp",
    label: "Boat Ramp",
    type: PLACES_KEY,
  },
  {
    value: "Bridge",
    label: "Bridge",
    type: PLACES_KEY,
  },
  {
    value: "Business",
    label: "Business",
    type: PLACES_KEY,
  },
  {
    value: "City/Town",
    label: "City/Town",
    type: PLACES_KEY,
  },
  {
    value: "Club/Association",
    label: "Club/Association",
    type: PLACES_KEY,
  },
  {
    value: "Dam",
    label: "Dam",
    type: PLACES_KEY,
  },
  {
    value: "Dock Bar/Restaurant",
    label: "Dock Bar/Restaurant",
    type: PLACES_KEY,
  },
  {
    value: "Fishing Spot",
    label: "Fishing Spot",
    type: PLACES_KEY,
  },
  {
    value: "Free Dock",
    label: "Free Dock",
    type: PLACES_KEY,
  },
  {
    value: "Hotel",
    label: "Hotel",
    type: PLACES_KEY,
  },
  {
    value: "Inlet",
    label: "Inlet",
    type: PLACES_KEY,
  },
  {
    value: "Light House",
    label: "Light House",
    type: PLACES_KEY,
  },
  {
    value: "Lock",
    label: "Lock",
    type: PLACES_KEY,
  },
  {
    value: "Marina",
    label: "Marina",
    type: PLACES_KEY,
  },
  {
    value: "Mooring",
    label: "Mooring",
    type: PLACES_KEY,
  },
  {
    value: "Other",
    label: "Other",
    type: PLACES_KEY,
  },
  {
    value: "Point of Interest",
    label: "Point of Interest",
    type: PLACES_KEY,
  },
  {
    value: "Private Dock",
    label: "Private Dock",
    type: PLACES_KEY,
  },
  {
    value: "Restaurant/Bar",
    label: "Restaurant/Bar",
    type: PLACES_KEY,
  },
  {
    value: "Yacht Club",
    label: "Yacht Club",
    type: PLACES_KEY,
  },
];

export const AMENITIES_TYPE: TPOI_TYPE[] = [
  {
    value: "supplies",
    label: "Boat Supplies",
    type: AMENITIES_KEY,
  },
  {
    value: "cable_satellite",
    label: "Cable/Satellite",
    type: AMENITIES_KEY,
  },
  {
    value: "captains_lounge",
    label: "Captains Lounge",
    type: AMENITIES_KEY,
  },
  {
    value: "fitness_center",
    label: "Fitness Center",
    type: AMENITIES_KEY,
  },
  {
    value: "floating_docks",
    label: "Floating Docks",
    type: AMENITIES_KEY,
  },
  {
    value: "fuel",
    label: "Fuel",
    type: AMENITIES_KEY,
  },
  {
    value: "groceries",
    label: "Groceries",
    type: AMENITIES_KEY,
  },
  {
    value: "haul_out",
    label: "Haul Out",
    type: AMENITIES_KEY,
  },
  {
    value: "ice",
    label: "Ice",
    type: AMENITIES_KEY,
  },
  {
    value: "laundry",
    label: "Laundry",
    type: AMENITIES_KEY,
  },
  {
    value: "maintenance",
    label: "Maintenance Facility",
    type: AMENITIES_KEY,
  },
  {
    value: "pet_friendly",
    label: "Pet Friendly",
    type: AMENITIES_KEY,
  },
  {
    value: "picnic_area",
    label: "Picnic Area",
    type: AMENITIES_KEY,
  },
  {
    value: "playground",
    label: "Playground",
    type: AMENITIES_KEY,
  },
  {
    value: "pump_out",
    label: "Pump Out",
    type: AMENITIES_KEY,
  },
  {
    value: "restaurant",
    label: "Restaurant",
    type: AMENITIES_KEY,
  },
  {
    value: "restrooms",
    label: "Restrooms",
    type: AMENITIES_KEY,
  },
  {
    value: "security",
    label: "Security",
    type: AMENITIES_KEY,
  },
  {
    value: "showers",
    label: "Showers",
    type: AMENITIES_KEY,
  },
  {
    value: "swimming_pool",
    label: "Swimming Pool",
    type: AMENITIES_KEY,
  },
  {
    value: "Transient Slip",
    label: "Transient Slip",
    type: AMENITIES_KEY,
  },
  {
    value: "transportation",
    label: "Transportation",
    type: AMENITIES_KEY,
  },
  {
    value: "trash",
    label: "Trash",
    type: AMENITIES_KEY,
  },
  {
    value: "wiFi",
    label: "WiFi",
    type: AMENITIES_KEY,
  },
];

export const NAVIGATION_AIDS_TYPE: TPOI_TYPE[] = [
  {
    value: "Buoy",
    label: "Buoys",
    type: NAVIGATION_AIDS_KEY,
  },
  {
    value: "Beacon",
    label: "Beacons",
    type: NAVIGATION_AIDS_KEY,
  },
  {
    value: "Mile Marker",
    label: "Mile Markers",
    type: NAVIGATION_AIDS_KEY,
  },
];

export const PARTNERS_TYPE: TPOI_TYPE[] = [
  {
    value: "Dockshare",
    label: "Dockshare",
    type: PARTNERS_KEY,
  },
  {
    value: "Snag-A-Slip",
    label: "Snag-A-Slip",
    type: PARTNERS_KEY,
  },
];

const placeItems = [
  {
    name: "Artificial Reef",
    icon: <BaseIcon icon={ArtificialReefIcon} />,
  },
  {
    name: "Anchorage",
    icon: <BaseIcon icon={AnchorageIcon} />,
  },
  {
    name: "BoatRamp",
    icon: <BaseIcon icon={BoatRampIcon} />,
  },
  {
    name: "Bridge",
    icon: <BaseIcon icon={BridgeIcon} />,
  },
  {
    name: "Business",
    icon: <BaseIcon icon={BusinessIcon} />,
  },
  {
    name: "City/Town",
    icon: <BaseIcon icon={CityTownIcon} />,
  },
  {
    name: "Club/Association",
    icon: <BaseIcon icon={ClubAssociationIcon} />,
  },
  {
    name: "Dam",
    icon: <BaseIcon icon={DamIcon} />,
  },
  {
    name: "Hotel",
    icon: <BaseIcon icon={HotelIcon} />,
  },
  {
    name: "Inlet",
    icon: <BaseIcon icon={InletIcon} />,
  },
  {
    name: "Lock",
    icon: <BaseIcon icon={LockIcon} />,
  },
  {
    name: "Marina",
    icon: <BaseIcon icon={MarinaIcon} />,
  },
  {
    name: "Restaurant/Bar",
    icon: <BaseIcon icon={RestaurantBarIcon} />,
  },
  {
    name: "Yacht Club",
    icon: <BaseIcon icon={YachtClubIcon} />,
  },
  {
    name: "Other",
    icon: <BaseIcon icon={OtherIcon} />,
  },
  {
    name: "address",
    icon: <BaseIcon icon={OtherIcon} />,
  },
  {
    name: "my_location",
    icon: <BaseIcon icon={OtherIcon} />,
  },
  {
    name: "Dock Bar/Restaurant",
    icon: <BaseIcon icon={DockBarIcon} />,
  },
  {
    name: "Free Dock",
    icon: <BaseIcon icon={FreeDockIcon} />,
  },
  {
    name: "Private Dock",
    icon: <BaseIcon icon={PrivateDockIcon} />,
  },
  {
    name: "Fishing Spot",
    icon: <BaseIcon icon={FishingSpotIcon} />,
  },
  {
    name: "Light House",
    icon: <BaseIcon icon={LightHouseIcon} />,
  },
  {
    name: "Mooring",
    icon: <BaseIcon icon={MooringIcon} />,
  },
  {
    name: "Point of Interest",
    icon: <BaseIcon icon={PoiIcon} />,
  },
  {
    name: "Beach",
    icon: <BaseIcon icon={Beach} />,
  },
  {
    name: "Boat Club",
    icon: <BaseIcon icon={BoatClubIcon} />,
  },
];

export const findSelectedCategory = (selectedCategories: TPOI_TYPE[], value: TPOI_TYPE) =>
  selectedCategories?.some((item) => item.value === value.value);

export const findPoi = (poi_type: string) => {
  const result = placeItems.find((item: PlaceItem) => item.name === poi_type);

  if (!result) return placeItems[2].icon;

  return result?.icon ?? "";
};

/**
 * Convert a list of POIs to a list of ISearchResultListItem
 * @param pois - List of POIs to be converted`````
 * @returns a list of ISearchResultListItem
 */
export const fromPOIToSearchResultItem = (pois: IMapPois[]) => {
  let items: ISearchResultListItem[] = [];

  if (pois) {
    items = pois.map((item: IMapPois, index: number) => ({
      id: item.id,
      icon: findPoi(item.poi_type),
      distance: null,
      primaryLabel: item.name,
      secondaryLabel: item.address,
      numberList: index,
    }));
  }

  return items;
};

export const getDistanceFromLocation = (
  from: Position,
  to: Position,
  decimals = 1, // This function work in meters as default.
) =>
  Number(
    getDistance(from, to),
    // *
    //   // Convert meters to miles and round to one decimal
    //   0.000621371,
  ).toFixed(decimals);

export const isLatLng = (coord: string) => {
  const re = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/g;

  return re.test(coord);
};

export const isDDMFormat = (coord: string) => degreesDecimalsMinutesFormatRegex.test(coord);
export const isDMSFormat = (coord: string) => degreesMinutesSecondsFormatRegex.test(coord);

export function newOverallRaiting(
  currentRating: number,
  totalRaitings: number,
  newRaiting: number,
) {
  const newOverallRaiting = (currentRating * totalRaitings + newRaiting) / (totalRaitings + 1);

  return newOverallRaiting;
}
export const checkBuoysAndBeaconsStrings = (
  COLOR?: string,
  BUOY_TYPE?: string,
  CUSTOMSTRING?: string,
) => {
  const stringFormated = [];

  if (COLOR && COLOR !== "undefined") stringFormated.push(COLOR);
  if (BUOY_TYPE && BUOY_TYPE !== "undefined") stringFormated.push(BUOY_TYPE);
  if (CUSTOMSTRING && CUSTOMSTRING !== "undefined") stringFormated.push(CUSTOMSTRING);

  return stringFormated
    .join(" ")
    .replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase());
};

export const checkRestricedAreaStrings = (OBJNAME?: string, CATREA?: string, RESTRN?: string) => {
  const stringFormated = [];

  if (OBJNAME && OBJNAME !== "undefined") stringFormated.push(OBJNAME);
  if (CATREA && CATREA !== "undefined") stringFormated.push(CATREA);
  if (RESTRN && RESTRN !== "undefined") stringFormated.push(RESTRN);

  return stringFormated
    .join(", ")
    .replace(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase());
};

export const isNavAid = (type: ENavAids | undefined) => {
  if (type) {
    if (type === ENavAids.RestrictedArea || type === ENavAids.Buoy || type === ENavAids.Beacon) {
      return true;
    }
  }

  return false;
};

export const fuelVisibility = (poiSelected: IMapPois) =>
  [
    "Marina",
    "BoatRamp",
    "Dock",
    "Dock Bar/Restaurant",
    "Club/Association",
    "Yacht Club",
    "Hotel",
    "Business",
    "Restaurant/Bar",
  ].includes(poiSelected?.poi_type);

export const amenitiesTransientVisibility = (poiSelected: IMapPois) =>
  ["Marina", "Dock", "Dock Bar/Restaurant", "Yacht Club"].includes(poiSelected?.poi_type);

export const fuelDataFormatter = (poiSelected: IMapPois, isFuelEdit = false) => {
  const {
    gas, // bool
    gas_octane_87, // bool
    gas_octane_87_value,
    gas_octane_89, // bool
    gas_octane_89_value,
    gas_octane_93, // bool
    gas_octane_93_value,
    gas_octane_94_plus, // bool
    gas_octane_94_plus_value,
    diesel, // bool
    diesel_value,
  } = poiSelected;

  let fuelArray: TFuelFormmatedData[] = [
    {
      label: "87",
      visibility: gas_octane_87,
      value: gas_octane_87_value ?? "",
    },
    {
      label: "89",
      visibility: gas_octane_89,
      value: gas_octane_89_value ?? "",
    },
    {
      label: "93",
      visibility: gas_octane_93,
      value: gas_octane_93_value ?? "",
    },
    {
      label: "94+",
      visibility: gas_octane_94_plus,
      value: gas_octane_94_plus_value ?? "",
    },
    { label: "Diesel", visibility: diesel, value: diesel_value ?? "" },
  ];
  const gasObject = { label: "Gas", visibility: gas, value: "" };

  if (isFuelEdit) {
    return fuelArray.filter((value) => value.label !== "Diesel");
  }

  fuelArray = fuelArray.filter((value) => value.visibility === true);

  if (gas && !fuelArray.some((value) => value.label !== "Diesel")) {
    fuelArray.splice(0, 0, gasObject);
  }

  return fuelArray;
};
