import React, { useState, useEffect } from "react";
import { Stack, ToggleButtonGroup, ToggleButton, Button, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import LoopIcon from "@mui/icons-material/Loop";
import { CoordinatesFormats } from "@argonav/unit-system";

import SearchCoordinatesDD from "./SearchCoordinatesDD";
import SearchCoordinatesDDM from "./SearchCoordinatesDDM";
import SearchCoordinatesDMS from "./SearchCoordinatesDMS";
import SearchCoordinatesInput from "./SearchCoordinatesInput/SearchCoordinatesInput";
import { handleShowCoordinates } from "./utils";
import CoordinatesModal from "./CoordinatesModal";
import { styles } from "./styles";

import { addCenter, addZoom } from "@/store/slices/map";
import { DEFAULT_ZOOM } from "@/utils/maps";
import {
  coordPartRegex,
  coordPartRegexDD,
  decimalDegreesFormatRegex,
  degreesDecimalsMinutesFormatRegex,
  degreesMinutesSecondsFormatRegex,
  isNaNZero,
  decimalHandler,
  convertToLatLngFromDMS,
  convertToDMSFromLatLng,
} from "@/utils/globals";
import { TDDM, TDMS } from "@/types/map";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import {
  addPointSearchedByCoords,
  setRoutePointSetted,
  setCoordinatesValuesSetted,
} from "@/store/slices/route";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { setTempPinCopy } from "@/store/slices/reportpins";
import useDebounce from "@/hooks/useDebounce";
import { addSelectedPoiCopy } from "@/store/slices/search";

export const NAME_INPUT_LAT_DEGREE = "NAME_INPUT_LAT_DEGREE";
export const NAME_INPUT_LAT_MINUTES = "NAME_INPUT_LAT_MINUTES";
export const NAME_INPUT_LAT_SECONDS = "NAME_INPUT_LAT_SECONDS";

export const NAME_INPUT_LNG_DEGREE = "NAME_INPUT_LNG_DEGREE";
export const NAME_INPUT_LNG_MINUTES = "NAME_INPUT_LNG_MINUTES";
export const NAME_INPUT_LNG_SECONDS = "NAME_INPUT_LNG_SECONDS";

interface ISearchCoordinatesExpanded {
  coordinates?: string;
  onConfirmCoordinates: () => void;
}

export default function SearchCoordinatesExpanded({
  coordinates,
  onConfirmCoordinates,
}: ISearchCoordinatesExpanded) {
  const dispatch = useAppDispatch();
  const { creatingRoute, pointSearchedByCoords, routePointSetted } = useAppSelector(
    (state) => state.route,
  );

  const { tempPinCopy } = useAppSelector((state) => state.reportpins);
  const { selectedPoiCopy } = useAppSelector((state) => state.search);
  const coordinatesFormat = useAppSelector((state) => state.user.userOptions.coordinate_format);
  const [activeTab, setActiveTab] = React.useState(coordinatesFormat || "DD");
  const [latValue, setLat] = useState<string>("");
  const [lngValue, setLng] = useState<string>("");
  const debouncedLatValue = useDebounce(latValue, 500);
  const debouncedLngValue = useDebounce(lngValue, 500);
  const [latDDM, setLatDDM] = useState<TDDM>({ degrees: "", minutes: "" });
  const [lngDDM, setLngDDM] = useState<TDDM>({ degrees: "", minutes: "" });
  const [latDMS, setLatDMS] = useState<TDMS>({ degrees: "", minutes: "", seconds: "" });
  const [lngDMS, setLngDMS] = useState<TDMS>({ degrees: "", minutes: "", seconds: "" });
  const allCordinatesFormat = { activeTab, latValue, lngValue, latDDM, lngDDM, latDMS, lngDMS };

  const [isToggledYAxis, setIsToggledYAxis] = useState(false);
  const [isToggledXAxis, setIsToggledXAxis] = useState(false);

  const handleChangeHemisphere = (value: string) => {
    if (value === "") return value;

    return `${Number(value) * -1}`;
  };

  const cleanInput = (input: string, axis: boolean) => {
    const clean = input?.replace(",", ".").match(coordPartRegexDD)?.join("").replace("-", "");

    const append = axis ? "-" : "";

    if (clean) return `${append}${clean}`;

    return "";
  };

  const cleanInputMinutesSeconds = (input: string) => {
    const clean = input?.replace(",", ".").match(coordPartRegex)?.join("").replace("-", "");

    if (clean) return `${clean}`;

    return "";
  };

  const handleToggleYAxis = () => {
    setIsToggledYAxis(!isToggledYAxis);
    const newValue = handleChangeHemisphere(latValue);

    setLat(newValue);
    dispatch(setCoordinatesValuesSetted(true));
  };

  const handleToggleXAxis = () => {
    setIsToggledXAxis(!isToggledXAxis);
    const newValue = handleChangeHemisphere(lngValue);

    setLng(newValue);
  };

  const handleChange = (event: React.MouseEvent<HTMLElement>, activeTab: CoordinatesFormats) => {
    setActiveTab(activeTab);
  };

  const convertToDDM = (latitude: string, longitude: string) => {
    const degreeLat = parseInt(latitude, 10);
    const minutesLat = ((parseFloat(latitude) - parseInt(latitude, 10)) * 60).toFixed(4);

    setLatDDM({
      degrees: Number.isNaN(degreeLat) ? "" : Math.abs(degreeLat).toString(),
      minutes: Number.isNaN(Math.abs(Number(minutesLat)))
        ? ""
        : Math.abs(Number(minutesLat)).toString(),
    });

    const degreeLng = parseInt(longitude, 10);
    const minutesLng = ((parseFloat(longitude) - parseInt(longitude, 10)) * 60).toFixed(4);

    setLngDDM({
      degrees: Number.isNaN(degreeLng) ? "" : Math.abs(degreeLng).toString(),
      minutes: Number.isNaN(Math.abs(Number(minutesLng)))
        ? ""
        : Math.abs(Number(minutesLng)).toString(),
    });
    dispatch(setCoordinatesValuesSetted(true));
  };

  const convertToDMS = (latitude: string, longitude: string) => {
    const degreeLat = parseInt(latitude, 10);
    const minutesLat = ((parseFloat(latitude) - parseInt(latitude, 10)) * 60).toFixed(4);
    const secondsLat = ((Number(minutesLat) - parseInt(minutesLat, 10)) * 60).toFixed(2);

    setLatDMS({
      degrees: Number.isNaN(degreeLat) ? "" : Math.abs(degreeLat).toString(),
      minutes: Number.isNaN(Math.abs(parseInt(minutesLat, 10)))
        ? ""
        : Math.abs(parseInt(minutesLat, 10)).toString(),
      seconds: Number.isNaN(Math.abs(Number(secondsLat)))
        ? ""
        : Math.abs(Number(secondsLat)).toString(),
    });

    const degreeLng = parseInt(longitude, 10);
    const minutesLng = ((parseFloat(longitude) - parseInt(longitude, 10)) * 60).toFixed(4);
    const secondsLng = ((Number(minutesLng) - parseInt(minutesLng, 10)) * 60).toFixed(2);

    setLngDMS({
      degrees: Number.isNaN(degreeLng) ? "" : Math.abs(degreeLng).toString(),
      minutes: Number.isNaN(Math.abs(parseInt(minutesLng, 10)))
        ? ""
        : Math.abs(parseInt(minutesLng, 10)).toString(),
      seconds: Number.isNaN(Math.abs(Number(secondsLng)))
        ? ""
        : Math.abs(Number(secondsLng)).toString(),
    });
    dispatch(setCoordinatesValuesSetted(true));
  };

  const handleSetDDValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === NAME_INPUT_LAT_DEGREE) {
      if (coordPartRegexDD.test(e.target.value)) {
        const cleanedInput = cleanInput(e.target.value, isToggledYAxis);

        setLat(cleanedInput);
        dispatch(setCoordinatesValuesSetted(true));
        convertToDDM(cleanedInput, lngValue);
        convertToDMS(cleanedInput, lngValue);
      }
    } else if (coordPartRegexDD.test(e.target.value)) {
      const cleanedInput = cleanInput(e.target.value, !isToggledXAxis);

      setLng(cleanedInput);
      dispatch(setCoordinatesValuesSetted(true));
      convertToDDM(latValue, cleanedInput);
      convertToDMS(latValue, cleanedInput);
    }
  };

  const handleSetDDMValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === NAME_INPUT_LAT_DEGREE) {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLatDDM({ ...latDDM, degrees: cleanedInput });

      const minutes = Number(latDDM.minutes) / 60;

      let latitude = Math.abs(parseInt(cleanedInput, 10)) + Math.abs(parseFloat(String(minutes)));

      if (isToggledYAxis) {
        latitude *= -1;
      }

      setLat(Number.isNaN(latitude) ? "" : String(latitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDMS(String(latitude), lngValue);
    } else if (e.target.name === NAME_INPUT_LAT_MINUTES) {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLatDDM({ ...latDDM, minutes: cleanedInput });

      const minutes = Number(cleanedInput) / 60;

      let latitude = Math.abs(parseInt(latDDM.degrees, 10)) + Math.abs(parseFloat(String(minutes)));

      if (isToggledYAxis) {
        latitude *= -1;
      }

      setLat(Number.isNaN(latitude) ? "" : String(latitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDMS(String(latitude), lngValue);
    } else if (e.target.name === NAME_INPUT_LNG_DEGREE) {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLngDDM({ ...lngDDM, degrees: cleanedInput });

      const minutes = Number(lngDDM.minutes) / 60;

      let longitude = Math.abs(parseInt(cleanedInput, 10)) + Math.abs(parseFloat(String(minutes)));

      if (!isToggledXAxis) {
        longitude *= -1;
      }

      setLng(Number.isNaN(longitude) ? "" : String(longitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDMS(latValue, String(longitude));
    } else {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLngDDM({ ...lngDDM, minutes: cleanedInput });

      const minutes = Number(cleanedInput) / 60;

      let longitude =
        Math.abs(parseInt(lngDDM.degrees, 10)) + Math.abs(parseFloat(String(minutes)));

      if (!isToggledXAxis) {
        longitude *= -1;
      }

      setLng(Number.isNaN(longitude) ? "" : String(longitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDMS(latValue, String(longitude));
    }
  };

  const handleSetDMSValues = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === NAME_INPUT_LAT_DEGREE) {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLatDMS({ ...latDMS, degrees: cleanedInput });

      const minutes = Number(latDMS.minutes) / 60;
      const seconds = Number(latDMS.seconds) / 60 / 60;

      let latitude =
        Math.abs(parseInt(cleanedInput, 10)) +
        Math.abs(parseFloat(String(minutes))) +
        Math.abs(parseFloat(String(seconds)));

      if (isToggledYAxis) {
        latitude *= -1;
      }

      setLat(Number.isNaN(latitude) ? "" : String(latitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDDM(String(latitude), lngValue);
    } else if (e.target.name === NAME_INPUT_LAT_MINUTES) {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLatDMS({ ...latDMS, minutes: cleanedInput });

      const minutes = Number(cleanedInput) / 60;
      const seconds = Number(latDMS.minutes) / 60 / 60;

      let latitude =
        Math.abs(parseInt(latDMS.degrees, 10)) +
        Math.abs(parseFloat(String(minutes))) +
        Math.abs(parseFloat(String(seconds)));

      if (isToggledYAxis) {
        latitude *= -1;
      }

      setLat(Number.isNaN(latitude) ? "" : String(latitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDDM(String(latitude), lngValue);
    } else if (e.target.name === NAME_INPUT_LAT_SECONDS) {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLatDMS({ ...latDMS, seconds: cleanedInput });

      const minutes = Number(latDMS.minutes) / 60;
      const seconds = Number(cleanedInput) / 60 / 60;

      let latitude =
        Math.abs(parseInt(latDMS.degrees, 10)) +
        Math.abs(parseFloat(String(minutes))) +
        Math.abs(parseFloat(String(seconds)));

      if (isToggledYAxis) {
        latitude *= -1;
      }

      setLat(Number.isNaN(latitude) ? "" : String(latitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDDM(String(latitude), lngValue);
    } else if (e.target.name === NAME_INPUT_LNG_DEGREE) {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLngDMS({ ...lngDMS, degrees: cleanedInput });

      const minutes = Number(lngDMS.minutes) / 60;
      const seconds = Number(lngDMS.seconds) / 60 / 60;

      let longitude =
        Math.abs(parseInt(cleanedInput, 10)) +
        Math.abs(parseFloat(String(minutes))) +
        Math.abs(parseFloat(String(seconds)));

      if (!isToggledXAxis) {
        longitude *= -1;
      }

      setLng(Number.isNaN(longitude) ? "" : String(longitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDDM(latValue, String(longitude));
    } else if (e.target.name === NAME_INPUT_LNG_MINUTES) {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLngDMS({ ...lngDMS, minutes: cleanedInput });

      const minutes = Number(cleanedInput) / 60;
      const seconds = Number(lngDMS.minutes) / 60 / 60;

      let longitude =
        Math.abs(parseInt(lngDMS.degrees, 10)) +
        Math.abs(parseFloat(String(minutes))) +
        Math.abs(parseFloat(String(seconds)));

      if (!isToggledXAxis) {
        longitude *= -1;
      }

      setLng(Number.isNaN(longitude) ? "" : String(longitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDDM(latValue, String(longitude));
    } else {
      const cleanedInput = cleanInputMinutesSeconds(e.target.value);

      setLngDMS({ ...lngDMS, seconds: cleanedInput });

      const minutes = Number(lngDMS.minutes) / 60;
      const seconds = Number(cleanedInput) / 60 / 60;

      let longitude =
        Math.abs(parseInt(lngDMS.degrees, 10)) +
        Math.abs(parseFloat(String(minutes))) +
        Math.abs(parseFloat(String(seconds)));

      if (!isToggledXAxis) {
        longitude *= -1;
      }

      setLng(Number.isNaN(longitude) ? "" : String(longitude.toFixed(6)));
      dispatch(setCoordinatesValuesSetted(true));
      convertToDDM(latValue, String(longitude));
    }
  };

  const renderActiveForm = () => {
    switch (activeTab) {
      case CoordinatesFormats.DD:
        return (
          <SearchCoordinatesDD
            isToggledXAxis={isToggledXAxis}
            isToggledYAxis={isToggledYAxis}
            latValue={latValue}
            lngValue={lngValue}
            onHandleToggleXAxis={handleToggleXAxis}
            onHandleToggleYAxis={handleToggleYAxis}
            onSetCoordinatesValue={handleSetDDValues}
          />
        );
      case CoordinatesFormats.DDM:
        return (
          <SearchCoordinatesDDM
            isToggledXAxis={isToggledXAxis}
            isToggledYAxis={isToggledYAxis}
            latValue={latDDM}
            lngValue={lngDDM}
            onHandleToggleXAxis={handleToggleXAxis}
            onHandleToggleYAxis={handleToggleYAxis}
            onSetCoordinatesValue={handleSetDDMValues}
          />
        );
      case CoordinatesFormats.DMS:
        return (
          <SearchCoordinatesDMS
            isToggledXAxis={isToggledXAxis}
            isToggledYAxis={isToggledYAxis}
            latValue={latDMS}
            lngValue={lngDMS}
            onHandleToggleXAxis={handleToggleXAxis}
            onHandleToggleYAxis={handleToggleYAxis}
            onSetCoordinatesValue={handleSetDMSValues}
          />
        );
      default:
        return null;
    }
  };

  const handleSetPinCoordinates = () => {
    if (tempPinCopy) {
      if (debouncedLatValue && debouncedLngValue) {
        const lat = Number(debouncedLatValue);
        const lng = Number(debouncedLngValue);

        dispatch(
          setTempPinCopy({
            ...tempPinCopy,
            location: {
              lat,
              lng,
            },
          }),
        );
        dispatch(
          addCenter({
            lat,
            lng,
          }),
        );
      }
    }
  };

  const handleSearch = async () => {
    if (debouncedLatValue && debouncedLngValue) {
      const lat = Number(debouncedLatValue);
      const lng = Number(debouncedLngValue);

      dispatch(addPointSearchedByCoords({ lat, lng }));
      dispatch(addZoom(DEFAULT_ZOOM));
      dispatch(
        addCenter({
          lat,
          lng,
        }),
      );
    }
  };

  const handleSearchForRouting = () => {
    if (debouncedLatValue && debouncedLngValue) {
      const lat = Number(debouncedLatValue);
      const lng = Number(debouncedLngValue);

      dispatch(addPointSearchedByCoords({ lat, lng }));
      dispatch(addCenter({ lat, lng }));
    }
  };

  const handleSetPlaceCoordinates = () => {
    if (debouncedLatValue && debouncedLngValue) {
      const lat = Number(debouncedLatValue);
      const lng = Number(debouncedLngValue);

      dispatch(addSelectedPoiCopy({ ...selectedPoiCopy, lat, lng }));
    }
  };

  useEffect(() => {
    if (tempPinCopy) {
      handleSetPinCoordinates();
    } else if (creatingRoute) {
      handleSearchForRouting();
    } else if (selectedPoiCopy?.editMode) {
      handleSetPlaceCoordinates();
    } else {
      handleSearch();
    }
  }, [debouncedLatValue, debouncedLngValue]);

  const extractDecimalCoordinates = (input: string) => {
    const [lat, lng] = input.split(",").map((coord) => coord.trim());
    const cleanedInputLat = cleanInput(lat, isToggledYAxis);
    const cleanedInputLng = cleanInput(lng, !isToggledXAxis);

    setLat(cleanedInputLat);
    setLng(cleanedInputLng);
    dispatch(setCoordinatesValuesSetted(true));
    convertToDDM(cleanedInputLat, cleanedInputLng);
    convertToDMS(cleanedInputLat, cleanedInputLng);
  };

  const extractDegreesMinutesCoordinates = (input: string) => {
    const matches = degreesDecimalsMinutesFormatRegex.exec(input);

    if (!matches) return;

    const getCoordinateValues = (startIndex: number) => {
      const degrees = parseInt(matches[startIndex], 10);
      const minutes = parseFloat(matches[startIndex + 1]);
      const hemisphere = matches[startIndex + 2];

      return { degrees, minutes, hemisphere };
    };

    const latValues = getCoordinateValues(1);
    const lngValues = getCoordinateValues(4);

    const latLng = convertToLatLngFromDMS(latValues, lngValues);

    setLat(cleanInput(String(decimalHandler(latLng?.lat)), isToggledYAxis));
    setLng(cleanInput(String(decimalHandler(latLng?.lng)), !isToggledYAxis));
    dispatch(setCoordinatesValuesSetted(true));
    const { lat, lng } = convertToDMSFromLatLng(latLng.lat, latLng.lng);

    setLatDDM({
      degrees: isNaNZero(String(lat?.degrees)),
      minutes: isNaNZero(Math.abs(lat?.minutes).toFixed(4)),
    });
    setLngDDM({
      degrees: isNaNZero(String(lng?.degrees)),
      minutes: isNaNZero(Math.abs(lng?.minutes).toFixed(4)),
    });
    setLatDMS({
      degrees: isNaNZero(String(lat?.degrees)),
      minutes: isNaNZero(String(Math.abs(Math.trunc(lat?.minutes)))),
      seconds: isNaNZero(Math.abs(lat?.seconds).toFixed(2)),
    });
    setLngDMS({
      degrees: isNaNZero(String(lng?.degrees)),
      minutes: isNaNZero(String(Math.abs(Math.trunc(lng.minutes)))),
      seconds: isNaNZero(Math.abs(lng?.seconds).toFixed(2)),
    });
  };

  const extractDegreesMinutesSecondsCoordinates = (input: string) => {
    const matches = degreesMinutesSecondsFormatRegex.exec(input);

    if (!matches) return;

    const getCoordinateValues = (startIndex: number) => {
      const degrees = parseInt(matches[startIndex], 10);
      const minutes = parseInt(matches[startIndex + 1], 10);
      const seconds = parseFloat(matches[startIndex + 2]);
      const hemisphere = matches[startIndex + 3];

      return { degrees, minutes, seconds, hemisphere };
    };

    const latValues = getCoordinateValues(1);
    const lngValues = getCoordinateValues(5);

    const latLng = convertToLatLngFromDMS(latValues, lngValues);

    setLat(cleanInput(String(decimalHandler(latLng?.lat)), isToggledYAxis));
    setLng(cleanInput(String(decimalHandler(latLng?.lng)), !isToggledYAxis));
    dispatch(setCoordinatesValuesSetted(true));
    const { lat, lng } = convertToDMSFromLatLng(latLng.lat, latLng.lng);

    setLatDDM({
      degrees: isNaNZero(String(lat?.degrees)),
      minutes: isNaNZero(Math.abs(lat?.minutes).toFixed(4)),
    });
    setLngDDM({
      degrees: isNaNZero(String(lng?.degrees)),
      minutes: isNaNZero(Math.abs(lng?.minutes).toFixed(4)),
    });
    setLatDMS({
      degrees: isNaNZero(String(lat?.degrees)),
      minutes: isNaNZero(String(Math.abs(Math.trunc(lat?.minutes)))),
      seconds: isNaNZero(Math.abs(lat?.seconds).toFixed(2)),
    });
    setLngDMS({
      degrees: isNaNZero(String(lng?.degrees)),
      minutes: isNaNZero(String(Math.abs(Math.trunc(lng.minutes)))),
      seconds: isNaNZero(Math.abs(lng?.seconds).toFixed(2)),
    });
  };

  const handlePaste = async (event: React.ClipboardEvent) => {
    event.preventDefault();

    const clipboardText = await navigator.clipboard.readText();

    const clipboardTextTrimed = clipboardText.trim();

    if (decimalDegreesFormatRegex.test(clipboardTextTrimed)) {
      extractDecimalCoordinates(clipboardTextTrimed);
    } else if (degreesDecimalsMinutesFormatRegex.test(clipboardTextTrimed)) {
      extractDegreesMinutesCoordinates(clipboardTextTrimed);
    } else if (degreesMinutesSecondsFormatRegex.test(clipboardTextTrimed)) {
      extractDegreesMinutesSecondsCoordinates(clipboardTextTrimed);
    } else {
      dispatch(
        setSnackBarMsjSucceded({
          state: true,
          type: "error",
          msj: (
            <Stack alignItems="center" direction="row" spacing={4}>
              <Typography variant="body2">Invalid Coordinate</Typography>
              <CoordinatesModal invalidValue={clipboardText} />
            </Stack>
          ),
        }),
      );
    }
  };

  const handleCopy = () => {
    const clipboardText = handleShowCoordinates(
      allCordinatesFormat,
      isToggledYAxis ? "S" : "N",
      isToggledXAxis ? "E" : "W",
    );

    if (clipboardText) navigator.clipboard.writeText(clipboardText);
  };

  const handleCleanCoordinates = () => {
    setLat("");
    setLng("");
    setLatDDM({ degrees: "", minutes: "" });
    setLngDDM({ degrees: "", minutes: "" });
    setLatDMS({ degrees: "", minutes: "", seconds: "" });
    setLngDMS({ degrees: "", minutes: "", seconds: "" });
    dispatch(setCoordinatesValuesSetted(false));
    dispatch(addPointSearchedByCoords(null));
    dispatch(setRoutePointSetted(false));
  };

  const handleCleanPinCoordinates = () => {
    setLat("");
    setLng("");
    setLatDDM({ degrees: "", minutes: "" });
    setLngDDM({ degrees: "", minutes: "" });
    setLatDMS({ degrees: "", minutes: "", seconds: "" });
    setLngDMS({ degrees: "", minutes: "", seconds: "" });
    dispatch(setCoordinatesValuesSetted(false));
  };

  useEffect(() => {
    if ((!creatingRoute && !pointSearchedByCoords) || routePointSetted) {
      handleCleanCoordinates();
    }
  }, [pointSearchedByCoords, creatingRoute, routePointSetted]);

  useEffect(
    () => () => {
      handleCleanCoordinates();
    },
    [],
  );

  useEffect(() => {
    if (coordinates) {
      if (decimalDegreesFormatRegex.test(coordinates)) {
        extractDecimalCoordinates(coordinates);
      } else if (degreesDecimalsMinutesFormatRegex.test(coordinates)) {
        extractDegreesMinutesCoordinates(coordinates);
      } else if (degreesMinutesSecondsFormatRegex.test(coordinates)) {
        extractDegreesMinutesSecondsCoordinates(coordinates);
      }
    }
  }, [coordinates]);

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "v") {
        handlePaste(event as unknown as React.ClipboardEvent);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Stack component="form" direction="column" pt={3} spacing={2}>
      <Stack alignItems="stretch" direction="row" px={2} spacing={2} sx={{ width: "100%" }}>
        <ToggleButtonGroup
          exclusive
          fullWidth
          aria-label="Platform"
          color="primary"
          size="small"
          value={activeTab}
          onChange={handleChange}
        >
          <ToggleButton value={CoordinatesFormats.DD}>DD</ToggleButton>
          <ToggleButton value={CoordinatesFormats.DDM}>DDM</ToggleButton>
          <ToggleButton value={CoordinatesFormats.DMS}>DMS</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Stack pb={0.5} px={2}>
        {renderActiveForm()}
      </Stack>
      <Stack direction="row" justifyContent="center" px={2} spacing={1}>
        <Button
          color="primary"
          disabled={!debouncedLatValue || !debouncedLngValue}
          startIcon={<LoopIcon />}
          sx={styles.successButton}
          variant="outlined"
          onClick={tempPinCopy ? handleCleanPinCoordinates : handleCleanCoordinates}
        >
          Clear
        </Button>
        <Button
          color="primary"
          disabled={!debouncedLatValue || !debouncedLngValue}
          startIcon={<Check />}
          sx={styles.successButton}
          variant="contained"
          onClick={onConfirmCoordinates}
        >
          Confirm Location
        </Button>
      </Stack>

      <SearchCoordinatesInput
        coordinates={allCordinatesFormat}
        isToggledXAxis={isToggledXAxis}
        isToggledYAxis={isToggledYAxis}
        onCopy={handleCopy}
        onPaste={handlePaste}
      />
    </Stack>
  );
}
