import { Stack, Box, Typography, Divider, CardMedia } from "@mui/material";
import { useState } from "react";

import { styles } from "./styles";

import { IMapPois } from "@/types/pois";
import PhotoModal from "@/components/Modals/PhotoModal";
import useDisclosure from "@/hooks/useDisclosure";
import ImageSlider from "@/components/Slider/ImageSlider";

interface IPlaceInfoDescription {
  poiSelected: IMapPois;
}

function PlaceInfoDescription({ poiSelected }: IPlaceInfoDescription) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [imageFullScreen, setImageFullScreen] = useState<string | null>(null);
  const [selectedSlider, setSelectedSlider] = useState<number | undefined>(undefined);

  const handleOpenFullScreen = (url: string, index?: number) => {
    setImageFullScreen(url);
    onOpen();
    if (index) setSelectedSlider(index);
  };

  const handleCloseFullScreen = () => {
    setImageFullScreen(null);
    setSelectedSlider(undefined);
    onClose();
  };

  return (
    <>
      {poiSelected?.assets?.length > 0 && (
        <Stack
          p={2}
          spacing={1}
          sx={styles.container}
          // pb={poiSelected?.poi_type !== "Private Dock" ? 0 : 2}
        >
          {poiSelected?.assets?.length > 1 ? (
            <ImageSlider
              selectedSlider={selectedSlider}
              withoutFeatures={poiSelected.assets.length <= 2}
            >
              {poiSelected?.assets?.map((asset: any, index: number) => (
                <CardMedia
                  key={`${asset?.url || asset.download_url}`}
                  alt={`${poiSelected?.name}`}
                  component="img"
                  image={`${asset?.url || asset.download_url}` || "/img/placeholders/Image.png"}
                  sx={styles.cardMedia}
                  onClick={() => handleOpenFullScreen(asset?.url || asset.download_url, index)}
                />
              ))}
            </ImageSlider>
          ) : (
            <CardMedia
              alt={`${poiSelected?.name}`}
              component="img"
              image={
                `${poiSelected?.assets?.[0]?.url || poiSelected?.assets?.[0]?.download_url}` ||
                "/img/placeholders/Image.png"
              }
              sx={styles.cardMediaSingle}
              onClick={() =>
                handleOpenFullScreen(
                  poiSelected?.assets?.[0]?.url || poiSelected?.assets?.[0]?.download_url,
                )
              }
            />
          )}
        </Stack>
      )}
      {poiSelected?.description && poiSelected?.poi_type !== "Private Dock" && (
        <Stack justifyContent="flex-start" p={2} pt={0} width="100%">
          <Typography variant="body1">{poiSelected?.description}</Typography>
        </Stack>
      )}
      {(poiSelected?.number_of_slips || poiSelected?.clean_marina) && (
        <Box p={2}>
          {poiSelected.number_of_slips && (
            <Typography variant="body1">
              Total Number of Slips: {poiSelected?.number_of_slips}
            </Typography>
          )}
          {poiSelected?.clean_marina && (
            <Typography variant="body1">Certified Clean Marina.</Typography>
          )}
        </Box>
      )}
      <Divider />
      <PhotoModal
        assets={poiSelected?.assets}
        open={isOpen && !!imageFullScreen}
        selectedSlider={selectedSlider}
        url={`${imageFullScreen}` || "/img/placeholders/Image.png"}
        onClose={handleCloseFullScreen}
      />
    </>
  );
}

export default PlaceInfoDescription;
