export const styles = {
  container: {
    position: "relative",
  },

  // badge: {
  //   "& .MuiBadge-badge": {
  //     backgroundColor: "white",
  //   },
  // },
};
