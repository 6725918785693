import { alpha, styled } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import { ImportContacts } from "@mui/icons-material";
import Button, { ButtonProps } from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";

import WorldMapOutlined from "@/components/MarkersIcon/WorldMapOutlined";
import usePremiumUser from "@/hooks/usePremiumUser";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setPremiumSubscriptionModal, setSignInModal } from "@/store/slices/user";
import { setCoords } from "@/store/slices/map";
import { setSelectedTrip } from "@/store/slices/captainslog/actions";
import { addRouteCreated } from "@/store/slices/route";

const SideMenuButton = styled((props: ButtonProps) => <Button {...props} />)(({ theme }) => ({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  width: "100%",
  color: theme.palette.action.active,
  minHeight: "68px",
  transform: "scale(.96)",
  transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",

  "& .MuiTypography-caption": {
    marginTop: theme.spacing(0.25),
    paddingLeft: theme.spacing(0.75),
    paddingRight: theme.spacing(0.75),
    lineHeight: "normal",
    width: "100%",
    fontWeight: 700,
  },

  "&.Mui-active": {
    color: theme.palette.primary.main,
    backgroundColor: `${alpha(theme.palette.primary.light, 0.12)}`,
    transform: "scale(1)",
  },

  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: `${alpha(theme.palette.primary.light, 0.3)}`,
    transform: "scale(1)",
  },
}));

export default function SideMenuButtons() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isPremium = usePremiumUser();
  const { coords } = useAppSelector((state) => state.map);
  const { logged } = useAppSelector((state) => state.auth);
  const { tripEditMode } = useAppSelector((state) => state.captainslog);

  const handleNavigatePremium = (path: string) => {
    if (isPremium) {
      navigate(path);
      if (coords) dispatch(setCoords(undefined));
    } else if (logged && !isPremium) {
      dispatch(setPremiumSubscriptionModal(true));
    } else {
      dispatch(setSignInModal(true));
    }
  };

  const handleNavitageMap = () => {
    if (tripEditMode) {
      window.location.reload();
    } else {
      dispatch(setSelectedTrip(null));
      dispatch(addRouteCreated(null));
      navigate("/");
    }
  };

  return (
    <Stack direction="column" spacing={1.25} sx={{ px: 1.25 }}>
      <SideMenuButton
        className={location.pathname === "/" && !tripEditMode ? "Mui-active" : ""}
        disabled={location.pathname === "/" && tripEditMode}
        onClick={handleNavitageMap}
      >
        <WorldMapOutlined />
        <Typography component="span" variant="caption">
          Map
        </Typography>
      </SideMenuButton>

      <SideMenuButton
        className={location.pathname === "/captains-log" || tripEditMode ? "Mui-active" : ""}
        disabled={tripEditMode}
        onClick={() => handleNavigatePremium("/captains-log")}
      >
        <ImportContacts />
        <Typography component="span" variant="caption">
          Captain&apos;s Log
        </Typography>
      </SideMenuButton>
    </Stack>
  );
}
