import { createSlice } from "@reduxjs/toolkit";

import { IMapPois } from "@/types/pois";
import { TPOI_TYPE } from "@/utils/poi";

export interface ISearch {
  loading: boolean;
  selectedPoi: IMapPois | null;
  selectedPoiCopy: IMapPois | null;
  editPoiCoordinates: boolean;
  openDrawer: boolean;
  editedPlace: boolean;
  hoveredPoi: IMapPois | null;
  selectedPoiFromMap: boolean | undefined;
  poiList: IMapPois[] | null;
  selectedCategories: TPOI_TYPE[];
  debouncedQuery: string;
  openAccordion: boolean;
  showSearchArea: boolean;
  page: number;
}

const initialState: ISearch = {
  loading: false,
  debouncedQuery: "",
  openDrawer: false,
  editedPlace: false,
  selectedPoi: null,
  selectedPoiCopy: null,
  editPoiCoordinates: false,
  hoveredPoi: null,
  selectedPoiFromMap: false,
  poiList: null,
  selectedCategories: [],
  openAccordion: false,
  showSearchArea: false,
  page: 1,
};

const searchSlice = createSlice({
  name: "searchSlice",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    addSelectedPoi: (state, action) => {
      state.selectedPoi = action.payload;
    },
    addSelectedPoiCopy: (state, action) => {
      state.selectedPoiCopy = action.payload;
    },
    addHoveredPoi: (state, action) => {
      state.hoveredPoi = action.payload;
    },
    setEditedPlace: (state, action) => {
      state.editedPlace = action.payload;
    },
    addSelectedPoiFromMap: (state, action) => {
      state.selectedPoiFromMap = action.payload;
    },
    addPoiList: (state, action) => {
      state.poiList = action.payload;
    },
    addCategory: (state, action) => {
      state.selectedCategories = action.payload;
    },
    addDebouncedQuery: (state, action) => {
      state.debouncedQuery = action.payload;
    },
    setOpenAccordion: (state, action) => {
      state.openAccordion = action.payload;
    },
    setShowSearchArea: (state, action) => {
      state.showSearchArea = action.payload;
    },
    addPage: (state, action) => {
      state.page = action.payload;
    },
    setOpenDrawer: (state, action) => {
      state.openDrawer = action.payload;
    },
    setEditPoiCoordinates: (state, action) => {
      state.editPoiCoordinates = action.payload;
    },
  },
});

export const {
  addSelectedPoi,
  addSelectedPoiCopy,
  addHoveredPoi,
  addPoiList,
  addCategory,
  addDebouncedQuery,
  setLoading,
  addSelectedPoiFromMap,
  setOpenAccordion,
  setShowSearchArea,
  addPage,
  setOpenDrawer,
  setEditPoiCoordinates,
  setEditedPlace,
} = searchSlice.actions;

export default searchSlice.reducer;
