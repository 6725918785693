import defaultTheme from "@/themes/theme";

export const styles = {
  icon: {
    width: "20px",
    height: "20px",
    background: "white",
    color: defaultTheme.palette.primary.light,
    borderRadius: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 400,
  },

  iconSelected: {
    width: "20px",
    height: "20px",
    background: "white",
    color: defaultTheme.palette.primary.light,
    borderRadius: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 400,
    border: "2px solid",
    borderColor: defaultTheme.palette.primary.light,
  },

  iconDragged: {
    width: "30px",
    height: "30px",
  },
};
