import { PathLayer } from "@deck.gl/layers";

import { Position, Trip } from "@/types/map";
import { colorForDepth, validateNoDepthRoute } from "@/utils/route";
import { UserInformation } from "@/types/users";
import { setDepthAlert } from "@/store/slices/route";

type BartLine = {
  name: number;
  color: string;
  path: [longitude: number, latitude: number][];
};

export function getRouteLayer(
  routeCreated: Trip | null,
  userInformation: UserInformation | undefined,
  zoom: number,
  dispatch: any,
) {
  if (!routeCreated) return null;

  const handleGeneratePath = (routeCreated: Trip) => {
    const pathFormatted: BartLine[] = [];

    let currentRoute: [longitude: number, latitude: number][] = [];
    let noDepth = false;

    if (routeCreated) {
      noDepth = validateNoDepthRoute(routeCreated?.planned_coords);

      routeCreated.planned_coords.forEach((item: Position, i: number) => {
        if (item?.longitude !== undefined && item?.latitude !== undefined) {
          const nextItem = routeCreated.planned_coords[i + 1];
          const isLastItem = i === routeCreated.planned_coords.length - 1;

          if (
            !isLastItem &&
            nextItem?.longitude !== undefined &&
            nextItem?.latitude !== undefined
          ) {
            currentRoute.push([item.longitude, item.latitude]);
            currentRoute.push([nextItem.longitude, nextItem.latitude]);

            if (
              colorForDepth(userInformation?.current_vessel, noDepth, nextItem.depth) !==
              colorForDepth(userInformation?.current_vessel, noDepth, item.depth)
            ) {
              pathFormatted.push({
                name: i + 1,
                color: colorForDepth(
                  userInformation?.current_vessel,
                  noDepth,
                  item.depth,
                  item.transparent,
                ),
                path: currentRoute,
              });
              currentRoute = [];
            }
          } else {
            pathFormatted.push({
              name: i + 1,
              color: colorForDepth(
                userInformation?.current_vessel,
                noDepth,
                item.depth,
                item.transparent,
              ),
              path:
                currentRoute.length > 0
                  ? [...currentRoute, [item.longitude, item.latitude]]
                  : [[item.longitude, item.latitude]],
            });
          }
        }
      });
    }

    if (noDepth) dispatch(setDepthAlert(noDepth));

    return pathFormatted;
  };

  const layer = new PathLayer<BartLine>({
    id: "PathLayer",
    data: handleGeneratePath(routeCreated),

    getColor: (d: BartLine) => {
      if (!d.color) return [0, 0, 0] as any;
      const hex = d.color;

      // convert to RGB
      return hex.match(/[0-9a-f]{2}/g)?.map((x) => parseInt(x, 16));
    },
    getPath: (d: BartLine) => d.path,
    getWidth: () => {
      const baseWidth = 100;
      const referenceZoom = 13;
      const width = baseWidth * 2 ** (referenceZoom - zoom);

      return width;
    },
    capRounded: true,
    pickable: true,
  });

  return layer;
}
