import AdvancedMarker from "../AdvancedMarker";
import { getPoiIcon } from "../Utils/PoiIcons";

import BridgeMapIcon from "./BridgeMapIcon";

import { IconKeysPois } from "@/types/markers";
import { IMapPois } from "@/types/pois";

interface IEditPoi {
  poi: IMapPois;
}

function EditPoi({ poi }: IEditPoi) {
  /**
   * This function evaluate if the icon bridge has clearance or not.
   * @param poi IMapPois
   * @returns React.ReactNode or String
   */
  const evaluateIcons = (poi: IMapPois) => {
    const iconBridgeType =
      poi?.poi_type === "Bridge"
        ? poi?.info?.ver_clr !== null &&
          poi?.info?.ver_clr !== undefined &&
          poi?.info?.ver_clr !== "" &&
          poi?.info?.ver_clr !== "0" &&
          poi?.info?.ver_clr !== "0.0"
          ? IconKeysPois.BridgeClearance
          : IconKeysPois.Bridge
        : null;

    if (iconBridgeType === IconKeysPois.BridgeClearance) {
      return <BridgeMapIcon infoIcon={poi} />;
    }

    return getPoiIcon(poi.poi_type as IconKeysPois);
  };

  return (
    <AdvancedMarker
      selected
      icon={evaluateIcons(poi)}
      position={{ lat: Number(poi.lat), lng: Number(poi.lng) }}
      onClick={() => null}
    />
  );
}

export default EditPoi;
