import { useState } from "react";
import { TextField, Button, Stack, Typography, CircularProgress } from "@mui/material";
import { useFormik } from "formik";

import BaseModal from "@/components/Modals/BaseModal";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { setSnackBarMsjSucceded } from "@/store/slices/auth/actions";
import { Trip } from "@/types/map";
import tripsService from "@/services/trips";
import socialService from "@/services/social";
import uploadAsset from "@/utils/uploadAsset";
import { ITripMode } from "@/types/route";
import { generateWaypoints } from "@/components/Route/Route";

type SaveRoute = {
  name: string;
  description: string;
};

interface ISaveRouteForm {
  open: boolean;
  routeCreated: Trip;
  onClose: () => void;
  onCleanRouteStatus: () => void;
  onTakeScreenshot: () => Promise<File | null>;
}

export default function SaveRouteForm({
  open,
  routeCreated,
  onClose,
  onCleanRouteStatus,
  onTakeScreenshot,
}: ISaveRouteForm) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { startPoint, endPoint, waypoints } = useAppSelector((state) => state.route);

  const handleSubmit = async (values: SaveRoute) => {
    try {
      const payloadData = {
        name: values.name,
        description: values.description,
        publication_status: "published",
        save_mode: ITripMode.DRAFT,
        waypoints: generateWaypoints(startPoint, endPoint, waypoints),
      };
      const { status } = await tripsService.updateTrip(payloadData, routeCreated.id);

      if (status === 200) {
        const { status: statusConfirm } = await tripsService.confirmUpdate(routeCreated.id);

        if (statusConfirm === 200) {
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "success",
              msj: "Route successfully saved to Captain's Log",
            }),
          );
        } else {
          dispatch(
            setSnackBarMsjSucceded({
              state: true,
              type: "warning",
              msj: "Route successfully saved to Captain's Log without waypoints",
            }),
          );
        }

        onCleanRouteStatus();
        const routeScreenShot = await onTakeScreenshot();

        if (routeScreenShot instanceof File && routeCreated.id) {
          const assetResponse = await socialService.createVoyageImage({
            voyageId: routeCreated.id,
            fileName: routeScreenShot.name,
            kind: "map",
          });

          const presignedUrl = assetResponse.upload_url;

          await uploadAsset({ url: presignedUrl, file: routeScreenShot });
        }
      }
      setLoading(false);
      onClose();
    } catch (error: any) {
      dispatch(setSnackBarMsjSucceded({ state: true, type: "error", msj: error.toString() }));
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    } as SaveRoute,
    onSubmit: async (values: SaveRoute) => {
      setLoading(true);
      handleSubmit(values);
    },
  });

  return (
    <BaseModal open={open} size="xs" title="Save to Captain’s Log" onClose={onClose}>
      <Stack component="form" spacing={2} sx={{ px: 3, pb: 2 }} onSubmit={formik.handleSubmit}>
        <Typography color="text.secondary" variant="body1">
          Add a route name and description.
        </Typography>

        <Stack spacing={2}>
          <TextField
            fullWidth
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            label="Route Name"
            name="name"
            placeholder="e.g. St. Michaels, Maryland"
            value={formik.values.name ?? ""}
            onChange={formik.handleChange}
          />
          <TextField
            fullWidth
            multiline
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
            label="Route Description"
            minRows={4}
            name="description"
            placeholder="e.g. An awesome trip on the Chesapeake Bay with friends"
            value={formik.values.description ?? ""}
            onChange={formik.handleChange}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <Button
            color="whiteBg"
            sx={{ color: "primary.main" }}
            type="submit"
            variant="outlined"
            onClick={onClose}
          >
            Go Back
          </Button>
          <Button color="primary" disabled={loading} type="submit" variant="contained">
            {loading ? <CircularProgress size={30} sx={{ color: "white" }} /> : "Save & Exit"}
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
}
