const sliderButtons = {
  position: "absolute",
  backgroundColor: "common.white",
  top: "40%",
  transform: "translate(0, -40%)",
  width: "unset",
  height: "unset",
  "&:hover": {
    backgroundColor: "common.white",
    "& .MuiSvgIcon-root": {
      color: "primary.dark",
    },
  },
  "&:before": {
    content: "none",
  },
  "&:focus": {
    backgroundColor: "common.white",
  },
  "&.slick-disabled": {
    backgroundColor: "common.white",
    "& .MuiSvgIcon-root": {
      color: "grey.400",
    },
  },
};

export const styles = {
  container: {
    "& .slick-dots": {
      position: "unset",
      mt: -1,
    },

    "& .slick-dots li": {
      margin: 0,
    },

    "& .slick-dots li button::before": {
      fontSize: "9px",
      color: "grey.300",
      opacity: 1,
    },

    ".slick-dots li.slick-active button:before": {
      color: "primary.main",
      opacity: 1,
    },
  },

  nextArrow: {
    ...sliderButtons,
    right: 0,
    borderRadius: "100px 0px 0px 100px",
    pr: 0,
  },

  nextArrowFullscreen: {
    ...sliderButtons,
    top: "50%",
    transform: "translate(0, -50%)",
    right: -40,
    boxShadow: 4,
  },

  prevArrow: {
    ...sliderButtons,
    left: 0,
    borderRadius: "0px 100px 100px 0px",
    pl: 0,
    zIndex: 99,
  },

  prevArrowFullscreen: {
    ...sliderButtons,
    top: "50%",
    transform: "translate(0, -50%)",
    left: -40,
    zIndex: 99,
    boxShadow: 4,
  },
};
