import { layoutStyles } from "@/themes/layoutStyles";

export const styles = {
  notificationButton: {
    height: 32,
    width: 32,
    boxShadow: 4,
  },
  link: {
    display: "flex",
    direction: "row",
    alignItems: "center",
  },

  lineWithIcon: {
    display: "flex",
    mt: 0.5,
    "& svg": {
      mr: 0.5,
      color: "action.active",
    },
  },

  dateExpiration: {
    fontWeight: 700,
  },

  chipWithIcon: {
    pl: 0.5,
    pt: "4px",
    pb: "4px",
    height: "auto",
    background: "#0277BD14",
    color: "#0277BD",
    fontWeight: 700,
    position: "absolute",
    top: 20,
    right: 14,
    "& .MuiSvgIcon-root": {
      color: "action.active",
    },
    "& .MuiChip-label": {
      pl: 0.25,
    },
  },

  alignIcons: {
    display: "flex",
    alignItems: "center",
  },

  paper: {
    position: "fixed",
    top: 70,
    right: 12,
    width: "408px",
    overflow: "hidden",
    zIndex: 1,
  },

  scrollContainer: {
    ...layoutStyles.scroll,

    overflowY: "auto",
    maxHeight: "calc(100vh - 140px)",

    "& .menuListItemError": {
      color: "error.main",

      "& .MuiListItemIcon-root, & .MuiSvgIcon-root": {
        color: "error.main",
      },
    },
  },

  accountItemsContainer: {
    p: 3,
    pt: 1,
  },

  accountItems: {
    border: "1px solid",
    borderColor: "divider",
    backgroundColor: "background.paper",
    borderRadius: 1,
    "& .MuiListItem-root:last-of-type": {
      borderBottom: "none",
    },
  },

  listItemLabel: {
    color: "text.secondary",
    lineHeight: "normal",
  },

  listItemInfo: {
    color: "text.primary",
    lineHeight: "normal",
  },
};
